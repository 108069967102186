import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkAccess from 'helpers/checkAccess';

import PreviewScreenLayout from './components/PreviewScreenLayout';
import propsToData from '../../helpers/propsToData';
import signRequired from '../../helpers/signRequired';

class PreviewScreen extends React.Component {
    state = { storeEventError: null };

    handleFinish = async () => {
        const { handleFinish } = this.props;

        if (!handleFinish) {
            return;
        }

        const result = await handleFinish();

        if (result instanceof Error) {
            this.setState({ storeEventError: result });
        }
    }

    render() {
        const { storeEventError } = this.state;
        const { backToEdit, busy, setBusy, userUnits, userInfo } = this.props;
        const { task, template, steps, template: { jsonSchema: { pdfRequired } } } = propsToData(this.props);

        const { signerUsers, performerUnits } = task;

        const showSignerList = signRequired(template, task) && signerUsers && !!signerUsers.length;
        const isUserUnitHead = checkAccess({ isUserUnitHead: performerUnits }, userInfo, userUnits);

        return (
            <PreviewScreenLayout
                task={task}
                template={template}
                storeEventError={storeEventError}
                busy={busy}
                setBusy={setBusy}
                handleFinish={this.handleFinish}
                backToEdit={backToEdit}
                pdfRequired={pdfRequired}
                isUserUnitHead={isUserUnitHead}
                showSignerList={showSignerList}
                steps={steps}
            />
        );
    }
}

PreviewScreen.propTypes = {
    backToEdit: PropTypes.func.isRequired,
    busy: PropTypes.bool.isRequired,
    setBusy: PropTypes.func.isRequired,
    userUnits: PropTypes.array.isRequired,
    userInfo: PropTypes.object.isRequired,
    handleFinish: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth: { userUnits, info } }) => ({ userUnits, userInfo: info });

export default connect(mapStateToProps)(PreviewScreen);
