import React, { Fragment } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

interface StatusTypeProviderProps {
    for: Array<string>;
}

export const StatusTypeProvider: React.FC<StatusTypeProviderProps> = props => (
    <DataTypeProvider
        {...props}    
        formatterComponent={({value}) => <Fragment>
                {typeof value === 'string' 
                    ? value 
                    : (value || {}).name || '' /* {Number(value).toLocaleString('uk-UA', { style: 'decimal', maximumFractionDigits: 2 })} */}
            </Fragment>}
        />
);
