import React, { FC, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRoles } from '@eis/hooks/useFundStaff';
import { SchemaForm } from '@core/components/JsonSchema';


export interface InventoryPartProps {
    inventory: Array<object>;
    schema: object;
}

export const InventoryPart: FC<InventoryPartProps> = props => {
    const { schema, inventory } = props;
    const roles = useRoles();

    return (
        <Box paddingTop={2}>
            {(inventory.length === 0)
                ? <Alert severity="warning">Немає збережених анкетних даних</Alert>
                : <Fragment>
                    <SchemaForm
                        value={(inventory[0] as any).data}
                        readOnly={true}
                        schema={schema} />
                    {roles.admin &&
                        <Fragment>
                            <Typography variant="h6">This part is draft</Typography>
                            <pre>{JSON.stringify(inventory[0], null, 2)}</pre>
                        </Fragment>
                    }
                </Fragment>
            }
        </Box>
    )
}