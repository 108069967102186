import React from 'react';
import { translate } from 'react-translate';
import _ from 'lodash/fp';

import DataTable from 'components/DataTable';
import EmptyPage from 'components/EmptyPage';

import dataTableConnect from 'services/dataTable/connectWithOwnProps';
import dataTableAdapter from 'services/dataTable/adapter';

import CreateTaskButton from '@cab/modules/tasks/components/CreateTaskButton';
import dataTableSettings from '@cab/modules/workflow/pages/WorkflowList/variables/dataTableSettings';

import currentText from './currentText';


const WorkflowTable = (props) => {
    const {
        t,
        count,
        filters,
        checkable,
        loading,
        actions: { load },
        handleItemClick,
        TableToolbar
    } = props;

    if (count === 0 && !filters.filtered && !filters.name && !loading) {
        const text = currentText(filters);
        return (
            <EmptyPage
                title={t(text.title)}
                description={t(text.description)}
            >
                <CreateTaskButton /> 
            </EmptyPage>
        );
    }

    const settings = dataTableSettings({ t, filters, actions: { load } });

    return (
        <DataTable
            {..._.merge(settings, dataTableAdapter(props, settings))}
            checkable={checkable}
            onRowClick={handleItemClick}
            CustomToolbar={TableToolbar}
        />
    );
};

const translated = translate('WorkflowListPage')(WorkflowTable);
export default dataTableConnect(translated);
