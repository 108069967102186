import React from 'react';
import { createStyles, IconButton, makeStyles, Tooltip, TableCell } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';

const useStyles = makeStyles(theme => createStyles({
    cell: {
        padding: `2px 4px`,
    },
    rightColumn: {
        position: 'sticky',
        right: 0,
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0.7)'
    },
    headColumn: {
        fontSize: 0,
        '&>:nth-child(2)': {
            display: 'none'
        }
    }
}))

interface ActionsColumnProps extends TableFixedColumns.CellProps {
    handleClick: (el: any, row: any) => void,
    hint: string
}

export const ActionsColumn: React.FC<ActionsColumnProps> = props => {
    const classes = useStyles();
    const { handleClick, hint, ...rest } = props;

    const selfHandleClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.stopPropagation();
        handleClick(ev.currentTarget, rest.tableRow!.row);
    }

    const rowType = rest.tableRow!.type.toString();

    if (rowType === 'Symbol(data)') {
        return (
            <TableCell className={`${classes.cell} ${classes.rightColumn}`}>
                <Tooltip title={hint} >
                    <IconButton
                        onClick={selfHandleClick} >
                        <MoreIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        )
    }

    // HEADER
    if (rowType === 'Symbol(heading)') {
        return (
          <TableFixedColumns.Cell
              {...rest}
              className={`
                  MuiTableCell-root
                  MuiTableCell-head
                  ${classes.rightColumn}
                  ${classes.headColumn}
              `}
          />
        )
    }

    // other
    return <td/>
}
