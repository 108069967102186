import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from '../../styles';

/**
 * Компонент для отображения заголовка таба с иконкой
 */
export const TabLabel = (props: { Icon: React.FC; title: string; }) => {
    const { Icon, title } = props;
    const classes = useStyles();

    return (
        <Box className={classes.flexRow}>
            <Icon />
            <span className={classes.tabTitle}>{title}</span>
        </Box>
    );
};
