import React from 'react';
import { translate } from 'react-translate';

import {
    Paper,
    withStyles
} from '@material-ui/core';

import {
    DataTypeProvider,
    CustomPaging,
    PagingState,
    SearchState
} from '@devexpress/dx-react-grid';

import {
    Grid,
    Table,
    Toolbar,
    TableHeaderRow,
    PagingPanel,
    ColumnChooser,
    DragDropProvider,
    TableColumnVisibility,
    TableColumnReordering,
    TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';

import TimeLabel from 'components/Label/Time';
import Preloader from 'components/Preloader';
import ErrorScreen from 'components/ErrorScreen';

import endPoint from '../../../../../endPoints/registryHistory';
import dataTableConnect from 'services/dataTable/connect';

import evalate from 'helpers/evalate';
import storage from 'helpers/storage';
import processList from 'services/processList';

import RegistryModal from './RegistryModal';

const styles = { flex: { flex: 1 } };

const TableRow = handleClick => ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        onClick={() => handleClick(row)}
        style={{ cursor: 'pointer' }}
    />
);

const initialState = t => ({
    error: null,
    columns: [{
        name: 'operation',
        title: t('Operation')
    }, {
        name: 'data',
        title: t('Name')
    }, {
        name: 'createdBy',
        title: t('CreatedBy')
    }, {
        name: 'createdAt',
        title: t('CreatedAt')
    }],
    tableColumnExtensions: [
        { columnName: 'createdBy', align: 'right' }
    ],
    columnWidths: [
        { columnName: 'operation', width: 120 },
        { columnName: 'data', width: 640 },
        { columnName: 'createdBy', width: 240 },
        { columnName: 'createdAt', width: 160 }
    ],
    columnOrder: ['createdAt', 'operation', 'data', 'createdBy'],
    hiddenColumns: [],
    customColumns: []
});

class RegistryHistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState(initialState(props.t), props);
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        const { filters: { keyId }, selectedKey } = this.props;
        if (selectedKey && keyId !== selectedKey.id) {
            this.init();
        }
    }

    componentWillReceiveProps(nextProps) {
        const defaultProps = initialState(nextProps.t);
        this.setState(this.propsToState(defaultProps, nextProps), this.forceUpdate);
    }

    init = async () => {
        const { t, actions, selectedKey } = this.props;

        if (selectedKey && !processList.has('RegistryHistoryTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true)) {
            actions.clearFilters();
            const result = await processList.hasOrSet('RegistryHistoryTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true);
            this.setState({ error: (result instanceof Error) ? new Error(t(result.message)) : null });
        }
    }

    hiddenColumnsNames = () => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { hiddenColumns } = registryTableSettings[selectedKey.id] || {};

        return hiddenColumns || ['createdBy', 'createdAt'];
    }

    onHiddenColumnNamesChange = (hiddenColumnNames) => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');

        registryTableSettings[selectedKey.id] = {
            ...(registryTableSettings[selectedKey.id] || {}),
            hiddenColumnNames
        };

        storage.setItem('registryTableSettings', JSON.stringify(registryTableSettings));
    }

    propsToState = (defaultProps, { selectedKey }) => {
        if (!selectedKey || !selectedKey.schema) {
            return defaultProps;
        }

        const customColumns = Object.keys(selectedKey.schema.properties || {})
            .filter(propertyName => !['object', 'array'].includes(selectedKey.schema.properties[propertyName].type));

        return {
            columns: [
                ...defaultProps.columns,
                ...customColumns.map(propertyName => ({
                    name: propertyName,
                    title: selectedKey.schema.properties[propertyName].description,
                    hidden: !!selectedKey.schema.properties[propertyName].hidden
                }))
            ],
            tableColumnExtensions: defaultProps.tableColumnExtensions,
            columnWidths: [
                ...defaultProps.columnWidths,
                ...customColumns.map(propertyName => ({
                    columnName: propertyName,
                    width: 240
                }))
            ],
            columnOrder: [
                ...defaultProps.columnOrder,
                ...customColumns
            ],
            customColumns
        };
    };

    // componentWillReceiveProps(nextProps) {
    //     const defaultProps = initialState(nextProps.t);
    //     this.setState(this.propsToState(defaultProps, nextProps), this.forceUpdate);
    // }

    deleteHiddenColumns = array => array.filter(item => !item.hidden)

    setColumnOrder = columnOrder => this.setState({ columnOrder });

    setColumnWidths = columnWidths => this.setState({ columnWidths });

    renderGrid() {
        const { columns, tableColumnExtensions, columnWidths, columnOrder, customColumns, error } = this.state;
        const { t, data, selectedKey, rowsPerPage, page, loading, count, actions } = this.props;

        const tableMessages = { noData: t('NoData') };
        const tableData = selectedKey ? data : [];
        const pagingPanelMessages = {
            info: t('Pagination')
        };
        const columnChooserMessages = {
            showColumnChooser: t('ChooseColumns')
        };

        if (loading) {
            return <Preloader />;
        }

        if (error) {
            return <ErrorScreen error={error} />;
        }

        return (
            <Grid
                rows={Array.isArray(tableData) ? tableData : []}
                columns={this.deleteHiddenColumns(columns) || []}
            >
                <DataTypeProvider
                    for={['createdAt']}
                    formatterComponent={({ row }) => (row.createdAt ? <TimeLabel date={row.createdAt} /> : null)}
                />
                <DataTypeProvider
                    for={['operation']}
                    formatterComponent={({ row }) => t(row.operation)}
                />
                <DataTypeProvider
                    for={['data']}
                    formatterComponent={({ row }) => {
                        if (!selectedKey) {
                            return null;
                        }

                        const content = evalate(selectedKey.toString, row.data);

                        if (content instanceof Error) {
                            content.commit({
                                type: 'registry',
                                selectedKey
                            });

                            return null;
                        }

                        return content || null;
                    }}
                />
                <DataTypeProvider
                    key={customColumns}
                    for={customColumns}
                    formatterComponent={({ row, column }) => row.data.data[column.name] || null}
                />
                <SearchState />
                <PagingState
                    currentPage={page}
                    onCurrentPageChange={newPage => actions.onChangePage(newPage - 1)}
                    pageSize={rowsPerPage}
                    onPageSizeChange={actions.onChangeRowsPerPage}
                />
                <CustomPaging
                    totalCount={count || 0}
                />
                <DragDropProvider />
                <Table
                    messages={tableMessages}
                    columnExtensions={tableColumnExtensions}
                    rowComponent={TableRow(record => this.setState({ selectedRecord: record }))}
                />
                <TableColumnReordering
                    order={columnOrder}
                    onOrderChange={this.setColumnOrder}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={this.setColumnWidths}
                />
                <TableHeaderRow showSortingControls={false} />
                <PagingPanel
                    messages={pagingPanelMessages}
                    pageSizes={[10, 50, 100]}
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={this.hiddenColumnsNames()}
                    onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                />
                <Toolbar />
                <ColumnChooser
                    messages={columnChooserMessages}
                />
            </Grid>
        );
    }

    render() {
        const { selectedRecord } = this.state;
        const { selectedKey } = this.props;

        return (
            <Paper>
                {this.renderGrid()}
                {selectedRecord ? (
                    <RegistryModal
                        open={!!(selectedKey && selectedRecord)}
                        selected={selectedKey || {}}
                        value={((selectedRecord && selectedRecord.data) || {})}
                        handleClose={() => this.setState({ selectedRecord: null })}
                    />
                ) : null}
            </Paper>
        );
    }
}

const styled = withStyles(styles)(RegistryHistoryTable);
const translated = translate('RegistryPage')(styled);
export default dataTableConnect(endPoint)(translated);
