import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 0)
    },
    title: {
        paddingBottom: theme.spacing()
    },
    rowMetric: {
        ...theme.typography.body1,
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '1.2rem',
        marginBottom: theme.spacing(0.5)
    },
    table: {
        minWidth: 650,
    },
    hint: {
        fontSize: '0.95rem',
        cursor: 'help'
    },
    metricName: {},
    metricValue: {
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing()
    }
}));