import React from 'react';
import { createStyles, makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
    paperM: {
        margin: theme.spacing(2)
    },
    paperP: {
        padding: theme.spacing(2)
    },
    whiteBg: {
        background: 'white'
    }
}))

interface CleanLayoutProps {
    margin?: boolean;
    padding?: boolean; 
    white?: boolean;
}

export const CleanLayout: React.FC<CleanLayoutProps> = ({children, margin, padding, white}) => {
    const classes = useStyles();
    return (
        <Box className={`${margin ? classes.paperM : ''} ${padding ? classes.paperM : ''} ${white ? classes.whiteBg : ''}`}>
            {children}
        </Box>
    );
}
