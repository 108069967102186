// TODO: вынести на уровень приложения создание произвольных mime
export const CUSTOM_MIME: Array<{mime: string; ext: string}> = [
    { mime: 'application/avk-smeta', ext: '.ims,.imd,.imp,.bsdu,.ibd,.bds,.bpdu,.bdcu' }
];

/**
 * Заменяет не существующие(служебные) mime-типы на список расширений
 * @param accept 
 * @returns 
 */
export  const makeMimeExtensions = (accept: string): string => {
    let result = accept;
    for (const r of CUSTOM_MIME ) {
        result = result.replace(r.mime, r.ext);
    }

    return result;
}