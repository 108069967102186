import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CheckIcon from '@material-ui/icons/Check';
import WaitIcon from '@material-ui/icons/QueryBuilderOutlined';
import BlockIcon from '@material-ui/icons/Block';
import { Button, Typography, withStyles } from '@material-ui/core';
import DataTable from 'components/DataTable';
// import { searchUsers } from 'actions/users';
import { deleteSignatures } from '@cab/actions/task';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

const styles = {
    signers: {
        paddingLeft: 10,
        paddingTop: 40,
        marginBottom: -15
    },
    button: {
        margin: '20px 40px',
    }
}

const dataTableConfig = ({ t, authInfo }) => ({
    controls: {
        pagination: false,
        toolbar: false,
        search: false,
        header: true,
        refresh: false,
        switchView: false,
        toggleWidth: true
    },
    columns: [{
        id: 'signed',
        width: 48,
        render: (value, { signed, rejected }) => {
            if (rejected) {
                return <BlockIcon style={{ color: 'red' }} />;
            }
            if (signed) {
                return <CheckIcon color="primary" />;
            }
            return <WaitIcon color="secondary" />;
        }
    }, {
        id: 'name',
        // name: t('Signers'),
        render: (value, { userId }) => {
            const me = userId === authInfo.userId ? ` (${t('You')})` : '';
            return (value ? (value.split(' ').map(capitalizeFirstLetter).join(' ') + me) : null);
        }
    }]
});


const SignerList = ({ t, authInfo, classes, actions, task: { finished, documentId, isMePerformer, signerUsers, signerUserNames, document: { signatures, signatureRejections } } }) => {
    const tableData = Array.isArray(signerUserNames)
        ? signerUserNames.map((signer, index) => ({
            name: signer,
            userId: signerUsers[index],
            signed: !!(signatures || []).find(({ createdBy }) => createdBy === signerUsers[index]),
            rejected: !!(signatureRejections || []).find(({ userId }) => userId === signerUsers[index])
        }))
        : signerUserNames;

    return (
        <>
            <Typography variant="body1" className={classes.signers}>{t('Signers')}</Typography>
            <DataTable
                data={tableData}
                {...dataTableConfig({ t, authInfo })}
            />
            {((signatures && signatures.length) || (signatureRejections && signatureRejections.length)) && isMePerformer && !finished ? (
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => actions.deleteSignatures(documentId)}
                >
                    {t('DeleteSignatures')}
                </Button>
            ) : null}
        </>
    );
};

const mapsStateToProps = ({
    auth: { info },
}) => ({ authInfo: info });

const mapDispatchToProps = dispatch => ({
    actions: {
        deleteSignatures: bindActionCreators(deleteSignatures, dispatch)
    }
});

const styled = withStyles(styles)(SignerList);
const translated = translate('TaskPage')(styled);
export default connect(mapsStateToProps, mapDispatchToProps)(translated);
