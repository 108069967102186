import React from 'react';
import { translate } from 'react-translate';

import { Popper, withStyles, List, ListItem, Paper, ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface IComponentFilterHandlersProps {
  classes: any;
  rootRef: HTMLInputElement | null;
  anchorEl: HTMLInputElement;
  filterHandlers: any;
  activeFilter: string;
  setActiveFilter: (filter: string | null) => void;
  filters: any;
  onClose: () => void;
  onFilterChange: (filters: any) => void;
}

const defaultProps: Partial<IComponentFilterHandlersProps> = {
  rootRef: null,
  filterHandlers: {},
  setActiveFilter: () => null,
  filters: {},
  onClose: () => null,
  onFilterChange: () => null
};

const styles = {
  popper: {
    zIndex: 10
  },
  list: {
    padding: 0
  }
};

const FilterHandlers: React.FC<IComponentFilterHandlersProps> = props => {
  const {
    classes, filterHandlers, setActiveFilter, filters,
    activeFilter, onClose, onFilterChange, anchorEl, rootRef
  } = props;

  const handleChange = (filterValue: string) => {
    onFilterChange({
      ...filters,
      [activeFilter]: filterValue
    });

    onClose();
  }

  const renderContent = () => {
    if (activeFilter) {
      const FilterHandler = filterHandlers[activeFilter];
      return (
        <>
          <List component="nav" className={classes.list}>
            <ListItem button={true} onClick={() => setActiveFilter(null)}>
              <ListItemIcon>
                <ArrowBackIcon />
              </ListItemIcon>
              <ListItemText primary={<FilterHandler type="name" />} />
            </ListItem>
          </List>
          <FilterHandler value={filters[activeFilter]} onChange={handleChange} />
        </>
      );
    }

    return (
      <List component="nav" className={classes.list}>
        {Object.keys(filterHandlers).filter(filterName => !filters[filterName]).map((filterName, index) => {
          const FilterHandler = filterHandlers[filterName];
          return (
            <ListItem key={index} button={true} onClick={() => setActiveFilter(filterName)}>
              <ListItemIcon>
                <FilterHandler type="icon" />
              </ListItemIcon>
              <ListItemText primary={<FilterHandler type="name" />} />
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      container={rootRef}
      className={classes.popper}
      disablePortal={true}
      placement="bottom-start"
      modifiers={{
        flip: {
          enabled: false
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent'
        }
      }}
    >
      <Paper>
        {renderContent()}
      </Paper>
    </Popper>
  );
}

FilterHandlers.defaultProps = defaultProps;

const styled = withStyles(styles)(FilterHandlers);
export default translate('DataTable')(styled);
