import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { Preloader } from '@eis/components';
// Редекларация в компонентах почему-то теряет контекст
import { AppHeaderContext } from '@eis/components/AppHeaderContext'; 

import TaskTable from './TaskTable';

class TaskListLayout extends React.Component {
    static contextType = AppHeaderContext;

    componentDidMount() {
        this.context.setTitle(this.props.title);
    }

    renderContent() {
        const { templates, data, endPoint, handleItemClick, TableToolbar } = this.props;

        if (templates === null || data === null) {
            return <Preloader />;
        }

        return (
            <TaskTable
                endPoint={endPoint}
                handleItemClick={handleItemClick}
                CustomToolbar={TableToolbar}
            />
        );
    }
    render() {
        return this.renderContent()
    }
}

TaskListLayout.propTypes = {
    templates: PropTypes.array,
    endPoint: PropTypes.object.isRequired,
    TableToolbar: PropTypes.instanceOf(React.Component),
    checkable: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

TaskListLayout.defaultProps = {
    templates: null,
    TableToolbar: null,
    checkable: false,
    title: null,
    loading: false,
    handleItemClick: () => null
};

export default translate('TaskListPage')(TaskListLayout);
