interface IDownloadFileName {
    fileName?: string, 
    description?: string, 
    scanDocumentName?: string
}

export default function downloadBase64Attach({ fileName, description, scanDocumentName }: IDownloadFileName, blob: string | Blob) {
    const { document, URL } = window;

    let url = blob;

    if (blob instanceof Blob) {
        url = URL.createObjectURL(blob);
    }

    const element = document.createElement('a');
    element.setAttribute('href', url as any);
    element.setAttribute('download', fileName || description || scanDocumentName || 'file');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    setTimeout(() => {
        document.body.removeChild(element);
    }, 500)
}
