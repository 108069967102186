class ChangeEvent {

    constructor(
        public data: any = null, 
        public force = false, 
        public hard = false ) {
    }
}

export default ChangeEvent;
