import { pickBy, isUndefined, isNull } from "lodash";

/**
 * Удаляет все свойства которые равны undefined и возвращает новый "без мусора" объект
 * @param original Оригинальный объект
 */
export function cleanUndefined(original: object) {
    return pickBy(original, p => !isUndefined(p)); 
} 

/**
 * Удаляет все свойства которые равны undefined или null и возвращает новый "без мусора" объект
 * @param original Оригинальный объект
 */
 export function cleanNoIdent(original: object) {
    return pickBy(original, p => !(isUndefined(p) || isNull(p))); 
} 