import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import Scrollbar from 'components/Scrollbar';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import TreeList from 'components/TreeList';
import { loadWorkflowTemplates, loadWorkflowCategories } from '@cab/actions/workflow';
import SelectEntryTaskDialog from './SelectEntryTaskDialog';
import arrayToTree from 'array-to-tree';
import sortArray from 'sort-array';

const styles = theme => ({
    helpText: {
        color: '#7e7e7e'
    },
    listWrapp: {
        marginTop: 15,
        '@media screen and (max-width: 425px)': {
            padding: 0
        }
    },
    title: {
        fontSize: '1.5rem',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightMedium
    },
    noPadding: {
        paddingTop: '0 !important',
        paddingBottom: 0
    }
});

const categoryTree = (list, firstLevelId = null) => arrayToTree(list.map(item => ({
    ...item,
    parentId: item.parentId === firstLevelId ? 0 : item.parentId
})), {
    customID: 'id',
    parentProperty: 'parentId',
    childrenProperty: 'items'
});

const templatesToSelectItems = ({
    id,
    workflowTemplateCategoryId,
    name,
    ...rest
}) => ({
    ...rest,
    description: name,
    itemId: id,
    parentId: workflowTemplateCategoryId
});

const sortItems = array => {
    if (!array) return [];

    const recursive = arr => {
        arr.forEach(({ items }) => {
            if (!items) return;

            sortArray(items, {
                by: 'trimmed',
                order: 'asc',
                computed: {
                    trimmed: item => (item.description || '').trim() + (item.name || '').trim()
                }
            });

            recursive(items);
        });
    };

    recursive(array);

    return array;
};

class CreateTaskDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { search: '', selected: null };
    }

    async componentDidMount() {
        const { templates, categories, actions } = this.props;

        if (templates === null) await actions.loadWorkflowTemplates();
        if (categories === null) await actions.loadWorkflowCategories();
    }

    filterTemplate = ({ name }) => {
        const { search } = this.state;
        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');
        return regex.test(name);
    };

    handleSearch = ({ target: { value } }) => this.setState({ search: value });

    formTree = () => {
        const { templates, categories } = this.props;
        const { search } = this.state;

        if (templates === null || categories === null) return [];

        const filteredTemplates = templates.filter(({ isActive }) => isActive).filter(({ entryTaskTemplateIds }) => Array.isArray(entryTaskTemplateIds) && entryTaskTemplateIds.filter(({ hidden }) => !hidden).length);

        if (search.length) {
            return filteredTemplates.filter(this.filterTemplate).map(templatesToSelectItems);
        }

        const filteredCategories = categories
            .filter(({ id }) => filteredTemplates
                .some(({ workflowTemplateCategoryId }) => workflowTemplateCategoryId === id) || categories
                    .some(({ parentId }) => parentId === id && filteredTemplates
                        .some(({ workflowTemplateCategory }) => workflowTemplateCategory && workflowTemplateCategory.parentId === id)));

        return categoryTree([].concat(
            filteredCategories,
            filteredTemplates.map(templatesToSelectItems)
        ));
    };

    createLink = ({ id, itemId, entryTaskTemplateIds }) => {
        if (id) {
            return null;
        }

        if (entryTaskTemplateIds && entryTaskTemplateIds.length) {
            if (entryTaskTemplateIds.length > 1) {
                return null;
            }
            return '/tasks/create/' + itemId + '/' + entryTaskTemplateIds[0].id;
        }

        return '/tasks/create/' + itemId;
    };

    handleEntryPointSelect = selected => this.setState({ selected });

    render() {
        const { t, classes, open, handleClose } = this.props;
        const { search, selected } = this.state;

        return (
            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={'md'}
                    fullWidth={true}
                    className={classes.modal}
                >
                    <DialogTitle
                        disableTypography={true}
                        classes={
                            {
                                root: classes.title
                            }
                        }
                        id="select-template"
                    >
                        {t('SelectTemplate')}
                    </DialogTitle>
                    <DialogTitle className={classes.noPadding}>
                        <TextField
                            autoFocus={true}
                            onChange={this.handleSearch}
                            value={search}
                            fullWidth={true}
                            margin="normal"
                            label={t('SearchTemplate')}
                            id="search-template"
                            variant="standard"
                            type="search"
                            InputProps={
                                {
                                    startAdornment: <SearchIcon />
                                }
                            }
                        />
                        <Typography className={classes.helpText} variant="caption" gutterBottom={true}>
                            {t('SearchTemplateHelpText')}
                        </Typography>
                    </DialogTitle>
                    <Scrollbar>
                        <DialogContent className={classes.noPadding}>
                            <TreeList
                                id="create-task-dialog-tree"
                                items={sortItems(this.formTree())}
                                createLink={this.createLink}
                                onChange={this.handleEntryPointSelect}
                            />
                        </DialogContent>
                    </Scrollbar>
                    <DialogActions>
                        <Button onClick={handleClose} id="create-task-dialog-close-btn">
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <SelectEntryTaskDialog
                    open={!!selected}
                    template={selected}
                    onClose={() => this.setState({ selected: null })}
                />
            </>
        );
    }
}

const mapStateToProps = ({ workflowTemplate }) => ({
    templates: workflowTemplate.list,
    categories: workflowTemplate.categories
});

const mapDispatchToProps = dispatch => ({
    actions: {
        loadWorkflowTemplates: bindActionCreators(loadWorkflowTemplates, dispatch),
        loadWorkflowCategories: bindActionCreators(loadWorkflowCategories, dispatch)
    }
});

const translated = translate('CreateTaskDialog')(CreateTaskDialog);
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(translated));
