import React  from 'react';
import { PaperLayout } from './PageLayouts';

interface ErrorBoundaryProps { };
interface ErrorBoundaryState { 
    error: Error | null;  
    errorInfo: any
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
  
    // static getDerivedStateFromError(error) {
    //   // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    //   return { hasError: true };
    // }
  
    componentDidCatch(error: Error, errorInfo: any) {
      // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
      this.setState({error, errorInfo});
    }
  
    render() {
      if (this.state.error) {
        // Можно отрендерить запасной UI произвольного вида
        return (
          <PaperLayout>
            <h2>Помилка</h2>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              <code>
              {this.state.errorInfo.componentStack}
              </code>
            </div>
          </PaperLayout>
        );
      }
  
      return this.props.children; 
    }
  }