import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Card, CardActionArea, CardContent, CardHeader, Avatar, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import HomeIcon from '@material-ui/icons/HomeWork';
import moment from 'moment';

import { RegistryKeysEnum } from '@eis/consts';
import { requestRegisterKeyRecords } from '../../../../../actions/registry';

interface CardProjectProps {
    id: string;
    classes: Record<string, string>;
    title: string;
    projectNumber: string;
    date: string;
    description: string;
    handleClick: (id: string) => void;
}
export const CardProject: React.FC<CardProjectProps> = props => {
    const { classes, id, title, projectNumber, description, date, handleClick } = props;
    const [strDate, setStrDate] = useState<string>('-');
    const dispatch = useDispatch();
    
    useEffect(() => {
        const loadRequests = async () => {
            if (id) {
                const options = {
                    data_like: { projectRecordId: id }
                }

                // Заявки по проекту
                const requests = await requestRegisterKeyRecords(RegistryKeysEnum.Requests, options)(dispatch);
                if (requests.meta && requests.meta.count) {
                    setStrDate(moment(requests['0'].data.requestDate, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY'));
                }
            }
        };

        loadRequests();
        // eslint-disable-next-line
    }, [id]);

    const dateJoin = date
        ? <Fragment><br/>Дата приєднання: {date}</Fragment>
        : null;

    return (
        <Grid item xs={6}>
            <Card className={classNames([classes.cardProject])}>
                <CardActionArea onClick={() => handleClick(projectNumber)}>
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={
                            <Avatar className={classes.avatar}>
                                <HomeIcon />
                            </Avatar>
                        }
                        title={projectNumber}
                        subheader={<Typography variant={'caption'}>Дата подачі: {strDate}{dateJoin}</Typography>}
                    />
                    <CardContent>
                        <Typography variant="body1" className={classes.body}>
                            {title}
                        </Typography>
                        <Typography variant="body2" className={classes.body}>
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};
