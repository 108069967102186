import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { useLocation } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { Collapse, List } from '@material-ui/core';

// import { CategoryHeader, NavigatorItem as Item } from '@eis/components';
import Scrollbar from 'components/Scrollbar';
import { INavigatorItemInfo } from '@eis/types';
import { MenuItem } from './Menuitem';
import SideBarTheme from './SideBarTheme';


// const useStyles = makeStyles(theme => createStyles({
//     list: {
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(2)
//     },
//     item: {
//         paddingLeft: 0,
//         paddingTop: 6,
//         paddingBottom: 6,
//         paddingRight: 0
//     },
//     itemCategory: {
//         boxShadow: '0 -1px 0 #404854 inset',
//         paddingTop: 16,
//         paddingBottom: 16
//     },
//     firebase: {
//         fontSize: 24,
//         fontFamily: theme.typography.fontFamily,
//         color: theme.palette.common.white
//     },
//     itemActionable: {
//         '&:hover': {
//             backgroundColor: 'rgba(255, 255, 255, .1)'
//         },
//         '& svg': {
//             fill: '#eee',
//             backgroundColor: 'rgba(255, 255, 255, .1)'
//         }
//     },
//     itemActiveItem: {
//         color: '#4fc3f7'
//     },
//     itemPrimary: {
//         color: 'inherit',
//         fontSize: theme.typography.fontSize,
//         '&$textDense': {
//             fontSize: theme.typography.fontSize
//         }
//     },
//     divider: {
//         marginTop: theme.spacing(2)
//     },
//     icon: {
//         position: 'relative',
//         top: '-1px',
//         '& svg': {
//             fontSize: 23
//         }
//     },
//     sidebarWrapper: {
//         height: '100%',
//         position: 'fixed',
//         '& > div': {
//             minHeight: '100vh'
//         }
//     }
// }));


interface NavigatorProps {
    menu: Array<INavigatorItemInfo>,
    handleMenuExpand?: any,

}

export const Navigator: FC<NavigatorProps> = props => {
    // const classes = useStyles();
    const t = useTranslate('Navigator');
    
    const expandMenu: Record<string, boolean> = useSelector((state: {app: any}) => state.app ? state.app.expandMenu || {} : {});
    // const [expandState, setState] = useState<Record<string, boolean>>({})

    const location = useLocation();
    const { menu, handleMenuExpand } = props;

    const renderItem = (item: INavigatorItemInfo, dense = false) => {
        if (item.Component) {
            return <item.Component />
        }
        
        const asCategory = item.children && item.children.length > 0;

        return (
            <MenuItem
                handleClick={asCategory ? () => handleMenuExpand(item.id!) : undefined}
                path={item.path}
                selected={location.pathname === item.path}
                dense={dense}
                title={t(item.title || item.id)}
                icon={item.icon}
                asCategory={asCategory} 
                open={asCategory && expandMenu[item.id!]}
                />
        )
    }

    const renderChilds = (parent: INavigatorItemInfo) => {
        return (
            <Collapse in={expandMenu[parent.id!]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {parent.children!.map((item, idx) => <Fragment key={idx}>{renderItem(item, true)}</Fragment>)}
                </List>
            </Collapse>
        )
    }


    return (
        <ThemeProvider theme={SideBarTheme}>
            <ScrollbarWrapper options={{ suppressScrollX: true }}>
                <List component="nav">
                    {menu.map((category, categoryKey) => (
                        <Fragment key={categoryKey}>
                            {renderItem(category)}
                            {category.children && category.children.length > 0 && renderChilds(category)}
                        </Fragment>
                    ))}
                </List>
            </ScrollbarWrapper>
        </ThemeProvider>
    )
}

const ScrollbarWrapper: React.FC<{ options?: any }> = props => {
    const { children, ...allProps } = props;
    return <Scrollbar {...allProps}><Fragment>{children}</Fragment></Scrollbar>
}