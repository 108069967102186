import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core';


const  useStyles = makeStyles(theme => createStyles({
    moreLink: {
        padding: '2px 6px',
        backgroundColor: theme.palette.primary.main,
        fontSize: '80%',
        color: 'white',
        borderRadius: '2px',
        textDecoration: 'none',
        '& :active': {
            textDecoration: 'none'
        }
    }
}));

interface DetailButtonProps {
    text: string,
    to: string;
}

export const MoreButton: FC<DetailButtonProps> = props => {
    const classes = useStyles();
    const { text, to } = props;
return <Link to={to}><span className={classes.moreLink}>{text}</span></Link>
}