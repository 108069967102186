import React, { Fragment } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

interface CurrencyTypeProviderProps {
    for: Array<string>;
}

export const CurrencyTypeProvider: React.FC<CurrencyTypeProviderProps> = props => (
    <DataTypeProvider
        {...props}    
        formatterComponent={({value}) => <Fragment>
                {Number(value).toLocaleString('uk-UA', { style: 'decimal', maximumFractionDigits: 2 })}
            </Fragment>}
        />
);
