import { IModuleInfo } from '@eis/types';
import MessagePage from './pages/Message';
import MessageListPage from './pages/MessageList';
import { MessageNotify } from './components/Notify';
import config from 'config/index'

const module: IModuleInfo = {
    appbar: [{
        component: config.notifications === undefined || config.notifications ? MessageNotify : undefined
    }],
    routes: [{
        path: '/messages/:messageId',
        component: MessagePage
        // access: { isUnitedUser: false, unitHasAccessTo: 'navigation.messages.MessageListPage' }
    }, {
        path: '/messages',
        component: MessageListPage,
        title: 'InboxTitle',
        defaultFilters: { deleted: 0 }
        // access: { isUnitedUser: false, unitHasAccessTo: 'navigation.messages.MessageListPage' }
    }]
};

export default module;