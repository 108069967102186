import React from 'react';
import {
    Template,
    TemplatePlaceholder,
    Plugin,
    TemplateConnector
} from '@devexpress/dx-react-core';
import {
    Button
} from '@material-ui/core';


interface CreateNewRecordButtonProps {
    label: string,
    disabled: boolean,
    onClick: any
}

export const CreateNewRecordButton: React.FC<CreateNewRecordButtonProps> = props => {
    const { label, onClick, disabled } = props;
    return (
        <Plugin name="CreateNewRecordButton">
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                <TemplateConnector>
                    {() => (
                        <Button
                            size="large"
                            variant="outlined"
                            id="add-new-row"
                            disabled={disabled}
                            onClick={onClick}
                        >
                            {label}
                        </Button>
                    )}
                </TemplateConnector>
            </Template>
        </Plugin>
    );
}