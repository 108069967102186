/** Тип для указания элементов управления на тулбаре редактора и поддерживаемом функционале */
export interface EditorToolbarOptions {
    /** Підтримка Вирівнювання */
    align?: boolean;
    
    /** Підтримка блоку в лапках */
    blockquote?: boolean;
    
    /** Підтримка блоку "код". Синтаксичне підсвічування не підтримується */
    codeBlock?: boolean;

    /** Підтримка кольору тексту та фону */
    color?: boolean;
    
    /** Підтримка кольору тексту */
    colorBg?: boolean;
    
    /** Підтримка кольору фону */
    colorText?: boolean;
    
    /** Підтримка всіх типів зображення */
    decoration?: boolean;
    
    /** Підтримка жирного зображення */
    decorationBold?: boolean;
    
    /** Підтримка похилого зображення */
    decorationItalic?: boolean;
    
    /** Підтримка перекреслення */
    decorationStrike?: boolean;
    
    /** Підтримка підкреслення */
    decorationUnderline?: boolean;

    /** Підтримка вибору типу шрифту*/
    font?: boolean;
    
    /** Підтримка вибору заголовка (теги h1, h2 тощо) */
    header?: boolean;
    
    /** Підтримка відступів */
    indent?: boolean;

    /** Підтримка нумерованих та ненумерованих списків */
    list?: boolean;
    
    /** Підтримка не нумерованих списків */
    listBullet?: boolean;
    
    /** Підтримка нумерованих списків */
    listOrdered?: boolean;

    /** Підтримка над/під рядкового тексту */
    scriptLine?: boolean;
    
    /** Підтримка різного розміру шрифту */
    size?: boolean;
}

export const defaultEditorToolbarOptions: EditorToolbarOptions = {
    header: true,
    decoration: true,
    align: true,
    list: true,
    indent: true
}


export const quillModules = (options: EditorToolbarOptions = defaultEditorToolbarOptions) => {
    const toolbar = new Array<Array<any>>(); 
    
    // block 1
    let block = new Array<any>();
    if (options.header) block.push( {'header': [false, 1, 2, 3, 4, 5]} );  // toggled drop buttons
    if (options.font) block.push( { 'font': [] } );
    if (options.size) block.push( { 'size': ['small', false, 'large', 'huge'] } );
    if (block.length > 0) toolbar.push(block);

    // block 2
    if (options.align) toolbar.push( [{'align': []}] );  // toggled drop buttons
    
    // block 3
    block = new Array<any>();
    if (options.color || options.colorText ) block.push({ 'color': []});
    if (options.color || options.colorBg ) block.push({'background': []});
    if (block.length > 0) toolbar.push(block);

    // block 4
    block = new Array<any>();
    if (options.decoration || options.decorationBold ) block.push('bold');
    if (options.decoration || options.decorationItalic ) block.push('italic');
    if (options.decoration || options.decorationUnderline) block.push('underline');
    if (options.decoration || options.decorationStrike) block.push('strike');
    if (block.length > 0) toolbar.push(block);

    // block 5
    if (options.scriptLine) toolbar.push([{ 'script': 'sub'}, { 'script': 'super' }]);
    
    // block 6
    block = new Array<any>();
    if (options.list || options.listOrdered ) block.push({'list': 'ordered'});
    if (options.list || options.listBullet ) block.push({'list': 'bullet'});
    if (options.indent ) block.push({'indent': '-1'}, {'indent': '+1'});
    if (block.length > 0 ) toolbar.push(block);
    
    // block 7 
    block = new Array<any>();
    if (options.blockquote) block.push('blockquote');
    if (options.codeBlock) block.push('code-block');
    if (block.length > 0 ) toolbar.push(block);

    if (toolbar.length > 0) toolbar.push(['clean']);
    
    return { toolbar: toolbar.length > 0 ? toolbar : null }; 
};

export const quillFormats = (options: EditorToolbarOptions = defaultEditorToolbarOptions) => {
    const formats = new Set<string>();
    
    Object.keys(options).forEach(key => {
        switch (key) {
            case 'codeBlock': 
                if (options.codeBlock) formats.add('code-block');
                break;

            case 'listOrdered':
            case 'listBullet':    
                if ((options as Record<string, boolean>)[key]) formats.add('list'); 
                break;

            case 'color':
            case 'colorText':    
                if ((options as Record<string, boolean>)[key]) formats.add('color'); 
                if (options.color) formats.add('background'); 
                break;
            
            case 'colorBg':    
                if (options.colorBg) formats.add('background'); 
                break;

            case 'decoration': 
                if (options.decoration) { 
                    formats.add('bold'); 
                    formats.add('italic'); 
                    formats.add('underline'); 
                    formats.add('strike'); 
                }
            break;


            default: 
                if ((options as Record<string, boolean>)[key]) formats.add('key');            
        }

    });
    return formats;
}