import React from 'react';
import { translate } from 'react-translate';

import _ from 'lodash/fp';

import DataTable from 'components/DataTable';
import EmptyPage from 'components/EmptyPage';

import endPoint from '@cab/endPoints/message';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import TableToolbar from './TableToolbar';
import dataTableSettings from '../variables/dataTableSettings';

const MessageTable = (props) => {
    const {
        t,
        data,
        filters,
        loading,
        actions: { load },
        handleItemClick
    } = props;

    if (!loading && data && data.length === 0 && !filters.filtered && !filters.name) {
        return (
            <EmptyPage
                title={t('EmptyListTitle')}
                description={t('EmptyListDescription')}
                id="msg-empty-page"
            />
        );
    }

    const settings = dataTableSettings({ t, actions: { load } });
    const hightlightIds = (data || []).filter(({ isRead }) => !isRead).map(({ id }) => id);

    return (
        <DataTable
            dense={true}
            onRowClick={handleItemClick}
            CustomToolbar={tableProps => <TableToolbar {...tableProps} t={t} />}
            hightlight={hightlightIds}
            {..._.merge(settings, dataTableAdapter(props, settings))}
        />
    );
};

const translated = translate('MessageListPage')(MessageTable);
export default dataTableConnect(endPoint)(translated);
