import { RequestNumberEnum } from "@eis/consts";

export const generateAccessObj = (data) => {
    let accessObj = {
        [RequestNumberEnum.ForParticipant]: { isVisible: true, isApply: false },
        [RequestNumberEnum.ForProjectApproval]: { isVisible: true, isApply: false },
        [RequestNumberEnum.ForChanges]: { isVisible: false, isApply: false },
        [RequestNumberEnum.ForVerification]: { isVisible: false, isApply: false },
        [RequestNumberEnum.ForExtension]: { isVisible: false, isApply: false }
    }

    for(let item of data) {
        switch (item.processInfo.data.menuOrder) {
            case RequestNumberEnum.ForParticipant:
                let isSuccess = item.lastRequestStatus && ['12','13','14','15'].includes(item.lastRequestStatus.code)
                let isReject = item.lastRequestStatus && ['06'].includes(item.lastRequestStatus.code);
                accessObj[RequestNumberEnum.ForParticipant].isApply = isReject;
                accessObj[RequestNumberEnum.ForProjectApproval].isApply = isSuccess;
                accessObj[RequestNumberEnum.ForExtension].isApply = isSuccess;
                accessObj[RequestNumberEnum.ForExtension].isVisible = isSuccess;
                break;
            case RequestNumberEnum.ForProjectApproval:
                let isApply = !!item.requestsList.length;
                accessObj[RequestNumberEnum.ForProjectApproval].isApply = !isApply;
                accessObj[RequestNumberEnum.ForChanges].isApply = isApply;
                accessObj[RequestNumberEnum.ForChanges].isVisible = isApply;
                accessObj[RequestNumberEnum.ForVerification].isApply = isApply;
                accessObj[RequestNumberEnum.ForVerification].isVisible = isApply;
                break;
            case RequestNumberEnum.ForExtension:
                accessObj[RequestNumberEnum.ForExtension].isApply = !item.requestsList.length;
                break;
            case RequestNumberEnum.ForVerification:
                accessObj[RequestNumberEnum.ForVerification].isApply = item.requestsList.length < 4;
                break;
            default:
                break;
        }
    }

    return accessObj;
}