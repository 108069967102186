import React, { useState } from 'react';
import { translate } from 'react-translate';
import DataTable from 'components/DataTable';
import { IControls, IActions } from './types';

interface IDataTableStatedProps {
  controls: IControls;
  actions: IActions;
  data: any;
  dataTableProps: {
    [x:string]: any;
  }
  isIncreasing: boolean;
  reserIsIncreasing: () => void;
}

const defaultProps: Partial<IDataTableStatedProps> = {
  data: [],
  isIncreasing: false,
  controls: {
    pagination: false,
    toolbar: true,
    search: true,
    header: true,
    refresh: true,
    switchView: true
  }
};

const DataTableStated: React.FC<IDataTableStatedProps> = props => {
  const {
    controls,
    actions,
    data,
    isIncreasing,
    reserIsIncreasing, 
    ...dataTableProps
  } = props;

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10); 

  const getFilteredData = () => {
    // const { data } = props;
    return data; // filters will be here
  }

  const getData = () => {
    const firstIndex = (page - 1) * rowsPerPage;
    const lastIndex = page * rowsPerPage;
    
    const data = getFilteredData();

    isIncreasing && page !== 1 && setPage(1);

    if ((data || []).length <= firstIndex) {
      const start = firstIndex - rowsPerPage;
      return data.slice(start < 0 ? 0 : start, lastIndex - rowsPerPage);
    }

    return data.slice(firstIndex, lastIndex);
  };

  const onChangePage = (page: number) => {
    setPage(page + 1);
    reserIsIncreasing && reserIsIncreasing();
  };

  const onChangeRowsPerPage = (rowsPerPage: number) => setRowsPerPage(rowsPerPage);

  return (
    <DataTable
      {...dataTableProps}
      actions={
        {
          ...actions,
          onChangePage: onChangePage,
          onChangeRowsPerPage: onChangeRowsPerPage
        }
      }
      data={getData()}
      page={page}
      count={data.length}
      rowsPerPage={rowsPerPage}
      controls={
        {
          ...controls,
          bottomPagination: data.length > rowsPerPage
        }
      }
    />
  );
}

DataTableStated.defaultProps = defaultProps;

export default translate('DataTableStated')(DataTableStated);
