/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import ReactDataSheet from 'react-datasheet';
import DataCell from 'react-datasheet/lib/DataCell';
import Virtualized from 'components/Virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VirtualizedData from 'components/Virtualized/VirtualizedData';
import store from 'store';

import DataSheetContainer from './DataSheetContainer';

const isEmpty = obj => Object.keys(obj).length === 0;

class DataSheet extends ReactDataSheet {
    pageClick(e) {
        const { editing } = this.state;

        const isEditing = !isEmpty(editing);

        const element = this.dgDom;
        if (element && !element.contains(e.target) && !isEditing) {
            this.setState(this.defaultState);
            this.removeAllListeners();
        }
    }

    handleFinishEditing = (r, c, nw) => {
        this.onChange(r, c, nw);
        this.setState({ editValue: '' });
        this.editing = false;
        setTimeout(() => this.setFocusToSelected(), 100);
    };

    setFocusToSelected = () => {
        const activCell = this.dgDom && this.dgDom.querySelector('td.selected');
        activCell && activCell.focus();
    };

    onMouseOver(i, j) {
        if (this.state.selecting && isEmpty(this.state.editing)) {
            this._setState({ end: { i, j } });
            const selected = this.dgDom.querySelector('tbody td.selected');
            selected && selected.focus();
        }
    }

    scrollToSelected = () => {
        const cellElem = this.dgDom.querySelector('tbody td.selected');
        if (!cellElem) return;
        const { scrollRef } = this.props;
        if (!scrollRef.current) return;

        const scrollContainer = scrollRef.current.scrollBarRef._container;

        if (cellElem.offsetLeft < scrollContainer.scrollLeft) {
            scrollContainer.scrollLeft = cellElem.offsetLeft;
            return;
        }

        if (cellElem.offsetLeft + cellElem.clientWidth > scrollContainer.clientWidth + scrollContainer.scrollLeft) {
            scrollContainer.scrollLeft = cellElem.offsetLeft + cellElem.clientWidth - scrollContainer.clientWidth + scrollContainer.scrollLeft + 2;
        }
    };

    componentDidMount = () => {
        this.unsubscribe = store.subscribe(() => !this.editing && this.setFocusToSelected());
    };

    componentWillUnmount = () => this.unsubscribe();

    render() {
        const {
            sheetRenderer: SheetRenderer,
            rowRenderer: RowRenderer,
            cellRenderer,
            dataRenderer,
            valueRenderer,
            dataEditor,
            valueViewer,
            attributesRenderer,
            className,
            overflow,
            data,
            height,
            headerRef,
            dataListRef,
            keyFn
        } = this.props;
        const { forceEdit, selecting, editing } = this.state;

        return (
            <DataSheetContainer
                headerRef={headerRef}
                dataListRef={dataListRef}
                virtualizeRef={this.virtualizeRef}
                height={height}
                data={data}
            >
                <AutoSizer disableWidth={true}>
                    {
                        ({ height: contentHeight }) => (
                            <Virtualized
                                data={data}
                                virtualizeRef={(ref) => {
                                    this.virtualizeRef = ref;
                                }}
                                headerRef={headerRef}
                                dataListRef={dataListRef}
                                height={contentHeight}
                                rowHeight={22}
                                selecting={selecting && isEmpty(editing)}
                            >
                                <span
                                    ref={
                                        r => {
                                            this.dgDom = r;
                                        }
                                    }
                                    tabIndex="0"
                                    className="data-grid-container"
                                    onKeyDown={this.handleKey}
                                >
                                    <SheetRenderer
                                        data={data}
                                        selecting={selecting && isEmpty(editing)}
                                        className={
                                            ['data-grid', className, overflow]
                                                .filter(a => a)
                                                .join(' ')
                                        }
                                    >
                                        <VirtualizedData
                                            rowRenderer={
                                                (row, i) => (
                                                    <RowRenderer key={keyFn ? keyFn(i) : i} row={i} cells={row}>
                                                        {
                                                            row.map((cell, j) => {
                                                                const isEditing = this.isEditing(i, j);
                                                                const selected = this.isSelected(i, j);
                                                                selected && this.scrollToSelected();

                                                                return (
                                                                    <DataCell
                                                                        key={cell.key ? cell.key : `${i}-${j}`}
                                                                        row={i}
                                                                        col={j}
                                                                        cell={cell}
                                                                        forceEdit={forceEdit}
                                                                        onMouseDown={this.onMouseDown}
                                                                        onMouseOver={this.onMouseOver}
                                                                        onDoubleClick={this.onDoubleClick}
                                                                        onContextMenu={this.onContextMenu}
                                                                        onChange={this.handleFinishEditing}
                                                                        onRevert={this.onRevert}
                                                                        onNavigate={this.handleKeyboardCellMovement}
                                                                        onKey={this.handleKey}
                                                                        selected={selected}
                                                                        editing={isEditing}
                                                                        clearing={this.isClearing(i, j)}
                                                                        attributesRenderer={attributesRenderer}
                                                                        cellRenderer={cellRenderer}
                                                                        valueRenderer={valueRenderer}
                                                                        dataRenderer={dataRenderer}
                                                                        valueViewer={valueViewer}
                                                                        dataEditor={dataEditor}
                                                                        editValue={this.state.editValue}
                                                                        {...(isEditing
                                                                            ? {
                                                                                onEdit: (e) => {
                                                                                    this.editing = true;
                                                                                    this.handleEdit(e);
                                                                                }
                                                                            }
                                                                            : {})}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </RowRenderer>
                                                )
                                            }
                                        />
                                    </SheetRenderer>
                                </span>
                            </Virtualized>
                        )
                    }
                </AutoSizer>
            </DataSheetContainer>
        );
    }
}

export default DataSheet;
