import React from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
    outlinedError: {
        background: '#FFFFFF'
    },
    outlinedWarning: {
        background: '#FFFFFF'
    },
    outlinedInfo: {
        background: '#FFFFFF'
    }
}));

export interface AlertMessageProps extends AlertProps { };

/**
 * Show short important message with icon.
 */
export const AlertMessage: React.FC<AlertMessageProps> = props => {
    const styles = useStyles();
    return <MuiAlert classes={styles} elevation={1} variant="outlined" {...props} />;
}
