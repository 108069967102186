import React from 'react';
import {
    Template,
    TemplatePlaceholder,
    Plugin,
    TemplateConnector
} from '@devexpress/dx-react-core';
import {
    createStyles, makeStyles, Tooltip
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ToggleButton } from '@material-ui/lab';

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginRight: theme.spacing(0.5),
        padding: theme.spacing()
    },
    selected: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main
    }
}))


interface CreateNewRecordButtonProps {
    hint: string,
    onClick: any,
    enabled: boolean
}

export const EnableFilterButton: React.FC<CreateNewRecordButtonProps> = props => {
    const { hint, onClick, enabled } = props;
    const classes = useStyles();
    return (
        <Plugin name="EnableFilterButton">
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                <TemplateConnector>
                    {() => (
                        <Tooltip title={hint} >
                            <ToggleButton classes={classes}
                                // size="large"
                                // variant="outlined"
                                // id="add-new-row"
                                selected={enabled}
                                onClick={onClick}
                            >
                                <FilterListIcon />
                            </ToggleButton>
                        </Tooltip>
                    )}
                </TemplateConnector>
            </Template>
        </Plugin>
    );
}