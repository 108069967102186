import AppConfig from 'config/index';
import { CustomToolBarWithSwitchProps } from './CustomToolBarWithSwitch'

export interface SwitchTabProps {
    personalTitle: string,
    unitTitle: string,
    personal: string,
    unit: string
}

export const makeSwitchProps = (props: SwitchTabProps, current?: string): CustomToolBarWithSwitchProps => {
    const taskMenu = AppConfig.taskMenu || { unitFirst: false, personalDefault: true };
    return {
        current: current || (taskMenu.unitFirst ?  props.unit : props.personal),
        unitTitleId: props.unit,
        items: taskMenu.unitFirst
            ? {
                [props.unit]: props.unitTitle,
                [props.personal]: props.personalTitle
            }
            : {
                [props.personal]: props.personalTitle,
                [props.unit]: props.unitTitle
            }
    }
}