import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import {
    Typography,
    withStyles
} from '@material-ui/core';
import {
    getPDFDocumentDecoded,
    loadTaskDocument,
    downloadDocumentAttach
} from '@cab/actions/task';
import processList from 'services/processList';
import WarningIcon from '@material-ui/icons/Warning';
import FilePreview from 'components/FilePreview';
import Preloader from 'components/Preloader';
import ErrorScreen from 'components/ErrorScreen';

class TaskPreview extends React.Component {
    state = {
        busy: true,
        error: null
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const { actions, task, fileList } = this.props;
        await processList.hasOrSet('getPDFDocumentDecoded', actions.getPDFDocumentDecoded, task, true);
        const { attachments } = await processList.hasOrSet('loadTaskDocument', actions.loadTaskDocument, task.documentId);

        try {
            await Promise.all(attachments.filter(({ isGenerated }) => isGenerated).map((file) => {
                if (fileList[file.id]) {
                    return Promise.resolve(fileList[file.id]);
                }

                return processList.hasOrSet('downloadDocumentAttach', actions.downloadDocumentAttach, file);
            }));
        } catch (e) {
            this.setState({ error: e });
        }

        this.setState({ busy: false });
    }

    render() {
        const { busy, error } = this.state;
        const {
            documents,
            pdfDocuments,
            task,
            template,
            fileList,
            t,
            classes
        } = this.props;

        if (!task || busy) {
            return <Preloader />;
        }

        if (error) {
            return <ErrorScreen error={error} />;
        }

        const pdfDocument = pdfDocuments[task.documentId];
        const { attachments } = documents[task.documentId] || {};

        if (pdfDocument instanceof Error) {
            return (
                <div className={classes.errorWrapper}>
                    <WarningIcon style={{ color: '#d32f2f' }}/>
                    <Typography variant={'body2'}>{t('FileLoadingError')}</Typography>
                </div>
            );
        }

        return (
            <Fragment>  
                <FilePreview file={pdfDocument} fileType={'pdf'} fileName={template.name} />
                {
                    (attachments || []).filter(({ isGenerated }) => isGenerated).map(file => (
                        <FilePreview key={file.id} file={fileList[file.id]} fileType={'pdf'} fileName={file.name} />
                    ))
                }
            </Fragment>
        );
    }
}


const styles = {
    errorWrapper: {
        padding: 30
    }
};

TaskPreview.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    fileList: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    documents: PropTypes.object.isRequired,
    pdfDocuments: PropTypes.object.isRequired
};

const mapStateToProps = ({
    task: { documents },
    files: { pdfDocuments, list } }) => ({
        fileList: list,
        documents,
        pdfDocuments
    });

const mapDispatchToProps = dispatch => ({
    actions: {
        getPDFDocumentDecoded: bindActionCreators(getPDFDocumentDecoded, dispatch),
        loadTaskDocument: bindActionCreators(loadTaskDocument, dispatch),
        downloadDocumentAttach: bindActionCreators(downloadDocumentAttach, dispatch)
    }
});

const styled = withStyles(styles)(TaskPreview);
const translated = translate('InboxFilesPage')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
