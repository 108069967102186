import React from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { Box } from '@material-ui/core';
import { FileIcon, FileIconProps, defaultStyles } from 'react-file-icon';
import { useStyles } from "./styles";

/** Компонент для отображения имени файла с иконкой */
export const FileNameCellProvider: React.FC<DataTypeProviderProps> = props => {
    const classes = useStyles();
    return (
        <DataTypeProvider
            {...props}
            formatterComponent={(props) => {
                const extension = String(props.value).split('.').pop() || '';
                const iconsProps: FileIconProps = (defaultStyles as Record<string, FileIconProps>)[extension];
                return (
                    <Box className={classes.fileName}>
                        <Box className={classes.fileIcon}>
                            <FileIcon extension={extension} {...iconsProps} />
                        </Box>
                        <span>{props.value}</span>
                    </Box>
                );
            }} />
    );
};
