import React, { useEffect, useMemo, useState } from 'react';
import { translate } from 'react-translate';
import objectPath from 'object-path';
import classNames from 'classnames';

import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableCell,
  TableRow,
  GridList,
  withStyles,
  Theme,
  createStyles
} from '@material-ui/core';

import DataTableHeader from 'components/DataTable/DataTableHeader';
import DataTableRow from '@core/components/DataTable/DataTableRow';
import DataTableCard from '@core/components/DataTable/DataTableCard';
import DataTableToolbar from 'components/DataTable/DataTableToolbar';
import CollapsedTableRows from '@core/components/DataTable/CollapsedTableRows';
import CollapsedTableCards from '@core/components/DataTable/CollapsedTableCards';

import Preloader from 'components/Preloader';
import arrayUnique from 'helpers/arrayUnique';
import { IDataTableProps, IDataFilesProps } from './types';

const ITEM_STATE_NAME = 'filesGroupingState';

const styles = (theme: Theme) => createStyles({
  rootContainer: {
    maxWidth: 800
  },
  fixedTable: {
    tableLayout: 'fixed'
  },
  TableCell: {
    [theme.breakpoints.down('xs')]: {
      padding: '10px 5px',
      fontSize: 13,
      lineHeight: '18px'
    }
  },
  empty: {
    marginTop: 15,
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: '20px'
  },
  cardContainer: {
    padding: '8px 8px 0',
    margin: '0 !important'
  },
  tableBody: {
    overflowX: 'auto'
  }
});

const defaultProps: Partial<IDataTableProps> = {
  view: 'table',
  hightlight: [],
  rowsSelected: [],
  components: {},
  fixedTable: false,
  stickyHeader: true,
  controls: {
    pagination: false,
    toolbar: true,
    search: true,
    header: true,
    refresh: true,
    switchView: true
  }
}

const DataTable: React.FC<IDataTableProps> = (props) => {
  const {
    t, data, actions, classes, sort, components, controls, stickyHeader, search, emptyDataText,
    rowsSelected, onRowClick, cellStyle, columns, checkable, count, page, rowsPerPage,
    dense, hiddenColumns, fixedTable, dragEvents, hightlight, fileStorage, filters,
    filterHandlers, CustomToolbar, toolbarPosition, limitWidth, changeParentWidth,
    groupBy, groupAttribute, groupTitle, groupDefaultValue,
  } = props;

  const [view, setView] = useState<string>(props.view);
  const [grouping, setGrouping] = useState<boolean>(false);
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);

  const toolbarProps = {
    t, view, isFullWidth, page, count, rowsPerPage, checkable, actions,
    data, search, filters, filterHandlers,
    CustomToolbar, toolbarPosition, rowsSelected, columns, hiddenColumns,
    controls, groupBy, grouping
  };

  const switchView = () => setView(view === 'table' ? 'cell' : 'table');

  const groupByName = useMemo(() => {
    return (groupBy === 'attributes' && groupAttribute) || 'labels';
  }, [groupBy, groupAttribute]);

  useEffect(() => {
    const groupingState = JSON.parse(localStorage.getItem(ITEM_STATE_NAME) || '{}');
    setGrouping(groupingState[groupByName]);
  }, [groupByName]);

  // Event for switch view
  const toggleGrouping = () => {
    if (groupByName) {
      const groupingState: Record<string, boolean> = 
        JSON.parse(localStorage.getItem(ITEM_STATE_NAME) || '{}');
      if (grouping) {
        // Need remove
        delete groupingState[groupByName]; 
      } else {
        // need add
        groupingState[groupByName] = true;
      }
      localStorage.setItem(ITEM_STATE_NAME, JSON.stringify(groupingState));
    }
    setGrouping(!grouping);
  }

  const switchWidth = () => {
    changeParentWidth && changeParentWidth(!isFullWidth)
    setIsFullWidth(!isFullWidth);
  };

  const createSortHandler = (columnId: string) => () => {
    const order = objectPath.get(sort, columnId) === 'desc' ? 'asc' : 'desc';
    actions.onColumnSortChange && actions.onColumnSortChange(columnId, order);
  };

  const handleSelectRow = (itemId: string) => () => {
    if (rowsSelected.includes(itemId)) {
      rowsSelected.splice(rowsSelected.indexOf(itemId), 1);
    } else {
      rowsSelected.push(itemId);
    }

    actions.onRowsSelect && actions.onRowsSelect([...rowsSelected]);
  };

  const renderPreloading = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={(columns || []).length + (checkable ? 1 : 0)}>
            <Preloader />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderEmptyData = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell
            align="center"
            colSpan={(columns || []).length + (checkable ? 1 : 0)}
            id="empty-data-cell"
          >
            {emptyDataText || t('EmptyData')}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderBody = () => {
    const { isRowSelectable } = actions || {};

    if (!columns || !Array.isArray(columns)) {
      return (
        <TableRow>
          <TableCell>define columns pls</TableCell>
        </TableRow>
      );
    }

    if (!data) {
      return renderPreloading();
    }

    if (Array.isArray(data) && data.length === 0) {
      return renderEmptyData();
    }

    const renderRow = (row: any, key: number) => (
      <DataTableRow
        key={key}
        rowIndex={key}
        item={row}
        cellStyle={cellStyle}
        hightlight={hightlight && row.id && hightlight.includes(row.id)}
        selected={rowsSelected && row.id && rowsSelected.includes(row.id)}
        selectable={isRowSelectable ? isRowSelectable(row) : true}
        checkable={checkable}
        columns={columns}
        hiddenColumns={hiddenColumns}
        onSelect={handleSelectRow(row.id)}
        onClick={onRowClick ? onRowClick.bind(this, row) : null}
      />
    );

    if (groupBy && grouping) {
      if (groupBy === 'attributes' && groupAttribute) {
        const groups = arrayUnique([].concat(...data.map((group: IDataFilesProps) =>
          group[groupBy] && group[groupBy][groupAttribute]
            ? group[groupBy][groupAttribute]
            : groupDefaultValue || ''
        )));

        return (
          <TableBody>
            {groups.sort(function (a: string, b: string) {
              return +a - +b;
            }).map((group: string) => (
              <CollapsedTableRows
                key={group}
                title={groupTitle + ' ' + group}
                renderRow={renderRow}
                colSpan={(columns || []).length + (checkable ? 1 : 0)}
                data={data.filter((filterData: IDataFilesProps) => {
                  return filterData[groupBy] && filterData[groupBy][groupAttribute]
                    ? filterData[groupBy][groupAttribute].includes(group)
                    : groupDefaultValue === group;
                })}
              />
            ))}
            <CollapsedTableRows
              title={t('WithoutGroup')}
              renderRow={renderRow}
              colSpan={(columns || []).length + (checkable ? 1 : 0)}
              data={data.filter((filterData: IDataFilesProps) => (
                !filterData[groupBy] || !filterData[groupBy][groupAttribute]) && !groupDefaultValue
              )}
            />
          </TableBody>
        );
      }
      if (groupBy === 'labels') {
        const groups = arrayUnique(...data.map((group: any) => group[groupBy]).slice());
        return (
          <TableBody>
            {groups.map((group: string) => (
              <CollapsedTableRows
                key={group}
                title={group}
                renderRow={renderRow}
                colSpan={(columns || []).length + (checkable ? 1 : 0)}
                data={data.filter((filterData: IDataFilesProps) => {
                  return filterData[groupBy].includes(group);
                })}
              />
            ))}
            <CollapsedTableRows
              title={t('WithoutGroup')}
              renderRow={renderRow}
              colSpan={(columns || []).length + (checkable ? 1 : 0)}
              data={data.filter((filterData: IDataFilesProps) => !filterData[groupBy] || !filterData[groupBy].length)}
            />
          </TableBody>
        );
      }
    }

    return (
      <TableBody>
        {data.filter(Boolean).map(renderRow)}
      </TableBody>
    );
  }

  const renderCards = () => {
    if (!data || !data.length) {
      return null;
    }

    const CardComponent = components.DataTableCard || DataTableCard;

    const renderCard = (row: any, key: any) => (
      <CardComponent
        key={key}
        item={row}
        actions={actions}
        selected={rowsSelected && row.id && rowsSelected.includes(row.id)}
        selectable={true}
        checkable={true}
        fileStorage={fileStorage}
        columns={columns}
        onSelect={handleSelectRow(row.id)}
      />
    );

    if (groupBy && grouping) {
      if (groupBy === 'attributes' && groupAttribute) {
        const groups = arrayUnique([].concat(...data.map((group: IDataFilesProps) =>
          group[groupBy] && group[groupBy][groupAttribute]
            ? group[groupBy][groupAttribute]
            : groupDefaultValue || ''
        )));

        return (
          <TableBody>
            {groups.sort(function (a: string, b: string) {
              return +a - +b;
            }).map((group: string) => (
              <CollapsedTableCards
                key={group}
                title={groupTitle + ' ' + group}
                renderCard={renderCard}
                data={data.filter((filterData: IDataFilesProps) => {
                  return filterData[groupBy] && filterData[groupBy][groupAttribute]
                    ? filterData[groupBy][groupAttribute].includes(group)
                    : groupDefaultValue === group;
                })}
              />
            ))}
            <CollapsedTableCards
              title={t('WithoutGroup')}
              renderCard={renderCard}
              data={data.filter((filterData: IDataFilesProps) => (!filterData[groupBy] || !filterData[groupBy][groupAttribute]) && !groupDefaultValue)}
            />
          </TableBody>
        );
      }
      if (groupBy === 'labels') {
        const groups = arrayUnique(...data.map((group: IDataFilesProps) => group[groupBy].slice()));
        return (
          <TableBody>
            {groups.map((group: string) => (
              <CollapsedTableCards
                key={group}
                title={group}
                renderCard={renderCard}
                data={data.filter((filterData: IDataFilesProps) => {
                  return filterData[groupBy].includes(group);
                })}
              />
            ))}
            <CollapsedTableCards
              title={t('WithoutGroup')}
              renderCard={renderCard}
              data={data.filter((filterData: IDataFilesProps) => !filterData[groupBy] || !filterData[groupBy].length)}
            />
          </TableBody>
        );
      }
    }

    return (
      <GridList className={classes.cardContainer}>
        {data.map(renderCard)}
      </GridList>
    );
  }

  const renderTableContent = () => {
    if (!data) {
      return renderPreloading();
    }

    if (Array.isArray(data) && data.length === 0) {
      return renderEmptyData();
    }

    if (view === 'table') {
      return renderBody();
    }

    return null;
  };

  const handleChangeRowsPerPage = (e: any) => {
    if (actions && actions.onChangeRowsPerPage)
      return actions.onChangeRowsPerPage(e.target.value)
  }

  return (
    <div
      className={limitWidth ? classes.rootContainer : ''} {...dragEvents}
      style={isFullWidth ? { width: "calc(100vw - 350px)", maxWidth: 'unset' } : {}}>
      {controls.toolbar &&
        <DataTableToolbar
          {...toolbarProps}
          switchView={switchView}
          switchWidth={switchWidth}
          toggleGrouping={toggleGrouping}
        />
      }
      <div className={classes.tableBody}>
        <TableContainer>
          <Table
            className={classNames({ [classes.fixedTable]: fixedTable })}
            size={dense ? 'small' : 'medium'}
            stickyHeader={stickyHeader} >
            {(view === 'table' && controls.header) ? (
              <DataTableHeader createSortHandler={createSortHandler} {...props} />
            ) : null}
            {renderTableContent()}
          </Table>
        </TableContainer>
        {view !== 'table' && renderCards()}
        {controls.bottomPagination &&
          <TablePagination
            id="table-pagination"
            backIconButtonProps={{
              id: 'table-pagination-back'
            }}
            nextIconButtonProps={{
              id: 'table-pagination-next'
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={(e, newPage) => actions.onChangePage && actions.onChangePage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to }) => t('DISPLAYED_ROWS', { from, to, total: count })}
          />
        }
      </div>
    </div>
  );
}

DataTable.defaultProps = defaultProps;

export { default as DataTableStated } from './DataTableStated';
export default translate('DataTable')(withStyles(styles)(DataTable));
