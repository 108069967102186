import React, { Fragment, useEffect, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import PreviewIcon from '@material-ui/icons/Visibility';
import FileViewerDialog from '@core/components/FileViewerDialog'

import { FileEntity, FileStorageType } from './types';


interface PreviewButtonProps {
    fileStorage: FileStorageType;
    file: FileEntity;
    hint?: string;
    handleDownloadFile: (downloadToken: string) => any;
}

/** Icon-кнопка для загрузки превью файла */
export const PreviewButton: React.FC<PreviewButtonProps> = props => {
    const { hint, fileStorage, file, handleDownloadFile } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const getDocument = () => fileStorage && fileStorage[file.downloadToken!];

    const handleDownload = async () => {
        setLoading(true);
        // if (!getDocument()) {
        //     await handleDownloadFile(file.downloadToken!);
        // }
        // setLoading(false);
        // setOpenDialog(true);
    };

    useEffect(() => {
        const load = async () => {
    //         // let body = fileStorage && fileStorage[file.downloadToken!];
    //         // if (!body) {
            await handleDownloadFile(file.downloadToken!);
    //         // }
    //         const body = await handleDownloadFile(file.downloadToken!);
    //         // const error = body instanceof Error ? body : null;
    //         // TODO: ошибку куда-то вывести?
    //         // console.log('wwww', body)
    //         // setDocument(body);
    //         // if (!error) {
                
    //         // }
        }
        if (loading) {
            if (getDocument()) {
                setOpenDialog(true);
            } else {
                load();
                setLoading(false);
            }
        } 
        // eslint-disable-next-line
    }, [loading]);


    return (
        <Fragment>
            <Tooltip title={hint || 'Preview'}>
                <IconButton
                    disabled={loading || !Boolean(file.downloadToken)}
                    onClick={handleDownload}>
                    {loading && <CircularProgress size={24} />}
                    {!loading && <PreviewIcon />}
                </IconButton>
            </Tooltip>
            <FileViewerDialog
                file={getDocument()}
                fileName={file.name}
                open={openDialog && getDocument()} //  !!(showPreview && file && !error)
                extension={(file.name.split('.').pop() || '').toLowerCase()}
                onClose={() => { 
                    setOpenDialog(false);
                    setLoading(false);
                }}
            />
        </Fragment>
    );
};
