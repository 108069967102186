import { IMetricBlock } from './types';

// Перелік інформації по Заявках №1 необхідної для формування основних звітів зі зведеними показниками
export const MetricBlocks1: Array<IMetricBlock> = [
    { // 0
        title: 'Загальні показники',
        metrics: [
            {
                id: 'reqTotal',
                title: 'Кількість отриманих заявок'
            },
            {
                id: 'projectTotal',
                title: 'Кількість проeктів'
            }]        
    },
    { // 1
        title: 'Заявки',
        metrics: [{
            id: '-',
            title: 'Подано',
            col: [
                { id: 'reqByNum1', title: '1' },
                { id: 'reqByNum2', title: '2' },
                { id: 'reqByNum3', title: '3' },
                {
                    id: 'reqByNum4',
                    title: '4',
                },
                {
                    id: 'reqByNum5',
                    title: '5',
                },
                {
                    id: 'reqTotal',
                    title: 'Загалом',
                },
            ]
        },
        {
            id: '-',
            title: 'Схвалено',
            col: [
                { id: 'reqAccept1' },
                { id: 'reqAccept2' },
                { id: 'reqAccept3' },
                { id: 'reqAccept4' },
                { id: 'reqAccept5' },
                { id: 'reqAcceptTotal' }
            ]
        },
        {
            id: '-',
            title: 'Відхилено',
            col: [
                { id: 'reqReject1' },
                { id: 'reqReject2' },
                { id: 'reqReject3' },
                { id: 'reqReject4' },
                { id: 'reqReject5' },
                { id: 'reqRejectTotal' }
            ]
        },
        {
            id: '-',
            title: 'В обробці',
            col: [
                { id: 'reqProcess1' },
                { id: 'reqProcess2' },
                { id: 'reqProcess3' },
                { id: 'reqProcess4' },
                { id: 'reqProcess5' },
                { id: 'reqProcessTotal' }
            ]
        },
        // {
        //     id: '-',
        //     title: 'На доопрацюванні',
        //     col: [
        //         { id: 'reqFinals1' },
        //         { id: 'reqFinals2' },
        //         { id: 'reqFinals3' },
        //         { id: 'reqFinals4' },
        //         { id: 'reqFinals5' },
        //         { id: 'reqFinalTotal' }
        //     ]
        // },
        {
            id: '-',
            title: 'В процесі подання',
            col: [
                { id: 'started1' },
                { id: 'started2' },
                { id: 'started3' },
                { id: 'started4' },
                { id: 'started5' },
                { id: 'startedTotal' }
            ]
        }
    ]
    },
    { // 2
        title: 'Проекти',
        metrics: [
            {
                id: 'projectTotal',
                title: 'Загальна кількість'
            },
            {
                id: 'projectA_B',
                title: 'Кількість по типам (А / Б)'
            },
            {
                id: 'projectBig',
                title: 'Кількість по статусу правочину (значний / незначний)'
            }]        
    },
    //
    {
        title: 'Загальні показники',
        metrics: [
            {
                id: 'req1Total',
                title: 'Кількість отриманих'
            },
            {
                id: 'req1Addon',
                title: 'Кількість отриманих доопрацювань'
            },
            {
                id: 'req1NoProcess',
                title: 'Кількість не прийнятих'
            },
            {
                id: 'req1Reject',
                title: 'Кількість відхилених'
            }]
    },
    {
        title: 'Прийняті Заявки',
        metrics: [
            {
                id: 'req1Access',
                title: 'Кількість'
            },
            {
                id: 'req1SumTotal',
                title: 'Загальна вартість Проектів, млн.грн.'
            },
            {
                id: 'req1SumAverage',
                title: 'Загальна вартість Проектів, млн.грн.'
            },
            {
                id: 'req1SumGrant',
                title: 'Загальна розрахована сума Грантів, млн.грн.'
            },
            {
                id: 'req1SumGrantAverage',
                title: 'Середня розрахована сума Грантів, млн.грн.'
            },
            {
                id: 'req1Place',
                title: 'Заявлена опалювальна площа, м2.',
                hint: 'Заявлена опалювальна площа будинків відповідно до Описів проекту, м2.'
            },
            {
                id: 'req1OSBBCount',
                title: 'Заявлена кількість домогосподарств',
            },
            {
                id: 'req1Energy',
                title: 'Очікувана економія енергії, тис.кВт*год.',
            },
            {
                id: 'req1EnergyAverage',
                title: 'Середня очікувана. економія енергії, %',
            },
            {
                id: 'req1Saving',
                title: 'Очікувана економія коштів, млн.грн.',
            },
            {
                id: 'req1CO2',
                title: 'Очікуване зниження викидів СО2, тис.тонн/рік.',
            }
        ]
    },
    {
        title: 'У розподілі по поверховісті будівель',
        metrics: [
            {
                id: '-',
                title: 'Кількість Заявок',
                col: [
                    {
                        id: 'req1CountLevel1_2',
                        title: '1-2 поверхи',
                    },
                    {
                        id: 'req1CountLevel3_5',
                        title: '3-5 поверхів',
                    },
                    {
                        id: 'req1CountLevel6_9',
                        title: '6-9 поверхів',
                    },
                    {
                        id: 'req1CountLevel9',
                        title: 'більше 9 поверхів',
                    }
                ]
            },
            {
                id: '-',
                title: 'Мінімальна/максимальна/середня вартість проектів, млн.грн.',
                col: [
                    {
                        id: 'req1SumLevel1_2',
                        title: '1-2 поверхи',
                    },
                    {
                        id: 'req1SumLevel3_5',
                        title: '3-5 поверхів',
                    },
                    {
                        id: 'req1SumLevel6_9',
                        title: '6-9 поверхів',
                    },
                    {
                        id: 'req1SumLevel9',
                        title: 'більше 9 поверхів',
                    }
                ]
            },
            {
                id: '-',
                title: 'Мінімальна/максимальна/середня питома вартість Проектів на 1м2 опалювальної площі будинку, тис.грн/м2.',
                col: [
                    {
                        id: 'req1SumLevel1_2',
                        title: '1-2 поверхи',
                    },
                    {
                        id: 'req1SumLevel3_5',
                        title: '3-5 поверхів',
                    },
                    {
                        id: 'req1SumLevel6_9',
                        title: '6-9 поверхів',
                    },
                    {
                        id: 'req1SumLevel9',
                        title: 'більше 9 поверхів',
                    }
                ]
            },
            {
                id: '-',
                title: 'Мінімальна/максимальна/середня очікувана економія економія енергії, %',
                col: [
                    {
                        id: 'req1EnergySavedLevel1_2',
                        title: '1-2 поверхи',
                    },
                    {
                        id: 'req1EnergySaved3_5',
                        title: '3-5 поверхів',
                    },
                    {
                        id: 'req1EnergySaved6_9',
                        title: '6-9 поверхів',
                    },
                    {
                        id: 'req1EnergySaved9',
                        title: 'більше 9 поверхів',
                    }
                ]
            },
            {
                id: '-',
                title: 'Мінімальна/максимальна/середня очікувана економія економія коштів, млн.грн.',
                col: [
                    {
                        id: 'req1SavedLevel1_2',
                        title: '1-2 поверхи',
                    },
                    {
                        id: 'req1SavedLevel3_5',
                        title: '3-5 поверхів',
                    },
                    {
                        id: 'req1SavedLevel6_9',
                        title: '6-9 поверхів',
                    },
                    {
                        id: 'req1SavedLevel9',
                        title: 'більше 9 поверхів',
                    }
                ]
            },
        ]
    }
];
