import { IModuleInfo } from '@eis/types';

// CORE
// import testModule from 'modules/test';

// OWN
import profileModule from './profile';
import projectsModule from './projects';
import tasksModule from './tasks';
import workflowModule from './workflow';
// import usersModules from './users';
import homeModule from './home';

import messagesModule from './messages';
import commonModule from './common';
// import connectionModule from './flows';
import InfoPageModule from './info';
// import CRMModule from './crm';
import registryModule from './registry';
// import catalog from './catalog';
// import services from './services';
// import subjects from './subjects';
// import resources from './resources';

const modules: Array<IModuleInfo> = [    
    commonModule,    // Logo + Dashboard
    projectsModule,
    // connectionModule,
    // CRMModule,
    // subjects,        // Участники системы
    // catalog,         // Каталог сервисов
    // services,        // Страницы сервисов и компонентов сервисов
    // resources,       // Інформаційні ресурси
    messagesModule,  // Messages
    // adminModule,
    tasksModule,     // Задачи
    // inboxModule,  // Файлы-Документы
    workflowModule,  
    registryModule,
    profileModule,   // Иконка на тулбаре + страница профиля
    // usersModules, 
    // testModule,      // Тестирование КЕП
    InfoPageModule,
    homeModule      // 
];

export default modules;
