import { createStyles, makeStyles } from "@material-ui/core";


export const useStyles = makeStyles(theme => createStyles({
    paper: {
        background: '#122945'
    },
    navLink: {
        color: '#fff',
        textDecoration: 'none',
        '&.active > div': {
            backgroundColor: 'rgba(255, 255, 255, .1)',
            color: '#fff'
        }
    },
    item: {
        paddingLeft: 0,
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 0,
        color: '#ffffff'
    },
    itemActionable: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .1)'
        },
        '& svg': {
            fill: '#eee',
            backgroundColor: 'rgba(255, 255, 255, .1)'
        }
    },
    subNavLink: {
        padding: '4px 0',
        paddingLeft: '55px!important'
    },
    noPadding: {
        paddingLeft: '4px !important',
        paddingRight: 4
    },
    itemPrimary: {
        color: 'inherit',
        fontSize: theme.typography.fontSize,
        '&$textDense': {
            fontSize: theme.typography.fontSize
        }
    },
    badge: {
        // left: -4,
        // height: 16,
        backgroundColor: theme.palette.error.main,
        // padding: 4,
        marginRight: theme.spacing()
    },
    textDense: {
        
    }
}));
