import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import store, { history } from '@app/store';
import { install as GAInstall } from 'ga-gtag';

import translation from '@app/translation';

import RootLayout from '@app/components/RootLayout';
import { AppRouter, Auth, VersionUpdater } from './eis/components';
import { AppConfigJson, IModuleInfo } from './eis/types';
import { getAppbar } from './eis/utils';
import config from './config';


interface AppProps {
    config: AppConfigJson;
    theme: Theme;
    modules: Array<IModuleInfo>;
    initActions: Record<string, any>;
}

const App: React.FC<AppProps> = (props) => {
    useEffect(() => {
        console.log(`CURRENT APP VERSION: `, config.application.version);
        if (config.gtag) {
            GAInstall(config.gtag);
        }
    }, []);

    const appBarWidgets = getAppbar(props.modules);//useMemo(() => getAppbar(modules), [dispatch]);
// config={props.config} initActions={props.initActions}
    return (
        <MuiThemeProvider theme={props.theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                    <TranslatorProvider translations={translation}>
                        <Auth>
                            <VersionUpdater url={config.cabinetUrl + '/version.json'}/>
                            <Router history={history}>
                                <RootLayout config={props.config} appBarWidgets={appBarWidgets} >
                                    <AppRouter modules={props.modules} />
                                </RootLayout>
                            </Router>
                        </Auth>
                    </TranslatorProvider>
                </Provider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    )
}

export default App;
