import { IEndpoint } from './types';

const tasks: IEndpoint = {
  dataURL: 'tasks',
  sourceName: 'taskList',
  defaultOptions: {},
  mapData: null,
  reduce: null
}

export default tasks;