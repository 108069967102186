import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import FileDataTable from '@core/components/FileDataTable';
import { AppRootState } from '@app/store';
import { getFilesInfo } from '@app/application/actions/files';
import { IFileInfo } from '@eis/types';
import evalate from '@core/helpers/evalate';
import { Typography } from '@material-ui/core';
import renderHTML from '../../../helpers/renderHTML';


interface ISortItem {
    fileName: string;
    updatedAt: string;
}

/** Сортиповка по имени файла (+ дата, если одинаковые имена*/
const sortList = (data: any): Array<ISortItem> => {
    const list: Array<ISortItem> = data;
    list.sort((i1, i2) => {
        let Result = i1.fileName.localeCompare(i2.fileName);
        if (Result === 0) {
            Result = Math.sign(moment(i2.updatedAt).valueOf() - moment(i1.updatedAt).valueOf())
        }
        return Result;
    })
    return list;
}

interface PreviewDocumentProps {
    rootDocument: any;
    sample?: string;
    hidden?: boolean;
    actions?: any;
    fileStorage?: any;
    demo?: boolean;
    files?: string | Array<string>;
    
    /** Требуется группировка по аттрибуту */
    groupAttribute?: string;
    /** Читабельный заголовок для аттрибута */
    groupTitle?: string;
    /** Значение по умолчанию аттрибута, если оно отсутвует у файла */
    groupDefaultValue?: string;
}
const PreviewDocument: React.FC<PreviewDocumentProps> = props => {
    const { handleDeleteFile, ...actions } = props.actions || {};
    const { rootDocument, fileStorage, hidden, demo, files, sample, 
        groupAttribute, groupTitle, groupDefaultValue } = props;
    const { task } = useSelector((state: AppRootState) => state);
    const dispatch = useDispatch();
    const [data, setData] = useState<Array<any>>([]);

    useEffect(() => {
        if (demo) return;

        const load = async () => {
            let ids = '';

            if (Array.isArray(files)) {
                // Данные представлены массивом идентификаторов
                ids = files.join(',');
            } else {
                // Данные представлены вычесляемым калбеком
                const evResult = evalate(files || '', rootDocument.data);
                if (Array.isArray(evResult)) ids = evResult.join(',');
            }
            if (Boolean(ids)) {
                const info: Array<IFileInfo> = (await dispatch(getFilesInfo(ids))) as any;
                // sortList(info);
                setData(info);
            }
        }

        if (files) {
            if (data.length === 0) {
                load();
            }
        } else {
            const workflowFiles = task.workflowFiles && (task.workflowFiles as any)[rootDocument.id];
            if (workflowFiles) {
                setData(sortList(workflowFiles));
            } else {
                actions.getDocumentWorkflowFiles();
            }
        }
        // eslint-disable-next-line
    }, [task, rootDocument]);

    if (hidden) return null;

    const sampleText = sample && typeof sample === 'string' ? renderHTML(sample) : sample;
    return (
        <Fragment>
            {sampleText &&
                <Typography>
                    {sampleText}
                </Typography>
            }
            <FileDataTable
                data={data}
                actions={actions}
                groupBy={groupAttribute ? 'attributes' : 'labels' }
                groupAttribute={groupAttribute}
                groupTitle={groupTitle} 
                groupDefaultValue={groupDefaultValue}
                asics={true}
                fileStorage={fileStorage}
                dense={true}
                limitWidth={true}
            />
        </Fragment>
    )
}

export default PreviewDocument;