import React, { Fragment } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';


import SignIcon from '@material-ui/icons/VpnKey';

// import { useStyles } from "./styles";
import { FileEntity, FileStorageType, FilesTreeViewMessages } from './types';
import { IconButton, Tooltip } from '@material-ui/core';
import { DownloadButton } from './DownloadButton';
import { PreviewButton } from './PreviewButton';

interface ActionsFileProviderProps {
    /** Redux file storage */
    fileStorage: Record<string, FileStorageType>;
    // /** Show Preview button in file row */
    // previewButton?: boolean;
    // /** Show "Key" button in file row */
    // signButton?: boolean;
    // /** Show Download button in file row */
    // downloadButton?: boolean;
    /** Text for UI */
    messages: FilesTreeViewMessages;
    /** */
    actions: {
        downloadOriginal?: (downloadToken: string) => any;
        downloadPreview?: (downloadToken: string) => any;
        downloadSign?: (downloadToken: string) => any;
    }
}


export const ActionsFileProvider: React.FC<DataTypeProviderProps & ActionsFileProviderProps> = React.memo(props => {
    const { fileStorage, messages, actions } = props;
    // const classes = useStyles();

    const renderActions = (file: FileEntity) => (
        <Fragment>
            {actions.downloadSign && file.signer &&
                <Tooltip title={messages.signButton || 'Who signed'}>
                    <IconButton>
                        <SignIcon />
                    </IconButton>
                </Tooltip>
            }
            {actions.downloadPreview &&
                <PreviewButton
                    hint={messages.previewButton}
                    fileStorage={fileStorage.previews}
                    file={file}
                    handleDownloadFile={dt => actions.downloadPreview!(dt)}
                />
            }
            {actions.downloadOriginal &&
                <DownloadButton
                    hint={messages.downloadButton}
                    fileStorage={fileStorage.list}
                    file={file}
                    handleDownloadFile={dt => actions.downloadOriginal!(dt)} />
            }
        </Fragment>
    );

    return (
        <DataTypeProvider
            {...props}
            formatterComponent={p => renderActions(p.row.fileData)} />
    );
}
)

