import React from 'react';
import {
    Plugin,
    Template,
    TemplatePlaceholder
} from '@devexpress/dx-react-core';


export const ToolbarExtView: React.FC = props => {
    return <Plugin
        name="ToolbarExtView"
        dependencies={[{ name: 'Toolbar' }]}
    >
        <Template name="toolbarContent" >
            <TemplatePlaceholder />
            {props.children}
        </Template>
    </Plugin>
}