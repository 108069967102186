import React from 'react';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => createStyles({
    paper: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    paperW: {
        maxWidth: 900
    }
}))

interface PaperLayoutProps {
    fullWidth?: boolean;
    className?: string;
}

export const PaperLayout: React.FC<PaperLayoutProps> = ({children, fullWidth, className}) => {
    const classes = useStyles();
    return (
        <Paper variant="outlined" className={classNames(classes.paper, className, {
            [classes.paperW]: !fullWidth
        })}>
            {children}
        </Paper>
    );
}
