import React, { Fragment } from 'react';
import { useTranslate } from 'react-translate';

import { Box } from '@material-ui/core';

import { IStatus, IRequest } from '../../types';
import { useStyles } from '../../styles';

import moment from 'moment';
import 'moment/locale/uk';

moment.locale('uk');

interface IRequestTableProps {
    requestsList: Array<IRequest>;
    statuses: Array<IStatus>
}

interface ITableRow {
    row: IRequest,
    status: IStatus | undefined
}

export const RequestTable: React.FC<IRequestTableProps> = props => {
    const {requestsList, statuses} = props;
    const t = useTranslate('Projects');
    const classes = useStyles();

    const renderData = requestsList && requestsList.map(row => {
        let status = statuses.find(el => el.id === row.data.requestStatus.statusId);
        return <RequestTableRow key={row.id} row={row} status={status}/>
    })

    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" className={classes.table}>
            <Box gridColumn="span 3" className={classes.tableHeader}> {t('requestProcessNumber')} </Box>
            <Box gridColumn="span 3" className={classes.tableHeader}> {t('requestNumber')} </Box>
            <Box gridColumn="span 3" className={classes.tableHeader}> {t('requestDate')} </Box>
            <Box gridColumn="span 3" className={classes.tableHeader}> {t('requestStatus')} </Box>
        
            { renderData }
        </Box>  
    );
};

const RequestTableRow: React.FC<ITableRow> = props => {
    const {row, status} = props;
    const t = useTranslate('Projects');

    const rd = row.data.requestDate; //test '5.2.2021 2:10:12'

    const requestDate: string = rd
        ? rd.includes('T')
            ? moment(rd).format('D MMMM YYYY')
            : moment(rd, 'DD.MM.YYYY HH:mm:ss').format('D MMMM YYYY')
        : ''

    return (
        <Fragment>
            <Box gridColumn="span 3">
              { row.data.workflowNumber ? row.data.workflowNumber : t('requestEmpty')}
            </Box>
            <Box gridColumn="span 3">
              { row.data.requestNumber }
            </Box>
            <Box gridColumn="span 3">
              { requestDate }
            </Box>
            <Box gridColumn="span 3">
                { status && status.data.name ? status.data.name.replaceAll('"', '') : t('requestEmpty') }
            </Box>
        </Fragment>
    );
}