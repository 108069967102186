import React from 'react';

import {
    List,
    ListItem,
    withStyles,
    ListItemText,
    ListItemIcon,
    CircularProgress,
    Box
} from '@material-ui/core';

import { FileIcon,  defaultStyles } from 'react-file-icon';
import FileName from 'components/Attach/FileName';
import renderHTML from 'helpers/renderHTML';

const styles = theme => ({
    list: {
        padding: 8,
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    listItem: {
        padding: 0
    },
    listItemText: {
        padding: '0 0 0 16px',
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    iconWrapp: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 45
        }
    },
    smallIcon: {
        minWidth: 15
    },
    fileIcon: {
        width: 26, // иконки рисуются в боксе 40х48
        height: 32,
        display: 'inline-block'
    }
});

const FileNameColumn = ({ classes, item, name, customName, extension, iconSize, whiteSpace }) => (
    <List className={classes.list}>
        <ListItem className={classes.listItem}>
            <ListItemIcon
                classes={
                    {
                        root: iconSize < 20 ? classes.smallIcon : classes.iconWrapp
                    }
                }
            >
                {
                    (item instanceof File)
                    ? (
                        <CircularProgress size={iconSize || 32} />
                    ) : (
                        <Box className={classes.fileIcon}>
                            <FileIcon extension={extension} {...defaultStyles[extension]} />
                        </Box>
                    )
                }
            </ListItemIcon>
            <ListItemText
                inset={true}
                className={classes.listItemText}
                primary={(
                    <FileName whiteSpace={whiteSpace}>{customName ? renderHTML(customName) : name}</FileName>
                )}
            />
        </ListItem>
    </List>
);

export default withStyles(styles)(FileNameColumn);
