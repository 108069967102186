import { createStyles, makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => createStyles({
    fileName: {
        display: 'flex',
        alignItems: 'center'
    },
    fileIcon: {
        width: 26,
        height: 32,
        display: 'inline-block',
        marginRight: theme.spacing(1.5)
    },
    actionsCell: {
        textAlign: 'right',
        padding: theme.spacing(0, 1)
    }
}));
