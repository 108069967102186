import React from 'react';
import { IModuleInfo } from '@eis/types';
import { InfoPage } from './pages/InfoPage';
import HelpIcon from '@material-ui/icons/Help';
import { PagesIDEnum } from '@app/application/menu/consts';

const module: IModuleInfo = {
    routes: [{
        id: 'InfoPage',
        path: '/info',
        component: InfoPage
    },
    // {
    //     id: 'NewsPage',
    //     path: '/news',
    //     component: NewsPage
    // }
],
    navigation: [
    //     {
    //     id: PagesIDEnum.News,
    //     priority: 60,
    //     Component: NewsMenuItem
    // }, 
    {
        id: PagesIDEnum.InfoPage,
        path: '/info',
        icon: <HelpIcon />
    }]
};

export default module;