import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useRoles } from '@eis/hooks/useFundStaff';
import AppConfig from 'config/index';


const useStyles = makeStyles(theme => createStyles({
    root: {
        borderColor: theme.palette.primary.main
    },
    selected: {
        backgroundColor: theme.palette.primary.main + ' !important;',
        '& > span': {
            color: 'white',
            fontWeight: 'bold'
        }
    },
    label: {
        color: theme.palette.primary.main
    }

}));

export interface CustomToolBarWithSwitchProps {
    /** Какой сейчас активный */
    current: string;
    /** Идентификатор элемента для юнита */
    unitTitleId: string;
    /** Идентификатор-Заголовок */
    items: Record<string, string>;
}

export const CustomToolBarWithSwitch: React.FC<CustomToolBarWithSwitchProps> = props => {
    const classes = useStyles();
    const history = useHistory();
    const isStaff = useRoles();

    const handleClick = (event: React.MouseEvent<HTMLElement>, newItem: string | null) => {
        // console.log(newItem);
        if (newItem && newItem !== props.current) {
            history.push(`/tasks/${newItem}`);
        }
    }

    const forUnitTitle =  AppConfig.taskMenu && AppConfig.taskMenu.unitTitle; 
    const forStaffTitle =  AppConfig.taskMenu && AppConfig.taskMenu.stuffTitle;

    const getTitle = (key: string) => {
        return key === props.unitTitleId 
        ? 
            (isStaff ? forStaffTitle : forUnitTitle) || props.items[key]
        : props.items[key]
    }

    return (
        <ToggleButtonGroup
            size="small"
            exclusive={true}
            value={props.current}
            onChange={handleClick}>
            {
                Object.keys(props.items).map(key => (
                    <ToggleButton key={key} value={key} classes={classes}>
                        { getTitle(key) }
                    </ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    )
}