import React from 'react';
import { Typography, Card, CardActionArea, CardContent } from '@material-ui/core';
import classNames from 'classnames';

interface CardRequestProps {
    id: number;
    idx: number;
    active: boolean;
    classes: Record<string, string>;
    title: string;
    description: string;
    handleClick: (id: number, idx: number) => void;
}
export const CardRequest: React.FC<CardRequestProps> = props => {
    const { id, idx, classes, active, title, description, handleClick } = props;

    const cardContent = () => ((
        <CardContent>
            <Typography variant="h5" className={active ? classes.title : classes.titleDraft}>
                <span className={classNames(classes.digit, {
                    [classes.digitDraft]: !active
                })}>{idx}</span> {title}
            </Typography>
            <Typography variant="body1" className={classes.body}>
                {description}
            </Typography>
        </CardContent>
    ));

    return (
        <Card className={classNames([classes.card, {
            [classes.cardOk]: active
        }])}>
            {active
                ?
                <CardActionArea onClick={() => handleClick(id, idx)}>
                    {cardContent()}
                </CardActionArea>
                :
                cardContent()}
        </Card>
    );
};
