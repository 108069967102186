import React, { FC } from 'react';
import { createStyles, LinearProgress, makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => createStyles({
    root: {
        height: 2,
        zIndex: 1300,
        marginBottom: -2
    },
    progress: {
        height: 2
    }
}));


interface ProgressLineProps {
    loading?: boolean;
    styles?: React.CSSProperties
}

/**
 * Progress line on top app
 * @param param
 */
export const ProgressLine: FC<ProgressLineProps> = ({ loading, styles }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={styles}>
            {loading && <LinearProgress className={classes.progress} />}
        </div>
    );
}
