import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-translate';

import ModulePage from 'components/ModulePage';
import endPoint from '@cab/endPoints/message';

import processList from 'services/processList';
import { load, onFilterChange } from 'services/dataTable/actions';

import MessageListLayout from './components/MessageListLayout';

class MessageListPage extends ModulePage {
    componentDidMount() {
        super.componentDidMount();
        processList.set('messageListInit', () => this.init(true));
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        processList.hasOrSet('messageListInit', this.init);
    }

    init = (refresh) => {
        const {
            data,
            error,
            defaultFilters,
            actions
        } = this.props;

        if ((data || error) && !refresh) {
            return;
        }

        defaultFilters ? actions.onFilterChange(defaultFilters) : actions.load();
    };

    handleItemClick = (message) => {
        const { history } = this.props;
        history.push(`/messages/${message.id}`);
    };

    render() {
        const {
            t,
            data,
            title,
            loading,
            location
        } = this.props;

        return (
            <MessageListLayout
                data={data}
                title={t(title)}
                loading={loading}
                location={location}
                handleItemClick={this.handleItemClick}
            />
        );
    }
}

const translated = translate('MessageListPage')(MessageListPage);
const mapStateToProps = ({ messagesList: { loading, data } }) => ({ loading, data });
const mapDispatchToProps = dispatch => ({
    actions: {
        load: bindActionCreators(load(endPoint), dispatch),
        onFilterChange: bindActionCreators(onFilterChange(endPoint), dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(translated);
