import React, { Fragment } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import className from 'classnames';

const useStyles = makeStyles(theme => createStyles({
    root: {
        margin: `${theme.spacing(2)}px auto`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        
        // margin: '0px 8px -12px -8px'
    },
    dot: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        margin: '0px 2px',
        backgroundColor: 'silver',
        borderRadius: '50%',
        cursor: 'pointer'
    }, 
    current: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'default'
    }
}));

interface StepperMiniProps {
    steps: Array<string>,
    current: number,
    onClick?: (step: number) => any;
}

export const StepperMini: React.FC<StepperMiniProps> = props => {
    const { steps, current, onClick } = props;
    const classes = useStyles();

    return (
        <Fragment>
            <Box className={classes.root}>
                {steps.map((step, index) => (
                    <Box 
                        className={className(classes.dot, {[classes.current]: index === current})} 
                        title={step}
                        onClick={onClick !== undefined ? onClick(index) : undefined} >
                    </Box>
                ))}
            </Box>
        </Fragment>
    )
}