import { cleanUndefined } from '@core/helpers/cleanObject';
import React, { useEffect } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import ChangeEvent from '../ChangeEvent';

import { StringElement } from './StringElement/StringElement';
import { StringElementProps } from "./StringElement/StringElementProps";

interface NumberFormatCustomProps {
    toFixed?: number;
    ref: React.Ref<any>, 
    onChange: (value: any) => void, 
    format?: string 
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps & NumberFormatProps> = (props) => {
    const { ref, onChange, format, toFixed } = props;
    const { 
        thousandSeparator, allowedDecimalSeparators, decimalSeparator, allowNegative, ...rest } = props;
    return (
        <NumberFormat
            {...rest}
            allowedDecimalSeparators={allowedDecimalSeparators || ['.', ',']}
            allowNegative={allowNegative !== undefined ? allowNegative : false}
            decimalScale={toFixed || 2}
            decimalSeparator={decimalSeparator || '.'}
            fixedDecimalScale={true}
            format={format}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange(values.value)
            }}
            thousandSeparator={thousandSeparator || ' '}
            isNumericString={true}
        />
    );
}

export interface NumberElementProps extends StringElementProps {
    toFixed: number
}

const defaultProps: Partial<NumberElementProps> = {
    toFixed: 0
}

export const NumberElement: React.FC<NumberElementProps> = props => {
    const { value, onChange, required, defaultValue, mask, toFixed } = {...defaultProps, ...props};

    // Задаємо при старті значення за замовченням
    useEffect(() => {
        if (required && !value && defaultValue !== undefined && onChange) {
            onChange(defaultValue);
        }
        // eslint-disable-next-line 
    }, []);

    const selfOnChange = (value: string | number | ChangeEvent) => {
        if (onChange) {
            const numberValue = Number(value);
            onChange(numberValue)
        }
    }

    const handleInputValue = (value: string) => {
        return Number(Number(value.trim().replaceAll(' ','')).toFixed(toFixed));
    }

    // Перевизначені проперті
    const sp: StringElementProps = {
        ...props,
        type: 'text',
        mask: mask ? String(mask).replace(/9/g, '#') : undefined,
        inputComponent: NumberFormatCustom as any,
        onInputValue: handleInputValue as any,
        onChange: selfOnChange
    }

    return (
        <StringElement 
            {...cleanUndefined(sp) as any} 
            />
    )
}  

export default NumberElement;