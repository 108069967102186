import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-translate';
import {
    IntegratedSorting,
    SortingState,
    Sorting,
    SearchState,
    IntegratedFiltering,
    DataTypeProvider,
    PagingState,
    IntegratedPaging
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    Toolbar,
    SearchPanel,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { PaperLayout, useAppHeader } from '@eis/components';
import { Preloader } from '@eis/components/Preloader';
import TimeLabel from '@core/components/Label/Time';
import { RegistryKeysEnum } from '@eis/consts';
import { requestRegisterKeyRecords } from '@cab/actions/registry';
import { columns, PageSizes } from './columns';
import { genRows, IRow } from './rows';
import { CurrencyTypeProvider } from './CurrencyTypeProvider';
import { useStyles } from '../styles';
import { Cell, HeaderCell } from './components';
import { StatusTypeProvider } from './StatusTypeProvider';


export const ProjectsListPage: React.FC = () => {
    const t = useTranslate('Projects');
    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [rows, setRows] = useState<Array<IRow> | undefined>();

    // const [projects, setProjects] = useState<Array<IProjectInfo> | undefined>();
    // const projects = useRegistry<IProjectInfo>(RegistryKeysEnum.FondProjects, true);
    const dispatch = useDispatch();
    useEffect(() => {
        const load = async () => {
            const projects = await requestRegisterKeyRecords(RegistryKeysEnum.FondProjects, { strict: false, limit: 30000 })(dispatch);
            setRows(genRows(projects));
        }
        load();
    }, [dispatch]);

    // const rows = genRows(projects || []);
    const [sorting, setSorting] = useState<Array<Sorting>>([{ columnName: 'projectNumber', direction: 'asc' }]);
    const [searchValue, setSearchState] = useState('');

    useAppHeader({ title: t('title') });

    if (rows === undefined) return <Preloader />

    return (
        <PaperLayout fullWidth={true} >
            <div style={{ counterReset: `row ${ pageSize * currentPage }` }}></div>
            <Grid
                rows={rows}
                columns={columns}
            >
                <SortingState
                    columnSortingEnabled={false}
                    columnExtensions={columns}
                    sorting={sorting}
                    onSortingChange={setSorting} />
                <IntegratedSorting />

                <SearchState
                    value={searchValue}
                    onValueChange={setSearchState} />
                <IntegratedFiltering />

                <DataTypeProvider
                    for={['num']}
                    formatterComponent={() => (<span className={classes.rowWithNumber}> </span>)} />
                <DataTypeProvider
                    for={['projectNumber']}
                    formatterComponent={({ value }) => (<NavLink to={`projects/${value}`}>{value}</NavLink>)} />
                <StatusTypeProvider
                    for={['projectStatus']} />
                <DataTypeProvider
                    for={['contractDate']}
                    formatterComponent={({ value }) => value
                        ? <TimeLabel date={value} format='DD.MM.YYYY' />
                        : null} />
                <CurrencyTypeProvider
                    for={['totalCost', 'grantAmount']} />
                
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    />

                <IntegratedPaging />

                <Table
                    columnExtensions={columns}
                    cellComponent={Cell}
                    messages={{
                        noData: t(searchValue ? 'ProjectsNotFound' : 'ProjectsListClear')
                    }} />

                <TableHeaderRow
                    showSortingControls={true}
                    cellComponent={HeaderCell} />
                <Toolbar />
                <SearchPanel 
                    messages={{
                        searchPlaceholder: t('searchPlaceholder')
                    }}/>
                <PagingPanel
                    pageSizes={PageSizes}
                    messages={{
                        rowsPerPage: t('rowsPerPage'),
                        info: (p) => `${p.from}-${p.to} з ${p.count}`
                    }}
                    />

            </Grid>
        </PaperLayout>
    )
}

