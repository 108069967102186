import { ThemeOptionsExt } from "@app/theme";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

// Общие стили элементы форм
export const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        marginBottom: theme.spacing(2),
        maxWidth: ThemeOptionsExt(theme).controlsMaxWidth,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
            minWidth: '40px!important',
            '&>div>label': {
                top: 3,
                paddingRight: 20
            },
            '&>div>div>input': {
                fontSize: 13,
                lineHeight: '22px'
            }
        }
    },
    noMargin: {
        margin: 0
    },
    menuItem: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    textField: {
        '& p': {
            whiteSpace: 'normal'
        }
    },
    adornment: {
        '& p': {
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('xs')]: {
                fontSize: 13
            }
        }
    },
    placeHolderRoot: {
        fontSize: 16,
        lineHeight: '18px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13
        }
    },
    select: {
        '&:focus': {
            background: 'transparent'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px',
            minHeight: 22,
            display: 'flex',
            alignItems: 'center'
        }
    }
}));

export const useWidthStyles = (width: any, maxWidth: any) => {
    return makeStyles(() => createStyles({
        customWidth: {
            width,
            maxWidth
        }
    }))
}