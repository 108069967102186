import momentJS from 'moment';
// import ts from 'typescript';
import EvaluateError from './EvaluateError';
import api from './api'


/**
 * 
 * @param func 
 * @param params 
 * @returns 
 */
export const evaluateJS = (func: string, ...params: any[]) => {
    try {
        // eslint-disable-next-line
        const moment = momentJS;
        // eslint-disable-next-line
        const frontApi = api;
        // eslint-disable-next-line
        return eval(func)(...params);
    } catch (e: any) {
        return new EvaluateError(e.message, func, params);
    }
}

/**
 * 
 * @param funcTS 
 * @param params 
 * @returns 
 */
export const evaluateTS = (funcTS: string, ...params: any[]) => {
    return ''
    // const func = ts.transpile(funcTS);
    // return evalateJS(func, params);
}