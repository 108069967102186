import qs from 'qs';

export default {
    dataURL: 'custom/mvs/registers/get-subscribed-services',
    sourceName: 'subscribedServicesList',
    method: 'POST',
    mapData: (payload) => {
        const { meta } = payload;
        const { limit, count, offset } = meta || {};

        return {
            data: payload,
            page: Math.ceil(offset / limit) + 1,
            rowsPerPage: limit,
            count
        };
    },
    getDataUrl: (dataURL, { page, rowsPerPage, sort }, useQueryParams = true) => {
        const offset = ((page || 1) - 1) * rowsPerPage;

        const queryString = qs.stringify({ offset, limit: rowsPerPage, sort }, { arrayFormat: 'index' });

        return dataURL + ((useQueryParams && queryString) ? '?' + queryString : '');
    }
};
