import React, { Fragment, useCallback, useState, useEffect } from 'react';
import {
    InputProps as InputPropsType, InputAdornment,
    SelectProps, MenuItem, TextField, TextFieldProps, InputLabelProps
} from '@material-ui/core';
import { useTranslate } from 'react-translate';
import classNames from 'classnames';
import { debounce } from 'lodash';

import evalate from '@core/helpers/evalate';
import renderHTML from '@core/helpers/renderHTML';
import { useStyles, useWidthStyles } from '../elementsStyles';
import FieldLabel from '../../components/FieldLabel';
import EJVError from '../../components/EJVError';
import { StringElementProps } from './StringElementProps';
import { modification } from './helper';
import ChangeEvent from '../../ChangeEvent';
import { cleanUndefined } from '@core/helpers/cleanObject';
import { Masked } from './mask';


// Значення за замовченням
const defaultProps: Partial<StringElementProps> = {
    type: 'text',
    fullWidth: true,
    cutTags: true,
    path: []
}

/**
 * Компонент для редагування текстових даних
 */
export const StringElement: React.FC<StringElementProps> = props => {
    const { hidden, value, defaultValue, width, maxWidth, onChange, ...rest } = { ...defaultProps, ...props };
    const [focused, setFocused] = useState(false);

    const getDefaultValue = () => {
        // TODO: Get from url\localStorage value  
        return value || defaultValue || ''
    }
    const [inputValue, setInputValue] = useState(getDefaultValue());

    const classes = useStyles();
    const classesWidth = useWidthStyles(width, maxWidth)();
    const t = useTranslate('Elements');

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    // Виконує переданий хендлєр події зміни даних
    const notifyChange = useCallback(debounce((value: string | number) => {
        if (onChange) {
            if (changeOnBlur) {
                onChange(new ChangeEvent(value, true));
            } else {
                onChange(value);
            }
        }
    }, .3 * 1000), [onChange]);

    // Схований елемент
    if (hidden) return null;

    // Загальні проперті та котрі необхідні для аналізу 
    const { fullWidth, autoFocus, path, disabled, helperText, sample, description, schema, placeholder,
        required, readOnly, error, options, outlined,
        noMargin,
        startAdornment, endAdornment, InputProps,
        onFocus, changeOnBlur, onBlur, useTrim, onInput,
        type, multiline, rows, rowsMax, maxLength, inputProps,
        mask, inputComponent, onInputValue
    } = rest;

    const formHelperText = (helperText || sample || (schema && schema.sample)) 
        && renderHTML(helperText || sample || (schema && schema.sample));
    const errorText = error && <EJVError error={error} />;

    // Обробник події на зміну значення 
    const handleChange = (input: HTMLInputElement) => {
        if (input) {
            const selectionStart = input.selectionStart || 0;
            const oldLength = input.value.length;

            // Вбудована обробка введеного тексту
            let newValue = modification(input.value, props);
            try {
                // Довільна обробка з сценарію процесу 
                if (onInput) newValue = evalate(onInput, newValue);

                newValue = String(onInputValue(newValue));
            } catch (err) {
                // Nothing to do
            }

            if (newValue !== input.value) {
                const cursorPosition = selectionStart + (newValue.length - oldLength);
                input.value = newValue;
                input.selectionStart = cursorPosition;
                input.selectionEnd = cursorPosition;
            }
            setInputValue(newValue);
            return newValue;
        } else {
            return inputValue;
        }
    };

    // Маска вводу
    const getMask = (): string | undefined => {
        const { stepName, parentValue, defaultMask, rootDocument } = props;

        if (typeof mask === 'string') return mask;

        if (mask && Array.isArray(mask)) {
            const activePattern = mask.map(({ isActive, pattern }) => {
                const result = evalate(isActive, value, rootDocument.data[stepName] || {}, rootDocument.data || {}, parentValue);
                if (result instanceof Error) return null;
                if (result) return pattern;
                return null;
            }).filter(patt => patt).pop() || null;

            if (!activePattern) return defaultMask || undefined;
            return activePattern;
        }

        return undefined;
    };

    // Варіант view компонента
    const variant = outlined ? 'outlined' : 'standard';

    // Використання id в такому виді потребує скролінг по якорю сторінки
    const cid = path.join('-');

    // Рендер списку для popup
    const renderSelectOptions = () => {
        const { emptyValue, rootDocument } = props;

        let items: Array<any>;
        try {
            const selectItems: Array<string | { id: string, name: string }> = typeof options === 'string'
                ? evalate(options, rootDocument.data)
                : Object.values(options);

            items = selectItems.map(option => {
                const optionId = typeof option === 'string' ? option : option.id;
                const optionName = typeof option === 'string' ? option : option.name;
                return <MenuItem key={optionId} value={optionId} className={classes.menuItem}>{optionName}</MenuItem>;
            });
        } catch (err: any) {
            items = [<MenuItem disabled={true} className={classes.menuItem}>{t('ErrorGenItems')}: {err.message}</MenuItem>];
        }

        if (emptyValue !== undefined) {
            items.unshift(
                <MenuItem 
                    key="-" value={typeof emptyValue === 'string' ? emptyValue : ''} 
                    className={classes.menuItem}>
                        {typeof emptyValue === 'string' ? <em>{emptyValue}</em> : '-'}
                </MenuItem>);
        }

        return items;
    }

    const asSelect = Boolean(options && !readOnly);

    // Показати label маленького розміру над значенням
    const shrink: boolean = Boolean(value) || (value !== undefined && schema?.type === 'number') 
        || Boolean(readOnly) || focused;
    const ilp: InputLabelProps = {
        shrink
    }

    // Атрибути Masked-елемента
    const { formatChars, maskChar } = rest;
    const inputMaskProps = {
        mask: getMask(),
        formatChars,
        maskChar
    }

    const ip: InputPropsType = {
        ...InputProps,
        readOnly,

        // текстове в декілька рядків
        multiline,
        rows: multiline && rows ? rows : undefined,
        rowsMax: multiline && rowsMax ? rowsMax : undefined,

        // Суфікси\префікси
        startAdornment: (focused || inputValue) && startAdornment
            ? <InputAdornment position="start">{startAdornment}</InputAdornment>
            : undefined,
        endAdornment: (focused || inputValue) && endAdornment
            ? <InputAdornment position="end">{endAdornment}</InputAdornment>
            : undefined,

        // <input> с кастомізованою логікою 
        inputComponent: inputComponent || (mask ? Masked as any : undefined),
    };

    const sp: SelectProps = {
        // TODO: що потрібно?
    }

    // Атрибути стандартного <input> 
    const nativeInputProps: Partial<HTMLInputElement> = {
        ...inputProps,
        ...inputMaskProps,
        maxLength,
        // style: !multiline ? { height: 'auto' } as any : undefined
    }

    const textFieldProps: TextFieldProps = {
        // загальні
        id: cid,
        type,
        select: asSelect,
        autoFocus,
        disabled,
        error: Boolean(error),

        // Стили, внешний вид
        fullWidth,
        variant,
        classes: {
            root: classNames({
                [classes.formControl]: true,
                [classesWidth.customWidth]: Boolean(width) || Boolean(maxWidth),
                [classes.noMargin]: noMargin
            })
        },

        // Контент
        label: description ? <FieldLabel description={description} required={required} /> : null,
        value: inputValue !== undefined 
            ? inputValue
            : readOnly ? t('NotSet') : '',
        helperText: (formHelperText || Boolean(error)) && (
            <Fragment>
                {formHelperText}
                {errorText && formHelperText && <br />}
                {errorText}
            </Fragment>
        ),
        placeholder,

        // Проперті внутрішніх компонентів
        InputLabelProps: ilp,
        InputProps: asSelect ? undefined : cleanUndefined(ip),
        inputProps: asSelect ? undefined : cleanUndefined(nativeInputProps),
        SelectProps: asSelect ? cleanUndefined(sp) : undefined,

        // Події
        onChange: (event) => {
            const newValue = handleChange(event.target as HTMLInputElement);
            if (newValue !== inputValue) {
                setInputValue(newValue);
                if (!changeOnBlur) {
                    notifyChange(newValue);
                }
            }
        },

        onFocus: (event) => {
            setFocused(true);
            if (onFocus) onFocus(event);
        },

        onBlur: (event) => {
            setFocused(false);
            if (changeOnBlur || useTrim) {
                let newValue = handleChange(event.target as HTMLInputElement);
                if (useTrim && type === 'text') newValue = String(newValue).trim();
                notifyChange(newValue);
            }
            if (onBlur) onBlur(event);
        }
    }

    return (
        <TextField
            {...textFieldProps} >
            {asSelect && renderSelectOptions()}
        </TextField>
    )
}
