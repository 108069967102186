import {AUTH_HOOK} from "@eis/types";
import { merge, get } from "lodash";

export type ModuleHooks = {
    auth: AUTH_HOOK[];
};

interface ModuleHooksStorage {
    storage: ModuleHooks;
    extend(hooks: ModuleHooks): void;
    run(path: string, ...rest: any): void;
}

export const moduleHooks: ModuleHooksStorage = Object.seal({
    storage: Object.seal({
        auth: []
    }) as ModuleHooks,
    extend(hooks: ModuleHooks) {
        this.storage = merge(this.storage, hooks);
    },
    run(path: string, ...rest: any): void{
        get(this.storage, path, []).forEach((cb: Function | unknown) => {
            if(!(cb instanceof Function)) {
                return console.error(`[STORAGE.HOOK]: hook is not callable`);
            }

            //TODO: add catching errs
            cb(...rest);
        });
    }
});
