import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import {
    Toolbar,
    Button,
    withStyles
} from '@material-ui/core';

import SigningDialog from './SigningDialog';
import RejectSigningDialog from './RejectSigningDialog';

import signRequired from '../../../helpers/signRequired';

const styles = theme => ({
    leftIcon: {
        marginRight: theme.spacing()
    },
    grow: {
        flexGrow: 1
    },
    toolbar: {
        position: 'sticky',
        width: '100%',
        left: 0,
        bottom: 0,
        padding: 8,
        background: '#fff',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        '@media screen and (max-width: 600px)': {
            paddingLeft: '8px',
            display: 'block'
        }
    },
    backButton: {
        marginRight: 10,
        '@media screen and (max-width: 600px)': {
            margin: '10px 0',
            width: '100%'
        }
    },
    printButton: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
});

const SigningActionLayout = ({
    t,
    classes,
    busy,
    handleFinish,
    backToEdit,
    task: { isMePerformer, isMeSigner, signerUsers },
    task,
    template,
    alreadySigned,
    alreadyRejected,
    steps,
    showSigningDialog,
    showRejectSigningDialog,
    toggleSigningDialog,
    toggleRejectSigningDialog,
    onSelectKey,
    onRejectSigning,
    finished
}) => {
    if (finished) {
        return (
            <Toolbar className={classes.toolbar}>
                {
                    isMePerformer && steps.length > 0 ? (
                        <Button
                            size="large"
                            variant="outlined"
                            onClick={backToEdit}
                            className={classes.backButton}
                            id="prev-step-btn"
                        >
                            {t('PrevStepBtn')}
                        </Button>
                    ) : null
                }
            </Toolbar>
        );
    }
    return (
        <Toolbar className={classes.toolbar}>
            <div className={classes.grow} />

            {
                isMePerformer && !alreadySigned && !alreadyRejected && steps.length > 0 ? (
                    <Button
                        size="large"
                        variant="outlined"
                        onClick={backToEdit}
                        className={classes.backButton}
                        id="prev-step-btn"
                    >
                        {t('PrevStepBtn')}
                    </Button>
                ) : null
            }

            {
                signRequired(template, task) && (isMeSigner || !signerUsers.length) ? (
                    <>
                        <Button
                            size="large"
                            disabled={alreadySigned || alreadyRejected || busy}
                            variant="contained"
                            color="primary"
                            onClick={() => toggleSigningDialog(true)}
                            className={classes.backButton}
                            id="sign-btn"
                        >
                            {t('SignBtn')}
                        </Button>
                        {
                            signerUsers.length && !isMePerformer ? (
                                <Button
                                    size="large"
                                    disabled={alreadySigned || alreadyRejected || busy}
                                    variant="outlined"
                                    onClick={() => toggleRejectSigningDialog(true)}
                                    id="reject-sign-btn"
                                >
                                    {t('RejectSignBtn')}
                                </Button>
                            ) : null
                        }
                    </>
                )
                    : (
                        <Button
                            size="large"
                            disabled={busy}
                            variant="contained"
                            onClick={handleFinish}
                            color="primary"
                            id="finish-btn"
                        >
                            {t('FinishBtn')}
                        </Button>
                    )
            }
            <SigningDialog
                open={showSigningDialog}
                onSelectKey={onSelectKey}
                onClose={() => toggleSigningDialog(false)}
            />
            <RejectSigningDialog
                open={showRejectSigningDialog}
                handleDone={onRejectSigning}
                onClose={() => toggleRejectSigningDialog(false)}
            />
        </Toolbar>
    );
};


SigningActionLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    busy: PropTypes.bool.isRequired,
    handleFinish: PropTypes.func.isRequired,
    backToEdit: PropTypes.func.isRequired,
    alreadySigned: PropTypes.bool.isRequired,
    alreadyRejected: PropTypes.bool.isRequired,
    showSigningDialog: PropTypes.bool.isRequired,
    showRejectSigningDialog: PropTypes.bool.isRequired,
    toggleSigningDialog: PropTypes.func.isRequired,
    toggleRejectSigningDialog: PropTypes.func.isRequired,
    onSelectKey: PropTypes.func.isRequired,
    onRejectSigning: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    printPdfButton: PropTypes.bool,
    steps: PropTypes.array,
    finished: PropTypes.bool
};

SigningActionLayout.defaultProps = {
    steps: [],
    printPdfButton: false,
    finished: false
};

const styled = withStyles(styles)(SigningActionLayout);
const translated = translate('TaskPage')(styled);
export default translated;
