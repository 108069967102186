import { IFileColumn } from './types';

export enum DefaultColumnsName {
    FileName = 'fileName',
    FileDate = 'fileDate',
    Actions = 'actions'
}

export const DefaultColumnFileName: IFileColumn = {
    name: DefaultColumnsName.FileName,
    columnName: DefaultColumnsName.FileName,
    title: 'Документ',
    width: 'auto'
};

export const DefaultColumnFileDate: IFileColumn = {
    name: DefaultColumnsName.FileDate,
    columnName: DefaultColumnsName.FileDate,
    title: 'Дата',
    width: 150,
    align: 'center',
    wordWrapEnabled: true
};

export const makeDefaultActionColumn = (width: number | string): IFileColumn => {
    return {
        name: DefaultColumnsName.Actions,
        columnName: DefaultColumnsName.Actions,
        title: ' ',
        width
    }
}