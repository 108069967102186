import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import { FilesTreeView, FileEntity, IFileRow, IFileColumn } from '@eis/components/FilesTreeView';
import { useSelector } from 'react-redux';
import { AppRootState } from '../../../../../../store';

enum TreeColumnsName {
    Project = 'project',
    Request = 'request',
    DocsType = 'docsType',
    DocTypeTitle = 'docTypeTitle',
    DocNumber = 'docNumber'
}


const columns: Array<IFileColumn> = [
    {
        name: TreeColumnsName.Project,
        columnName: TreeColumnsName.Project
    },
    {
        name: TreeColumnsName.Request,
        columnName: TreeColumnsName.Request
    },
    {
        name: TreeColumnsName.DocsType,
        columnName: TreeColumnsName.DocsType
    },
    {
        name: TreeColumnsName.DocTypeTitle,
        columnName: TreeColumnsName.DocTypeTitle
    },
    {
        name: TreeColumnsName.DocNumber,
        columnName: TreeColumnsName.DocNumber,
        title: 'Номер',
        align: 'center',
        width: 200
    }
];

interface IFileRowExt extends IFileRow {
    project: string;
    request: string;
    docsType: string;
    docTypeTitle: string;
    fileData: any;
    docNumber: string;
}

interface DocType {
    code: string;
    name: string;
    codeSort: number;
    docGroup: string;
    listProc: string,
    shortName: string;
    docFieldGroup: Array<string>;
}

interface StaticName {
    rootTitle: string;
    rootNumber: string;
    other: string;
}

const makeRows = (files: Array<FileEntity>, names: StaticName, docTypes: Array<DocType>): Array<IFileRowExt> => {
    const result = new Array<IFileRowExt>();
    const nums = new Map<string, number>();

    const makeRow = (file: FileEntity, docFolder: string, docName: string): IFileRowExt => {
        const d = new Date(file.createdAt);
        const docNumber = `${names.rootNumber}/${file.attributes.request}/${file.attributes.docType}-${d.getUTCFullYear()}/${d.getUTCMonth()}/${d.getUTCDate()}`;
        nums.set(docNumber, nums.has(docNumber) ? nums.get(docNumber)! + 1 : 1);

        return {
            id: file.id,
            project: names.rootTitle,
            request: `Заявка ${file.attributes.request || 1}`,
            docsType: docFolder,
            docTypeTitle: docName,
            fileDate: d,
            fileName: file.name,
            fileData: file,
            docNumber: `${docNumber}-${nums.get(docNumber)}`
        }
    }

    Array.from(files).sort((f1: FileEntity, f2: FileEntity) => {
        return f1.createdAt < f2.createdAt ? -1 : 1;
    })
    .forEach(file => {
        let docCode = String(file.attributes.docType);
        if (docCode.length === 1) docCode = '0' + docCode;
        const dt = docTypes.find(d => d.code === docCode);
        if (dt && dt.docFieldGroup) {
            dt.docFieldGroup.forEach(f => result.push(makeRow(file, String(f).trim(), dt.shortName)))
        } else {
            result.push(makeRow(file, names.other, file.name));
        }
    });
    
    return result;
}


export interface ProjectFilesTreeViewProps {
    rootNumber: string;
    docTypes: Array<DocType>;
    files?: Array<FileEntity>;
}

export const ProjectFilesTreeView: React.FC<ProjectFilesTreeViewProps> = props => {
    const t = useTranslate('Projects');
    const fileStorage = useSelector((appStore: AppRootState) => appStore.files);
    const [rows] = useState(makeRows(
        props.files || [],
        {
            rootTitle: t('RootTitle', { project: props.rootNumber }),
            rootNumber: props.rootNumber,
            other: t('OtherDocs')
        },
        props.docTypes));
    return (
        <FilesTreeView
            rows={rows}
            columns={columns}
            grouping={[
                TreeColumnsName.Project,
                TreeColumnsName.Request,
                TreeColumnsName.DocsType,
                TreeColumnsName.DocTypeTitle]}
            fileStorage={fileStorage}
            previewButton={false}
            signButton={false}
            downloadButton={true}
            messages={{
                noFiles: t('NoFiles'),
                previewButton: t('previewButton'),
                signButton: t('signButton'),
                downloadButton: t('downloadButton'),
                searchPlaceholder: t('searchPlaceholder'),
                viewModeHint: t('viewModeHint')
            }}
        />
    )
}
