import React from 'react';

import AddItemButton from './AddItemButton';
import DeleteItemButton from './DeleteItemButton';
import SchemaForm from '../../SchemaForm';

export default ({ t, rows, errors, schema, actions, columns, onChange, fileStorage, path, data, readOnly, ...rest }) => {
    let tableColumns = [];

    if (rows) {
        tableColumns.push({
            variant: 'header',
            render: (value, item, columnKey, rowIndex) => rows[rowIndex]
        });
    }

    tableColumns = tableColumns.concat(Object.keys(columns).map((key) => {
        const { description, ...elementSchema } = columns[key];
        return {
            id: key,
            name: description,
            padding: 'checkbox',
            render: (value, item, columnKey, rowIndex) => (
                <SchemaForm
                    schema={elementSchema}
                    path={path.concat(rowIndex, key)}
                    fileStorage={fileStorage}
                    actions={actions}
                    margin="none"
                    description={''}
                    value={value}
                    errors={errors}
                    required={Array.isArray(schema.required) && schema.required.includes(key)}
                    onChange={onChange.bind(null, rowIndex, key)}
                    noMargin={true}
                    readOnly={readOnly}
                />
            )
        };
    }));

    if (!rows) {
        tableColumns.push({
            width: '10px',
            padding: 'none',
            render: (value, item, columnKey, rowIndex) => <DeleteItemButton t={t} readOnly={readOnly} deleteItem={actions.deleteItem(rowIndex)} />
        });
    }

    return {
        ...rest,
        actions,
        controls: {
            pagination: false,
            toolbar: true,
            search: false,
            header: !!(data && data.length),
            refresh: false,
            switchView: false,
            toggleWidth: true,
            ...rest.controls
        },
        checkable: false,
        cellStyle: { verticalAlign: 'baseline' },
        CustomToolbar: rows ? null : props => <AddItemButton readOnly={readOnly} {...props} t={t} />,
        columns: tableColumns
    };
};
