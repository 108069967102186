import { Dispatch } from 'redux';
import storage from '@core/helpers/storage';
import { logout } from '@core/actions/auth';
import { showServiceMessage } from '@core/actions/error';
import * as Sentry from '@sentry/browser';
import { API_URL } from './api';


interface API2MessageReq {
    service: API2ServicesEnum, 
    method: API2MethodsEnum, 
    params: Record<string, any>
}

export enum API2ServicesEnum {
    Registry = 'registry',
    CRM = 'crm',
    Bus = 'bus',
    Auth = 'auth'
}

export enum API2MethodsEnum {
    // Registry
    DashboardMetrics = 'registry/dashboard-metrics?',
    Catalog = 'registry/catalog/services?',

    // CRM
    UnreadCrmCount = 'crm/inbox/count/{token}',

    // Bus
    Swagger = 'bus/doc/{method}',

    // Auth
    SignOut = 'signOut',
    SignInCRM = 'signInCRM',

    // Flow
    FlowsApi = 'registry/flow/api?',
    FlowsWeb = 'registry/flow/web?',
    FlowWeb = 'registry/flow/web/{flow}',
}

const disabledDebugActions = [
    'REQUEST_USER_INFO',
    'SEARCH_USERS'
];

/**
 * Сервис для выполнения запросов по модели одного эндпоинта и полезной нагрузки
 */
export class API2 {

    private static dispatch: Dispatch;

    /**
     * Инициализация сервиса
     * @param dispatch Dispatch-функция приложения для обработки actions
     */
    public static init(dispatch: Dispatch) {
        API2.dispatch = dispatch;
    } 


    /**
     * Создает запрос и отправляет на task-сервис
     * @param request 
     * @param action 
     * @param payload 
     */
    private static async createRequest<T = any>(request: any, action: string, payload: any): Promise<T> {
        const { url, ...conf } = request;
        const { method } = request;
        API2.dispatch({ type: action, payload, body: request.body, url, method });
    
        const debugUserId = storage.getItem('debug-user-id');
        
        const headers = {
            ...conf.headers,
            token: storage.getItem('token'),
        }

        if (debugUserId && !disabledDebugActions.includes(action)) {
            headers['debug-user-id'] = debugUserId;
        }
        
        const requestData: RequestInit = {
            ...conf,
            cache: 'reload',
            headers
        };
    
        try {
            const response = await fetch(url, requestData);
    
            if (response.status === 401) {
                API2.dispatch(await logout());
                throw new Error('401 unauthorized');
            }
    
            if (response.status === 404) {
                throw new Error('404 not found');
            }
    
            const responseBody = await response.json();
            const errors = [].concat(responseBody.error, responseBody.errors).filter(err => Boolean(err));
    
            if (errors.length > 0) {
                const error = new Error(JSON.stringify(errors));
                throw error;
            }
        
            API2.dispatch({
                type: `${action}_SUCCESS`,
                payload: responseBody,
                request: payload,
                url,
                method,
                body: request.body
            });
            return responseBody;
        } catch (error: any) {
            if (error.message === 'Failed to fetch' && navigator.onLine) {
                API2.dispatch(showServiceMessage(error));
            }
    
            API2.dispatch({ type: `${action}_FAIL`, payload: error, url, method, body: request.body, request: payload });
    
            if (error.message !== '401 unauthorized' && error.message !== '404 not found' && error.message !== 'NetworkError when attempting to fetch resource.' && error.message === 'Failed to fetch') {
                Sentry.withScope((scope) => {
                    Object.keys(error).forEach(key => scope.setExtra(key, error[key]));
                    scope.setTag('service', 'api');
                    scope.setTag('Message', error.message);
                    scope.setExtra('url', url);
                    scope.setExtra('method', method);
                    scope.setExtra('body', request.body);
                    scope.setExtra('response', error.response);
                    Sentry.captureException(error);
                });
            }
            throw (error);
        }
    }

    /**
     * 
     * @param url 
     * @param body 
     * @returns 
     */
    private static async post<T = any>(url: string, body: API2MessageReq): Promise<T> {
        const request = {
            url: API_URL + url,
            method: 'post',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        };

        return API2.createRequest(request, body.method, body.params);
    }

    /**
     * 
     * @param service 
     * @param method 
     * @param params 
     * @returns 
     */
    public static async send<T = any>(service: API2ServicesEnum, method: API2MethodsEnum, params: Record<string, any> = {}): Promise<T> {
        const res = await API2.post<{data: T}>(`proxy`, { service, method, params });
        return res.data as T;
    }
}