import React from 'react';
import { translate } from 'react-translate';

import {
  withStyles,
  InputBase,
  createStyles
} from '@material-ui/core';


interface ISearchInputComponent {
  t: (text: string) => string;
  classes: any;
  value: string;
  onChange: () => void;
  onKeyPress: () => void;
  onFocus: () => void;
  onBlur: () => void;
}

const defaultProps: Partial<ISearchInputComponent> = {
  value: ''
}

const styles = (theme: any) => createStyles({
  root: {
      color: 'inherit',
      width: '100%',
      backgroundColor: '#fafafa',
      borderBottom: `1px solid ${theme.borderColor}`
  },
  input: {
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
          minWidth: 200
      }
  }
});

const SearchInputComponent: React.FC<ISearchInputComponent> = ({
  t,
  classes,
  value,
  onChange,
  onKeyPress,
  onFocus,
  onBlur
}) => (
    <InputBase
        id="search-input"
        value={value}
        placeholder={t('Search')}
        autoComplete="off"
        onChange={onChange}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        classes={classes}
    />
);

SearchInputComponent.defaultProps = defaultProps;

const styled = withStyles(styles)(SearchInputComponent);
export default translate('DataTable')(styled);
