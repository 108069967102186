import { Column, SortingState, Table, } from '@devexpress/dx-react-grid';

export const columns: Array<Column & Table.ColumnExtension & SortingState.ColumnExtension> = [
    {
        name: 'num',
        title: '№',
        columnName: 'num',
        sortingEnabled: false,
        width: 60
    },
    {
        name: 'projectNumber',
        title: 'Номер',
        columnName: 'projectNumber',
        sortingEnabled: true,
        width: 100
    },
    {
        name: 'projectStatus',
        title: 'Статус',
        columnName: 'projectStatus',
        sortingEnabled: true,
        width: 200,
        wordWrapEnabled: true
    },
    {
        name: 'osbbName',
        title: 'Назва ОСББ',
        columnName: 'osbbName',
        width: '30%',
        sortingEnabled: true,
        wordWrapEnabled: true
    },
    {
        name: 'region',
        title: 'Регион',
        columnName: 'region',
        sortingEnabled: true,
        wordWrapEnabled: true
    },
    {
        name: 'contractDate',
        title: 'Дата приєднання',
        columnName: 'contractDate',
        sortingEnabled: true
    },
    {
        name: 'totalCost',
        title: 'Вартість, грн',
        columnName: 'totalCost',
        sortingEnabled: true,
        align: 'right'
    },
    {
        name: 'grantAmount',
        title: 'Грант, грн',
        columnName: 'grantAmount',
        sortingEnabled: true,
        align: 'right'
    }
];

// export const SummaryColumns: Array<SummaryItem> = [
    // { columnName: 'contractDate', type: 'count' },
    // { columnName: 'totalCost', type: 'sum' },
    // { columnName: 'grantAmount', type: 'sum' },
// ];

export const PageSizes = [10, 20, 50, 100];