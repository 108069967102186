import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { history } from '@app/store';
import objectPath from 'object-path';
import evalate from 'helpers/evalate';
import asyncFilter from 'helpers/asyncFilter';
import renderHTML from 'helpers/renderHTML';
import queueFactory from 'helpers/queueFactory';
import Preloader from 'components/Preloader';
import {
    ChangeEvent,
    validateData,
    removeHiddenFields
} from 'components/JsonSchema';

import {
    loadTask,
    setTaskStep,
    setTaskSigners,
    storeTaskDocument,
    uploadDocumentAttach,
    deleteDocumentAttach,
    calculateFields,
    downloadDocumentAttach,
    setTaskDocumentValues,
    updateTaskDocumentValues,
    getDocumentWorkflowFiles,
    externalReaderCheckData,
    handleSilentTriggers
} from '@cab/actions/task';
import { addError } from 'actions/error';

import { downloadFile } from '@cab/actions/files';

import waiter from 'helpers/waitForAction';
import deepObjectFind from 'helpers/deepObjectFind';
import getDeltaProperties from 'helpers/getDeltaProperties';
import parseTaskFromXLSX from 'helpers/parseTaskFromXLSX';

import GreetingsPage from './components/GreetingsPage';
import EditScreenLayout from './components/EditScreenLayout';

import propsToData from '../../helpers/propsToData';

const STORE_VALUES_INTERVAL = 2000;

class EditScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            validationErrors: [],
            validationPageErrors: [],
            externalReaderErrors: [],
            storeEventError: null,
            blockForward: false,
            allowNavigate: false
        };
    
        const { taskId} = propsToData(props);
        this.queue = queueFactory.get(taskId);
    }

    getActiveStep = () => {
        const { taskSteps } = this.props;
        const { steps, stepId, taskId } = propsToData(this.props);

        if (!steps) {
            return null;
        }

        return taskSteps[taskId] || (steps.includes(stepId) ? steps.indexOf(stepId) : 0);
    }

    componentDidMount() {
        const { taskSteps, getRootPath, actions } = this.props;

        const {
            steps,
            taskId,
            stepId,
            template: { jsonSchema: { greetingsPage } }
        } = propsToData(this.props);

        if (stepId === undefined && (taskSteps[taskId] !== undefined || !greetingsPage)) {
            this.handleSetStep(0);
            actions.setTaskStep(taskId, 0);
        }

        if (stepId) {
            for (let i = 0; i < steps.indexOf(stepId); i++) {
                if (!this.validateStep(steps[i])) {
                    history.replace(getRootPath() + `/${steps[i]}`);
                    return;
                }
            }
        }

        this.handleSilentTriggers();
    }

    componentDidUpdate(prevProps) {
        const { actions } = this.props;
        const { stepId: oldStepId } = propsToData(prevProps);
        const { stepId: newStepId, taskId, steps } = propsToData(this.props);

        if (oldStepId !== newStepId) {
            actions.setTaskStep(taskId, steps.indexOf(newStepId));
            this.scrollToTop();
        }
    }

    validateStep = (step) => {
        const {
            steps,
            task: { document: { data } },
            template: { jsonSchema: { properties } }
        } = propsToData(this.props);

        const stepName = step || steps[this.getActiveStep()];
        const stepProperties = removeHiddenFields(properties[stepName], data);
        const validationErrors = validateData(data[stepName] || {}, stepProperties, data);

        this.setState({
            validationErrors,
            validationPageErrors: validationErrors
                .filter(item => !item.dataPath && !item.path)
                .filter(({ keyword }) => keyword !== 'contactConfirmation')
        }, () => this.scrollToInvalidField(validationErrors));

        console.log('validation.errors', validationErrors, data[stepName]);
        return !Object.keys(validationErrors).length;
    };

    validatePage = () => {
        const {
            task: { document: { data } },
            template: { jsonSchema }
        } = propsToData(this.props);

        const jsonSchemaProperties = Object.keys(jsonSchema.properties).reduce((acc, propertyName) => ({
            ...acc,
            [propertyName]: {
                ...jsonSchema[propertyName],
                required: [],
                properties: {}
            }
        }), {});

        const pageProperties = removeHiddenFields({
            ...jsonSchema,
            properties: jsonSchemaProperties
        }, data);

        const validationPageErrors = validateData(data, pageProperties, data).filter(({ keyword }) => keyword !== 'contactConfirmation');
        console.log('validation.page.errors', validationPageErrors, data);
        this.setState({ validationPageErrors });
        return !Object.keys(validationPageErrors).length;
    };

    externalReaderCheck = async () => {
        const { stepId, taskId, task: { documentId }, template: { jsonSchema: { properties } } } = propsToData(this.props);
        const { actions, setBusy } = this.props;

        const asyncCheck = (Object.values((properties[stepId] || {})) || []).filter(prop => {
            if (typeof prop === 'object' && prop.control === 'externalReaderCheck') return true;
            return false;
        });

        if (!asyncCheck.length) return true;

        setBusy(true);
    
        await waiter.run(taskId);

        const checkIsChecking = (asyncCheck || []).map(({ isChecking }) => isChecking);

        const isCheckingArray = (checkIsChecking || []).map(isCheckingFunc => {
            const { origin} = propsToData(this.props);
            const documentData = (origin && origin.document && origin.document.data) || {};
            const checking = evalate(isCheckingFunc, documentData[stepId], documentData);
            if (checking === false) return false;
            return true;
        });

        if (isCheckingArray.filter(el => el === false).length === asyncCheck.length) return true;

        const messages = (asyncCheck || [])
            .map(({ pendingMessage }) => pendingMessage)
            .filter((e, i) => isCheckingArray[i] === true);

        this.setState({
            pendingMessage: messages,
            externalReaderErrors: []
        });

        this.queue.push(async () => {
            const allowNavigate = await asyncFilter(asyncCheck || [], async (control, index) => {
                const { service, method, path, checkValid,serviceErrorMessage } = control;

                if (isCheckingArray[index] === false) return;

                const body = {
                    service,
                    method,
                    path
                };

                const result = await actions.externalReaderCheckData(documentId, body);
        
                if (!result) {
                    serviceErrorMessage && this.setState({ externalReaderErrors: [ serviceErrorMessage ] });
                    return true;
                }

                const errors = (checkValid || []).map(({ isValid, errorText }) => {
                    const res = evalate(isValid, objectPath.get(result.data, path), result.data[stepId] || {}, result.data || {});
                    if (res instanceof Error) return null;
                    if (res === false) return renderHTML(errorText);
                    return null;
                }).filter(mss => mss);

                if (errors.length) {
                    const { externalReaderErrors } = this.state;
                    this.setState({ externalReaderErrors: externalReaderErrors.concat(errors) });
                    return true;
                }

                return false;
            });
            
            actions && actions.loadTask && await actions.loadTask(taskId);

            setBusy(false);

            this.setState({ pendingMessage: null });

            const allowed = !(allowNavigate || []).length;

            if (allowed) this.incrementStep();
        });

        return false;
    };

    incrementStep = () => {
        const { setBusy } = this.props;
    
        const activeStep = this.getActiveStep();

        this.handleSilentTriggers();

        this.handleSetStep(activeStep + 1);

        setBusy(false);
    };

    handleSetStep = async (step) => {
        const { getRootPath } = this.props;
        const { steps, taskId } = propsToData(this.props);

        if (!steps[step]) {
            return;
        }

        await waiter.run(taskId);
        history.replace(getRootPath() + `/${steps[step]}`);
    };

    handleFinish = async () => {
        const { handleFinish } = this.props;

        if (!handleFinish || !this.validateStep() || !this.validatePage()) {
            return;
        }

        const result = await handleFinish();

        if (result instanceof Error) {
            this.setState({ storeEventError: result });
        }
    }

    handleNextStep = async () => {
        const { steps } = propsToData(this.props);
        const activeStep = this.getActiveStep();
        
        if (activeStep < steps.length - 1 && this.validateStep()) {

            const allowed = await this.externalReaderCheck();

            if (allowed) this.incrementStep();
        }
    };

    handleSilentTriggers = async() => {
        const { actions } = this.props;
        const {
            taskId,
            stepId,
            origin,
            task: { deleted },
            template: { jsonSchema }
        } = propsToData(this.props);
        
        if (deleted) return null;
    
        const triggers = (jsonSchema.calcTriggers || []).filter(trigger => !trigger.source);

        if (!triggers.length) return;

        const documentData = {
            ...(origin && origin.document && origin.document.data),
            $workflow: (origin && origin.workflow) 
        }
        
        await actions.handleSilentTriggers({
            taskId,
            triggers,
            stepData: documentData[stepId],
            documentData
        });

        this.handleStore();
    };

    clearErrors = () => {
        this.setState({
            validationErrors: [],
            validationPageErrors: [],
            externalReaderErrors: []
        });
    };

    blockForwardNavigation = blockForward => this.setState({  blockForward });

    handlePrevStep = () => {
        const activeStep = this.getActiveStep();

        this.clearErrors();

        if (activeStep > 0) {
            this.handleSetStep(activeStep - 1);
        }
    };

    handleForceStore = async () => {
        const { taskId } = propsToData(this.props);
        return waiter.run(taskId);
    };

    handleStore = async () => {
        const { actions } = this.props;
        const { task, task: { finished }, origin, origin: { lastUpdateLogId } } = propsToData(this.props);
        const properties = getDeltaProperties(task.document.data, origin.document.data);

        if (!properties.length || finished) {
            return null;
        }

        const result = await actions.storeTaskDocument({
            task,
            data: { properties },
            params: lastUpdateLogId ? `?last_update_log_id=${lastUpdateLogId}` : ''
        });

        if (result instanceof Error) {
            this.setState({ storeEventError: result });
        }

        return result;
    };

    handleChange = async (...path) => {
        const { actions } = this.props;
        const { validationErrors } = this.state;
        const { taskId,
            task: { id, deleted },
            template: { jsonSchema }
        } = propsToData(this.props);

        if (deleted) {
            return null;
        }

        const changes = path.pop();
        const triggers = jsonSchema.calcTriggers || [];
        const pagePath = path.slice(1).join('.');
        const schema = objectPath.get(jsonSchema.properties, path.join('.properties.'));

        this.setState({
            validationErrors: (validationErrors || [])
                .filter((error) => error.path !== pagePath)
        });

        actions.updateTaskDocumentValues(id, path, changes, triggers, schema);

        const interval = (changes instanceof ChangeEvent && changes.force) ? 50 : STORE_VALUES_INTERVAL;
        return waiter.addAction(taskId, this.handleStore, interval);
    };

    handleImport = async (file) => {
        const { actions } = this.props;
        const { taskId, template: { jsonSchema: { importSchema } } } = propsToData(this.props);

        try {
            const data = await parseTaskFromXLSX(file, importSchema);
            actions.setTaskDocumentValues(taskId, data);
            this.handleStore();
        } catch (e) {
            console.log('import.error', e);
            actions.addError(new Error('FailImportingData'));
        }
    };

    downloadDocumentAttach = async (item, asics = false) => {
        const { actions } = this.props;
        return item.downloadToken
            ? actions.downloadFile(item, asics)
            : actions.downloadDocumentAttach(item, asics);
    };

    scrollToInvalidField = (errors) => {
        if (!errors) return;

        try {
            const firstError = deepObjectFind(errors, ({ path }) => !!path);

            if (!firstError) return;

            const replacepath = firstError.path.replace(/\./g, '-');

            const firstInvalidField = document.getElementById(firstError.path)
                || document.getElementById(replacepath)
                || document.querySelector(`input[name=${replacepath}]`);

            if (!firstInvalidField) return;

            const type = firstInvalidField.getAttribute('type');
            const isHidden = type === 'hidden' || firstInvalidField.style.display === 'none';

            if (isHidden) {
                const parent = firstInvalidField.parentNode;
                parent && parent.scrollIntoView({ block: 'center' });
            } else {
                firstInvalidField.scrollIntoView({ block: 'center' });
            }
        } catch {
            console.log('scrollToInvalidField errors', errors);
        }
    };

    scrollToTop = () => {
        const topPagePart = document.querySelector('#steper') || document.querySelector('header');
        topPagePart && topPagePart.scrollIntoView();
    };

    render() {
        const { fileStorage, actions, busy, setBusy, computedMatch, tasks, origins, templates, userUnits } = this.props;
        const { validationErrors, validationPageErrors, storeEventError, externalReaderErrors, pendingMessage, blockForward } = this.state;
        const { task, origin, template, steps, stepId, template: { jsonSchema: { greetingsPage } } } = propsToData(this.props);

        const activeStep = this.getActiveStep();

        if (activeStep === null) {
            return <Preloader />;
        }

        const stepName = steps[activeStep];

        if (stepId === undefined && greetingsPage) {
            return <GreetingsPage {...greetingsPage} onDone={() => this.handleSetStep(this.getActiveStep())} />;
        }

        if (!stepName) {
            return <Preloader />;
        }

        return (
            <EditScreenLayout
                busy={busy}
                task={task}
                origin={origin}
                actions={{
                    setBusy,
                    handleStore: this.handleStore,
                    setValues: actions.setTaskDocumentValues.bind(null, task.id),
                    handleForceStore: this.handleForceStore,
                    handleDeleteFile: actions.deleteDocumentAttach,
                    calculateFields: actions.calculateFields,
                    handleDownloadFile: this.downloadDocumentAttach,
                    setTaskSigners: actions.setTaskSigners.bind(this, task.id),
                    uploadDocumentAttach: actions.uploadDocumentAttach.bind(this, task.documentId),
                    getDocumentWorkflowFiles: actions.getDocumentWorkflowFiles.bind(this, task.documentId, stepName),
                    scrollToInvalidField: this.scrollToInvalidField,
                    clearErrors: this.clearErrors,
                    blockForwardNavigation: this.blockForwardNavigation
                }}
                userUnits={userUnits}
                storeEventError={storeEventError}
                validationErrors={validationErrors}
                validationPageErrors={validationPageErrors}
                externalReaderErrors={externalReaderErrors}
                pendingMessage={pendingMessage}
                setStoreEventError={error => this.setState({ storeEventError: error })}
                steps={steps}
                stepName={stepName}
                activeStep={activeStep}
                template={template}
                handleSetStep={this.handleSetStep}
                computedMatch={computedMatch}
                fileStorage={fileStorage}
                handleImport={this.handleImport}
                handleChange={this.handleChange}
                handleStore={this.handleStore}
                handleNextStep={this.handleNextStep}
                handlePrevStep={this.handlePrevStep}
                handleFinish={this.handleFinish}
                tasks={tasks}
                origins={origins}
                templates={templates}
                blockForward={blockForward}
            />
        );
    }
}

EditScreen.propTypes = {
    actions: PropTypes.object.isRequired,
    tasks: PropTypes.object.isRequired,
    userInfo: PropTypes.object.isRequired,
    origins: PropTypes.object.isRequired,
    templates: PropTypes.object.isRequired,
    handleFinish: PropTypes.func,
    fileStorage: PropTypes.object,
    userUnits: PropTypes.array,
    computedMatch: PropTypes.object,
    setBusy: PropTypes.object,
    busy: PropTypes.bool
};

EditScreen.defaultProps = {
    handleFinish: null,
    fileStorage: {},
    userUnits: [],
    computedMatch: {},
    setBusy: null,
    busy: false
};

const mapStateToProps = ({
    auth: { userUnits, info },
    files: { list: fileStorage },
    task: { steps }
}) => ({
    fileStorage,
    userUnits,
    userInfo: info,
    taskSteps: steps
});

const mapDispatchToProps = dispatch => ({
    actions: {
        loadTask: bindActionCreators(loadTask, dispatch),
        addError: bindActionCreators(addError, dispatch),
        setTaskStep: bindActionCreators(setTaskStep, dispatch),
        downloadFile: bindActionCreators(downloadFile, dispatch),
        setTaskSigners: bindActionCreators(setTaskSigners, dispatch),
        calculateFields: bindActionCreators(calculateFields, dispatch),
        storeTaskDocument: bindActionCreators(storeTaskDocument, dispatch),
        uploadDocumentAttach: bindActionCreators(uploadDocumentAttach, dispatch),
        deleteDocumentAttach: bindActionCreators(deleteDocumentAttach, dispatch),
        downloadDocumentAttach: bindActionCreators(downloadDocumentAttach, dispatch),
        setTaskDocumentValues: bindActionCreators(setTaskDocumentValues, dispatch),
        updateTaskDocumentValues: bindActionCreators(updateTaskDocumentValues, dispatch),
        getDocumentWorkflowFiles: bindActionCreators(getDocumentWorkflowFiles, dispatch),
        externalReaderCheckData: bindActionCreators(externalReaderCheckData, dispatch),
        handleSilentTriggers: bindActionCreators(handleSilentTriggers, dispatch)
    }
});

const translated = translate('TaskPage')(EditScreen);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
