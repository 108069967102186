import React from 'react';
import PropTypes from 'prop-types';
import {
    Chip,
    Paper,
    Toolbar,
    withStyles
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Content from 'layouts/components/Content';
import humanDateFormat from 'helpers/humanDateFormat';
import SmartMessage from './SmartMessage';
import { Preloader, useAppHeader } from '@eis/components';

const styles = {
    wrapper: {
        backgroundColor: '#eeeeee',
        marginTop: 20
    },
    chip: {
        marginRight: 20,
        border: 'none'
    },
    paper: {
        marginTop: 20,
        padding: 15
    }
};

const MessageLayout = ({
    t,
    classes,
    location,
    title,
    loading,
    message
}) => {
    useAppHeader({ title, backButton: '/messages' })
    return (
        <Content>
            {
                message ? (
                    <>
                        <div className={classes.wrapper}>
                            <Toolbar>
                                <Chip
                                    icon={<CalendarTodayIcon />}
                                    label={t('CreatedAt', { time: humanDateFormat(message.createdAt) })}
                                    className={classes.chip}
                                    variant="outlined"
                                />
                            </Toolbar>
                        </div>
                        <Paper className={classes.paper} elevation={1}>
                            <SmartMessage template={message.fullMessage} />
                        </Paper>
                    </>
                ) : <Preloader />
            }
        </Content>

    );
}

MessageLayout.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    message: PropTypes.object
};

MessageLayout.defaultProps = {
    loading: false,
    message: null
};

export default withStyles(styles)(MessageLayout);
