import React, { Fragment, useCallback, useState } from 'react';
import {
    DataTypeProvider, Table as TableBase,
    GroupingState, IntegratedGrouping,
    SearchState, IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    Toolbar,
    SearchPanel,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
    IconButton,
    Tooltip
} from '@material-ui/core'
import ListIcon from '@material-ui/icons/ViewHeadlineOutlined';
import TreeIcon from '@material-ui/icons/AccountTreeOutlined';

import { Preloader } from '../Preloader';
import { DefaultColumnFileName, DefaultColumnFileDate, makeDefaultActionColumn, DefaultColumnsName } from './columns';
import { FileStorageType, FilesTreeViewMessages, IFileColumn, IFileRow } from './types';
import TimeLabel from '@core/components/Label/Time';
import { FileNameCellProvider } from './FileNameCellProvider';
import { ActionsFileProvider } from './ActionsFileProvider';
import { getFile, downloadASICS, downloadFilePreview } from '@cab/actions/files';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { ToolbarExtView } from './ToolbarExtView';

export interface IDocType {
    id: string
}

interface FilesTreeViewProps {
    /** */
    columns: Array<IFileColumn>;
    /** Имена колонок для группировки */
    grouping: Array<string>;
    /** */
    rows: Array<IFileRow>;
    // makeTree: (rootDocId: string, docTypes: Array<IDocType>, files: Array<FileProps>) => FileTreeNode;
    messages: FilesTreeViewMessages,
    /** Redux file storage */
    fileStorage: Record<string, FileStorageType>;
    /** Show Preview button in file row */
    previewButton?: boolean;
    /** Show "Key" button in file row */
    signButton?: boolean;
    /** Show Download button in file row */
    downloadButton?: boolean;
    /** */
    searchPanel?: boolean;
}

/** Отображает сгрупированное значение */
const GroupCellContent = (props: TableGroupRow.ContentProps) => (
    <span>{props.row.value}</span>
);


const TableCell: React.FC<TableBase.DataCellProps> = props => {
    const classes = useStyles();
    const className = props.column.name === DefaultColumnsName.Actions
        ? classes.actionsCell
        : undefined;
    return <Table.Cell {...props} className={className} />
}

export const FilesTreeView: React.FC<FilesTreeViewProps> = props => {
    const { grouping, rows, columns, messages,
        fileStorage, previewButton, signButton, downloadButton } = props;

    const [searchValue, setSearchState] = useState('');
    const [treeMode, setTreeMode] = useState<boolean>(true);

    const [cols] = useState<Array<IFileColumn>>([
        DefaultColumnFileName,
        ...columns,
        DefaultColumnFileDate,
        makeDefaultActionColumn(48 * (Number(downloadButton) + Number(previewButton) + Number(signButton)) + 16)
    ])

    const dispatch = useDispatch();

    const downloadOriginal = useCallback(async (downloadToken: string) =>
        await getFile({ downloadToken })(dispatch), [dispatch]);

    const downloadPreview = useCallback(async (downloadToken: string) =>
        await downloadFilePreview({ downloadToken })(dispatch), [dispatch]);

    const downloadSign = useCallback(async (downloadToken: string) =>
        await downloadASICS({ downloadToken })(dispatch), [dispatch]);

    if (rows === undefined) return <Preloader />;

    return (
        <Fragment>
            <Grid
                rows={rows}
                columns={cols} >

                <GroupingState
                    grouping={treeMode ? grouping.map(col => ({ columnName: col })) : []}
                />
                <FileNameCellProvider
                    for={[DefaultColumnsName.FileName]} />
                <DataTypeProvider
                    for={[DefaultColumnsName.FileDate]}
                    formatterComponent={({ value }) => value
                        ? <TimeLabel date={value} />
                        : null} />
                <ActionsFileProvider
                    for={[DefaultColumnsName.Actions]}
                    {...{
                        fileStorage,
                        messages,
                        actions: {
                            downloadOriginal: downloadButton ? downloadOriginal : undefined,
                            downloadPreview: previewButton ? downloadPreview : undefined,
                            downloadSign: signButton ? downloadSign : undefined
                        }
                    }}
                />

                <SearchState
                    value={searchValue}
                    onValueChange={setSearchState} />
                <IntegratedFiltering />

                <IntegratedGrouping />
                <Table
                    columnExtensions={cols}
                    cellComponent={TableCell}
                    messages={{
                        noData: messages.noFiles
                    }} />
                <TableHeaderRow />
                <TableGroupRow
                    contentComponent={GroupCellContent}
                    indentColumnWidth={10}
                />
                <TableColumnVisibility
                    hiddenColumnNames={treeMode ? undefined : grouping}
                />
                <Toolbar />
                <SearchPanel
                    messages={{
                        searchPlaceholder: messages.searchPlaceholder
                    }} />
                <ToolbarExtView>
                    <Tooltip title={messages.viewModeHint || 'View mode'}>
                        <IconButton onClick={() => setTreeMode(!treeMode)}>
                            {treeMode ? <ListIcon /> : <TreeIcon /> }
                        </IconButton>
                    </Tooltip>
                </ToolbarExtView>
            </Grid>
        </Fragment>
    )
}
