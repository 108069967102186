import { Dispatch } from 'redux';
import { getFEEMetrics } from '../../../actions/registry';


/** Расчет необходимых метрик */
export const calculateMetrics = async (dispatch: Dispatch): Promise<Record<string, number | string>> => {
    let reqTotal = 0,
        reqRejectTotal = 0,
        reqAcceptTotal = 0;

    const feeMetrics = await getFEEMetrics()(dispatch);
    console.log('feeMetrics', feeMetrics)

    // Количество заявок
    reqTotal = feeMetrics.applied.reduce((i: number, r: number) => r + i, 0);
    // Количество принятых
    reqAcceptTotal = feeMetrics.accepted.reduce((i: number, r: number) => r + i, 0);
    // Количество отклоненных
    reqRejectTotal = feeMetrics.rejected.reduce((i: number, r: number) => r + i, 0);

    return Promise.resolve({
        reqTotal,
        reqAcceptTotal,
        reqRejectTotal,
        reqProcessTotal: reqTotal - reqAcceptTotal - reqRejectTotal,

        reqByNum1: feeMetrics.applied[0],
        reqByNum2: feeMetrics.applied[1],
        reqByNum3: feeMetrics.applied[2],
        reqByNum4: feeMetrics.applied[3],
        reqByNum5: feeMetrics.applied[4],

        reqAccept1: feeMetrics.accepted[0],
        reqAccept2: feeMetrics.accepted[1],
        reqAccept3: feeMetrics.accepted[2],
        reqAccept4: feeMetrics.accepted[3],
        reqAccept5: feeMetrics.accepted[4],

        reqReject1: feeMetrics.rejected[0],
        reqReject2: feeMetrics.rejected[1],
        reqReject3: feeMetrics.rejected[2],
        reqReject4: feeMetrics.rejected[3],
        reqReject5: feeMetrics.rejected[4],

        reqProcess1: feeMetrics.applied[0] - feeMetrics.accepted[0] - feeMetrics.rejected[0],
        reqProcess2: feeMetrics.applied[1] - feeMetrics.accepted[1] - feeMetrics.rejected[1],
        reqProcess3: feeMetrics.applied[2] - feeMetrics.accepted[2] - feeMetrics.rejected[2],
        reqProcess4: feeMetrics.applied[3] - feeMetrics.accepted[3] - feeMetrics.rejected[3],
        reqProcess5: feeMetrics.applied[4] - feeMetrics.accepted[4] - feeMetrics.rejected[4],

        started1: feeMetrics.started[0],
        started2: feeMetrics.started[1],
        started3: feeMetrics.started[2],
        started4: feeMetrics.started[3],
        started5: feeMetrics.started[4],
        startedTotal: feeMetrics.started.reduce((c: number, r: number) => r + c, 0),

        projectTotal: feeMetrics.projectTotal,
        projectA_B: `${feeMetrics.projectA} / ${feeMetrics.projectB}`,
        projectBig: `${feeMetrics.projectSignificant} / ${feeMetrics.projectNoSignificant}`
    });
};
