import React, { useEffect, useState } from 'react';
import { Box, createStyles, Link, makeStyles } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import Icon from '@material-ui/icons/Cached';
import { useTranslate } from 'react-translate';

/**
 * Compare new version with parent and return true if need update
 */
const needUpdate = (newVersion: string): boolean => {
    const currentDig = String(process.env.REACT_APP_VERSION).split('.');
    const newDig = String(newVersion).split('.');
    const count = Math.max(currentDig.length, newDig.length);
    let result = false;
    for (let i = 0; i < count; i++) {
        const c = Number(currentDig.length > i ? currentDig[i] : 0);
        const n = Number(newDig.length > i ? newDig[i] : 0);
        result = result || n > c;
    }

    return result;
}

const CHECK_VERSION_INTERVAL = 1000 * 60 * 5;

const useStyles = makeStyles(theme => createStyles({
    versionUpdater: {
        margin: 0,
        backgroundColor: orange[100],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: orange[400],
        textAlign: 'center',
        fontSize: '85%',
        padding: 2,
        cursor: 'pointer'
    },
    versionUpdaterA: {
        width: '100%',
        display: 'block'
    }
}))

interface VersionUpdaterProps {
    url: string
}

export const VersionUpdater: React.FC<VersionUpdaterProps> = props => {
    const { url } = props;
    const classes = useStyles();
    const t = useTranslate('App');
    const [visible, setVisible] = useState<boolean>(false);

    const refreshPage = () => {
        // Clear caches
        if ('caches' in window) {
            caches.keys().then((names) => {
                console.log(names);
                names.forEach(async (name) => {
                    console.log(name);
                    await caches.delete(name)
                })
            })
        }
        // reload page
        window.location.reload();
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const res = await fetch(url+`?${Math.round(Math.random() * 999999)}`);
                if (res.ok) {
                    const { version } = await res.json();
                    if (needUpdate(version)) {
                        setVisible(true);
                    }
                }
            } catch (err) {
                console.warn(err);
            }
        }, CHECK_VERSION_INTERVAL);
        return () => clearInterval(interval);
    }, [url]);

    if (!visible) return null;

    return (
        <Box className={classes.versionUpdater} >
            <Link onClick={refreshPage} className={classes.versionUpdaterA}>
                <Icon fontSize="small" style={{marginBottom: '-0.25em'}}/> {t('RestartPage')}
            </Link>
        </Box>
    )
}