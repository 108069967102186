/**
 * Registry keys
 */
export enum RegistryKeysEnum {
    Subjects = 1,
    Wso2Services = 22,
    Users = 29,
    Resources = 38,
    Services = 39,
    ServicesComponents = 40,
    Wso2EndPoint = 41,
    FormFunctioning = 48,
    ServiceWeb = 66,
    Processes = 98,
    AccessToEndPoint = 68,
    FunctionWeb = 70,
    AccessToWeb = 72,
    News = 77,
    Requests = 97,
    Statuses = 105,
    FondProjects = 92,
    FondDocType = 95,
    Inventory = 115
}

/**
 * Request numbers
 */
export enum RequestNumberEnum {
    ForParticipant = 1,
    ForProjectApproval = 2,
    ForChanges = 3,
    ForVerification = 4,
    ForExtension = 5
}