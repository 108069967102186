import React, { Fragment } from 'react';
import { useTranslate } from 'react-translate';

import { CleanLayout, useAppHeader } from '@eis/components';
import { makeStyles, Theme, createStyles, Container, Grid, Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getCreateTaskLink } from '@eis/tasks/utils';
import { RegistryKeysEnum } from '@eis/consts';
import { useRegistry } from '@eis/hooks';
import { Registry } from '@eis/registry';
import { useOwnProjects } from '@eis/hooks/useOwnProjects';
import { CardProject } from './components/CardProject';
import { Cards } from './components/Cards';

export const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%'
    },
    card: {
        margin: theme.spacing(2.5, 0)
    },
    cardOk: {
        marginBottom: theme.spacing(5)
    },
    cardProject: {
        //width: '49.5%'
    },
    cardHeader: {
        paddingBottom: 0
    },
    intro: {
        margin: theme.spacing(2, 0)
    },
    title: {
        color: theme.palette.primary.main,
    },
    titleDraft: {
        color: 'inherited',
    },
    digit: {
        border: '2px solid',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        // fontSize: '120%',
        fontWeight: 'bold',
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: '50%',
        borderColor: theme.palette.primary.main,
    },
    digitDraft: {
        borderColor: 'rgba(0, 0, 0, 0.75)',
    },
    body: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    testMode: {
        fontWeight: 'bold'
    }
}));


interface HomePageProps {

}

export const HomePage: React.FC<HomePageProps> = props => {
    const t = useTranslate('Home');
    useAppHeader({ title: t('title') });
    const classes = useStyles();
    const history = useHistory();
    const processes = useRegistry<Registry.dc_process>(RegistryKeysEnum.Processes, false);
    processes.sort((p1, p2) => {
        return Math.sign(p1.data.menuOrder - p2.data.menuOrder)
    });

    const projects = useOwnProjects();

    // console.log('processes1', processes);

    const handleClickRequest = (id: number, idx: number) => {
        history.push(getCreateTaskLink(id))
    }

    const handleClickProject = (id: string) => {
        history.push(`/projects/${id}/requests`)
    }

    const renderProjects = () => {
        if (!projects || projects.length === 0) return null;
        
        // console.log('projects', projects);
        return (
            <Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} ><Typography variant='h5'>Проекти</Typography></Grid>
                    {projects.map(p => (
                        <CardProject
                            id={p.id}
                            key={p.id}
                            classes={classes}
                            date={p.contractDate 
                                ? (p.contractDate).split(' ')[0]
                                : null
                            }
                            title={p.osbbName}
                            projectNumber={p.projectNumber}
                            description={p.projectStatus ? p.projectStatus.name : ''}
                            handleClick={handleClickProject}
                        />
                    ))
                    }
                </Grid>
            </Fragment>
        )
    }

    return (
        <CleanLayout margin={true}>
            <Container className={classes.root} >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="body1" className={classes.intro}>
                            <span className={classes.testMode}>Платформа для подачі заявок знаходиться у дослідній експлуатації.</span>&nbsp;
                            Сьогодні ви можете подати тільки <b>{genText(processes)}</b>. Подача наступних заявок знаходиться в процесі розробки.
                        </Typography>
                        <Typography variant="body1" className={classes.intro}>
                            До початку заповнення заявки, ми рекомендуємо головам правління ОСББ або особам, які будуть їм допомогати, ознайомитися з&nbsp;
                            <Link target="_blank" href="https://eefund.org.ua/sites/default/files/Програма%2030.06/Інструкція_Користувача_заявка_1.pdf">інструкцією</Link>.
                        </Typography>
                        <Cards
                            processes={processes}
                            classes={classes}
                            handleClickRequest={handleClickRequest} >
                            {renderProjects()}
                        </Cards>
                    </Grid>
                </Grid>
            </Container>
        </CleanLayout>
    )
}

function genText(processes: Array<Registry.dc_process>): string {
    if (processes) {
        const enabled = processes.filter(p => p.data.enabled).map(p => p.data.menuOrder).sort(); 
        return enabled.length > 1
            ? `Заявки № ${enabled.slice(0, enabled.length - 1).join(', ')} та ${enabled[enabled.length - 1]}`
            : 'Заявку №' + enabled.join();

    } else return 'Заявку №1';
}