import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useTranslate } from 'react-translate';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CloudIcon from '@material-ui/icons/CloudDownloadOutlined';

import Limits from './Limits';
import { SelectPrevious } from './SelectPrevious';
import { makeMimeExtensions } from './custom-mime';


const useStyles = makeStyles(theme => createStyles({
    dropZone: {
        outline: 'none',
        padding: theme.spacing(1, 0),
        textAlign: 'center',
        border: '#aaa 2px dashed',
        borderRadius: 3,
        '@media screen and (max-width: 425px)': {
            padding: theme.spacing()
        }
    },
    dropZoneActive: {
        background: 'rgba(0, 0, 0, 0.1)'
    },
    dropZoneAccept: {
        borderColor: '#00e676'
    },
    dropZoneReject: {
        borderColor: theme.palette.error.main
    },
    button: {
        marginLeft: theme.spacing()
    },
    buttons: {
        padding: theme.spacing(1, 0)
    }
}));



interface SelectFileAreaProps {
    // /** Описание поля со схемы. Используется для диалога выбора */
    // description?: boolean;
    readOnly?: boolean;
    /** Максимальный размер файла */
    maxSize?: number;
    /** Mime типы */
    accept?: string;
    /** Максимальное количество записей */
    maxItems?: number;
    /** Разрешать указывать ранее загруженные */
    allowPrevious?: boolean;
    /** Выбор нескольких файлов */
    multiple?: boolean;
    /** Name of dropzone node */
    name?: string;
    // renderContent?: (state: any) => JSX.Element;
    onSelect: (value: any) => void;
}

const defaultProps: Partial<SelectFileAreaProps> = {
    multiple: true
}

export const SelectFileArea: React.FC<SelectFileAreaProps> = props => {
    const { maxSize, readOnly, accept, onSelect, maxItems, allowPrevious, multiple } = { ...defaultProps, ...props };
    const t = useTranslate('Elements');
    const classes = useStyles();
    const [ openPrevious, setOpenPrevious ] = useState(false);
    // const [ filesPrevious, setFilesPrevious ] = useState<string | undefined>();

    const { getRootProps, getInputProps, open, //acceptedFiles,
        isDragActive, isDragAccept, isDragReject } = useDropzone({
            disabled: readOnly,
            accept: accept ? makeMimeExtensions(accept) : undefined,
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            maxSize,
            // Если указать явно ограничение, то при выборе большего числа происходит сброс ранее выбранных
            // maxFiles: maxItems,
            multiple: multiple || Number(maxItems) > 0,
            onDrop: (files) => onSelect(files)
        });

    const handleClosePrevious = () => {
        setOpenPrevious(false);
    }

    return (
        <Box>
            <Box
                {...getRootProps({
                    className: classNames(classes.dropZone, {
                        [classes.dropZoneActive]: isDragActive,
                        [classes.dropZoneAccept]: isDragAccept,
                        [classes.dropZoneReject]: isDragReject
                    })
                })}>
                <input {...getInputProps()} />

                <SaveAltIcon fontSize="large" />
                <Typography variant="body1">
                    {t('DropFilesHere')} {t('DropVariantOther')}
                </Typography>
                <Box className={classes.buttons}>
                    <Button
                        name={`browse`}
                        disabled={readOnly}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={open}
                        startIcon={<FolderOpenIcon />}
                    >{t('UploadLocal')}
                    </Button>

                    {allowPrevious &&
                        <Button
                            name={`usePrevious`}
                            classes={{
                                root: classes.button
                            }}
                            disabled={readOnly}
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<CloudIcon />}
                            onClick={() => setOpenPrevious(true)}
                        >{t('UploadPrevious')}
                        </Button>

                    }
                </Box>
                <Limits accept={accept} maxSize={maxSize} maxItems={maxItems} />

            </Box>
            { allowPrevious && 
                <SelectPrevious 
                    open={openPrevious}
                    onClose={handleClosePrevious}
                    />
            }
        </Box>
    )
}

export default SelectFileArea;
