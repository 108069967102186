import React from 'react';
import { useSelector } from 'react-redux';
import { history, AppRootState } from '@app/store';

import {
    Badge,
    IconButton
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

/**
 * "Bell" icon in toolbar
 */
export const MessageNotify: React.FC = () => {
    const { unreadCount } = useSelector((state: AppRootState) => state.messages);

    return (
        <IconButton id="message-count-btn" onClick={() => history.push('/messages')}>
            <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon />
            </Badge>
        </IconButton>
    )
};

