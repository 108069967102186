import React from 'react';
import objectPath from 'object-path';

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from '@material-ui/core';

import { IColumn } from './types';

interface IDataTableHeader {
  classes: any;
  checkable: boolean;
  columns: Array<IColumn>;
  hiddenColumns: Array<String>;
  sort: any;
  createSortHandler: (id: string) => any; 
}

const defaultProps: Partial<IDataTableHeader> = {
  classes: {},
  columns: [],
  hiddenColumns: [],
  checkable: false,
  sort: {},
  createSortHandler: () => null,
}

const DataTableHeader: React.FC<IDataTableHeader> = ({
  classes,
  columns,
  hiddenColumns,
  checkable,
  sort,
  createSortHandler
}) => (
  <TableHead>
    <TableRow>
      {checkable ? (
        <TableCell padding="checkbox" width={64} />
      ) : null}
      {(columns || [])
        .filter(column => !hiddenColumns.includes(column.id))
        .map(({ render, disableClick, ...column }, columnKey) => {
          const direction = objectPath.get(sort, column.id);
          const { sortable, ...colParams } = column;
          return (
            <TableCell className={classes.TableCell} key={columnKey} {...colParams}>
              {column.sortable ? (
                <TableSortLabel
                  active={!!direction}
                  direction={direction}
                  onClick={createSortHandler(column.id)}
                >
                  {column.name}
                </TableSortLabel>
              ) : column.name}
            </TableCell>
          );
        })}
    </TableRow>
  </TableHead>
);

DataTableHeader.defaultProps = defaultProps;

export default DataTableHeader;
