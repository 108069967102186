import React, { Fragment } from 'react';
import { translate } from 'react-translate';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';

// Original:
// const FieldLabel = ({ t, description, required }) => description + (required ? '' : t('NotRequired'));

// New:
const useStyles = makeStyles(theme => createStyles({
    req: {
        color: 'red',
        cursor: 'default'
    },
}))

export const FieldLabel = ({ t, description, required }) => {
    const classes = useStyles();
    return (
        <Fragment>
            {description}
            {required && 
                <Tooltip title={t('Required')}>
                    <span className={classes.req}> *</span>
                </Tooltip>}
        </Fragment> 
    )
}

export default translate('Elements')(FieldLabel);
