import React from 'react';
import PropTypes from 'prop-types';

import { Preloader, useAppHeader } from '@eis/components';

import MessageTable from './MessageTable';
const MessageListLayout = ({ title, data, handleItemClick }) => {
    useAppHeader({title})
    return data === null 
        ? <Preloader /> 
        : <MessageTable handleItemClick={handleItemClick} />
}


MessageListLayout.propTypes = {
    data: PropTypes.array,
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

MessageListLayout.defaultProps = {
    data: null,
    loading: false,
    handleItemClick: () => null
};

export default MessageListLayout;
