import React, { useState } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FileEntity, FileStorageType } from './types';
import downloadBase64Attach from '@core/helpers/downloadBase64Attach';
import base64ToBlob from '@core/helpers/base64ToBlob';


interface DownloadButtonProps {
    fileStorage: FileStorageType;
    file: FileEntity;
    hint?: string;
    handleDownloadFile: (downloadToken: string) => any;
}

/** Icon-кнопка для загрузки файла */
export const DownloadButton: React.FC<DownloadButtonProps> = props => {
    const { hint, fileStorage, file, handleDownloadFile } = props;
    const [loading, setLoading] = useState<boolean>(false);
    
    const handleDownload = async () => {
        setLoading(true);

        // const { t, item, handleDownloadFile } = this.props;
        // const fileName = item.fileName || item.name || t('IsGenerated');

        const body = fileStorage && fileStorage[file.id];
        const fileName = file.name;
        if (body) {
            downloadBase64Attach({ fileName }, base64ToBlob(body, file.contentType));
        } else {
            const document = await handleDownloadFile(file.downloadToken!);
            if (document) {
                downloadBase64Attach({ fileName }, base64ToBlob(document));
            } else {
                /// TODO: Какое вывести сообщение ????
            }
        }
        
        setLoading(false);

    };


    return (
        <Tooltip title={hint || 'Download'}>
            <IconButton
                disabled={loading || !Boolean(file.downloadToken)}
                onClick={handleDownload}>
                {loading && <CircularProgress size={24} />}
                {!loading && <DownloadIcon />}
            </IconButton>
        </Tooltip>
    );
};
