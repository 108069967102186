import { AppRootState } from '@app/store';
import { useSelector } from 'react-redux';
import { CreateTasksEnum, DefaultCreateTasksId } from './consts';
import { RegistryKeysEnum } from '../consts'
import { Registry } from '../registry'

export interface ILinkInfo {
    url: string, 
    title?: string
    access: Array<string>
}

/**
 * Return url for start task/process
 * @param id {CreateTasksEnum} Process id
 */
export const getCreateTaskLink = (id: CreateTasksEnum | number, process?: Registry.dc_process) => {
    const processId = process !== undefined
        ? process.data.process
        : typeof id === 'number'
            ? id
            : DefaultCreateTasksId[id];
    return `/tasks/create/${processId}/${processId}001`;
}

/**
 * Return task/process description
 * @param id {CreateTasksEnum} Process id
 */
const getCreateTaskDescription = (process?: Registry.dc_process): string | undefined => {
    return process && process.data.description;
}

/**
 * Hook for get url and title 
 */
export const useCreateTaskInfo = (id: CreateTasksEnum): ILinkInfo => {
    const registries: any = useSelector((state: AppRootState) => state.registry.keyRecords);
    const processes = registries[RegistryKeysEnum.Processes]
        ? registries[RegistryKeysEnum.Processes] as Array<Registry.dc_process>
        : undefined;
    const process = processes ? processes.find(item => item.data.name === id) : undefined;

    return {
        url: getCreateTaskLink(id, process),
        title: getCreateTaskDescription(process),
        access: (process && process.data.unitStart) ? process.data.unitStart.split(',') : [],
    }
}

/**
 * Return items for popup menu
 * @param id Group id like 2.1 
 */
export const useMenuGroup = (id?: string): Array<ILinkInfo> | null => {
    const registries: any = useSelector((state: AppRootState) => state.registry.keyRecords);
    if (!(Boolean(id) && Boolean(registries) && registries[RegistryKeysEnum.Processes])) return null;

    const processes = registries[RegistryKeysEnum.Processes] as Array<Registry.dc_process>
    if (Boolean(processes)) {
        return processes
            .filter((process: Registry.dc_process) => process.data.menuGroup.split(',').includes(id!))
            .map((process: Registry.dc_process) => ({
                url: getCreateTaskLink(process.data.process, process),
                title: getCreateTaskDescription(process),
                access: process.data.unitStart ? process.data.unitStart.split(',') : [],
                order: process.data.menuOrder || 0
            }))
            .sort((i1, i2) => i2.order - i1.order)

    } else {
        return null
    }
}