import React, { Fragment, useEffect, useState } from 'react';
import { useTranslate } from 'react-translate';
import {
    CssBaseline,
    Container,
    Grid,
    /**Typography*/
} from '@material-ui/core';
import { CleanLayout, Preloader, useAppHeader } from '@eis/components';
import { MetricBlocks1 } from './metrics';
import { MetricsBlock } from './MetricsBlock';
import { useDispatch } from 'react-redux';
import { calculateMetrics } from './calculateMetrics';

interface RowSize {
    md?: any;
    xs?: any;
    sm?: any;
}

const EmptyMetrics = {};

/**
 * Page component
 */
const Dashboard: React.FC = props => {
    const t = useTranslate('Dashboard');
    const [metrics, setMetrics] = useState<Record<string, number | string>>(EmptyMetrics)
    useAppHeader({ title: t('title') });

    const dispatch = useDispatch();
    useEffect(() => {
        const calc = async () => {
            setMetrics(await calculateMetrics(dispatch));
        };
        calc();
    }, [dispatch])

    return (
        <Fragment>
            <CssBaseline />
            <CleanLayout padding={true}>
                <Container>
                    {EmptyMetrics === metrics
                        ? <Preloader />
                        : <Grid container spacing={3}>
                            <Grid item xs={6}><MetricsBlock block={MetricBlocks1[0]} data={metrics} /></Grid>
                            <Grid item xs={12}><MetricsBlock block={MetricBlocks1[1]} data={metrics} /></Grid>
                            <Grid item xs={12}><MetricsBlock block={MetricBlocks1[2]} data={metrics} /></Grid>
                        </Grid>
                    }
                </Container>
            </CleanLayout>
        </Fragment>
    )
}

export default Dashboard;
