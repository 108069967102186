import React, { Fragment } from 'react';
import Queue from 'queue';
import classNames from 'classnames';
import { Typography, withStyles, FormHelperText, Theme } from '@material-ui/core';

// import { ThemeOptionsExt } from '@app/theme';
import { BaseElementProps } from '../BaseElementProps';

import renderHTML from '../../../../helpers/renderHTML';

import EJVError from '../../../JsonSchema/components/EJVError';
import FileDataTable from '../../../FileDataTable';
import { ChangeEvent } from '../../../JsonSchema';
import SelectFileArea from './components/SelectFileArea';
import { ThemeOptionsExt } from '../../../../../manager/theme';
import evalate from '@core/helpers/evalate';


const styles = (theme: Theme) => ({
    root: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
        maxWidth: ThemeOptionsExt(theme).controlsMaxWidth
    },
    label: {
        marginTop: theme.spacing()
    },
    required: {
        "&::after": {
            content: '"*"',
            color: "red",
            fontSize: "inherit",
            marginLeft: theme.spacing(0.5)
        }
    }
});

interface SelectFilesState {
    uploadFileList: Array<{
        labels: Array<string>;
    }>;
};

export interface FileValue {
    createdAt: string;
    documentId: string;
    id: string;
    isGenerated: boolean;
    labels: Array<string>;
    link: string;
    name: string;
    type: string;
}

interface SelectFilesProps extends BaseElementProps {
    rootDocument: any;
    actions: {
        setBusy: any;
        handleDeleteFile: any;
        uploadDocumentAttach: any;
        handleDownloadFile: any;
    };
    attributes?: Record<string, any>;
    previousAttributes?: Record<string, any>;
    value: Array<FileValue>;
    labels?: Array<string>;
    maxSize?: number;
    view: any;
    active: boolean;
    classes: Record<string, string>;
    name: string;
    accept: string;
    fileStorage: boolean;
    allowPrevious?: boolean;
    maxItems?: number;
    multiple?: boolean;
    getFileName?: string;
    onChange?: (value: any) => void;
};

class SelectFiles extends React.Component<SelectFilesProps, SelectFilesState> {
    queue: ReturnType<typeof Queue>;

    constructor(props: SelectFilesProps) {
        super(props);
        const { actions } = props;
        this.state = { uploadFileList: [] };

        this.queue = Queue({ autostart: true, concurrency: 1 });

        if (actions && actions.setBusy) {
            this.queue.on('end', () => actions.setBusy(false));
        }
    }

    componentDidMount() {
        this.handleRemoveHidden();
    }

    componentDidUpdate(prevProps: SelectFilesProps) {
        const { value } = this.props;
        if ((prevProps.value && prevProps.value.length) !== (value && value.length)) {
            this.handleRemoveHidden();
        }
    }

    handleRemoveHidden = () => {
        const { value, hidden } = this.props;

        if (value && hidden) {
            value.forEach(item => this.handleDeleteFile(item));
        }
    }

    handleDeleteFile = (attach: { id: string }) => {
        const { actions, value } = this.props;

        actions.handleDeleteFile(attach);
        this.handleChange(Object.values(value).filter(item => Boolean(item)).filter(({ id }) => id !== attach.id), true, true);
    }

    getAttributes = () => {
        const { attributes, rootDocument, parentValue } = this.props;
        const result: Record<string, any> = {};
        try {
            if (attributes) {
                for (let a in attributes) {
                    if (typeof attributes[a] === 'string' && attributes[a].indexOf('(') === 0) {
                        try {
                            result[a] = evalate(attributes[a], { rootDocument, parentValue })
                        } catch (error) {
                            console.warn('Calc attributes', {attribute: attributes[a], error })
                        }
                    } else {
                        result[a] = attributes[a]
                    }
                }
            }
        } catch (err) {
            console.error(err);
        }
        return result;
    }

    uploadFile = (file: File, labels: Array<string>) => async () => {
        const { value, actions: { uploadDocumentAttach }, getFileName, rootDocument, parentValue } = this.props;
        const fileList = Object.values(value || {});

        // Change file name
        let newName: string = '';
        if (getFileName) {
            try {
                newName = evalate(getFileName, { 
                    rootDocument, 
                    parentValue, 
                    fileName: file.name, 
                    fileExt: String(file.name).split('.').pop()
                })
            } catch (error) {
                newName = '';
                console.warn('getFileName', {f: getFileName, error })
            }
        }
        if (!newName) {
            newName = file.name;
        }

        const uploadedFile = await uploadDocumentAttach(file, labels, newName, this.getAttributes());

        if (uploadedFile) {
            fileList.push({ ...uploadedFile, labels });
            this.handleChange(fileList, true, true);
        }

        return new Promise(resolve => {
            const { uploadFileList } = this.state;
            this.setState({ uploadFileList: uploadFileList.slice(1) }, () => resolve(''));
        });
    };

    handleChange = async (data: any, force = false, hard = false) => {
        const { onChange, schema } = this.props;
        let updateData = data;

        if (schema && schema.type === 'object') {
            updateData = { ...data };
        }

        return onChange && onChange(new ChangeEvent(updateData, force, hard));
    };

    onDrop = (labels: Array<string>) => async (acceptedFiles?: Array<any>) => {
        const { uploadFileList } = this.state;
        const { actions, demo } = this.props;

        if (!acceptedFiles || !acceptedFiles.length) {
            return;
        }

        actions && actions.setBusy && actions.setBusy(true);
        this.setState({
            uploadFileList: uploadFileList
                .concat(acceptedFiles.map(file => {
                    file.labels = labels;
                    return file;
                }))
        }, () => {
            if (!demo) {
                acceptedFiles.forEach(file => this.queue.push(this.uploadFile(file, labels)));
            }
        });
    };

    render() {
        const {
            view,
            path,
            name,
            error,
            accept,
            hidden,
            labels,
            value,
            sample,
            classes,
            maxSize,
            actions,
            active,
            readOnly,
            fileStorage,
            description,
            allowPrevious,
            maxItems,
            multiple,
            required,
        } = this.props;

        const canUpload = !readOnly || (readOnly && !active);

        const { uploadFileList } = this.state;

        if (hidden) return null;

        const fileList = new Array<any>()
            //.concat(Object.values(value || {}), uploadFileList)
            .concat(value, uploadFileList)
            .filter(item => Boolean(item));

        const changeParentWidth = (isFullWidth: boolean) => {
            if(path && path.length > 1) {
                document!.querySelector<HTMLElement>('#' + path[0] + '-0-container')!.style.maxWidth = (isFullWidth ? "unset" : "");
                document!.querySelector<HTMLElement>('#' + path[0] + '-0-container')!.style.width = (isFullWidth ? "calc(100vw - 350px)" : "");
                document!.querySelector<HTMLElement>('#' + path.join('-'))!.style.maxWidth = (isFullWidth ? "100%" : "");
            }
            if(path && path.length === 1) {
              document!.querySelector<HTMLElement>('#' + path[0] + '>div:last-child')!.style.maxWidth = (isFullWidth ? "unset" : "");
              document!.querySelector<HTMLElement>('#' + path[0] + '>div:last-child')!.style.width = (isFullWidth ? "calc(100vw - 350px)" : "");
            }
        };
        
        const renderDataTable = (dragEvents?: any) => (fileList && fileList.length ? (
            <FileDataTable
                dragEvents={dragEvents}
                view={view}
                data={fileList}
                fileStorage={fileStorage}
                groupBy={labels ? 'labels' : undefined}
                changeParentWidth={changeParentWidth}
                actions={
                    {
                        handleDeleteFile: canUpload ? this.handleDeleteFile : null,
                        handleDownloadFile: actions.handleDownloadFile
                    }
                }
            />
        ) : null);

        const renderSelectFileArea = (label: string) => {
            return <SelectFileArea
                name={name}
                maxSize={maxSize}
                accept={accept}
                allowPrevious={allowPrevious}
                maxItems={maxItems}
                multiple={multiple}
                readOnly={!canUpload}
                onSelect={this.onDrop([label].filter(l => Boolean(l)))}
            />
        }

        return (
            <div
                className={classes.root}
                id={(path || []).join('-')}
            >
                {
                    description &&
                    <Typography
                        variant="h6"
                        className={classNames(required && classes.required)}
                    >
                        {description}
                    </Typography>
                }
                {
                    sample &&
                    <div className={classes.raw}>
                        {renderHTML(sample)}
                    </div>
                }
                {
                    labels && labels.length ? (
                        <Fragment>
                            {
                                labels.map(label => (
                                    <div key={label}>
                                        {
                                            label !== 'public' &&
                                            <Typography variant="h5" className={classes.label}>{label}</Typography>
                                        }
                                        {renderSelectFileArea(label)}
                                    </div>
                                ))
                            }
                            {renderDataTable()}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {(multiple || !fileList || fileList.length === 0) && renderSelectFileArea('')}
                            {renderDataTable()}
                        </Fragment>
                    )
                }
                {
                    error &&
                    <FormHelperText error={!!error} >
                        <EJVError error={error} />
                    </FormHelperText>
                }
            </div>
        );
    }
}

// SelectFiles.propTypes = {
//     classes: PropTypes.object.isRequired,
//     actions: PropTypes.object,
//     sample: PropTypes.string,
//     accept: PropTypes.string,
//     value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
//     maxSize: PropTypes.number,
//     path: PropTypes.array
// };

// SelectFiles.defaultProps = {
//     sample: '',
//     accept: '',
//     actions: {},
//     maxSize: null,
//     value: null,
//     path: []
// };

export default withStyles(styles)(SelectFiles);
