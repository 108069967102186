import React from 'react';
import {
    Tooltip,
    IconButton
} from '@material-ui/core';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import FileNameColumn from './components/FileNameColumn';
import DataTableCard from './components/DataTableCard';
import DeleteFileButton from './components/DeleteFileButton';

export default ({ t, handleDownload, directDownload, handleDeleteFile }) => ({
    components: {
        DataTableCard
    },
    controls: {
        pagination: false,
        toolbar: true,
        search: false,
        header: true,
        refresh: false,
        switchView: false,
        customizateColumns: false,
        toggleWidth: true,
    },
    checkable: false,
    columns: [{
        id: 'fileName',
        name: t('FileName'),
        padding: 'none',
        render: (value, item) => {
            const fileName = value || item.name || t('Unnamed');
            return (
                <FileNameColumn
                    name={fileName}
                    item={item}
                    extension={fileName.split('.').pop()}
                />
            );
        }
    }, {
        id: 'url',
        name: t('DownloadFile'),
        width: 64,
        align: 'right',
        padding: 'checkbox',
        render: (url, file) => {
            const downloadFileButton = directDownload
                ? (
                    <a href={url || file.link}>
                        <Tooltip title={t('DownloadFile')}>
                            <IconButton>
                                <SaveAltIcon />
                            </IconButton>
                        </Tooltip>
                    </a>
                )
                : (
                    <Tooltip title={t('DownloadFile')}>
                        <IconButton onClick={handleDownload}>
                            <SaveAltIcon />
                        </IconButton>
                    </Tooltip>
                );

            return (
                <>
                    {downloadFileButton}
                    {handleDeleteFile ? (
                        <DeleteFileButton file={file} handleDeleteFile={handleDeleteFile} />
                    ) : null}
                </>
            );
        }
    }]
});
