import React from 'react';
import { PagesIDEnum } from '@app/application/menu/consts';
import { IModuleAccess, IModuleInfo } from '@eis/types';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ProjectPage from './pages/ProjectPage';
import ProjectsListPage from './pages/ProjectsListPage';
import config from 'config/index';


const staffAccess: IModuleAccess = { userHasUnit: config.staffUnits } 

const module: IModuleInfo = {
    navigation: [
        {
            id: PagesIDEnum.Projects,
            icon: <AllInboxIcon />,
            path: '/projects',
            access: staffAccess
        }
    ],
    routes: [{
        path: '/projects/:projectId/:tabId',
        component: ProjectPage
    },
    {
        path: '/projects/:projectId',
        component: ProjectPage,
    }, {
        path: '/projects',
        component: ProjectsListPage,
        title: 'ProjectsListPage',
        // defaultFilters: { deleted: 0 }
        access: staffAccess
    }]
};

export default module;