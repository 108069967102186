import React from 'react';
import { IRouteItemInfo } from '../types';
import { useSelector } from 'react-redux';
// import { translate } from 'react-translate';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import checkAccess from 'helpers/checkAccess';
import { Preloader } from './Preloader';
import { history, AppRootState } from '@app/store';


interface PrivateRouteProps extends IRouteItemInfo { }

export const PrivateRoute: React.FC<PrivateRouteProps> = props => {
    const { access, redirect, component, ...rest } = props;
    const { userInfo, userUnits } = useSelector((state: AppRootState) => ({
        userUnits: state.auth.userUnits || [],
        userInfo: state.auth.info
    }));

    if (access && !checkAccess(access, userInfo, userUnits)) {
        history.replace('/');
        return <Preloader />;
    }

    if (redirect && props.to) return <Redirect to={props.to} exact={true} />;

    const Comp = component!;
    return <Route exact={true} {...rest} 
        render={(props: RouteComponentProps<any>): React.ReactNode => {
        
        const { match: { params } } = props;
        return <Comp {...props} {...rest} {...params} />;
    }}/>;
}

// const PrivateRoute = ({ location, redirect, component: Component, userInfo, userUnits, access, ...rest }) => {
//     if (access && !checkAccess(access, userInfo, userUnits)) {
//         history.replace('/');
//         return <Preloader />;
//     }

//     const RouteComponent = redirect ? Redirect : Route;

//     return (
//         <RouteComponent
//             {...rest}
//             render={(props) => {
//                 const { match: { params } } = props;

//                 return <Component {...props} {...rest} {...params} />;
//             }}
//         />
//     );
// };

// const translated = translate('PageTitles')(PrivateRoute);
// export default connect(({
//     auth: { userUnits, info }
// }) => ({
//     userUnits: userUnits || [],
//     userInfo: info
// }))(translated);
