import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useTranslate } from 'react-translate';
import { makeStyles, createStyles } from '@material-ui/core';
import { useAppHeader } from '@eis/components';

// import ModulePage from 'components/ModulePage';
import { Preloader, PaperLayout } from '@eis/components';

import promiseChain from 'helpers/promiseChain';

import { updateUserInfo, requestUserInfo, requestAuthMode } from 'actions/auth';

import ProfileLayout from './components/ProfileLayout';
import { AppRootState } from '@app/store';


const useStyles = makeStyles(theme => createStyles({
    formControl: {
        paddingBottom: theme.spacing(),
        margin: 0,
        marginTop: theme.spacing(),
        position: 'relative',
        maxWidth: 700,
        display: 'flex',
        ['@media (max-width:500px)']: { // eslint-disable-line no-useless-computed-key
            '& label': {
                fontSize: '0.75rem'
            }
        }
    },
    dialog: {
        '& > :last-child': {
            ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
                fontSize: '.7rem'
            }
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            margin: 0,
            '& > div > div': {
                margin: 15
            }
        }
    },
    dialogContentWrappers: {
        ['@media (max-width:767px)']: { // eslint-disable-line no-useless-computed-key
            padding: '24px 15px 20px',
            '& tr': {
                margin: '3px 0'
            }
        }
    }
}));

interface IUserProfileState {
    values: any, // Record<string, any>,
    saving: boolean,
    showNotification: boolean,
    expanded?: any
}

export const UserProfile: FC = props => {
    const { auth } = useSelector((state: AppRootState) => state);
    const dispatch = useDispatch();
    const { actions } = mapDispatchToProps(dispatch);
    const [state, setState] = useState<IUserProfileState>({
        values: auth.info || {},
        saving: false,
        showNotification: false
    });
    const classes = useStyles();
    
    const t = useTranslate('UserProfile')
    useAppHeader({
        title: t('title'),
    });

    useEffect(() => {
        actions.requestAuthMode();
        // eslint-disable-next-line
    }, []);

    if (!state.values) return <Preloader />
   
    // Что этот код делает?
    // const toggleExpanded = (panel: any) => () => {
    //     let { expanded } = state;
    //     if (expanded === panel) {
    //         expanded = panel === 1 ? 0 : 1;
    //     } else {
    //         expanded = panel;
    //     }
    //     setState({ ...state, expanded });
    // };

    const handleSave = () => {
        setState({ ...state, saving: true });
        return promiseChain([
            () => actions.updateUserInfo(state.values),
            () => setState({
                ...state,
                saving: false,
                showNotification: true
            }),
            () => setTimeout(() => setState({ ...state, showNotification: false }), 1000)
        ]);
    };

    const handleChange = ({ target }: any) => {
        setState({ ...state, values: { ...state.values, [target.name]: target.value } });
    };

    const checkboxChange = ({ target: { checked, name } }: any) => {
        setState({ ...state, values: { ...state.values, [name]: checked } });
    };

    const handleChangePhone = (phone: string) => promiseChain([
        requestUserInfo,
        () => new Promise<void>(resolve => {
            setState({...state, values: { ...state.values, phone } });
            resolve();
        })
    ]);

    const handleChangeDate = (key: any) => (date: any) => {
        setState({ ...state, values: { ...state.values, [key]: date } });
    };

    const profileProps = {
        ...state,
        t,
        classes,
        checkboxChange: checkboxChange,
        handleChange: handleChange,
        handleChangePhone: handleChangePhone,
        handleChangeDate: handleChangeDate,
        handleSave: handleSave
    };

    return (
        <PaperLayout>
            <ProfileLayout {...profileProps} />   
        </PaperLayout>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
        updateUserInfo: bindActionCreators(updateUserInfo, dispatch),
        requestUserInfo: bindActionCreators(requestUserInfo, dispatch),
        requestAuthMode: bindActionCreators(requestAuthMode, dispatch)
    }
});
