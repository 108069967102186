import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import renderHTML from 'helpers/renderHTML';

import classNames from 'classnames';
import {
    FormLabel,
    FormControl,
    FormHelperText,
    withStyles
} from '@material-ui/core';

import EJVError from './EJVError';
import FieldLabel from './FieldLabel';

const styles = theme => ({
    root: {
        display: 'block',
        marginBottom: theme.spacing(2),
        marginTop: 5,
        maxWidth: theme.controlsMaxWidth || 640,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        }
    },
    noMargin: {
        margin: 0
    },
    sample: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    groupContainer: {
        position: 'relative',
        marginTop: 20,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20
        }
    },
    outlined: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '0 20px'
    },
    errored: {
        borderColor: '#f44336',
        color: '#f44336'
    },
    sampleComponent: {
        whiteSpace: 'normal',
        fontSize: 11
    },
    rowDisplay: {
        display: 'inline-block',
        width: '50%'
    }
});


const getCoordinated = position => {
    const { innerWidth } = window;
    const { top, left } = position;
    if (innerWidth > 960) return { top: top && top.lg, left: left && left.lg };
    if (innerWidth > 600) return { top: top && top.md, left: left && left.md };
    if (innerWidth < 600) return { top: top && top.xs, left: left && left.xs };
    return {};
};

const ElementContainer = ({ classes, className, descriptionClassName, children, sample, error, description, required, bottomSample, width, maxWidth, position, row, bottomError, noMargin, height }) => {
    const sampleText = sample && typeof sample === 'string' ? renderHTML(sample) : sample;
    const sampleComponent = (error || sampleText)
        ? (
            <FormHelperText className={classNames({ [classes.sampleComponent]: !!error })}>
                {error && !bottomError ? <EJVError error={error} /> : sampleText}
            </FormHelperText>
        ) : null;

    return (
        <FormControl
            error={!!error}
            className={
                classNames(classes.root, {
                    [classes.rowDisplay]: row,
                    [classes.noMargin]: noMargin
                }, className)
            }
            style={
                {
                    width,
                    maxWidth,
                    ...(position ? getCoordinated(position) : {}),
                    height
                }
            }
            row={row.toString()}
        >
            {
                description ? (
                    <FormLabel component="legend" className={classNames(descriptionClassName)}>
                        <FieldLabel description={description} required={required} />
                    </FormLabel>
                ) : null
            }
            {!bottomSample && sampleComponent}
            {children}
            {bottomSample && sampleComponent}
            {
                error && bottomError ? (
                    <FormHelperText
                        error={!!error}
                    >
                        <EJVError error={error} />
                    </FormHelperText>
                ) : null
            }
        </FormControl>
    );
};

ElementContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    sample: PropTypes.any,
    errors: PropTypes.object,
    classes: PropTypes.object.isRequired,
    description: PropTypes.string,
    width: PropTypes.number,
    maxWidth: PropTypes.number,
    row: PropTypes.bool,
    bottomError: PropTypes.bool,
    position: PropTypes.object
};

ElementContainer.defaultProps = {
    sample: '',
    description: '',
    width: null,
    maxWidth: null,
    row: false,
    bottomError: false,
    position: null
};

const styled = withStyles(styles)(ElementContainer);
export default translate('Elements')(styled);
