import React from 'react';
import PropTypes from 'prop-types';

import {
    Dialog,
    withStyles,
    IconButton,
    Tooltip
} from '@material-ui/core';

import FilePreview from 'components/FilePreview';
import CloseIconMUI from '@material-ui/icons/Close';

const styles = theme => ({
    dialog: {
        '& .ps__thumb-y': {
            background: '#000'
        }
    },
    pageWrapper: {
        padding: theme.spacing(3),
        paddingTop: 50,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            paddingTop: 45
        }
    },
    paperFullWidth: {
        width: 'auto'
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 4,
        // minWidth: 40,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            top: 0,
            right: 0
        }
    }
});

const FileViewerDialog = ({ classes, open, onClose, extension, file, fileName }) => (
    <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={'body'}
        classes={
            {
                root: classes.dialog,
                paperFullWidth: !['pdf', 'xlsx', 'json', 'bpmn'].includes(extension) && classes.paperFullWidth
            }
        }
    >
        <div className={classes.pageWrapper}>
            <div className={classes.closeIcon}>
                <Tooltip title='Закрити'>
                    <IconButton
                        onClick={onClose}>
                        <CloseIconMUI />
                    </IconButton>
                </Tooltip>
            </div>
            <FilePreview
                file={file}
                fileName={fileName}
                fileType={extension}
            />
        </div>
    </Dialog>
);

FileViewerDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    extension: PropTypes.string.isRequired,
    file: PropTypes.string
};

FileViewerDialog.defaultProps = {
    open: false,
    file: null
};

export default withStyles(styles)(FileViewerDialog);
