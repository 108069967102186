import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';

import {
    Radio,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@material-ui/core';

import ElementContainer from '../components/ElementContainer';

class BooleanElement extends React.Component {
    handleChange = value => () => {
        const { onChange } = this.props;
        onChange && onChange(value);
    };

    renderElement() {
        const { t, value, required, description, readOnly, path } = this.props;

        return required
            ? (
                <FormGroup row={true}>
                    <FormControlLabel
                        control={(
                            <Radio
                                id={path.concat(true).join('-')}
                                checked={value === true}
                                onChange={this.handleChange(true)}
                                color="primary"
                            />
                        )}
                        label={t('Yes')}
                    />
                    <FormControlLabel
                        control={(
                            <Radio
                                id={path.concat(false).join('-')}
                                checked={value === false}
                                onChange={this.handleChange(false)}
                                color="primary"
                            />
                        )}
                        label={t('No')}
                    />
                </FormGroup>
            )
            : (
                <FormControlLabel
                    control={(
                        <Checkbox
                            id={path.join('-')}
                            color="primary"
                            disabled={readOnly}
                            checked={value}
                            onChange={({ target: { checked } }) => this.handleChange(checked)()}
                        />
                    )}
                    label={description}
                />
            );
    }

    render() {
        const { sample, description, required, error, hidden, noMargin } = this.props;

        if (hidden) return null;

        return (
            <ElementContainer
                sample={sample}
                description={required && description}
                required={required}
                error={error}
                noMargin={noMargin}
            >
                {this.renderElement()}
            </ElementContainer>
        );
    }
}

BooleanElement.propTypes = {
    path: PropTypes.array
};

BooleanElement.defaultProps = {
    path: []
};

export default translate('Elements')(BooleanElement);
