import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslate } from 'react-translate';

import FileDataTable from '../../../../FileDataTable';

interface SelectPreviousProps {
    open: boolean;
    onClose: () => void;
}

export const SelectPrevious: React.FC<SelectPreviousProps> = props => {
    const { open, onClose } = props
    const t = useTranslate('DialogPrevious');

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            fullWidth={true}
            maxWidth={'md'} 
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" >
                {t('title')}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {t('description')}
                </DialogContentText>
                <FileDataTable 
                    dense={true}
                    data={[]}
                    />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('Close')}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t('Ok')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
