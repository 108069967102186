import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslate } from 'react-translate';
import { Route, Switch, Link } from "react-router-dom";

import InfoIcon from '@material-ui/icons/InfoOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import InventoryIcon from '@material-ui/icons/AssignmentOutlined';
import RequestIcon from '@material-ui/icons/FormatListNumbered';

import { useAppHeader, PaperLayout, Preloader } from '@eis/components';
import { FileEntity } from '@eis/components/FilesTreeView';
import { useRegistry } from '@eis/hooks';
import { Registry } from '@eis/registry';
import { RegistryKeysEnum } from '@eis/consts';
import ErrorScreen from '@core/components/ErrorScreen';
import { AppRootState } from '@app/store';
import { requestRegisterKeyRecords, requestRegisterKeys } from '@cab/actions/registry';
import { loadWorkflowTemplates } from '@app/application/actions/workflow';

import { TabLabel } from './components/TabLabel';
import { ProjectPart } from './ProjectPart';
import { DocumentsPart } from './DocumentsPart';
import { getFilesByAttributes } from '@cab/actions/files';
import { ProjectRequestTab } from './components/ProjectRequestTab';
import { IProjectInfo, IRequest, IStatus } from './../types';
import { useRoles } from '@eis/hooks/useFundStaff';
import { InventoryPart } from './InventoryPart';


enum PageTabEnum {
    TabRequests = 'requests',
    TabFiles = 'files',
    TabInfo = 'info',
    TabInventory = 'inventory'
}

const TabsList: Array<{
    id: PageTabEnum;
    Icon: any;
    title: string;
}> = [
        { id: PageTabEnum.TabRequests, Icon: RequestIcon, title: 'RequestPart' },
        { id: PageTabEnum.TabFiles, Icon: FolderIcon, title: 'DocsPart' },
        { id: PageTabEnum.TabInfo, Icon: InfoIcon, title: 'InfoPart' },
        { id: PageTabEnum.TabInventory, Icon: InventoryIcon, title: 'InventoryPart' }
    ];

interface ISchemaPart {
    id: number,
    value: any,
    title?: string
}

interface ProjectPageProps {
    projectId: string;
    tabId?: PageTabEnum;
}

export const ProjectPage: React.FC<ProjectPageProps> = props => {
    const { projectId, tabId } = props;
    const t = useTranslate('Projects');

    let _tabId: PageTabEnum = PageTabEnum.TabRequests;
    if (tabId && Object.values(PageTabEnum).includes(tabId)) {
        _tabId = tabId;
    }

    const [activeTab, setActiveTab] = React.useState<PageTabEnum>(_tabId);

    const [loading, setLoading] = useState<boolean>(true);

    const { keys, docTypes, templates } = useSelector((state: AppRootState) => {
        return {
            keys: state.registry.keys,
            docTypes: state.registry.keyRecords[RegistryKeysEnum.FondDocType],
            templates: state.workflowTemplate.list
        }
    });

    const [projectRegisterId, setProjectRegisterId] = useState<string>('');

    const [files, setFiles] = useState<Array<FileEntity>>();
    const [project, setProject] = useState<IProjectInfo>();
    const [requests, setRequests] = useState<Array<IRequest>>();
    const [statuses, setStatuses] = useState<Array<IStatus>>();
    const [inventory, setInventory] = useState<Array<object>>();
    const roles = useRoles();

    const processes = useRegistry<Registry.dc_process>(RegistryKeysEnum.Processes, false);

    useAppHeader({ title: `Проект ${projectId}` });

    const dispatch = useDispatch();

    // 1. Load main project info
    useEffect(() => {
        const loadData = async () => {
            if (!keys) {
                // Получаем схемы ключей. Нужны для отображения общих данных проекта.
                await requestRegisterKeys()(dispatch);
            }

            if (!project) {
                const options = {
                    data_like: { projectNumber: projectId }
                }
                // Данные записи проекта
                const p = await requestRegisterKeyRecords(RegistryKeysEnum.FondProjects, options)(dispatch);

                if (p.meta && p.meta.count) {
                    setProject(p['0'].data);
                    setProjectRegisterId(p['0'].id);
                }
            }
        };
        loadData();
        // eslint-disable-next-line
    }, [projectId]);

    // 2. Load Tab data
    useEffect(() => {
        const loadFiles = async () => {
            if (!docTypes) {
                const options = {
                    limit: 1000
                };
                // Типы файлов
                await requestRegisterKeyRecords(RegistryKeysEnum.FondDocType, options)(dispatch);
            }

            if (project && !files) {
                // Файлы
                const res = await getFilesByAttributes({ projectId: project.rootDocument })(dispatch);
                setFiles(res);
                setLoading(false);
            }

            if (files) setLoading(false);
        };

        const loadRequests = async () => {
            // Схемы процессов для подачи заявок 2..5
            if (templates === null) await loadWorkflowTemplates()(dispatch);

            if (!statuses) {
                const limitOption = {
                    limit: 30
                }
                // Статусы заявок
                const statusList = await requestRegisterKeyRecords(RegistryKeysEnum.Statuses, limitOption)(dispatch);
                setStatuses(statusList);
            }

            if (projectRegisterId) {
                const options = {
                    data_like: { projectRecordId: projectRegisterId }
                }
                // Заявки по проекту
                const requests = await requestRegisterKeyRecords(RegistryKeysEnum.Requests, options)(dispatch);
                setRequests(requests);
                setLoading(false);

            }

            if (requests) setLoading(false);
        };

        const loadInventory = async () => {
            if (projectRegisterId) {
                const options = {
                    data_like: { projectRecordId: projectRegisterId }
                }
                const inventory = await requestRegisterKeyRecords(RegistryKeysEnum.Inventory, options)(dispatch);
                setInventory(inventory);
                setLoading(false);
            }
        }


        switch (activeTab) {
            case PageTabEnum.TabRequests:
                setLoading(true);
                loadRequests();
                break;

            case PageTabEnum.TabFiles:
                setLoading(true);
                loadFiles();
                break;

            case PageTabEnum.TabInfo:
                if (project) setLoading(false);
                break;

            case PageTabEnum.TabInventory:
                setLoading(true);
                loadInventory();
                break;
        }

        // eslint-disable-next-line
    }, [activeTab, projectRegisterId, project]);

    if (!loading && !project) {
        return <ErrorScreen error={{ message: t('NotFound') }} />;
    }

    if (project && !(roles.staff || roles.admin || roles.units.find(u => u.name === project!.edrpou))) {
        return <ErrorScreen error={{ message: 'До цієї сторінки доступ обмежено' }} />
    }

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: PageTabEnum) => {
        if (!loading) { 
            setLoading(true);
            setActiveTab(newValue);
        }
    };

    // Tabs content
    const renderContent = (isStaff: boolean) => (
        <Switch>
            {isStaff &&
                <Route path={`/projects/:projectId/${PageTabEnum.TabFiles}`} >
                    <DocumentsPart
                        rootNumber={projectId}
                        files={files && docTypes ? files.filter(f => roles.admin || roles.staff || docTypes.find(dt => dt.data.isVisibleExternalUser)) : undefined}
                        docTypes={docTypes ? docTypes.map(dt => dt.data) : []}
                    />
                </Route>
            }

            <Route path={`/projects/:projectId/${PageTabEnum.TabInfo}`} >
                <ProjectPart
                    project={project!}
                    schema={keys.find((k: { id: number }) => k.id === RegistryKeysEnum.FondProjects).schema}
                />
            </Route>

            <Route path={`/projects/:projectId/${PageTabEnum.TabInventory}`} >
                <InventoryPart
                    inventory={inventory!}
                    schema={keys.find((k: { id: number }) => k.id === RegistryKeysEnum.Inventory).schema}
                />
            </Route>

            <Route path={[
                `/projects/:projectId/${PageTabEnum.TabRequests}`,
                `/projects/:projectId`
            ]} >
                {requests && statuses &&
                    <ProjectRequestTab
                        project={project!}
                        requests={requests}
                        processes={processes}
                        statuses={statuses}
                    />
                }
            </Route>
        </Switch>
    );

    return (
        <PaperLayout fullWidth={true}>
            <Route
                path={`/projects/:projectId`}
                render={(history) => (
                    <Fragment>
                        <Tabs
                            disabled={loading}
                            value={activeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example"
                        >
                            {TabsList.map(item => {
                                // Прячем вкладку с файлами от НЕ сотрудников фонда
                                if (!(roles.staff || roles.admin) && item.id === PageTabEnum.TabFiles) return null;

                                return (
                                    <Tab
                                        key={item.id} value={item.id}
                                        label={<TabLabel Icon={item.Icon} title={t(item.title)} />}
                                        component={Link} to={`/projects/${projectId}/${item.id}`} />
                                )
                            })
                            }
                        </Tabs>

                    </Fragment>
                )} />

            {loading && <Preloader />}
            {!loading && renderContent(roles.staff || roles.admin)}
        </PaperLayout>
    )
}
