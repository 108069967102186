import React, { Fragment, useState } from 'react';
import { translate } from 'react-translate';

import {
  Button,
  DialogActions,
  Paper,
  Popover,
  Tooltip,
  Checkbox,
  MenuList,
  MenuItem,
  IconButton,
  ClickAwayListener,
  Typography
} from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import { IColumn } from './../types';

interface ICustomizateColumnsProps {
  t: (text: string) => string;
  classes: any;
  columns: Array<IColumn>;
  hiddenColumns: Array<string>;
  toggleColumnVisible: (id: string) => void;
}

const defaultProps: Partial<ICustomizateColumnsProps> = {
  columns: [],
  hiddenColumns: [],
  toggleColumnVisible: () => null
};

const CustomizateColumns: React.FC<ICustomizateColumnsProps> = props => {
  const { t, columns, hiddenColumns, toggleColumnVisible, classes } = props;
  const [anchorEl, setAnchor] = useState(null);

  const handleMenuOpen = (e: any) => setAnchor(e.currentTarget);

  const handleMenuClose = () => setAnchor(null);

  return (
    <Fragment>
      <Tooltip title={t('CustomizateColumns')}>
        <IconButton onClick={handleMenuOpen} id="customizate-columns">
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <Paper>
            <MenuList>
              {(columns || []).filter(({ hiddable }) => hiddable !== false).map((column: IColumn, key) => (
                <MenuItem id={'column-' + key} key={key} onClick={() => toggleColumnVisible(column.id)} dense={true} >
                  <Checkbox
                    color="primary"
                    size="small"
                    classes={{
                      root: classes.checkBoxMenuRoot
                    }}
                    checked={!(hiddenColumns || []).includes(column.id)} />
                  <Typography variant="subtitle2">{column.name || column.id}</Typography>
                </MenuItem>
              ))}

              <DialogActions>
                <Button
                  id="close-columns"
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={handleMenuClose}
                >
                  {t('Close')}
                </Button>
              </DialogActions>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Fragment>
  );
}

CustomizateColumns.defaultProps = defaultProps;

export default translate('DataTable')(CustomizateColumns);
