import React from 'react';

import StorageIcon from '@material-ui/icons/Storage';

import { RegistryPage } from './pages/Registry';
import { IModuleInfo } from '@eis/types';
import { PagesIDEnum } from '@app/application/menu/consts';

const module: IModuleInfo = {
    routes: [
        {
            path: '/registry',
            component: RegistryPage,
            access: { unitHasAccessTo: 'navigation.registry.RegistryPage' }
        }
    ],
    navigation: [{
        id: PagesIDEnum.Registry,
        priority: 15,
        icon: <StorageIcon />,
        path: '/registry',
        access: { unitHasAccessTo: 'navigation.registry.RegistryPage' }
    }]
};

export default module;