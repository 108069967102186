import { AppConfigJson } from '../eis/types';
import config from '../config.json';

const tc: AppConfigJson = config as any;

// @ts-ignore
config.application.version = process.env.REACT_APP_VERSION as string;

export default tc;

