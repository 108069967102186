import { IProjectInfo } from '../types';
import moment from 'moment'; 

export interface IRow {
    projectNumber: string;
    projectStatus: string;
    osbbName: string;
    region: string;
    contractDate?: Date;
    totalCost: number;
    grantAmount: number;
}

export function genRows(projects: Array<{data: IProjectInfo}> | undefined): Array<IRow> {
    if (!projects) return [];

    // console.log('projects', projects)
    return projects.map(p => {
        let contractDate: Date | undefined;
        if (p.data.contractDate) {
            const dateM = moment(p.data.contractDate, 'DD.MM.YYYY HH:mm:ss');
            contractDate = dateM.isValid() ? dateM.toDate() : undefined;
        } 
        return {
            projectNumber: p.data.projectNumber,
            projectStatus: p.data.projectStatus,
            osbbName: `${p.data.edrpou} ${p.data.osbbName}`,
            region: `${p.data.region}, ${p.data.settlement}`,
            contractDate,
            totalCost: Number(p.data.totalCost || 0),
            grantAmount: Number(p.data.grantAmount || 0)
        }
    })
}