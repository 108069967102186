import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from '@app/store';
import { AuthData } from '../types';
import { requestRegisterKeyRecords } from '../../core/actions/registry';
import { RegistryKeysEnum } from '../consts';

export interface IProject {
        projectNumber: string,
        rootDocument: string,
        code: string;
        osbbName: string;
        edrpou: string;
        region: string;
        settlement: string;
        contractDate: string;
}

export const useOwnProjects = () => {
    const [ projects, setProjects ] = useState<Array<any>>([]);
    const { userUnits, userInfo } = useSelector((state: AppRootState) => ({
        userUnits: state.auth.userUnits as Array<{id: number; name: string;}>,
        userInfo: state.auth.info as AuthData
    }));

    const dispatch = useDispatch(); 
    useEffect(() => {
        // Завантажує проекти всіх вказаних ОСББ 
        const load = async (edrpou: string[]) => {
            const prom = edrpou.map(search => {
                const options = {
                    data_like: { edrpou: search },
                    strict: true, 
                    limit: 30000 
                }
                return requestRegisterKeyRecords(RegistryKeysEnum.FondProjects, options)(dispatch);
            });
            const _projects = await Promise.all(prom);
            setProjects(_projects.reduce((p, c) => {
                return [...p, ...Array.from(c)]
            }, []));
        }
        
        const ids = new Array<string>();
        if (userUnits) {
            const units = userUnits.filter(u => {
                // Назва юніта і є edrpou
                return (u.name.length === 8) && (Number(u.name) > 0);
            });
            units.forEach(u => ids.push(u.name))            
        }

        // Якщо посадовим ключем
        if (userInfo.edrpou && !ids.includes(userInfo.edrpou)) { // '23876580' 
            ids.push(userInfo.edrpou);
        }
        
        if (ids.length > 0) {
            load(ids);
        }   
    }, [userInfo, userUnits, dispatch]);

    return projects;
};
