import React, { useEffect } from 'react';
import StringElement from './StringElement';
import { StringElementProps } from './StringElement/StringElementProps';

export interface IntegerElementProps extends StringElementProps {
    // TODO: new props
    minValue?: number;
    maxValue?: number;
}

export const IntegerElement: React.FC<IntegerElementProps> = props => {
    const { value, onChange, required, defaultValue, inputProps, minValue, maxValue, ...rest } = props;

    useEffect(() => {
        if (required && !value && onChange) {
            onChange(defaultValue || 0);
        }
        // eslint-disable-next-line 
    }, []);

    return (
        <StringElement
            {...rest}
            type="number"
            fullWidth={false}
            required={required}
            inputProps={{
                ...inputProps,
                min: String(minValue),
                max: String(maxValue)
            }}
            value={Number(value)}
            defaultValue={defaultValue ? Number(defaultValue) : undefined}
            onChange={val => onChange && onChange(Number(val))}
        />
    );
}

export default IntegerElement;

// class IntegerElement extends React.Component {
//     componentDidMount() {
//         const { value, onChange, required } = this.props;
//         if (required && value === null && onChange) {
//             onChange(0);
//         }
//     }

//     render() {
//         const { onChange, value, hidden } = this.props;

//         if (hidden) return null;

//         return (
//             <StringElement
//                 {...this.props}
//                 type="number"
//                 value={Number(value )}
//                 onChange={val => onChange && onChange(parseInt(val, 10))}
//             />
//         );
//     }
// }

// IntegerElement.propTypes = {
//     value: PropTypes.number,
//     onChange: PropTypes.func,
//     required: PropTypes.bool
// };

// IntegerElement.defaultProps = {
//     value: null,
//     onChange: () => null,
//     required: false
// };

// export default IntegerElement;
