import React from 'react';
import { IModuleInfo } from '@eis/types';
import { PagesIDEnum } from '../../menu/consts';
import StartPage from './pages/Home';
import { HomePage } from './pages/Home/Home';
import PageNotFound from './pages/PageNotFound';
import HomeIcon from '@material-ui/icons/Home';

const module: IModuleInfo = {
    navigation: [
        {
            id: PagesIDEnum.Home,
            icon: <HomeIcon />,
            path: '/home'
        }
    ],
    routes: [
        {
            path: '/home',
            component: HomePage
        },
        {
            path: '/',
            component: StartPage
        },
        {
            path: '*',
            component: PageNotFound
        }
    ]
};

export default module;