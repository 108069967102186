import { INavigatorItemInfo } from '@eis/types';
import { getNavigation } from '@eis/utils';
import checkAccess from 'helpers/checkAccess';
import modules from '../modules';
import { PagesIDEnum } from './consts';
import config from 'config/index';
// import { isUisStaffOnly } from '@eis/utils';


export const getMenu = (auth: { userInfo?: any; userUnits?: any; }): Array<INavigatorItemInfo> => {
    const items = getNavigation(modules)
        // Убираем меню, указанные в config как спрятанные 
        .filter(item => !config.hideMenu || !config.hideMenu.includes(item.id))
        // Убираем меню, к которым нет доступа
        .filter(item => !item.access || checkAccess(item.access, auth.userInfo, auth.userUnits))
        // Службе ЕИС не показываем разделы
        .filter(item => !([PagesIDEnum.GeneratorLevel, PagesIDEnum.RecipientLevel].includes(item.id ) 
                    ));

    const ordered = [
        PagesIDEnum.Logo, 
        PagesIDEnum.Home,
        PagesIDEnum.Dashboard,
        PagesIDEnum.Projects,
        // PagesIDEnum.News,
        PagesIDEnum.Tasks,
        PagesIDEnum.Workflow,
        PagesIDEnum.Users,
        PagesIDEnum.Registry,
        PagesIDEnum.InfoPage
    ];

    const result: Array<INavigatorItemInfo> = [];
    ordered.forEach(value => {
        const m = items.find(item => item.id === value);
        if (m) result.push(m);
    })
    return result;
};
