import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-translate';

import {
    Typography,
    Toolbar,
    withStyles,
    IconButton,
    Divider
} from '@material-ui/core';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ListIcon from '@material-ui/icons/List';

import { default as Content } from 'layouts/components/Content';

import PerformerUserSelect from './PerformerUserSelect';
import PerformerUserList from './PerformerUserList';

const styles = {
    flexGrow: {
        flexGrow: 1
    }
};

const TaskAssign = ({ t, classes, task, task: { performerUnits, finished }, userUnits }) => {
    const [edit, setEdit] = React.useState(false);

    const userHeadUnitIds = userUnits.filter(({ head }) => head).map(({ id }) => id);
    const units = performerUnits.filter(unitId => userHeadUnitIds.includes(unitId));
    const canEdit = !!units.length && !finished;

    return (
        <Content small={true}>
            <Divider light={true} />
            <Toolbar disableGutters={true}>
                <Typography variant="body2">{t('TaskAssign')}</Typography>
                <div className={classes.flexGrow} />
                {canEdit ? (
                    <IconButton
                        onClick={() => setEdit(!edit)}
                    >
                        {edit ? <ListIcon /> : <EditOutlinedIcon />}
                    </IconButton>
                ) : null}
            </Toolbar>
            <Divider light={true} />
            {edit
                ? <PerformerUserSelect task={task} setEdit={setEdit} />
                : <PerformerUserList task={task} setEdit={setEdit} />
            }
            <Divider light={true} />
        </Content>
    );
};

const mapState = ({ auth: { userUnits } }) => ({ userUnits });

const styled = withStyles(styles)(TaskAssign);
const translated = translate('TaskPage')(styled);
export default connect(mapState)(translated);
