import React from 'react';
import PropTypes from 'prop-types';
import {
    // MobileStepper,
    StepButton,
    Stepper,
    Step,
    StepLabel,
    Hidden,
    withStyles,
    Box
} from '@material-ui/core';
import evalate from 'helpers/evalate';
import { StepConnector } from './StepConnector';
import { StepperMini } from './StepperMini';

const styles = {
    stepperToolbar: {
        padding: '0 4px'
    },
    steeper: {
        backgroundColor: '#fafafa',
        padding: '24px 0',
        overflow: 'hidden'
    },
    step: {
        wordWrap: 'break-word',
        boxSizing: 'border-box'
    },
    stepBtn: {
        '& span': {
            maxWidth: '100%'
        }
    },
    mobileStepper: {
        backgroundColor: '#fafafa',
        width: '100%',
        '& > div': {
            margin: 'auto'
        }
    },
    descriptionWord: {
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginRight: 4
    },
    label: {
        maxHeight: '4.5em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '95%'
    }
};

const getTitle = (string, data) => {
    const evalatedTitle = evalate(string, data);

    if (!(evalatedTitle instanceof Error)) return evalatedTitle;

    return string;
};

const SchemaStepper = (props) => {
    const {
        task,
        steps,
        classes,
        activeStep,
        errors,
        handleStep,
        jsonSchema: {
            properties
        }
    } = props;
    if (steps.length <= 1) return null;

    // Ограничиваем вывод, если шагов много
    const needLimit = steps.length > 6;
    const titles = steps.map(stepId => properties[stepId].description);

    return (
        <>
            <Hidden
                smDown={true}
                implementation="css"
            >
                <Stepper
                    alternativeLabel={true}
                    activeStep={activeStep}
                    className={classes.steeper}
                    id="steper"
                    connector={null} //TODO: make line
                >
                    {
                        steps.map((stepId, index) => {
                            const showLabel = !needLimit ||
                                ((activeStep === 0) && (index <= 2)) ||
                                ((activeStep === (steps.length - 1)) && (index >= steps.length - 3)) ||
                                (index <= (activeStep + 1) && (index >= (activeStep - 1)));

                            return (
                                <Step
                                    key={stepId}
                                    title={properties[stepId].description}
                                    className={classes.step}
                                    style={{
                                        maxWidth: needLimit ? undefined : `${100 / steps.length}%`,
                                        minWidth: showLabel ? `${100 / 8}%` : undefined
                                    }}
                                >
                                    <StepConnector 
                                        first={index === 0}
                                        last={index === (steps.length-1)}/>
                                    <StepButton
                                        completed={false}
                                        className={classes.stepBtn}
                                        onClick={handleStep(index)}
                                        disabled={index > steps.length - 1}
                                    >

                                        {showLabel && <StepLabel error={errors[stepId]} >
                                            <Box className={classes.label}>{getTitle(properties[stepId].description || '', (task && task.document.data) || {})}</Box>
                                        </StepLabel>
                                        }
                                    </StepButton>
                                </Step>
                            )
                        })
                    }
                </Stepper>
            </Hidden>
            <Hidden mdUp={true}>
                <StepperMini 
                    steps={titles} 
                    current={activeStep}
                    onClick={handleStep}
                    />
                {/* <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    className={classes.mobileStepper}
                /> */}
            </Hidden>
        </>
    );
};

SchemaStepper.propTypes = {
    steps: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    handleStep: PropTypes.func.isRequired,
    jsonSchema: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired
};

export default withStyles(styles)(SchemaStepper);
