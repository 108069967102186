import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%'
    },
    input: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0
        }
    },
    multiSelect: {
        display: 'flex',
        height: 'auto'
    },
    singleValue: {
        fontSize: 16,
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: '18px'
        }
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap!important',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden'
    },
    chip: {
        margin: `${theme.spacing(0.5)}px 0`,
        maxWidth: '100%',
        boxSizing: 'border-box',
        padding: '4px 12px',
        '& span': {
            maxWidth: 'calc(100% - 24px)',
            display: 'block',
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: 0
        },
        '& svg': {
            margin: '0 0 0 4px'
        }
    },
    chipWrap: {
        margin: 2
    },
    chipRoot: {
        position: 'relative',
        height: 'unset',
        paddingTop: 7,
        paddingBottom: 7,
        paddingRight: 30,
        borderRadius: 50
    },
    chipLabel: {
        whiteSpace: 'initial'
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        )
    },
    noOptionsMessage: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
        overflow: 'hidden'
    }
});

export default styles;