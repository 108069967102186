import React from 'react';
import { translate } from 'react-translate';
import { loadWorkflowStatuses } from '@cab/actions/workflow';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    FormControl,
    Select,
    MenuItem,
    withStyles
} from '@material-ui/core';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(),
        minWidth: 120
    }
});

class SelectStatus extends React.Component {
    componentDidMount() {
        const { filters, actions } = this.props;

        if (!filters) {
            actions.loadWorkflowStatuses();
        }
    }

    render() {
        const { t, classes, value, filters, onChange } = this.props;

        return (
            <FormControl className={classes.formControl}>
                <Select
                    id="workflow-select-status-input"
                    value={value}
                    onChange={onChange}
                    SelectDisplayProps={{
                        id: 'workflow-select-status'
                    }}
                >
                    <MenuItem value={0}>{t('All')}</MenuItem>
                    {filters && filters.map(option => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                        >
                            {t(capitalizeFirstLetter(option.name))}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}
const mapStateToProps = ({ workflowTemplate }) => ({
    filters: workflowTemplate.statuses
});

const mapDispatchToProps = dispatch => ({
    actions: {
        loadWorkflowStatuses: bindActionCreators(loadWorkflowStatuses, dispatch)
    }
});

const translated = translate('WorkflowListPage')(SelectStatus);
const styled = withStyles(styles)(translated);
export default connect(mapStateToProps, mapDispatchToProps)(styled);
