import { flatten, trim } from "lodash";

export default errors => Object.values(errors || {}).map((error) => {
    let path = flatten(error.dataPath
        .split(".")
        .filter((v) => !!v)
        .map((v) => {
            const firstIndexOfBracket = v.indexOf("[");

            return [
                v.slice(0,  firstIndexOfBracket >=0 ? firstIndexOfBracket : v.length),
                ...(v
                    .match(/\[\d+\]*/gi) || [])
                    .map((v) => trim(v, "[]")
                    )
            ]
        }
        )
    ).join(".");


    if (error.params && error.params.missingProperty) {
        path += (path ? '.' : '') + error.params.missingProperty;
    }

    return {
        ...error,
        path
    };
});
