/**
 * Идентификаторы для пунктов меню первого уровня
 */
export enum PagesIDEnum {
    Logo = 'Logo',
    Dashboard = 'Dashboard',
    Projects = 'Projects',
    News = 'News',
    AllResources = 'AllResources',
    AllServices = 'AllServices',
    GeneratorLevel = 'GeneratorLevel',
    RecipientLevel = 'RecipientLevel',
    Tasks = 'Tasks',
    Workflow = 'Workflow',
    Users = 'Users',
    Registry = 'Registry',
    CRM = 'CRM',
    InfoPage = 'InfoPage',
    Services = 'Services',
    FlowsGenerator = 'ConnectionsGenerator',
    FlowsRecipient = 'ConnectionsRecipient',
    CurrentTasks = 'CurrentTasks',
    TrashTasks = 'TrashTasks',
    MyWorkflow = 'MyWorkflow',
    WorkflowDrafts = 'WorkflowDrafts',
    WorkflowTrash = 'WorkflowTrash',
    Home = 'Home',
}