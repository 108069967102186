import React from 'react';
// import { NavLink } from 'react-router-dom';
// import logo from '@app/assets/img/logo_wh_notext.svg';
import logo from '@app/assets/img/logo.svg';
import { createStyles, makeStyles, Theme, Link } from '@material-ui/core';
import { setOpenSidebar } from '@cab/actions/app';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => createStyles({
    link: {
        width: '100%',
        cursor: 'pointer'
    },
    logo: {
        margin: theme.spacing(1, 'auto'),
        width: '80%',
        height: 56,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${logo})`
    }
}))

export const Logo: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch()

    return (
        <Link onClick={() => dispatch(setOpenSidebar(false))} className={classes.link}>
            <div className={classes.logo} />
        </Link>
    );
} 