import React, { FC, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from 'react-translate';
import { Link, useLocation } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import {
    Theme, makeStyles, createStyles,      // for styles
    IconButton, Tooltip, Avatar,          // for app bar
    Menu, ListItemIcon, MenuItem, Divider // for menu
} from '@material-ui/core';

import UserName from 'components/Auth/UserName';
import { getAccessToken, logout, toggleDebugMode } from '@core/actions/auth';
import checkAccess from 'helpers/checkAccess';

import avatar from 'assets/img/user-image.svg';
import { AppRootState } from '@app/store';
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';

import config from 'config/index';
import storage from '@core/helpers/storage';
import { API2, API2ServicesEnum, API2MethodsEnum } from '@core/services/api2';


const { application, cabinetUrl, adminPanelUrl } = config;
const isAdminApp = application.type === 'admin';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        backgroundColor: '#e1e1e1',
        color: '#333',
        borderRadius: 50
    },
    link: {
        display: 'none',
        padding: '0 7px 0 16px',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        }
    },
    subTitle: {
        fontSize: 10,
        maxWidth: '135px',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    iconButtonAvatar: {
        padding: 0
    },
    avatar: {
        width: 41,
        height: 41,
        backgroundColor: '#e1e1e1'
    },
    menuLink: {
        textDecoration: 'none'
    },
    outerLinkRoot: {
        padding: 0
    },
    outerLink: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        width: '100%',
        height: '100%',
        padding: '6px 16px'
    },
    menuIcon: {
        width: theme.spacing(2),
        minWidth: theme.spacing(2),
        marginLeft: theme.spacing(3)
    },
    selectedItem: {
        fontWeight: 'bold'
    }
}));


const mapStateToProps = ({ auth: { info: userInfo, userUnits, debugMode } }: AppRootState) =>
    ({ userInfo, userUnits, debugMode });

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
        logout: bindActionCreators(logout, dispatch),
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch)
    }
});
type ConnectorType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type ProfileAppbarProps = ConnectorType & {};

/**
 * Button for app App Bar and dropdown profile menu
 */
const ProfileAppbar: FC<ProfileAppbarProps> = props => {
    const classes = useStyles();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const t = useTranslate('Navigator');
    const { userInfo, userUnits, debugMode, actions } = props;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleLogout = async () => {
        handleMenuClose();
        const accessToken = getAccessToken();
        await API2.send(API2ServicesEnum.Auth, API2MethodsEnum.SignInCRM, { accessToken });
        actions.logout(true, true);
        storage.setItem('backUrl', '/');
    };

    const renderOuterLink = (url: string, title: string) => {
        return (
            <MenuItem
                component='a' href={url} target="_blank"
                onClick={handleMenuClose}
            >
                {title}
            </MenuItem>
        );
    }

    const userUnit = (userUnits || []).map(({ name }: any) => name).filter((key: string) => key !== 'based').join(', ');
    const userIsGod = checkAccess({ userIsGod: true }, userInfo, userUnits);
    const userIsAdmin = userIsGod || checkAccess({ userIsAdmin: true }, userInfo, userUnits);
    const isProfile = location.pathname === '/profile';

    const renderMenu = () => {
        return (
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MenuItem
                    classes={{ selected: classes.selectedItem }}
                    selected={isProfile}
                    disabled={isProfile}
                    component={Link} to="/profile"
                    onClick={handleMenuClose}>
                    {t('MyProfile')}
                </MenuItem>
                {userIsAdmin && isAdminApp && cabinetUrl &&
                    renderOuterLink(cabinetUrl, t('ToCabinet'))
                }
                {userIsAdmin && !isAdminApp && adminPanelUrl &&
                    renderOuterLink(adminPanelUrl, t('ToAdminPanel'))
                }
                {userIsAdmin &&
                    <MenuItem onClick={actions.toggleDebugMode}>
                        {t('DebugMode')}
                        <ListItemIcon classes={{ root: classes.menuIcon }}>
                            {debugMode
                                ? <CheckIcon fontSize="small" />
                                : <UnCheckIcon fontSize="small" />
                            }
                        </ListItemIcon>
                    </MenuItem>
                }
                <Divider />
                <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
            </Menu>
        );
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.link}>
                    <div><UserName {...userInfo} /></div>
                    <Tooltip title={userUnit}>
                        <div className={classes.subTitle}>{userUnit}</div>
                    </Tooltip>
                </div>
                <IconButton
                    id="avatar-btn"
                    color="inherit"
                    className={classes.iconButtonAvatar}
                    onClick={handleMenuOpen}
                >
                    <Avatar className={classes.avatar} src={avatar} />
                </IconButton>
            </div>
            {renderMenu()}
        </Fragment>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAppbar);
