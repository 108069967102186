import React from 'react';

import TimeLabel from 'components/Label/Time';

import AttachesActions from './components/AttachesActions';
import FileNameColumn from './components/FileNameColumn';
import DataTableCard from './components/DataTableCard';
import SignatureDetails from './components/SignatureDetails';

export default ({ t, actions, fileStorage }) => ({
    actions,
    components: {
        DataTableCard
    },
    controls: {
        pagination: false,
        toolbar: true,
        search: false,
        header: true,
        refresh: false,
        switchView: true,
        customizateColumns: false,
        toggleWidth: true,
    },
    checkable: true,
    columns: [{
        id: 'fileName',
        name: t('FileName'),
        padding: 'none',
        render: (value, item) => {
            const fileName = value || item.name || t('Unnamed');
            const customName = item.customName || null;

            return (
                <FileNameColumn
                    name={fileName}
                    item={item}
                    customName={customName}
                    extension={fileName.split('.').pop()}
                />
            );
        }
    }, {
        id: 'createdAt',
        name: t('FileDate'),
        width: 140,
        align: 'center',
        render: (value, { updatedAt }) => (
            <TimeLabel date={value || updatedAt} />
        )
    }, {
        id: t('Signature'),
        name: '',
        width: 48,
        align: 'center',
        padding: 'none',
        disableClick: true,
        render: (value, item) => (item.signature ? <SignatureDetails item={item} /> : null)
    }, {
        id: t('Preview'),
        width: 48,
        align: 'right',
        padding: 'checkbox',
        disableClick: true,
        render: (value, item) => ((item instanceof File) ? null : (
            <AttachesActions item={item} actions={actions} fileStorage={fileStorage} />
        ))
    }]
});
