import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => createStyles({
    wrapp: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        maxWidth: 900
    },
    article: {
        padding: '0.75em 0'
    },
    articleDate: {
        marginTop: '-0.75em',
        marginBottom: '1em',
        opacity: 0.8
    },
    author: {
        opacity: 0.8
    },
    title: {
        marginBottom: '0.5em',
        fontWeight: 'bold',
    },
    headline: {
        fontWeight: 'bold'
    },
    text: {
        marginBottom: '0.5em'
    },
    link: {
        color: theme.palette.primary.dark
    },
    divider: {
        marginBottom: '1em'
    },
    logo: {
        width: 210,
        margin: theme.spacing(1, 0)
    }
}));
