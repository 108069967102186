import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import {
    ListItemIcon,
    ListItem,
    ListItemText,
    makeStyles,
    createStyles
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FontFamily } from '../../manager/theme';


const useStyles = makeStyles(theme => createStyles({
    icon: {
        maxWidth: 46
    },
    badge: {
        backgroundColor: theme.palette.error.main,
        padding: '1px 4px',
        borderRadius: '10px',
        minWidth: '20px',
        textAlign: 'center',
        display: 'inline-block',
        fontSize: '90%'
    },
    primary: {
        fontFamily: FontFamily
    }
}));

interface MenuItemProps {
    title: string,
    icon?: ReactNode,
    asCategory?: boolean
    open?: boolean
    badge?: string | number;
    selected?: boolean,
    path?: string,
    dense?: boolean,
    handleClick?: any
}

export const MenuItem: FC<MenuItemProps> = props => {
    const { title, icon, asCategory, open, badge, selected, path, dense, handleClick } = props;

    const classes = useStyles();

    const cBadge = (!asCategory &&
        ((typeof badge === 'string' && badge.length > 0) ||
            (typeof badge === 'number' && badge > 0)))
        ? <div className={classes.badge}>{badge}</div>
        : null;


    return (
        <ListItem
            selected={selected}
            button={true}
            component={path ? NavLink : 'div'}
            to={path}
            dense={dense}
            onClick={handleClick}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                classes={{
                    primary: classes.primary
                }} 
                primary={title} />
            {asCategory && (open ? <ExpandLess /> : <ExpandMore />)}
            { cBadge}
        </ListItem>
    )
}