import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-translate';

import {
    Tooltip,
    IconButton
} from '@material-ui/core';

import DeletedIcon from '@material-ui/icons/Delete';
import ConfirmDialog from 'components/ConfirmDialog';

interface DeleteRecordProps {
    hideButton?: boolean;
    initState?: boolean;
    handleClose?: () => void;
    rowsSelected: any;
    actions: any;
}

export const DeleteRecord: React.FC<DeleteRecordProps> = props => {
    const { hideButton, rowsSelected, actions, handleClose, initState } = props;
    const t = useTranslate('RegistryPage');
    const [openConfirmDialog, setOpenDialog] = useState<boolean>(Boolean(initState));

    const handleOpenConfirmDialog = () => setOpenDialog(true);

    const handleCloseConfirmDialog = () => {
        setOpenDialog(false);
        if (handleClose) handleClose();
    } 

    const handleDelete = async () => {
        actions.onRowsDelete && await actions.onRowsDelete([rowsSelected.id]);
        setOpenDialog(false);
    };

    return (
        <Fragment>
            {!hideButton &&
                <Tooltip title={t('Delete')}>
                    <IconButton onClick={handleOpenConfirmDialog} id="delete-record">
                        <DeletedIcon />
                    </IconButton>
                </Tooltip>
            }
            <ConfirmDialog
                fullScreen={false}
                open={openConfirmDialog}
                title={t('DeleteRecordConfirmation')}
                description={t('DeleteRecordConfirmationText')}
                handleClose={handleCloseConfirmDialog}
                handleConfirm={handleDelete}
            />
        </Fragment>
    );

}

// export default translate('RegistryPage')(DeleteRecord);
