import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';

import { Button, Dialog } from '@material-ui/core';

import DataTable from 'components/DataTable';
import TimeLabel from 'components/Label/Time';
import Preloader from 'components/Preloader';
import ErrorScreen from 'components/ErrorScreen';

import dataTableConnect from 'services/dataTable/connect';
import dataTableAdapter from 'services/dataTable/adapter';

import evalate from 'helpers/evalate';

import endPoint from '../../../../../endPoints/registryKeyHistory';

class KeyVersionSelect extends React.Component {
    state = { open: false, error: null };

    handleOpen = async () => {
        const { t, record, actions } = this.props;
        this.setState({ open: true });

        const result = await actions.onFilterChange({ keyId: record.keyId, recordId: record.id }, true);
        this.setState({ error: (result instanceof Error) ? new Error(t(result.message)) : null });
    };

    //@TODO: maybe, should be used through whole project
    serialize = (input) => {
        const { selectedKey } = this.props;
        let content = evalate(selectedKey.toString, input);

        if (typeof content !== 'string') {
            return '#ERR:SERIALIZATION'
        } else if (content instanceof Error) {
            content.commit({ type: 'registry', selectedKey });
            return null;
        }

        return content || null;
    };

    renderDialogContent() {
        const { error } = this.state;
        const { t, onSelect, selectedKey, loading } = this.props;

        if (loading) {
            return <Preloader />;
        }

        if (error) {
            return <ErrorScreen error={error} />;
        }

        return (
            <DataTable
                {...dataTableAdapter(this.props)}
                onRowClick={version => this.setState({ open: false }, () => onSelect(version))}
                columns={[{
                    id: 'createdAt',
                    name: t('CreatedAt'),
                    render: createdAt => <TimeLabel date={createdAt} />
                }, {
                    id: 'data',
                    name: t('Name'),
                    render: (record) => {
                        if (!selectedKey) {
                            return null;
                        }

                        return this.serialize(record);
                    }
                }, {
                    id: 'createdBy',
                    name: t('CreatedBy')
                }]}
                controls={{
                    pagination: true,
                    toolbar: true,
                    search: false,
                    header: true,
                    refresh: false,
                    switchView: false,
                    toggleWidth: true
                }}
            />
        );
    }

    render() {
        const { t } = this.props;
        const { open } = this.state;

        return (
            <>
                <Button color="primary" onClick={this.handleOpen}>{t('Versions')}</Button>
                <Dialog open={open} onClose={() => this.setState({ open: false })} >
                    {this.renderDialogContent()}
                </Dialog>
            </>
        );
    }
}

KeyVersionSelect.propTypes = {
    t: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    selectedKey: PropTypes.object,
    record: PropTypes.object,
    actions: PropTypes.object.isRequired
};

KeyVersionSelect.defaultProps = {
    onSelect: () => null,
    selectedKey: null,
    record: null
};

const translated = translate('RegistryPage')(KeyVersionSelect);
export default dataTableConnect(endPoint)(translated);
