// import { getUnreadInboxCount } from './actions/inbox';
// import { getUnreadMessageCount } from './actions/messages';
import { getMyUnreadTaskCount, getUnitUnreadTaskCount, getMainProcesses } from './actions/task';
import modules from './modules';
import reducers from './reducers';

export { getMenu } from './menu';

export default {
    modules,
    reducers,
    initActions: {
        // getUnreadInboxCount,
        getMainProcesses,
        getMyUnreadTaskCount,
        getUnitUnreadTaskCount,
    },
    timeActions: {
        // getUnreadMessageCount
    }
};
