import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from '@core/serviceWorker';
import { Theme, createTheme } from "@material-ui/core";
import * as Sentry from '@sentry/browser';
import { moduleHooks } from "@core/initStorage";

import App from './App';
import { IModuleInfo } from './eis/types';

import config from './config';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/css/mgrpdf.theme.css';
import 'assets/css/main.css';


// TODO: Динамическая загрузка через переменные не работает, вероянтно по причине не нахождения пути 
//       после приобразования кода самим сборщиком
// const appModule = config.application.type !== 'admin' 
//     ? import('./manager')
//     : import('./admin');

const appModule = import('./manager');


appModule.then((m: {default: any}) => {
    const appConfig = m.default;
    // console.log(appConfig);
    // const { config } = appConfig;
    const { sentryDns,  application } = config;

    if (sentryDns) {
        Sentry.init({ 
            dsn: sentryDns, 
            environment: application.environment, 
            release: application.version, 
            ignoreErrors: ['401 unauthorized', '404 not found', 'NetworkError when attempting to fetch resource.', 'Failed to fetch', 'Перелік закінчено', 'NS_BINDING_ABORTED:', 'Error: ESOCKETTIMEDOUT'] 
        });
    }

    const theme: Theme = createTheme(appConfig.theme);
    const modules = appConfig.modules as Array<IModuleInfo>;
    const initActions = appConfig.initActions;

    modules.forEach(({ hooks }) => {
        hooks && moduleHooks.extend(hooks);
    });

    ReactDOM.render(
        React.createElement(App, { config, theme, modules, initActions }),
        document.getElementById('root'));
})

//     // If you want your app to work offline and load faster, you can change
//     // unregister() to register() below. Note this comes with some pitfalls.
//     // Learn more about service workers: http://bit.ly/CRA-PWA
if (config.application.environment === 'prod') {
    serviceWorker.register();
} else {
    serviceWorker.unregister();
}
