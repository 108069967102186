import React, { useRef, useState } from 'react';

import {
  withStyles,
  ClickAwayListener
} from '@material-ui/core';

import InputComponent from 'components/DataTable/components/SearchInput/InputComponent';
import FilterChips from 'components/DataTable/components/SearchInput/FilterChips';
import FilterHandlers from 'components/DataTable/components/SearchInput/FilterHandlers';

import waiter from 'helpers/waitForAction';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
    marginLeft: 10
  },
  searchIcon: {
    padding: '0 8px'
  }
};

const SEARCH_INTERVAL = 1000;

interface ISearchInputProps {
  classes: any;
  search: string;
  actions: any;
  filters: any;
  filterHandlers: any;
}

const defaultProps: Partial<ISearchInputProps> = {
  search: '',
  actions: {},
  filters: {},
  filterHandlers: {}
};

const SearchInput: React.FC<ISearchInputProps> = props => {
  const { classes, search, filters, filterHandlers, actions } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const rootRef = useRef(null);

  const handleChange = (e: any) => {
    actions.onSearchChange && actions.onSearchChange(e.target.value, false);
    waiter.addAction('dataTableSearch', actions.load, SEARCH_INTERVAL);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setActiveFilter(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose} >
      <div
        className={classes.root}
        ref={rootRef}
      >
        <FilterChips
          filters={filters}
          onClose={handleClose}
          filterHandlers={filterHandlers}
          onFilterChange={actions.onFilterChange}
          onClick={(e: any, filterName: string) => (e: any) => {
            setAnchorEl(e.currentTarget);
            setActiveFilter(filterName);
          }}
        />
        <InputComponent
          value={search}
          onChange={handleChange}
          onKeyPress={(e: any) => e.key === 'Enter' && actions.load()}
          onFocus={(e: any) => {
            setAnchorEl(e.currentTarget);
            setActiveFilter(null);
          }}
        />
        <FilterHandlers
          filters={filters}
          filterHandlers={filterHandlers}
          onFilterChange={actions.onFilterChange}
          onClose={handleClose}
          anchorEl={anchorEl}
          rootRef={rootRef}
          activeFilter={activeFilter}
          setActiveFilter={(filterName: string) => setActiveFilter(filterName)}
        />
      </div>
    </ClickAwayListener>
  );
}

SearchInput.defaultProps = defaultProps;

export default withStyles(styles)(SearchInput);
