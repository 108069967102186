import React from 'react';
import classNames from 'classnames';

import {
  Toolbar,
  Tooltip,
  IconButton,
  TablePagination,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIconOutlined from '@material-ui/icons/ViewModuleOutlined';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import UnfoldMore from '@material-ui/icons/UnfoldMore';

import SelectAllButton from './components/SelectAllButton';
import SearchInput from './components/SearchInput';
import CustomizateColumns from './components/CustomizateColumns';
import { ICommonProps } from './types';

const styles = (theme: Theme) => createStyles({
  toolbar: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 5,
      paddingLeft: 5,
      paddingBottom: 0
    },
    '& > *': {
      marginRight: 6
    },
    '&:first-child': {
      paddingLeft: 2
    },
    '&:last-child': {
      paddingRight: 12,
      marginRight: 0
    }
  },
  paginationToolbar: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 8
    }
  },
  grow: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0
  },
  container: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  flexContainer: {
    display: 'flex'
  },
  row: {
    borderBottom: 'none',
    padding: '8px 0'
  },
  checkBoxMenuRoot: {
    padding: '4px 8px 4px 2px'
  },
  widthIcon: {
    transform: 'rotate(90deg)'
  }
});

interface IDataTableToolbarProps extends ICommonProps {
  isFullWidth: boolean;
  grouping: boolean;
  toggleGrouping: () => void;
  switchView: () => void;
  switchWidth: () => void;
}

const defaultProps: Partial<IDataTableToolbarProps> = {
  page: 0,
  count: 0,
  rowsPerPage: 10,
  data: [],
  rowsSelected: [],
  CustomToolbar: null,
  toolbarPosition: 'middle',
  filterHandlers: {}
}

const DataTableToolbar: React.FC<IDataTableToolbarProps> = (props) => {
  const {
    classes, view, isFullWidth, page, count, rowsPerPage, checkable,
    actions, data, search, filters, filterHandlers, toolbarPosition,
    rowsSelected, columns, hiddenColumns, controls, groupBy, grouping,
    switchWidth, CustomToolbar,
    t, switchView, toggleGrouping
  } = props;

  const selectableData = (data || []).filter(actions.isRowSelectable || Boolean);
  const selectedLength = selectableData.length;

  return (
    <div className={classNames(classes.container, {
      [classes.flexContainer]: !controls.pagination
    })}
    >
      <Toolbar className={classNames(classes.toolbar, classes.grow)}>
        {checkable ? (
          <SelectAllButton
            rowsSelected={rowsSelected}
            selectableData={selectableData}
            onRowsSelect={actions.onRowsSelect}
          />
        ) : null}
        {(CustomToolbar && toolbarPosition === 'start') ? (
          <CustomToolbar selectedLength={selectedLength} {...props} />
        ) : null}
        {controls.search ? (
          <SearchInput
            actions={actions}
            search={search}
            filters={filters}
            filterHandlers={filterHandlers}
          />
        ) : null}
        {controls.refresh ? (
          <Tooltip title={t('Reload')}>
            <IconButton onClick={(actions || {}).load} id="reload">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        {(CustomToolbar && toolbarPosition !== 'start') ? (
          <CustomToolbar selectedLength={selectedLength} {...props} />
        ) : null}
      </Toolbar>

      {controls.pagination ? (
        <TablePagination
          id="table-pagination"
          backIconButtonProps={{
            id: 'table-pagination-back'
          }}
          nextIconButtonProps={{
            id: 'table-pagination-next'
          }}
          className={classes.row}
          classes={{ toolbar: classes.paginationToolbar }}
          count={count}
          onPageChange={(e, newPage) => actions.onChangePage && actions.onChangePage(newPage)}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={t('ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to }) => t('DISPLAYED_ROWS', { from, to, total: count })}
          rowsPerPageOptions={[10, 20, 50]}
          onRowsPerPageChange={({ target: { value } }) => actions.onChangeRowsPerPage && actions.onChangeRowsPerPage(value)}
          page={page - 1}
        />
      ) : null}

      <Toolbar className={classes.toolbar}>
        {controls.switchView ? (
          <Tooltip title={t('SwitchView')}>
            <IconButton onClick={switchView} id="switch-view">
              {view === 'table'
                ? (<ViewModuleIconOutlined />)
                : (<ViewListIcon />)}
            </IconButton>
          </Tooltip>
        ) : null}
        {controls.toggleWidth && <Tooltip title={t(isFullWidth ? 'MinWidthTable' : 'MaxWidthTable')}>
          <IconButton id="switch-width" className={classes.widthIcon} onClick={() => switchWidth()}>
            {isFullWidth
              ? (<UnfoldLess />)
              : (<UnfoldMore />)
            }
          </IconButton>
        </Tooltip>}
        {groupBy ? (
          <Tooltip title={t('ToggleGrouping')}>
            <IconButton onClick={toggleGrouping} id="toggle-grouping">
              {grouping
                ? <ViewHeadlineIcon />
                : <ViewAgendaIcon />}
            </IconButton>
          </Tooltip>
        ) : null}
        {controls.customizateColumns ? (
          <CustomizateColumns
            classes={classes}
            columns={columns}
            hiddenColumns={hiddenColumns}
            toggleColumnVisible={actions.toggleColumnVisible}
          />
        ) : null}
      </Toolbar>
    </div>
  );
};

DataTableToolbar.defaultProps = defaultProps;

export default withStyles(styles)(DataTableToolbar);
