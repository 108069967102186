import React from 'react';
import { useState, useEffect, Fragment } from 'react';

import { Registry } from '@eis/registry';

import { IStatus, IRequest, ITest, IProjectInfo } from './../../types';
import { ProcessItem } from './ProcessItem';
import { generateAccessObj } from '../helpers/requestAccess';
import { RequestNumberEnum } from '@eis/consts';

interface IProjectRequestTabProps {
    project: IProjectInfo,
    requests: Array<IRequest>,
    processes: Array<Registry.dc_process>
    statuses: Array<IStatus>;
}

interface ILastStatus {
    code: string,
    status: string
}

interface IObjectInfo {
    processInfo: Registry.dc_process,
    requestsList: Array<IRequest>,
    lastRequestStatus?: ILastStatus
}

export const ProjectRequestTab: React.FC<IProjectRequestTabProps> = props => {
    const { project, requests, processes, statuses } = props;
    const [ dataList, setDataList ] = useState<Array<IObjectInfo>>([]);
    const [ accessObj, setAccessObj ] = useState<Record<number, ITest>>({});

    useEffect(() => {
        const list = processes.map((process): IObjectInfo => {
            const requestsInProcess = !!requests
                ? requests.filter(item => item.data.name.id === process.id)
                : [];
            
            let item = {
                processInfo: process,
                requestsList: requestsInProcess,
                lastRequestStatus: {code: '', status: ''}
            }

            if(requestsInProcess.length) {
                const theLastRequest = maxDates(requestsInProcess);
                const status = statuses.find(el => el.id === theLastRequest.data.requestStatus.statusId)
                if(status) {
                    item.lastRequestStatus = {
                        code: status.data.codeRec,
                        status: status.data.name
                    }
                }
            }
   
            return item;
        })
        
        
        list.sort((p1, p2) => {
            return Math.sign(p1.processInfo.data.menuOrder - p2.processInfo.data.menuOrder);
        });
        setDataList(list);

        const ao = generateAccessObj(list);
        if (project.isEdssbListAfter) {
            ao[RequestNumberEnum.ForVerification].isApply = false;
        }
        setAccessObj(ao);

        // eslint-disable-next-line
    }, [processes])

    const maxDates = (array: Array<IRequest>): IRequest => {
        const maxDate = array.reduce((prevValue, currValue) => {
            return new Date(prevValue.data.receivingDate) > new Date(currValue.data.receivingDate)
                ? prevValue
                : currValue;
        })

        return maxDate;
    }

    return (
        <Fragment>
            { dataList.map(item => (
                    <ProcessItem key={item.processInfo.id} item={item} statuses={statuses} accessObj={accessObj}/>
                ))
            }
        </Fragment>
    );
};
