import { routerReducer } from 'react-router-redux';

// Core
import authReducer from 'reducers/auth';
import usersReducer from 'reducers/users';
import errorReducer from 'reducers/error';
import edsReducer from 'reducers/eds';
import debugToolsReducer from 'reducers/debugTools';

import dataTableReducer from 'services/dataTable/reducer';

import appReducer from './app';
import taskReducer from './task';
import workflowReducer from './workflow';
import registryReducer from './registry';
import documentTemplateReducer from './documentTemplate';
import workflowTemplateReducer from './workflowTemplate';
import messagesReducer from './messages';
import fileReducer from './files';
import inboxReducer from './inbox';
import externalReaderReducer from './externalReader';

import workflowEndPoint from '../endPoints/workflow';
import workflowDraftEndPoint from '../endPoints/workflowDraft';
import workflowTrashEndPoint from '../endPoints/workflowTrash';

import registryRecordEndPoint from '../endPoints/registryRecord';
import registryHistoryEndPoint from '../endPoints/registryHistory';
import registryKeyHistoryEndPoint from '../endPoints/registryKeyHistory';
import messageEndPoint from '../endPoints/message';
import inboxFilesEndPoint from '../endPoints/inboxFiles';

// App
import taskEndPoint from '../endPoints/task';
import unitTaskEndPoint from '../endPoints/unitTask';
import closedTaskEndPoint from '../endPoints/closedTask';
import closedUnitTaskEndPoint from '../endPoints/closedUnitTask';

import myServicesEndPoint from '../endPoints/myServices';
import subscribedServicesEndPoint from '../endPoints/subscribedServices';
import allServicesEndPoint from '../endPoints/allServices';
import getMyEndPoints from '../endPoints/getMyEndpoints';
import getSubscribedEndPoints from '../endPoints/getSubscribedEndpoints';
import getMyLogs from '../endPoints/getMyLogs';
import getSubscribedLogs from '../endPoints/getSubscribedLogs';

import connection from './connection';

const reducerHelper = (data: any): any => {
    const param = {
        sourceName: '', 
        defaultOptions: {}, 
        mapData: null, 
        reduce: null,
        ...data
    }
    return dataTableReducer(param);
}

export default {
    // Core
    app: appReducer,
    router: routerReducer,
    eds: edsReducer,
    auth: authReducer,
    users: usersReducer,
    errors: errorReducer,
    task: taskReducer,
    workflow: workflowReducer,
    registry: registryReducer,
    documentTemplate: documentTemplateReducer,
    workflowTemplate: workflowTemplateReducer,
    messages: messagesReducer,
    files: fileReducer,
    inbox: inboxReducer,
    externalReader: externalReaderReducer,
    debugTools: debugToolsReducer,
    [taskEndPoint.sourceName]: dataTableReducer(taskEndPoint),
    [unitTaskEndPoint.sourceName]: dataTableReducer(unitTaskEndPoint),
    [closedTaskEndPoint.sourceName]: dataTableReducer(closedTaskEndPoint),
    [closedUnitTaskEndPoint.sourceName]: dataTableReducer(closedUnitTaskEndPoint),
    [registryRecordEndPoint.sourceName]: reducerHelper(registryRecordEndPoint),
    [registryHistoryEndPoint.sourceName]: reducerHelper(registryHistoryEndPoint),
    [registryKeyHistoryEndPoint.sourceName]: reducerHelper(registryKeyHistoryEndPoint),
    [workflowEndPoint.sourceName]: reducerHelper(workflowEndPoint),
    [workflowDraftEndPoint.sourceName]: reducerHelper(workflowDraftEndPoint),
    [workflowTrashEndPoint.sourceName]: reducerHelper(workflowTrashEndPoint),
    [messageEndPoint.sourceName]: reducerHelper(messageEndPoint),
    [inboxFilesEndPoint.sourceName]: reducerHelper(inboxFilesEndPoint),
    // App
    connection,
    [myServicesEndPoint.sourceName]: reducerHelper(myServicesEndPoint),
    [subscribedServicesEndPoint.sourceName]: reducerHelper(subscribedServicesEndPoint),
    [allServicesEndPoint.sourceName]: reducerHelper(allServicesEndPoint),
    [getMyEndPoints.sourceName]: reducerHelper(getMyEndPoints),
    [getSubscribedEndPoints.sourceName]: reducerHelper(getSubscribedEndPoints),
    [getMyLogs.sourceName]: reducerHelper(getMyLogs),
    [getSubscribedLogs.sourceName]: reducerHelper(getSubscribedLogs)
};