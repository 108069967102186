import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import { default as DrawerContent } from 'layouts/components/DrawerContent';
import { translate } from 'react-translate';

import Scrollbar from 'components/Scrollbar';
import TaskDetails from '../../../components/TaskDetails';
import StoreEventError from '../../../components/StoreEventError';
import TaskPreview from './TaskPreview';
import SigningAction from './SigningAction';

const styles = {
    taskPreviewContainer: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden'
    },
    screenContainer: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    pdfPreview: {
        flex: 1,
        overflow: 'hidden',
        '& .ps__rail-y': {
            opacity: '1!important',
            '& .ps__thumb-y': {
                backgroundColor: '#fff',
                zIndex: 1
            }
        }
    }
};

const PreviewScreenLayout = ({
    classes,
    t,
    task,
    template,
    storeEventError,
    busy,
    setBusy,
    handleFinish,
    backToEdit,
    pdfRequired,
    isUserUnitHead,
    showSignerList
}) => (
        <div className={classes.taskPreviewContainer}>
            <StoreEventError error={storeEventError} />
            <DrawerContent
                drawer={
                    (isUserUnitHead || showSignerList ? (
                        <TaskDetails
                            task={task}
                            template={template}
                            isUserUnitHead={isUserUnitHead}
                            showSignerList={showSignerList}
                        />
                    ) : null)
                }
            >
                <div className={classes.screenContainer}>
                    <Alert  severity="info" className={classes.warning}>{t('Example')}</Alert>
                
                    <div className={classes.pdfPreview}>
                        <Scrollbar>
                            <TaskPreview task={task} template={template} />
                        </Scrollbar>
                    </div>
                    <SigningAction
                        busy={busy}
                        setBusy={setBusy}
                        task={task}
                        template={template}
                        handleFinish={handleFinish}
                        backToEdit={backToEdit}
                    />
                </div>
            </DrawerContent>
        </div>
    );

PreviewScreenLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    backToEdit: PropTypes.func.isRequired,
    busy: PropTypes.bool.isRequired,
    setBusy: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    storeEventError: PropTypes.object,
    pdfRequired: PropTypes.bool.isRequired,
    isUserUnitHead: PropTypes.bool.isRequired,
    showSignerList: PropTypes.bool.isRequired
};

PreviewScreenLayout.defaultProps = {
    storeEventError: null
};

export default withStyles(styles)(translate('InboxFilesPage')(PreviewScreenLayout));
