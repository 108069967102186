import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate';

import {
    Typography,
    Button,
    Box,
    makeStyles,
    createStyles
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { logout } from 'actions/auth';

const useStyles = makeStyles(theme => createStyles({
    errorIcon: {
        fontSize: 82,
        color: 'red'
    },
    wrap: {
        width: 700,
        marginTop: 100,
        paddingLeft: 50,
        '@media (max-width: 767px)': {
            width: '100%',
            marginTop: 50,
            paddingLeft: 0
        }
    },
    title: {
        padding: '0 12px',
        color: '#00224e',
        marginTop: 15
    },
    errorText: {
        margin: '20px 0 28px'
    }
}));

interface ServiceMessageProps {
    error: Error
    canSwitchUser?: boolean
}

export const ServiceMessage: React.FC<ServiceMessageProps> = props => {
    const { error, canSwitchUser } = props;
    const classes = useStyles();
    const t = useTranslate('App');
    const dispatch = useDispatch();

    return (
        <Box className={classes.wrap}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography
                className={classes.title}
                variant="h4"
                gutterBottom={true}
            >
                {t(error.message)}
            </Typography>
            <Typography
                className={classes.title}
                variant="subtitle1"
                gutterBottom={true}
            >
                {t(error.message + ' subtitle')}
            </Typography>
            {
                error.message === 'User without needed role.'
                    || error.message === 'Access denied.'
                    || canSwitchUser
                    ? (
                        <Button variant="outlined" onClick={() => () => dispatch(logout())}>
                            {t('SwitchUser')}
                        </Button>
                    ) : null
            }
        </Box>
    );
}
