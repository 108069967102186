import React from 'react';

import TimeLabel from 'components/Label/Time';
import Deadline from 'components/Label/Deadline';
import UserNamesLabels from 'components/Label/UserNamesLabels';
import UnitNamesLabels from 'components/Label/UnitNamesLabels';
import TaskName from '../components/TaskName';

import { formatUserName } from 'helpers/userName';

const columns = t => [{
    id: 'document.number',
    name: t('DocumentNumber'),
    align: 'center',
    render: (value, { document, workflow }) => (document && document.number) || (workflow && workflow.number)
}, {
    id: 'name',
    name: t('TaskName'),
    align: 'left',
    sortable: false,
    render: (value, item) => <TaskName task={item} />
}, {
    id: 'applicantName',
    align: 'center',
    name: t('Applicant'),
    sortable: false,
    render: (value, { workflow: { userData } }) => formatUserName(userData && userData.userName)
}, {
    id: 'applicantType',
    align: 'center',
    name: t('ApplicantType'),
    sortable: false,
    render: (value, { workflow: { userData } }) => (userData && userData.isLegal !== undefined) ? (userData.isLegal ? t('LegalEntity') : t('Individual')) : ''
}, {
    id: 'performerUnits',
    align: 'center',
    name: t('PerformerUnits'),
    sortable: true,
    render: value => <UnitNamesLabels units={value || []} />
}, {
    id: 'performerUserNames',
    align: 'center',
    name: t('Performer'),
    sortable: true,
    render: value => <UserNamesLabels userNames={value || []} />
}, {
    id: 'createdAt',
    width: 160,
    align: 'right',
    sortable: true,
    padding: 'checkbox',
    name: t('createdAt'),
    render: value => <TimeLabel date={value} />
}, {
    id: 'dueDate',
    width: 200,
    align: 'center',
    sortable: true,
    name: t('deadline'),
    render: (value, { createdAt, dueDate, finished }) => (
        <>
            <TimeLabel date={value} />
            {!finished ? <Deadline start={createdAt} end={dueDate} /> : null}
        </>
    )
}];

export default ({ t }) => ({
    controls: {
        pagination: false,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true,
        bottomPagination: true,
        toggleWidth: false
    },
    checkable: false,
    columns: columns(t)
});
