import React, { FC, Fragment, useState } from 'react';
import { useTranslate } from 'react-translate';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { createTask } from '@cab/actions/task';

import {
    Button,
    // createStyles,
    // makeStyles
} from '@material-ui/core';
import CreateTaskDialog from './CreateTaskDialog';

// const useStyles = makeStyles(theme => createStyles({
//     fab: {
//         width: '100%!important',
//         borderRadius: '3px!important',
//         // color: theme.textColorDark,
//         // backgroundColor: theme.buttonBg,
//         minHeight: '50px',
//         marginBottom: '26px',
//         marginTop: '26px',
//         '&:hover': {
//             // backgroundColor: theme.buttonHoverBg
//         }
//     }
// }));


interface CreateTaskButtonProps {
    title?: string,
    color?: "primary" | "secondary" | "default" | undefined
}
export const CreateTaskButton: FC<CreateTaskButtonProps> = props => {
    const { title, color } = props;
    const [state, setState] = useState<boolean>(false);
    const t = useTranslate('Navigator');
    const closeCreateTaskDialog = () => setState(false);
    const openCreateTaskDialog = () => setState(true);

    const dialogsProps = {
        open: state,
        handleClose: closeCreateTaskDialog
    };
    
    return (
        <Fragment>
            <Button
                size="medium"
                variant="contained"
                aria-label="Add"
                color={(color as any) || 'primary'}
                // className={classes.fab}
                onClick={openCreateTaskDialog}
                id="add-new-task"
            >
                {title || t('AddNewTask')}
            </Button>
            <CreateTaskDialog
                {...dialogsProps}
            />
        </Fragment>
    );

}
export default CreateTaskButton;