import React from 'react';
import DoneAll from '@material-ui/icons/DoneAll';

import TaskListPage from './pages/TaskList';
// import TableTools from './pages/TaskList/components/TableTools';
import { CustomToolBarWithSwitch } from './pages/TaskList/components/CustomToolBarWithSwitch'
import TaskPage from './pages/Task';
import OnboardingTaskPage from './pages/OnboardingTask';
import UncreatedTask from './pages/UncreatedTask';
import MultisignTask from './pages/MultisignTask';
// import CreateTaskButton from 'application/manager/modules/tasks/components/CreateTaskButton';

import taskEndPoint from '../../endPoints/task';
import unitTaskEndPoint from '../../endPoints/unitTask';
import closedTaskEndPoint from '../../endPoints/closedTask';
import closedUnitTaskEndPoint from '../../endPoints/closedUnitTask';

// import MyTaskNavigation from './components/MyTaskNavigation';
// import { UnitTaskNavigation } from './menu/UnitTaskNavigation';
import { IModuleInfo, IRouteItemInfo } from '@eis/types';
import { PagesIDEnum } from '@app/application/menu/consts';
import { makeSwitchProps } from './pages/TaskList/components/utils';
import AppConfig from 'config/index';

const actualTaskSwitch = makeSwitchProps({
    personalTitle: 'Персональні',
    unitTitle: 'Підрозділу',
    personal: 'my-tasks',
    unit: 'unit-tasks'
});

const closedTaskSwitch = makeSwitchProps({
    personalTitle: 'Персональні',
    unitTitle: 'Підрозділу',
    personal: 'closed-tasks',
    unit: 'closed-unit-tasks'
});

const taskModules: Array<IRouteItemInfo> = [{
    title: 'ClosedTasksTitle',
    rootPath: '/tasks/closed-tasks',
    TableToolbar: () => <CustomToolBarWithSwitch {...{
        ...closedTaskSwitch,
        current: 'closed-tasks'
    }} />,
    endPoint: closedTaskEndPoint,
    defaultFilters: { finished: 1, deleted: 0, assigned_to: 'me' },
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.ClosedTasks'
    },
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
}, {
    title: 'UnitClosedTasksTitle',
    rootPath: '/tasks/closed-unit-tasks',
    TableToolbar: () => <CustomToolBarWithSwitch {...{
        ...closedTaskSwitch,
        current: 'closed-unit-tasks'
    }} />,
    endPoint: closedUnitTaskEndPoint,
    defaultFilters: { finished: 1, deleted: 0, assigned_to: 'unit' },
    hiddenColumns: {
        notUnitedUser: ['performerUserNames'],
        isUnitedUser: 'properties.hiddenColumns.tasks.UnitClosedTasks'
    },
    access: { unitHasAccessTo: 'navigation.tasks.UnitClosedTasks' }
}, {
    title: 'UnitInboxTasksTitle',
    rootPath: '/tasks/unit-tasks',
    TableToolbar: () => <CustomToolBarWithSwitch {...{
        ...actualTaskSwitch,
        current: 'unit-tasks'
    }} />,
    endPoint: unitTaskEndPoint,
    defaultFilters: { finished: 0, deleted: 0, assigned_to: 'unit' },
    hiddenColumns: {
        notUnitedUser: ['performerUserNames'],
        isUnitedUser: 'properties.hiddenColumns.tasks.UnitInboxTasks'
    }
}, {
    title: 'InboxTasksTitle',
    rootPath: '/tasks/my-tasks',
    TableToolbar: () => <CustomToolBarWithSwitch {...{
        ...actualTaskSwitch,
        current: 'my-tasks'
    }} />,
    endPoint: taskEndPoint,
    defaultFilters: { finished: 0, deleted: 0, assigned_to: 'me' },
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.InboxTasks'
    },
    access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.InboxTasks' }
}, {
    title: 'InboxTasksTitle',
    rootPath: '/tasks',
    TableToolbar: () => <CustomToolBarWithSwitch {...{
        ...actualTaskSwitch,
        current: 'my-tasks'
    }} />,
    endPoint: taskEndPoint,
    hiddenColumns: {
        notUnitedUser: ['applicantName', 'applicantType', 'performerUnits', 'performerUserNames', 'Строк виконання', 'dueDate'],
        isUnitedUser: 'properties.hiddenColumns.tasks.InboxTasks'
    }
}];

const taskRoutes: Array<IRouteItemInfo> = (new Array<IRouteItemInfo>()).concat(...taskModules.map((route: IRouteItemInfo) => {
    const { rootPath, ...rest } = route;
    return [
        {
            rootPath,
            path: rootPath + '/:taskId/signers/apply',
            component: MultisignTask
        },
        {
            rootPath,
            path: rootPath + '/redirect/:workflowId/:taskTemplateId',
            component: UncreatedTask
        },
        {
            rootPath,
            path: rootPath + '/create/:workflowTemplateId/:taskTemplateId',
            component: TaskPage
        }, {
            rootPath,
            path: rootPath + '/create/:workflowTemplateId',
            component: TaskPage
        }, {
            rootPath,
            path: rootPath + '/:taskId/:stepId',
            component: TaskPage
        }, {
            rootPath,
            path: rootPath + '/:taskId',
            component: TaskPage
        }, {
            ...rest,
            rootPath,
            path: rootPath,
            component: TaskListPage,
            // TableToolbar: TableTools
        }
    ]
})
);

const module: IModuleInfo = {
    routes: [
        {
            path: '/tasks/onBoarding/:stepId',
            component: OnboardingTaskPage,
            isOnboarding: true
        }, {
            path: '/tasks/onBoarding',
            component: OnboardingTaskPage,
            isOnboarding: true
        },
        ...taskRoutes,
        {
            path: '*',
            to: '/tasks/onBoarding',
            redirect: true,
            isOnboarding: true
        }
    ],
    navigation: [
        // {
        //     priority: 60,
        //     Component: CreateTaskButton,
        //     access: { isUnitedUser: false, unitHasAccessTo: 'navigation.tasks.CreateTaskButton' }
        // }, 
        {
            id: PagesIDEnum.Tasks,
            icon: <DoneAll />,
            priority: 40,
            access: {
                isUnitedUser: false,
                unitHasAccessTo: [
                    'navigation.tasks.InboxTasks',
                    'navigation.tasks.UnitInboxTasks',
                    'navigation.tasks.ClosedTasks',
                    'navigation.tasks.UnitClosedTasks'
                ]
            },
            children: [{
                id: PagesIDEnum.CurrentTasks,
                path: AppConfig.taskMenu && AppConfig.taskMenu.unitFirst
                    ? '/tasks/unit-tasks'
                    : '/tasks/my-tasks',
            }, {
                id: PagesIDEnum.TrashTasks,
                path: AppConfig.taskMenu && AppConfig.taskMenu.unitFirst
                    ? '/tasks/closed-unit-tasks'
                    : '/tasks/closed-tasks',
                access: { unitHasAccessTo: 'navigation.tasks.ClosedTasks' }
            },
            ]
        }]
};

export default module;
