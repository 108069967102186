import React, { createContext, useState, useContext, useEffect } from 'react';

type DebugToolsType = Record<string, any> | undefined;

export interface AppHeaderData {
    title: string,
    backButton: string,
    loading: boolean,
    debugTools: DebugToolsType
}

interface AppHeaderContextType extends AppHeaderData {
    setTitle: (value: string) => void;
    setBackButton: (value: string) => void;
    setLoading: (value: boolean) => void;
    setDebugTools: (value: DebugToolsType) => void;
}

const defaultValue: AppHeaderContextType = {
    title: '',
    backButton: '',
    loading: false,
    debugTools: undefined,
    setTitle: () => {},
    setBackButton: () => {},
    setLoading: () => {},
    setDebugTools: () => {}
}

export const AppHeaderContext = createContext<AppHeaderContextType>(defaultValue);

/**
 * Hook for set application title 
 */
export const useAppHeader = (params?: Partial<AppHeaderData>) => {
    const ctx = useContext(AppHeaderContext);
    useEffect(() => {
        if (params !== undefined) {
            if (params.title !== ctx.title && params.title) {
                ctx.setTitle(params.title);
            }
            if (params.backButton !== undefined) {
                ctx.setBackButton(params.backButton);
            } else {
                // Remove back
                if (ctx.backButton) 
                    ctx.setBackButton('');
            }
            if (params.loading !== undefined) {
                ctx.setLoading(params.loading);
            }
            if (params.debugTools !== ctx.debugTools) {
                ctx.setDebugTools(params.debugTools);
            }
        }    
    });
    return ctx;
}

interface AppHeaderContextProviderProps {
    appTitle: string
}
export const AppHeaderContextProvider: React.FC<AppHeaderContextProviderProps> = props => {
    const {children, appTitle} = props;
    const [title, setTitle] = useState<string>('');
    const [backButton, setBackButton] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [debugTools, setDebugTools] = useState<DebugToolsType>(undefined);

    const setBrowserTabTitle = (value: string) => {
        setTitle(value);
        //  Set browser tab title as current page title
        document.title = [value, appTitle].filter(Boolean).join(' - ');
    }

    // const setLoadingState = (value: boolean) => {
    //     setLoading(value);
    // }

    return (
        <AppHeaderContext.Provider value={{
            title, setTitle: setBrowserTabTitle,
            backButton, setBackButton,
            loading, setLoading: setLoading,
            debugTools, setDebugTools
        }}>
            {children}
        </AppHeaderContext.Provider>
    );
}
