import React, { Fragment } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import className from 'classnames';

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '12',
        margin: '0px -8px -12px -8px'
    },
    space: {
        width: 24 + 8
    }, 
    side: {
        height: '12',
        flex: 1 
    },
    line: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'silver',
    }
}));

interface StepConnectorProps {
    /**
     * Шаг является первым
     */
    first?: boolean,
    /**
     * Шаг является последним
     */
    last?: boolean
}

/**
 * Отображает соединительные линии между шагами
 */
export const StepConnector: React.FC<StepConnectorProps> = props => {
    const { first, last } = props;
    const classes = useStyles();

    return (
        <Fragment>
            <Box className={classes.root}>
                <Box className={className(classes.side, {[classes.line]: !first})}></Box>
                <Box className={classes.space}></Box>
                <Box className={className(classes.side, {[classes.line]: !last})}></Box>
            </Box>
        </Fragment>
    )
}