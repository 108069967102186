import React from 'react';
import { translate } from 'react-translate';

import { withStyles } from '@material-ui/core';

interface ISearchFilterChipsProps {
  classes: any;
  filters: any;
  filterHandlers: any;
  onClick: (e: any, filterName: string) => void;
  onClose: () => void;
  onFilterChange: (value: any) => void;
}

const defaultProps: Partial<ISearchFilterChipsProps> = {
  filters: {},
  filterHandlers: {}
}

const styles = {
  root: {
      display: 'flex'
  }
};

const SearchFilterChips: React.FC<ISearchFilterChipsProps> = props => {
  const { classes, filterHandlers, filters, onClick, onClose, onFilterChange } = props;

  const handleDelete = (filterName: string) => () => {
    const value = Object.keys(filters)
      .filter(fName => fName !== filterName)
      .reduce((acc, fName) => ({
        ...acc,
        [fName]: filters[fName]
      }), {});

    onFilterChange(value);
    onClose();
  };

  return (
    <div className={classes.root}>
      {Object.keys(filterHandlers).map((filterName) => {
        const FilterHandler = filterHandlers[filterName];

        if (!filters[filterName]) {
          return null;
        }

        return (
          <FilterHandler
            key={filterName}
            type="chip"
            value={filters[filterName]}
            onClick={(e: any) => onClick(e.currentTarget, filterName)}
            onDelete={handleDelete(filterName)}
          />
        );
      })}
    </div>
  );
}

SearchFilterChips.defaultProps = defaultProps;

const styled = withStyles(styles)(SearchFilterChips);
export default translate('DataTable')(styled);
