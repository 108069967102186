import React from 'react';
import PropTypes from 'prop-types';

import { Preloader } from '@eis/components';
import { AppHeaderContext } from '@eis/components/AppHeaderContext'; 

import WorkflowTable from './WorkflowTable';

class WorkflowListLayout extends React.Component {
    static contextType = AppHeaderContext;
    
    componentDidMount() {
        this.context.setTitle(this.props.title);
    }

    renderContent() {
        const {
            templates,
            TableToolbar,
            endPoint,
            checkable,
            handleItemClick
        } = this.props;

        if (templates === null) {
            return <Preloader />;
        }

        return (
            <WorkflowTable
                endPoint={endPoint}
                TableToolbar={TableToolbar}
                checkable={checkable}
                handleItemClick={handleItemClick}
            />
        );
    }

    render() {
        // const { location, title, loading } = this.props;
        return this.renderContent();
    }
}

WorkflowListLayout.propTypes = {
    templates: PropTypes.array,
    endPoint: PropTypes.object.isRequired,
    TableToolbar: PropTypes.instanceOf(React.Component),
    checkable: PropTypes.bool,
    location: PropTypes.object.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool,
    handleItemClick: PropTypes.func
};

WorkflowListLayout.defaultProps = {
    templates: null,
    TableToolbar: null,
    checkable: false,
    title: null,
    loading: false,
    handleItemClick: () => null
};

export default WorkflowListLayout;
