import React, { Fragment, useEffect }  from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import classNames from 'classnames';
import hotkeys, { KeyHandler } from 'hotkeys-js';

import {
    Drawer,
    makeStyles,
    CssBaseline,
    Theme,
    createStyles,    
} from '@material-ui/core';
// import colorsPrimary from '@material-ui/core/colors/indigo';

// import Scrollbar from 'components/Scrollbar';
import SplitPane from 'react-split-pane';

import Snackbars from 'components/Snackbars';
import ProgressLine from 'components/Preloader/ProgressLine';

import { setOpenSidebar, toggleExpandMenu } from '@cab/actions/app';
import { closeError } from 'actions/error';
import { toggleDebugMode } from 'actions/auth';

import { AppHeader } from './AppHeader';
import { Navigator } from './Navigator';
import DebugTools from 'layouts/components/DebugTools';

import checkAccess from 'helpers/checkAccess';
import { AppRootState } from '@app/store';
import { IAppBarItemInfo, INavigatorItemInfo } from '../types';
import { ThemeOptionsExt } from '../../manager/theme';
// import { useLocation } from 'react-router-dom';


export const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden'
    },
    sidebarWrapper: {
        width: drawerWidth,
        flexShrink: 0,
        '& .scrollbar-container::-webkit-scrollbar': {
            display: 'none'
        },
        '& .scrollbar-container': {
            scrollbarWidth: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: ThemeOptionsExt(theme).leftSidebarBg,
        position: 'inherit'
    },
    appContent: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        flex: 1,
        overflowY: 'auto',
        marginLeft: -drawerWidth,
        transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        overflowX: 'hidden'
    },
    // toolbar: {
    //     backgroundColor: theme.palette.primary.dark, // theme.leftSidebarBg,
    //     padding: 6
    // },
    collapseButton: {
        padding: 5,
        minWidth: 5
    },
    flexContent: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const LARGE_SCREEN_WIDTH = 600;

interface LayoutProps {
    loading?: boolean,
    debugTools?: any,
    title?: string,
    flexContent?: any,
    backButton?: any,
    location?: any,
    appBarWidgets?: Array<IAppBarItemInfo>,
    menu?: Array<INavigatorItemInfo>,
}


const Layout: React.FC<LayoutProps> = props => {
    const { loading, debugTools, title, children, flexContent, appBarWidgets, menu
     } = props;

    const classes = useStyles();
    const { debugMode, errors, userInfo, userUnits, onboardingTaskId, openSidebar } = useSelector(mapStateToProps);
    const dispatch = useDispatch();
    const { actions } = mapDispatchToProps(dispatch); 

    const handleDrawerToggle = () => {
        actions.setOpenSidebar(!openSidebar);
    };

    const toggleDebugMode: KeyHandler = (): void => { actions.toggleDebugMode() };


    useEffect(() => {
        if (openSidebar === null) {
            actions.setOpenSidebar(window.innerWidth > LARGE_SCREEN_WIDTH);
        }

        window.addEventListener('resize', updateWindowDimensions);
        hotkeys('ctrl+x', toggleDebugMode);

        return function clean() {
            const open = window.innerWidth > LARGE_SCREEN_WIDTH;
            if (openSidebar && !open) {
                actions.setOpenSidebar(open);
            }
            window.removeEventListener('resize', updateWindowDimensions);
            hotkeys.unbind('ctrl+x');
        }
    });

    const updateWindowDimensions = () => {
        const open = window.innerWidth > LARGE_SCREEN_WIDTH;

        if (open !== openSidebar) {
            actions.setOpenSidebar(window.innerWidth > LARGE_SCREEN_WIDTH);
        }
    }

    const renderNavigation = () => {
        return (
            <Drawer
                className={classes.sidebarWrapper}
                variant="persistent"
                open={openSidebar}
                onClose={handleDrawerToggle}
                classes={
                    {
                        paper: classes.drawerPaper
                    }
                }
            >
                <Navigator 
                    menu={menu || []} 
                    handleMenuExpand={(name: string) => actions.toggleExpandMenu(name)}
                    />
            </Drawer>
        );
    }

    const renderMainPane = () => {
        if (onboardingTaskId) {
            return <div id="main-container">{children}</div>;
        }

        return (
            <div id="main-container" className={classNames(classes.root, 'root-layout')}>
                {renderNavigation()}
                <div
                    className={
                        classNames(classes.appContent, {
                            [classes.contentShift]: openSidebar,
                            [classes.flexContent]: flexContent
                        })
                    }
                >
                    <AppHeader
                        widgets={appBarWidgets || []}
                        hideMenuButton={false}
                        title={title}
                        openSidebar={openSidebar}
                        onDrawerToggle={handleDrawerToggle}
                    />
                    {children}
                </div>
            </div>
        );
    }

    const renderPanes = () => {
        const userIsGod = checkAccess({ userIsGod: true }, userInfo, userUnits);
        const userIsAdmin = checkAccess({ userIsAdmin: true }, userInfo, userUnits);

        const useDebugPane = userIsGod && userIsAdmin && debugMode;

        const mainPane = renderMainPane();

        if (!useDebugPane) {
            return mainPane;
        }

        return (
            <SplitPane split="horizontal" minSize="calc(100% - 400px)">
                {mainPane}
                <DebugToolsWrapper
                    debugTools={debugTools} 
                    />
            </SplitPane>
        );
    }
    

    return (
        <Fragment>
            <CssBaseline />
            <ProgressLine loading={loading} />
            <Snackbars errors={errors} onClose={(errorIndex: any) => () => actions.closeError(errorIndex)} />
            {renderPanes()}
        </Fragment>
    )
}

const mapStateToProps = ({
    app: { openSidebar },
    errors: { list },
    auth: { debugMode, userUnits, info, info: { onboardingTaskId } }
}: AppRootState ) => ({
    errors: list,
    openSidebar,
    debugMode,
    userUnits,
    userInfo: info,
    onboardingTaskId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
        closeError: bindActionCreators(closeError, dispatch),
        setOpenSidebar: bindActionCreators(setOpenSidebar, dispatch),
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch),
        toggleExpandMenu: bindActionCreators(toggleExpandMenu, dispatch)
    }
});


// export { default as Content } from 'layouts/components/Content';
// export { default as DrawerContent } from 'layouts/components/DrawerContent';

// export { drawerWidth };

// const styled = withStyles(styles)(Layout);
// export default connect<ILayoutProps>(mapStateToProps, mapDispatchToProps)(Layout);
export default Layout;

// TODO 
const DebugToolsWrapper: React.FC<{debugTools?: any}> = props => <DebugTools {...props} />
// const HeaderWrapper: React.FC<{
//     hideMenuButton?: boolean,
//     title?: string,
//     open?: any,
//     backButton?: any,
//     onDrawerToggle: any}> = props => <Header {...props} />