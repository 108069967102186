import React, { FC, Fragment, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import classNames from 'classnames';
import hotkeys, { KeyHandler } from 'hotkeys-js';

import {
    Drawer,
    makeStyles,
    CssBaseline,
    Theme,
    createStyles,
    Box,
} from '@material-ui/core';
// import colorsPrimary from '@material-ui/core/colors/indigo';

// import Scrollbar from 'components/Scrollbar';
import SplitPane from 'react-split-pane';

import Snackbars from 'components/Snackbars';
import { ErrorBoundary, LocalStorageEditor, ProgressLine } from '@eis/components';

import { setOpenSidebar, toggleExpandMenu } from '@cab/actions/app';
import { closeError } from 'actions/error';
import { toggleDebugMode } from 'actions/auth';

import { AppHeader, AppHeaderContextProvider, AppHeaderContext } from '@eis/components';
import { Navigator } from '@eis/components/Navigator';
import DebugTools from 'layouts/components/DebugTools';

import checkAccess from 'helpers/checkAccess';
import { AppRootState } from '@app/store';
import { AppConfigJson, IAppBarItemInfo } from '@eis/types';
import { getMenu } from '@app/application';
import { ThemeOptionsExt } from '../theme';


export const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden'
    },
    sidebarWrapper: {
        width: drawerWidth,
        flexShrink: 0,
        '& .scrollbar-container::-webkit-scrollbar': {
            display: 'none'
        },
        '& .scrollbar-container': {
            scrollbarWidth: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: ThemeOptionsExt(theme).leftSidebarBg, // theme.palette.primary., //theme.leftSidebarBg,
        position: 'inherit'
    },
    appContent: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        flex: 1,
        overflowY: 'auto',
        marginLeft: -drawerWidth,
        transition: 'margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        overflowX: 'hidden'
    },
    // toolbar: {
    //     backgroundColor: theme.palette.primary.dark, // theme.leftSidebarBg,
    //     padding: 6
    // },
    collapseButton: {
        padding: 5,
        minWidth: 5
    },
    flexContent: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const LARGE_SCREEN_WIDTH = 600;


const useSelectors = () => {
    const { app, errors, auth } = useSelector((state: AppRootState) => state);
    const { loading, openSidebar } = app || {};
    const { debugMode, userUnits, info } = auth || {};
    return {
        auth,
        loading,
        openSidebar,
        errors: errors.list,
        debugMode,
        userUnits,
        userInfo: info,
        onboardingTaskId: info.onboardingTaskId
    }
}

interface LayoutProps {
    config: AppConfigJson,
    appBarWidgets: Array<IAppBarItemInfo>,

    // loading?: boolean,
    // debugTools?: any,
    // title?: string,
    // flexContent?: any,
    // backButton?: any,
    // location?: any,
}

const Layout: FC<LayoutProps> = props => {
    const { children, config, appBarWidgets } = props;
    const { auth, loading, openSidebar, errors, debugMode, userUnits, userInfo, onboardingTaskId } = useSelectors();
    const appMenu = getMenu(auth); //useMemo(() => getMenu(auth), [dispatch]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { actions } = mapDispatchToActions(dispatch);

    const userIsGod = debugMode && checkAccess({ userIsGod: true }, userInfo, userUnits);
    const isDebugPane = userIsGod && checkAccess({ userIsAdmin: true }, userInfo, userUnits);

    // Toggle DebugMode for keyboard
    const toggleDebugMode: KeyHandler = (): void => { actions.toggleDebugMode() };

    // Toggle left side 
    const handleDrawerToggle = () => { actions.setOpenSidebar(!openSidebar); };

    // Change opened Sidebar if size small
    const updateWindowDimensions = () => {
        const open = window.innerWidth > LARGE_SCREEN_WIDTH;

        if (open !== openSidebar) {
            actions.setOpenSidebar(open);
        }
    }

    useEffect(() => {
        if (openSidebar === null) {
            actions.setOpenSidebar(window.innerWidth > LARGE_SCREEN_WIDTH);
        }

        window.addEventListener('resize', updateWindowDimensions);
        hotkeys('ctrl+x', toggleDebugMode);

        return function clean() {
            const open = window.innerWidth > LARGE_SCREEN_WIDTH;
            if (openSidebar && !open) {
                actions.setOpenSidebar(open);
            }
            window.removeEventListener('resize', updateWindowDimensions);
            hotkeys.unbind('ctrl+x');
        }
        // eslint-disable-next-line
    }, [openSidebar, actions]);

    // Render "left part"
    const renderNavigation = () => {
        return (
            <Drawer
                className={classes.sidebarWrapper}
                variant="persistent"
                open={openSidebar}
                onClose={handleDrawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <Navigator
                    menu={appMenu || []}
                    handleMenuExpand={(name: string) => actions.toggleExpandMenu(name)}
                />
            </Drawer>
        );
    };

    // Render "right part"
    const renderMainPane = (content: React.ReactNode) => {
        if (onboardingTaskId) {
            return <Box id="main-container">{children}</Box>;
        }

        return (
            <Box id="main-container" className={classNames(classes.root, 'root-layout')}>
                {renderNavigation()}
                <Box
                    className={
                        classNames(classes.appContent, {
                            [classes.contentShift]: openSidebar,
                            [classes.flexContent]: true //flexContent TODO
                        })
                    }>
                        <AppHeader
                            widgets={appBarWidgets}
                            hideMenuButton={openSidebar}
                            openSidebar={openSidebar}
                            onDrawerToggle={handleDrawerToggle}
                        />
                        {content}
                </Box>
            </Box>
        );
    }

    // Add debug "layer" if needed
    const renderPanes = (content: React.ReactNode) => {
        if (!isDebugPane) {
            return (
                <AppHeaderContextProvider appTitle={config.application.name}>
                    { content }
                </AppHeaderContextProvider>
            ) 
        }
        //todo debugTools 
        return (
            <AppHeaderContextProvider appTitle={config.application.name}>
                <SplitPane split="horizontal" minSize="calc(100% - 400px)">
                    {content}
                    <DebugToolsWrapper config={config} />
                </SplitPane>
            </AppHeaderContextProvider>
        );
    }

    return (
        <Fragment>
            <CssBaseline />
            <ProgressLine loading={loading} />
            <Snackbars errors={errors} onClose={(errorIndex: any) => () => actions.closeError(errorIndex)} />
            {renderPanes(renderMainPane(<ErrorBoundary>{children}</ErrorBoundary>))}
        </Fragment>
    )
}

const mapDispatchToActions = (dispatch: Dispatch) => ({
    actions: {
        closeError: bindActionCreators(closeError, dispatch),
        setOpenSidebar: bindActionCreators(setOpenSidebar, dispatch),
        toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch),
        toggleExpandMenu: bindActionCreators(toggleExpandMenu, dispatch)
    }
});


export default Layout;

// TODO 
interface DebugToolsWrapperProps {
    config: AppConfigJson
}
const DebugToolsWrapper: React.FC<DebugToolsWrapperProps> = props => {
    const ctx = useContext(AppHeaderContext);
    console.log('ctx', ctx);
    const localStorageVars: Array<string> = Object.keys(props.config.localStorageVars) || [];
    const debugTools = {
        ...ctx.debugTools,
        LocalStorage: <LocalStorageEditor names={localStorageVars} />
    }
    return <DebugTools {...{ ...props, debugTools: debugTools }} />
}
