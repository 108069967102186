import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { SchemaForm } from '@core/components/JsonSchema';
import { useRoles } from '@eis/hooks/useFundStaff';

interface ProjectPartProps { 
    project: object; 
    schema: object; 
}

export const ProjectPart: React.FC<ProjectPartProps> = props => {
    const roles = useRoles();
    return (
        <Box paddingTop={2}>
            <SchemaForm
                value={props.project}
                readOnly={true}
                schema={props.schema} />
            
            {roles.admin &&
               <Fragment>    
                    <Typography variant="h6">This part is draft</Typography>
                    <pre>{JSON.stringify(props.project, null, 4)}</pre>
                </Fragment>
            }
        </Box>
    );
};
