import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Ajv from 'ajv';

const useStyles = makeStyles(theme => createStyles({
    cell: {
        margin: 0
    }
}))

interface RowDetailRecordRow {
    row: any
    validator?: Ajv.ValidateFunction
}

/**
 * Show record data as json object
 */
export const RowDetailRecord: React.FC<RowDetailRecordRow> = props => {
    const { row, validator } = props;
    const [ errorSchema, setErrorSchema ] = useState<string>('');
    const classes = useStyles();
    const lines = JSON.stringify(row.data, null, 4);
    
    useEffect(() => {
        if (row !== undefined && validator !== undefined) {
            const valid: boolean = !!validator(row.data);
            if (!valid && validator.errors) {
                setErrorSchema('\nErrors:\n' + JSON.stringify(validator.errors
                    .map(({keyword, schemaPath, ...error}) => error), null, 4));
            }
        }
    }, [validator, row]);

    return (
        <pre className={classes.cell}>
            id: { row.id + '\n' }
            { lines }
            { errorSchema }
        </pre>
    )
}