import React from 'react';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { readAsUint8Array } from 'helpers/readFileList';
import evalate from 'helpers/evalate';
import promiseChain from 'helpers/promiseChain';
import base64toUnit8Array from 'helpers/base64toUnit8Array';

import {
    loadTaskDocument,
    signTaskDocument,
    signTaskDocumentP7S,
    rejectDocumentSigning,
    downloadDocumentAttach,
    getTaskDocumentSignData,
    getTaskDocumentP7SSignData, generatePDFDocument
} from '@cab/actions/task';

import signManifest from 'services/eds/helpers/signManifest';
import SigningActionLayout from './SigningActionLayout';
// import signRequired from "@cab/modules/tasks/pages/Task/helpers/signRequired";

// const MAX_SIZE_P7S_SOURCE = 5 * 1024 * 1024;

class SigningAction extends React.Component {
    state = { showSigningDialog: false, showRejectSigningDialog: false };

    sign = async (signer) => {
        const {
            t,
            actions,
            setBusy,
            task,
            documents,
            pdfDocuments
        } = this.props;

        if (!task) {
            return null;
        }

        const { documentId } = task;
        const pdfDocument = pdfDocuments[task.documentId];
        const { attachments } = documents[task.documentId] || {};

        const attachmentsData = await Promise.all(attachments.map(actions.downloadDocumentAttach));
        const isError = attachmentsData.find(a => a.response && a.response.error);
        if (isError) {
            setBusy(false);
            throw new Error(t('ServerError') + '\n' + 
                attachmentsData
                    .filter(a => a.response.error)
                    .map(a => a.response.error.message).join('\n'));
        }

        const manifest = await actions.getTaskDocumentSignData(documentId);
        const files = [].concat(pdfDocument, attachmentsData).filter(Boolean).map(base64toUnit8Array);
        const signedManifest = await signManifest(manifest.slice(0, 1), signer);
        const signedFiles = await signManifest(files, signer, false);
        const signature = [].concat(signedManifest, signedFiles);

        const signResult = await actions.signTaskDocument(documentId, signature);

        if (!signResult || signResult instanceof Error) {
            setBusy(false);
            throw new Error(t(signResult.message));
        }

        return signResult;
    };

    signP7S = async (signer) => {
        const {
            t,
            actions,
            setBusy,
            task
        } = this.props;

        if (!task) {
            return null;
        }

        const { documentId } = task;

        const signData = await actions.getTaskDocumentP7SSignData(documentId);
        const signDataArrayBuffer = await readAsUint8Array(signData);

        const signature = await signManifest(signDataArrayBuffer, signer);
        const signResult = await actions.signTaskDocumentP7S(documentId, signature);

        if (!signResult || signResult instanceof Error) {
            setBusy(false);
            throw new Error(t(signResult.message));
        }

        return signResult;
    }

    signP7SAttach = async (signer) => {
        const { actions, task } = this.props;
        const { attachments } = await actions.loadTaskDocument(task.documentId);
        const { documentId } = task;

        const attachSignFunc = attach => async () => {
            const signData = await actions.getTaskDocumentP7SSignData(documentId, attach.id);
            const signDataArrayBuffer = await readAsUint8Array(signData);

            // if (signDataArrayBuffer.length > MAX_SIZE_P7S_SOURCE) {
            //     throw new Error(t('MaxSizeError'));
            // }
            // const hash = await signer.execute('HashData', signDataArrayBuffer);
            const [signature] = await signManifest([signDataArrayBuffer], signer);
            return actions.signTaskDocumentP7S(documentId, signature, attach.id);
        };

        return promiseChain(attachments/* .filter(({ isGenerated }) => isGenerated) */.map(attachSignFunc));
    }

    onRejectSigning = (rejectData) => {
        const { task, actions, setBusy } = this.props;
        const { documentId } = task;

        this.setState({ showRejectSigningDialog: false });
        setBusy(true);
        actions.rejectDocumentSigning(documentId, rejectData);
        setBusy(false);
    };

    onSelectKey = async (encryptedKey, signer, resetPrivateKey) => {
        const {
            setBusy,
            handleFinish,
            template: { jsonSchema: { isP7sSign } },
            task,
            task: { signerUsers },
            actions
        } = this.props;

        setBusy(true);

        await actions.generatePDFDocument(task.documentId);

        try {
            if (isP7sSign) {
                await this.signP7S(signer);
                await this.signP7SAttach(signer);
            }

            const signResult = await this.sign(signer);
            const { signatures } = signResult;
            const signers = signerUsers.filter(userId => !signatures.find(({ createdBy }) => createdBy === userId));

            this.setState({ showSigningDialog: false }, resetPrivateKey);
            setBusy(false);

            if (!signers.length) {
                handleFinish();
            }
        } catch (error) {
            setBusy(false);
            resetPrivateKey();
            throw error;
        }
    }

    toggleSigningDialog = bool => this.setState({ showSigningDialog: bool });

    toggleRejectSigningDialog = bool => this.setState({ showRejectSigningDialog: bool });

    render() {
        const {
            authInfo,
            template,
            task: { finished, document: { signatures, signatureRejections, data } },
            template: { jsonSchema: { stepOrders } }
        } = this.props;

        let steps = evalate(stepOrders, data);

        if (steps instanceof Error) {
            steps.commit({ type: 'step orders', template });
            steps = [];
        }

        const alreadySigned = !!signatures.find(({ createdBy }) => createdBy === authInfo.userId);
        const alreadyRejected = !!(signatureRejections || []).find(({ userId }) => userId === authInfo.userId);

        return (
            <SigningActionLayout
                {...this.props}
                {...this.state}
                finished={finished}
                alreadySigned={alreadySigned}
                alreadyRejected={alreadyRejected}
                toggleSigningDialog={this.toggleSigningDialog}
                toggleRejectSigningDialog={this.toggleRejectSigningDialog}
                onSelectKey={this.onSelectKey}
                onRejectSigning={this.onRejectSigning}
                steps={steps}
            />
        );
    }
}

const mapStateToProps = ({
    auth: { info },
    files: { pdfDocuments, list },
    task: { documents }
}) => ({ authInfo: info, pdfDocuments, documents, fileList: list });

const mapDispatchToProps = dispatch => ({
    actions: {
        loadTaskDocument: bindActionCreators(loadTaskDocument, dispatch),
        signTaskDocument: bindActionCreators(signTaskDocument, dispatch),
        signTaskDocumentP7S: bindActionCreators(signTaskDocumentP7S, dispatch),
        rejectDocumentSigning: bindActionCreators(rejectDocumentSigning, dispatch),
        downloadDocumentAttach: bindActionCreators(downloadDocumentAttach, dispatch),
        getTaskDocumentSignData: bindActionCreators(getTaskDocumentSignData, dispatch),
        getTaskDocumentP7SSignData: bindActionCreators(getTaskDocumentP7SSignData, dispatch),
        generatePDFDocument: bindActionCreators(generatePDFDocument, dispatch),
    }
});

const translated = translate('TaskPage')(SigningAction);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
