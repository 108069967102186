import * as api from 'services/api';
import store from 'store';
import config from 'config/index';
import globalConf from "../../config.json";
import { set, get } from "lodash";

import * as Sentry from '@sentry/browser';
import storage from 'helpers/storage';
import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import { AuthData } from "@eis/types";
import { moduleHooks } from "@core/initStorage";

export const REQUEST_UNITS = 'REQUEST_UNITS';
export const REQUEST_UNIT_INFO = 'REQUEST_UNIT_INFO';
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const REQUEST_AUTH_CRM = 'REQUEST_AUTH_CRM';
export const TOKEN_ERROR = 'TOKEN_ERROR';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SEARCH_USER = 'SEARCH_USER';

const REQUEST_USER_SUGGESTIONS = 'REQUEST_USER_SUGGESTIONS';
const REQUEST_AUTH_MODE = 'REQUEST_AUTH_MODE';

const SEND_SMS_CODE = 'SEND_SMS_CODE';
const CHECK_PHONE_EXISTS = 'CHECK_PHONE_EXISTS';
const VERIFY_SMS_CODE = 'VERIFY_SMS_CODE';
const SEND_EMAIL_CODE = 'SEND_EMAIL_CODE';
const CHECK_EMAIL = 'CHECK_EMAIL';
const VERIFY_EMAIL_CODE = 'VERIFY_EMAIL_CODE';

const TOGGLE_DEBUG_MODE = 'TOGGLE_DEBUG_MODE';

const AUTH_URL = 'auth';
const USERS_URL = 'users';

const { backendUrl } = config;
const LOGOUT_LINK = backendUrl + '/redirect/logout';

export const logout = (redirect = false, force = false) => {
    storage.removeItem('token');
    storage.removeItem('crmToken');
    storage.removeItem('debug-user-id');
    storage.removeItem('code');

    if (!force) {
        storage.setItem('backUrl', window.location.pathname + (window.location.search || ''));
    }

    if (redirect) {
        window.location.href = LOGOUT_LINK;
        return { type: 'LOGOUT_DEEP' };
    }

    return { type: 'LOGOUT' };
};

export const isLoggedInCompletely = () => {
    const { auth } = store.getState() || {};
    return !!storage.getItem('token') && auth && auth.token && auth.info;
};

export const getToken = () => storage.getItem('token');

/**
 * Return access_token from JWT
 */
export const getAccessToken = (): string => {
    const { authTokens }: any = jwtDecode(getToken());
    // console.log(authTokens);
    return authTokens.accessToken;
} 

export const requestUserInfo = () => (dispatch: Dispatch) => api
    .get(`${AUTH_URL}/me`, REQUEST_USER_INFO, dispatch, {}, {
        postRequestHook: async (data: any) => {
            Object.keys(globalConf.localStorageVars).forEach(key => {
                localStorage.hasOwnProperty(key) && set(data, key, localStorage.getItem(key));
            });

            return data;
        }
    })
    .catch<AuthData>((error) => {
        Sentry.captureException(error);
        return error;
    })
    .then<AuthData>(auth => {
        moduleHooks.run('auth', auth);

        Sentry.configureScope((scope) => {
            scope.setUser(auth);
            scope.setExtra('userCert', get(auth, 'services.eds.data.pem'));
        });
        return auth;
    })

export const requestUnits = () => (dispatch: Dispatch) => api
    .get('units', REQUEST_UNITS, dispatch)
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const requestAllUnits = () => (dispatch: Dispatch) => api
    .get('units/all', REQUEST_UNITS, dispatch)
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const requestTestCode = () => () => {
    const { testAuth }: any = config;
    const { body, url }: any = testAuth;
    const headers = new Headers(testAuth.headers);

    return fetch(url, { method: 'POST', mode: 'cors', headers, body: JSON.stringify(body) })
        .then(async (response) => {
            const result = await response.json();
            const { data: { code } } = result;
            return code;
        });
};

export const loginByCode = (code: string) => (dispatch: Dispatch) => api
    .post(`${AUTH_URL}/login`, { code }, REQUEST_AUTH, dispatch)
    .catch((error) => {
        Sentry.captureException(error);
        return error;
    });

export const requestAuth = (code: string) => async (dispatch: Dispatch) => {
    const existedToken = storage.getItem('token');

    if (code && (code !== 'undefined')) {
        storage.removeItem('debug-user-id');
        const loginResult = await loginByCode(code)(dispatch);

        if (loginResult instanceof Error || !loginResult || !loginResult.token) {
            storage.removeItem('token');
            storage.removeItem('crmToken');
            dispatch({ type: TOKEN_ERROR, payload: true });
            return loginResult;
        }

        const { token } = loginResult;
        storage.setItem('token', token);

        dispatch({ type: AUTH_SET_TOKEN, payload: token });
        return requestUserInfo()(dispatch);
    }

    if (existedToken) {
        return requestUserInfo()(dispatch);
    }

    return new Error('401 unauthorized');
};

export const updateUserInfo = (userInfo: any) => (dispatch: Dispatch) => {
    return api.put(USERS_URL, userInfo, UPDATE_USER_INFO, dispatch);
};

export const isLoggedIn = () => !!storage.getItem('token');

export const isRole = (check: any) => {
    const { auth: { info } } = store.getState() || {};
    if (!info) {
        return false;
    }
    const { courtIdUserScopes } = info;
    return courtIdUserScopes.includes(check);
};

export const getUserSuggestions = (searchString: string) => (dispatch: Dispatch) => {
    return api.post(`${USERS_URL}/search`, { searchString }, REQUEST_USER_SUGGESTIONS, dispatch).then(result => result.users);
};

export const requestAuthMode = () => (dispatch: Dispatch) => {
    return api.get(`${USERS_URL}/two_factor_auth`, REQUEST_AUTH_MODE, dispatch);
};

export const checkPhoneExists = (phone: string) => (dispatch: Dispatch) => api
    .get(`${USERS_URL}/phone/already_used?phone=${phone}`, CHECK_PHONE_EXISTS, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailSendingSMS')));
        Sentry.captureException(error);
        return error;
    });

export const setAuthMode = (mode: any) => (dispatch: Dispatch) => {
    return api.post(`${USERS_URL}/two_factor_auth`, mode, REQUEST_AUTH_MODE, dispatch);
};

export const sendSMSCode = (phone: string) => (dispatch: Dispatch) => api
    .post(`${USERS_URL}/phone/send_sms_for_phone_verification`, { phone }, SEND_SMS_CODE, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailSendingSMS')));
        Sentry.captureException(error);
        return error;
    });

export const verifySMSCode = (phone: string, code: string) => (dispatch: Dispatch) => api
    .post(`${USERS_URL}/phone/verify`, { phone, code }, VERIFY_SMS_CODE, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailVerifyingSMS')));
        Sentry.captureException(error);
        return error;
    });

export const sendEmailCode = (email: string) => (dispatch: Dispatch) => api
    .put(`${USERS_URL}/email/change`, { email }, SEND_EMAIL_CODE, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailSendingEmail')));
        Sentry.captureException(error);
        return error;
    });

export const checkEmail = (email: string) => (dispatch: Dispatch) => api
    .post(`${USERS_URL}/email/check`, { email }, CHECK_EMAIL, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailSendingEmail')));
        Sentry.captureException(error);
        return error;
    });

export const verifyEmailCode = (email: string, code: string) => (dispatch: Dispatch) => api
    .post(`${USERS_URL}/email/confirm`, { email, code }, VERIFY_EMAIL_CODE, dispatch)
    .catch((error) => {
        // dispatch(addError(new Error('FailVerifyingEmail')));
        Sentry.captureException(error);
        return error;
    });

export const searchUser = (data: any) => (dispatch: Dispatch) => {
    return api.post(`${USERS_URL}/search`, data, SEARCH_USER, dispatch);
};

export const toggleDebugMode = () => ({
    type: TOGGLE_DEBUG_MODE
});
