import React from 'react';
import { translate } from 'react-translate';

import _ from 'lodash/fp';

import DataTable from 'components/DataTable';
// import EmptyPage from 'components/EmptyPage';

import dataTableConnect from 'services/dataTable/connectWithOwnProps';
import dataTableAdapter from 'services/dataTable/adapter';

import dataTableSettings from '../variables/dataTableSettings';

const TaskTable = (props) => {
    const {
        t,
        data,
        // count,
        // filters,
        actions: { load },
        handleItemClick,
        CustomToolbar
    } = props;

    // if (count === 0 && !filters.filtered && !filters.name) {
    //     return (
    //         <EmptyPage
    //             title={t('EmptyListTitle')}
    //             description={t('EmptyListDescription')}
    //             id="task-empty-page"
    //         />
    //     );
    // }

    const hightlightIds = (data || []).filter(({ meta: { isRead }, finished }) => !isRead && !finished).map(({ id }) => id);
    const settings = dataTableSettings({ t, actions: { load } });

    return (
        <DataTable
            onRowClick={handleItemClick}
            hightlight={hightlightIds}
            CustomToolbar={CustomToolbar}
            dense={true}
            {..._.merge(settings, dataTableAdapter(props, settings))}
        />
    );
};

const translated = translate('TaskListPage')(TaskTable);
export default dataTableConnect(translated);
