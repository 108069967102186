const GET_ALL_SUBJECTS_SUCCESS = 'GET_ALL_SUBJECTS_SUCCESS';
const GET_ALL_SERVICES_SUCCESS = 'GET_ALL_SERVICES_SUCCESS';
const GET_MY_SERVICES_SUCCESS = 'GET_MY_SERVICES_SUCCESS';

export interface IConnectionStore {
    actual: Record<string, any>,
    origin: Record<string, any>
} 

const initialState: IConnectionStore = {
    actual: {},
    origin: {}
};

export default (state = initialState, action: {type: string, payload: any}): IConnectionStore => {
    switch (action.type) {
        case GET_ALL_SUBJECTS_SUCCESS:
        case GET_ALL_SERVICES_SUCCESS:
        case GET_MY_SERVICES_SUCCESS: {
            const { id } = action.payload;

            return {
                ...state,
                actual: {
                    ...state.actual,
                    [id]: action.payload
                },
                origin: {
                    ...state.origin,
                    [id]: JSON.parse(JSON.stringify(action.payload))
                }
            };
        }
        default:
            return state;
    }
};
