import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-translate';
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    PropTypes,
    Button,
    createStyles,
    makeStyles
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/CheckRounded';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';
import DoneAllIcon from '@material-ui/icons/DoneAllRounded';
import HelpIcon from '@material-ui/icons/HelpOutlineRounded';
import SmileOkIcon from '@material-ui/icons/SentimentSatisfiedAltRounded';
import SmileBadIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNextRounded';

import evalate from '@core/helpers/evalate';


const useStyles = makeStyles(theme => createStyles({
    success: {
        fontSize: 82,
        color: theme.palette.success.main
    },
    error: {
        fontSize: 82,
        color: theme.palette.error.main
    },
    warning: {
        fontSize: 82,
        color: theme.palette.warning.main
    },
    title: {
        textAlign: 'center'
    },
    button: {
        textDecoration: 'none'
    },
    actionButton: {
        marginRight: 10
    }
}));

export const FinalScreenIcons: Record<string, any> = {
    check: CheckIcon,
    error: ErrorIcon,
    warning: WarningIcon,
    doneAll: DoneAllIcon,
    help: HelpIcon,
    smileOk: SmileOkIcon,
    smileBad: SmileBadIcon,
    info: InfoIcon
}

export interface FinalScreenActionsProps {
    title: string; 
    link: string; 
    variant: "text" | "outlined" | "contained" | undefined;
    color: PropTypes.Color;
}

export interface FinalScreenProps {
    icon?: string;
    color?: 'success' | 'error' | 'warning',
    title?: string;
    subtitle?: string;
    actions?: Array<FinalScreenActionsProps>;
}

export interface SuccessMessageLayoutProps {
    finalScreen: string | Partial<FinalScreenProps>;
    nextTasks: Array<{id: string}>;
    rootPath: string;
    rootDocument: any;
}

const useFinalScreenProps = (finalScreen: string | FinalScreenProps, t: (v: string) => string, rootDocument: any): FinalScreenProps => { 
    let result: FinalScreenProps = {};
    try {
        result = typeof finalScreen === 'string'
            ? evalate(finalScreen, rootDocument) as FinalScreenProps
            : finalScreen;  
    } catch (err) {
    }

    result = {
        color: 'success',
        title: t('SuccessCommitMessageHeader'),
        subtitle: t('SuccessCommitMessageText'),
        icon: 'check',
        ...result
    }
    return useState<FinalScreenProps>(result)[0];
}

const SuccessMessageLayout: React.FC<SuccessMessageLayoutProps> = props => {
    const classes = useStyles();
    const t = useTranslate('TaskPage');
    const { rootPath, finalScreen, nextTasks, rootDocument } = props;

    const fs = useFinalScreenProps(finalScreen, t, rootDocument);

    const Icon = FinalScreenIcons[fs.icon!] || FinalScreenIcons.check;

    return (
        <Fragment>
            <DialogTitle className={classes.title}><Icon className={classes[fs.color!]} /></DialogTitle>
            <DialogTitle className={classes.title}>{fs.title}</DialogTitle>
            <DialogContent className={classes.title}>
                <DialogContentText>
                    {fs.subtitle}
                </DialogContentText>
            </DialogContent>
            {nextTasks && Array.isArray(nextTasks) && nextTasks.slice(0, 1).map((nextTask, key) => (
                <DialogContent className={classes.title} key={key}>
                    <Link to={`${rootPath}/${nextTask.id}`} className={classes.button}>
                        <Button variant="contained" color="primary" size="large">
                            {t('GoToNextTask')}
                            <NavigateNextIcon />
                        </Button>
                    </Link>
                </DialogContent>
            ))}
            {fs.actions && Array.isArray(fs.actions) && (
                <DialogContent className={classes.title}>
                    {fs.actions.map(({ title, link, variant, color }, key) => (
                        <a key={key} href={link} className={classes.button}>
                            <Button variant={variant} color={color} size="large" className={classes.actionButton}>
                                {title}
                            </Button>
                        </a>
                    ))}
                </DialogContent>
            ) }
        </Fragment>
    )
}

export default SuccessMessageLayout;
