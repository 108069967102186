import React from 'react';
import { translate } from 'react-translate';

import { Tooltip, Checkbox } from '@material-ui/core';

import { IFilterHandlerBaseProps } from '../types';

const getSelection = (rowsSelected: Array<any>, selectableData: Array<ISelectableDataItem>) => {
  if (rowsSelected.length) return [];

  return selectableData
    .filter((item: ISelectableDataItem) => !item.entryTaskFinishedAt)
    .map((item: ISelectableDataItem) => item.id);
};

interface ISelectableDataItem {
  id: string;
  entryTaskFinishedAt: string;
}

interface ISelectAllButtonProps extends IFilterHandlerBaseProps {
  rowsSelected: Array<any>;
  selectableData: Array<ISelectableDataItem>;
  onRowsSelect: any;
  t: (text: string) => string;
}

const defaultProps: Partial<ISelectAllButtonProps> = {
  rowsSelected: [],
  selectableData: []
}

const SelectAllButton: React.FC<ISelectAllButtonProps> = ({
  t,
  rowsSelected,
  selectableData,
  onRowsSelect
}) => (
  <Tooltip title={t('Select')}>
    <Checkbox
      id="select-checkbox"
      color="primary"
      indeterminate={rowsSelected.length > 0 && rowsSelected.length < selectableData.length}
      checked={(rowsSelected.length === selectableData.length) && !!selectableData.length}
      onChange={() => onRowsSelect && onRowsSelect(getSelection(rowsSelected, selectableData))}
    />
  </Tooltip>
);

SelectAllButton.defaultProps = defaultProps;

export default translate('DataTable')(SelectAllButton);
