import React from 'react';
import { useTranslate } from 'react-translate';
import ErrorScreen from 'components/ErrorScreen';
import { useAppHeader } from '@eis/components';


const PageNotFound: React.FC = () => {
    const title = useTranslate('App')('PageNotFound');
    useAppHeader({ title })
    return (
        <ErrorScreen error={new Error(title)} />
    );
}

export default PageNotFound;