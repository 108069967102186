import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-translate';

import Mime from '../../../../Mime';
import humanFileSize from '../../../../../helpers/humanFileSize';



const useStyles = makeStyles(theme => createStyles({
    limits: {
        ...theme.typography.body2,
        // display: 'flex',
        justifyContent: 'center',
        fontStyle: 'italic'
    },
    limitItem: {
        display: 'inline-block',
        marginLeft: theme.spacing()
    }
}));

interface LimitsProps {
    accept?: string,
    maxSize?: number;
    maxItems?: number;
}

const Limits: React.FC<LimitsProps> = props => {
    const { accept, maxSize, maxItems } = props;
    const t = useTranslate('Elements');
    const classes = useStyles();

    const limits = [];

    if (maxItems) {
        limits.push(t('UploadMaxCount', { count: maxItems }));
    }

    if (maxSize) {
        limits.push(t('MaxFileSizeLimit', { size: humanFileSize(maxSize, true) }));
    }

    if (accept) {
        limits.push(t('FileTypeLimit', {
            types: (
                <Mime>{accept}</Mime>
            )
        }));
    }

    return (
        <Box className={classes.limits}>
            {
                limits.map((limit, index) => (
                    <Box key={index} className={classes.limitItem}>
                        {limit}
                    </Box>
                ))
            }
        </Box>
    )
};


export default Limits;
