import { makeStyles, Theme, createStyles } from '@material-ui/core';

const SPACE_FOR_BUTTON = 115;

export const useStyles = makeStyles((theme: Theme) => createStyles({
    rowWithNumber: {
        '&::before': {
            counterIncrement: 'row',
            content: 'counter(row)'
        }
    },
    firstCell: {
        paddingLeft: `${theme.spacing()}px !important;`
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center'
    },
    tabTitle: {
        marginLeft: theme.spacing(1)
    },
    accordionHeader: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginRight: SPACE_FOR_BUTTON,
        padding: theme.spacing(1, 2),
        height: 64,
        '& .MuiAccordionSummary-content': {
            position: 'relative',
            alignItems: 'center'
        },
        '& .MuiAccordionSummary-expandIcon': {
            padding: 0,
            marginRight: theme.spacing(1)
        }
    },
    accordionInfo: {
        flexDirection: 'column'
    },
    accordionButton: {
        position: 'absolute',
        right: -SPACE_FOR_BUTTON
    },
    table: {
        margin: theme.spacing(2.5, 0)
    },
    tableHeader: {
        fontWeight: 600,
        marginBottom: theme.spacing(1)
    }
}));