import React from 'react';
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid';
import { Table as TableMUI, TableHeaderRow as TableHeaderRowMUI } from '@devexpress/dx-react-grid-material-ui';
import { useStyles } from '../styles';

/** 
 * Wrapper cell component for customize style 
 */
export const Cell: React.FC<Table.DataCellProps> = props => {
    const { column } = props;
    const classes = useStyles();

    const className = (column.name === 'num') ? classes.firstCell : undefined;
    return <TableMUI.Cell {...props} className={className} />;
};

/** 
 * Wrapper header cell for customize style
 */
export const HeaderCell: React.FC<TableHeaderRow.CellProps> = props => {
    const { column } = props;
    const classes = useStyles();

    const className = (column.name === 'num') ? classes.firstCell : undefined;
    return <TableHeaderRowMUI.Cell {...props} className={className} />
} 