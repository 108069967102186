import React from 'react';
import {
    Card,
    CardHeader,
    CardContent, Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { useStyles } from './styles';
import { IMetricBlock } from './types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface MetricsBlockProps {
    block: IMetricBlock;
    data: Record<string, number | string>;
}

export const MetricsBlock: React.FC<MetricsBlockProps> = props => {
    const { block, data } = props;
    const classes = useStyles();

    const asList = () => {
        return block.metrics.map((metric, idx) => (
            <Box key={idx} className={classes.rowMetric}>
                <span className={classes.metricName}>
                    {metric.title}
                    {metric.hint && <Tooltip title={metric.hint} arrow>
                        <HelpOutlineIcon className={classes.hint} color="primary" />
                    </Tooltip>}
                </span>
                <span className={classes.metricValue}>{data[metric.id] || '-'}</span>
            </Box>
        ));
    };

    const asTable = () => {
        return (
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell> </TableCell>
                        {block.metrics[0].col!.map(col => <TableCell key={col.id} align="center">{col.title}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {block.metrics.map((metric, idx) => (
                        <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                                {metric.title}
                            </TableCell>
                            {metric.col!.map(col => <TableCell key={col.id} align="center">{data[col.id] || '-'}</TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    return (
        <Card variant="outlined">
            <CardHeader
                title={block.title} />
            <CardContent>
                {Boolean(block.metrics[0].col) && asTable()}
                {!Boolean(block.metrics[0].col) && asList()}
            </CardContent>
        </Card>
    );
};
