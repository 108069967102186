import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
    Switch,
    FormControlLabel,
    makeStyles,
    createStyles
} from '@material-ui/core';

import ElementContainer from '../components/ElementContainer';
import { BaseElementProps } from './BaseElementProps';
import ChangeEvent from '../ChangeEvent';


const useStyles = makeStyles(theme => createStyles({
    onText: {
        paddingLeft: 10
    },
    offText: {
        marginLeft: 0
    },
    columns3: {
        display: 'inline-block',
        margin: 0,
        marginBottom: theme.spacing(),
        width: '30%',
        '& > label > label': {
            paddingLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
}));


export interface ToggleProps extends BaseElementProps {
    defaultValue?: boolean
    value?: boolean;
    onText: string;
    offText: string;
    columns?: number;
    onChange: (value: boolean | ChangeEvent) => void;
}

const defaultProps: Partial<ToggleProps> = {
    defaultValue: false
}

export const Toggle: FC<ToggleProps> = (props) => {
    const { defaultValue, value, onText, offText, path, hidden, onChange,
        disabled, readOnly, columns, sample, description, required, error, noMargin } = { ...defaultProps, ...props };

    const [editValue, setValue] = useState<boolean>(Boolean(value === undefined ? defaultValue : value));
    const classes = useStyles();

    useEffect(() => {
        onChange && onChange(editValue);
        // eslint-disable-next-line
    }, []);

    if (hidden) return null;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked !== editValue && onChange) {
            setValue(checked);
            onChange(checked);
        }
    }

    
    return (
        <ElementContainer
            sample={sample}
            description={description}
            required={required}
            error={error}
            noMargin={noMargin}
            className={
                classNames(
                    {
                        [classes.columns3]: columns && columns === 3
                    }
                )
            }
        >
            <FormControlLabel
                disabled={disabled || readOnly}
                className={classes.offText}
                control={(
                    <FormControlLabel
                        className={classes.onText}
                        control={(
                            <Switch
                                id={path.join('-')}
                                checked={editValue}
                                onChange={handleChange}
                                color="primary"
                            />
                        )}
                        label={onText}
                    />
                )}
                label={offText}
                labelPlacement="start"
            />
        </ElementContainer>
    )
}
