/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles } from '@material-ui/core';
import Ajv from 'ajv';


const useStyles = makeStyles(theme => createStyles({
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e8eaf6'
        }
    },
    error: {
        backgroundColor: 'rgba(255, 0, 0, 0.15)'
    }
}))

interface TableRowProps {
    row: any;
    tableRow: any
    children: any
    handleClick: (row: any) => void;
    validator?: Ajv.ValidateFunction
}

/**
 * Table row component with hover select and click handler 
 */
export const TableRow: React.FC<TableRowProps> = props => {
    const { handleClick, validator, ...restProps } = props;
    const [ errorSchema, setErrorSchema ] = useState<boolean>(false);

    const { row } = restProps; 
    const classes = useStyles();

    useEffect(() => {
        if (row !== undefined && validator !== undefined) {
            const valid: boolean = !!validator(row.data);
            setErrorSchema(!valid);
        }
    }, [validator, row]);

    return (
        <Table.Row
            className={`${classes.row} ${errorSchema ? classes.error : '' }`}
            {...restProps}
            onClick={() => handleClick(row)}
        />
    );
}
