import capitalizeFirstLetter from "@core/helpers/capitalizeFirstLetter";
import capitalizeLetters from "@core/helpers/capitalizeLetters";
import { StringElementProps } from "./StringElementProps";

/**
 * Выполняет все заданные модификации строки
 **/ 
export const modification = (value: string, props: StringElementProps): string => {
    if (!value) return '';
    const { changeCase, replaceLatinAnalogs, cutTags } = props;

    let result = value;
    if (changeCase) result = changeCaseFunctions(result, changeCase);
    if (replaceLatinAnalogs) result = replaceLatinCharactersAnalog(result);
    if (cutTags) result = removeHtml(result);

    return result;
};

export const changeCaseFunctions = (value: string, method: string) => {
    switch (method) {
        case 'toLowerCase':
            return value.toLocaleLowerCase();
        case 'toUpperCase':
            return value.toLocaleUpperCase();
        case 'toCapitalize':
            return capitalizeFirstLetter(value);
        case 'capitalizeLetters':
            return capitalizeLetters(value);
        default:
            return value;
    }
};

export const replaceLatinCharactersAnalog = (value: string) => {
    const isLatin = /[a-zA-Z]/.test(value);

    if (!isLatin) return value;

    const upperLat = 'OIYTEKHAMBCX';
    const lowerLat = 'oiyekacx';
    const upperCir = 'ОІУТЕКНАМВСХ';
    const lowerCir = 'оіуекасх';

    let str = (' ' + value).slice(1);

    (upperLat.split('') || []).forEach((char, index) => {
        const regexp = new RegExp(char, 'g');
        str = str.replace(regexp, upperCir[index]);
    });

    (lowerLat.split('') || []).forEach((char, index) => {
        const regexp = new RegExp(char, 'g');
        str = str.replace(regexp, lowerCir[index]);
    });

    return str;
};

export const removeHtml = (value: string) => {
    const hasTags = /<\/?[^>]+>/.test(value);
    if (hasTags) return value.replace(/<\/?[^>]+>/gi, '');
    return value;
};