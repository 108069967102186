/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import objectPath from 'object-path';
import diff from 'deep-diff';

import ElementContainer from 'components/JsonSchema/components/ElementContainer';

import Select from 'components/Select';
import waiter from 'helpers/waitForAction';
import queueFactory from 'helpers/queueFactory';
import processList from 'services/processList';

import { loadTask } from '@cab/actions/task';

import {
    requestRegisterKeyRecords,
    requestRegisterKeyRecordsFilter
} from 'actions/registry';

import { ChangeEvent, FieldLabel } from 'components/JsonSchema';

import defaultProps from 'components/JsonSchema/elements/Register/defaultProps';

class SingleKeyRegister extends React.Component {
    state = { options: null, filterData: {}, search: '', page: 0, loading: false, height: undefined };

    constructor(props) {
        super(props);

        const { taskId, actions, registerActions, setDefined, readOnly } = props;
        this.queue = queueFactory.get(taskId);

        this.queue.removeAllListeners('end');
        this.queue.on('end', async () => {
            if (setDefined) {
                await actions.handleStore();
                await registerActions.loadTask(taskId);
            }
            actions && actions.setBusy && actions.setBusy(false);
        });

        if (!readOnly) {
            this.queue.push(this.init);
        }
    }

    componentDidUpdate() {
        const { actions, filters, rootDocument, originDocument, onChange, multiple, usedInTable } = this.props;
        const { filterData, options } = this.state;

        if (!filters) {
            return;
        }

        if (originDocument.isFinal || usedInTable) {
            return;
        }

        const unsavedFilterData = this.getFilters(rootDocument);
        const savedFilterData = this.getFilters(originDocument);

        if (options && diff(savedFilterData, unsavedFilterData)) {
            this.setState({ options: null });
            actions && actions.setBusy && actions.setBusy(true);
            onChange(new ChangeEvent(multiple ? [] : null, true));
            return;
        }

        if (diff(filterData, savedFilterData)) {
            this.setState({ options: null, filterData: savedFilterData });
            this.queue.push(this.init);
        }
    }

    componentWillUnmount = () => this.queue.removeAllListeners('end');

    init = async () => {
        const {
            actions,
            registerActions,
            additionalFilter,
            setDefined,
            sortBy,
            keyId,
            value,
            onChange,
            markWhenEmpty,
            originDocument,
            usedInTable
        } = this.props;

        const filterData = this.getFilters(originDocument);
        this.setState({ loading: true });
        !usedInTable && actions && actions.setBusy && actions.setBusy(true);

        await actions.handleStore();

        const requestRecordsFuncName = !originDocument.isFinal && (filterData || additionalFilter || sortBy) ? 'requestRegisterKeyRecordsFilter' : 'requestRegisterKeyRecords';
        const options = await processList.hasOrSet(requestRecordsFuncName, registerActions[requestRecordsFuncName], keyId, this.getRequestOptions(this.props, this.state));

        if (options instanceof Error) {
            this.setState({ loading: false });
            !usedInTable && actions && actions.setBusy && actions.setBusy(false);
            return;
        }

        if (setDefined) {
            await actions.handleStore();
        }

        this.setState({ options, filterData, loading: false });

        if (markWhenEmpty) {
            if (!options.length) {
                onChange(new ChangeEvent(false, true));
            } else if (value === false) {
                onChange(new ChangeEvent(null, true));
            }
        }

        !usedInTable && actions && actions.setBusy && actions.setBusy(false);
    }

    getRequestOptions = (props, state) => {
        const {
            stepName,
            path,
            autocomplete,
            originDocument: { id: documentId }
        } = props;

        const { search, page } = state;

        const control = [].concat('documents', documentId, stepName, path).join('.');
        const requestOptions = { strict: true, limit: autocomplete ? 10 : 1000, control };

        if (autocomplete && search && search.length >= 3) {
            requestOptions.search = search;
        }

        if (autocomplete && page) {
            requestOptions.offset = page * 10;
        }

        return requestOptions;
    };

    getFilters = (documentData) => {
        const { filters } = this.props;

        if (!filters) {
            return null;
        }

        return filters.map(({ value }) => objectPath.get(documentData, value));
    };

    handleChange = async (value) => {
        const { registerActions, onChange, additionalFilter, originDocument, taskId } = this.props;
        const filterData = this.getFilters(originDocument);
        onChange && await onChange(new ChangeEvent(value, true));

        if (filterData || additionalFilter) {
            registerActions.loadTask(taskId);
        }
    };

    handleChangePage = (e, newPage) => {
        const { page } = this.state;
        if (page !== newPage) {
            this.setState({ page: newPage });
            this.queue.push(this.init);
        }
    }

    handleSearch = (value) => {
        const { search } = this.state;

        if (search === value) {
            return;
        }

        this.setState({ search: value }, () => {
            const { autocomplete } = this.props;
            if (autocomplete) {
                waiter.addAction('singleKeySearch', () => {
                    this.queue.push(this.init);
                }, 500);
            }
        });
    };

    handleMenuOpen = () => {
        // console.log('OPEN');
        if (this.props.useOwnContainer) {
            this.setState({ height: 350 });
        }
    }

    handleMenuClose = () => {
        if (this.props.useOwnContainer) {
            this.setState({ height: undefined });
        }
        // console.log('CLOSE');
    }

    render() {
        const { options, search, loading } = this.state;
        const { t, description, sample, required, keyId, error, outlined, path, noMargin, width, maxWidth, hidden, autocomplete, readOnly, useOwnContainer, ...props } = this.props;

        if (hidden) return null;

        return (
            <ElementContainer
                sample={sample}
                required={required}
                error={error}
                bottomSample={true}
                width={width}
                maxWidth={maxWidth}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                noMargin={noMargin}
                height={this.state.height}
            >
                <Select
                    {...props}
                    error={error}
                    readOnly={readOnly}
                    id={path.join('-')}
                    inputValue={search}
                    isLoading={loading}
                    description={<FieldLabel description={description} required={required} />}
                    loadingMessage={() => t('Loading')}
                    onChange={this.handleChange}
                    onChangePage={this.handleChangePage}
                    onInputChange={this.handleSearch}
                    onMenuOpen={this.handleMenuOpen}
                    onMenuClose={this.handleMenuClose}
                    usePagination={autocomplete}
                    pagination={options && options.meta}
                    useOwnContainer={useOwnContainer}
                    options={options && options.map(option => ({ ...option, value: option.id, label: option.stringified }))}
                />
            </ElementContainer>
        );
    }
}

SingleKeyRegister.propTypes = {
    actions: PropTypes.object.isRequired,
    properties: PropTypes.object,
    description: PropTypes.string,
    sample: PropTypes.string,
    outlined: PropTypes.bool,
    value: PropTypes.object,
    error: PropTypes.object,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    keyId: PropTypes.number,
    path: PropTypes.array,
    originDocument: PropTypes.object,
    useOwnContainer: PropTypes.bool,
    readOnly: PropTypes.bool
};

SingleKeyRegister.defaultProps = defaultProps;


const mapDispatchToProps = dispatch => ({
    registerActions: {
        loadTask: bindActionCreators(loadTask, dispatch),
        requestRegisterKeyRecords: bindActionCreators(requestRegisterKeyRecords, dispatch),
        requestRegisterKeyRecordsFilter: bindActionCreators(requestRegisterKeyRecordsFilter, dispatch)
    }
});

const translated = translate('Elements')(SingleKeyRegister);
export default connect(null, mapDispatchToProps)(translated);
