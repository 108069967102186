import React, { FC, Fragment } from 'react';
import { Registry } from '@eis/registry';
import { CardRequest } from './CardRequest';

export interface CardsProps {
    processes: Array<Registry.dc_process>,
    classes: Record<string, string>,
    handleClickRequest: (id: number, idx: number) => void
}

export const Cards: FC<CardsProps> = props => {
    const { processes, classes, handleClickRequest, children } = props;
    return <Fragment>
        { processes.map(p => {
            return <Fragment>
                <CardRequest {...{
                    id: p.data.process,
                    idx: p.data.menuOrder,
                    active: p.data.enabled,
                    key: p.data.process,
                    classes,
                    title: p.data.name,
                    description: p.data.description,
                    handleClick: handleClickRequest
                }} />
                {(p.data.menuOrder === 1) && children }
            </Fragment>
        }) }
    </Fragment>

}
