/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslate } from 'react-translate';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import {
    Badge,
    createStyles,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core';
import { INavigatorItemInfo } from '@eis/types';


const useStyles = makeStyles((theme: Theme) => createStyles({
    categoryWrapper: {
        display: 'block',
        marginLeft: -15,
        marginRight: -15,
        '&.active': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            '& + a': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&.active': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                },
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: '#fff'
                },
                '& ~ a': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    '&.active': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        color: '#fff'
                    }
                }
            }
        }
    },
    categoryHeader: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 0,
        '& svg': {
            fill: '#eee'
        }
    },
    itemActionable: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .1)'
        },
        '& svg': {
            // fill: '#eee',
            backgroundColor: 'rgba(255, 255, 255, .1)'
        }
    },
    icon: {
        position: 'relative',
        minWidth: 40,
        top: '-1px',
        '& svg': {
            fontSize: 23,
            backgroundColor: 'transparent'
        }
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white
    },
    navLink: {
        color: '#fff',
        textDecoration: 'none',
        '&.active > div': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: '#fff'
        }
    },
    badge: {
        // left: -4,
        // height: 16,
        backgroundColor: theme.palette.error.main,
        // padding: 4,
        // minWidth: '25px'
        marginRight: theme.spacing()
    }
}));

const highlight = (pathName: string, id?: string) => id && (pathName.indexOf(id.toLowerCase()) !== -1) && 'active';

interface CategoryHeaderProps extends Partial<INavigatorItemInfo> {
    handleDrawerToggle?: any,
    pathname: any,
    badge?: number,
    oneChild?: boolean,
    children?: any | Array<any>
}

export const CategoryHeader: React.FC<CategoryHeaderProps> = props => {
    const {
        pathname,
        id,
        title,
        path,
        icon,
        badge,
        oneChild,
        handleDrawerToggle,
        children
    } = props;

    const t = useTranslate('Navigator');
    const classes = useStyles();

    const categoryHeader = (
        <div className={classNames(classes.categoryWrapper, highlight(pathname, id))}>
            {
                oneChild ? null : (
                    <ListItem
                        {...({ button: !!path } as any)} // TODO: ??? error
                        className={
                            classNames(
                                classes.categoryHeader,
                                path && classes.itemActionable
                            )
                        }
                    >
                        {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
                        <ListItemText
                            classes={{
                                primary: classes.categoryHeaderPrimary
                            }}
                        >
                            {t(title || id)}
                        </ListItemText>
                        {badge && Number.isInteger(badge) &&
                            <ListItemSecondaryAction>
                                <Badge
                                    badgeContent={badge}
                                    classes={{
                                        badge: classes.badge
                                    }}
                                />
                            </ListItemSecondaryAction>
                        }

                    </ListItem>
                )
            }
            {children}
        </div>
    );

    // CategoryHeader.propTypes = {
    //     classes: PropTypes.object.isRequired
    // };

    // const CategoryHeaderContainer = (props) => {
    // const { classes, path, handleDrawerToggle, id } = props;

    // const categoryHeader = <CategoryHeader {...props} />;

    return path ? (
        <NavLink
            exact={true}
            to={path || ''}
            onClick={handleDrawerToggle}
            activeClassName="active"
            className={classes.navLink}
            id={id}
        >
            {categoryHeader}
        </NavLink>
    ) : categoryHeader

};

// CategoryHeaderContainer.propTypes = {
//     classes: PropTypes.object.isRequired,
//     path: PropTypes.string.isRequired,
//     id: PropTypes.string.isRequired,
//     handleDrawerToggle: PropTypes.func.isRequired
// };

// const styled = withStyles(styles)(CategoryHeaderContainer);
// export default translate('Navigator')(styled);
