import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import ColumnsIcon from '@material-ui/icons/ViewColumn';

interface ToggleColumnChooserProps {
    // An event that initiates overlay showing or hiding.
    onToggle(): void;
    // Returns a specified localization message.
    getMessage: (messageKey: string) => string;
    // A function that accepts the button's root React element. */
    buttonRef: (ref: React.ReactInstance) => void;
    // Current state of open chooser 
    active?: boolean;
}

/**
 * Button for open column chooser.
 * Props compatible with ColumnChooser.ToggleButtonProps
 */
export const ToggleColumnChooser: React.FC<ToggleColumnChooserProps> = props => {
    const { onToggle, getMessage, buttonRef, active } = props;
    return (
        <Tooltip  title={getMessage('showColumnChooser')} >
            <IconButton 
                disabled={active}
                ref={ref => buttonRef(ref as any)}
                onClick={onToggle} >
                    <ColumnsIcon />
            </IconButton>
        </Tooltip>
    )
}