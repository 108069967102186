import React from 'react';

import TimeLabel from 'components/Label/Time';

const columns = t => [{
    id: 'titleMessage',
    name: t('MessageTitle'),
    align: 'left',
    sortable: false
}, {
    id: 'date',
    name: t('createdAt'),
    align: 'right',
    sortable: true,
    render: (value, { createdAt }) => <TimeLabel date={createdAt} />
}];

export default ({ t }) => ({
    controls: {
        pagination: false,
        toolbar: true,
        search: true,
        header: true,
        refresh: true,
        customizateColumns: true,
        bottomPagination: true,
        toggleWidth: true
    },
    checkable: false,
    columns: columns(t)
});
