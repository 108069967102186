import React from 'react';
import { translate } from 'react-translate';
import objectPath from 'object-path';
import { Box, Menu, MenuItem } from '@material-ui/core';
import {
    DataTypeProvider,
    CustomPaging,
    PagingState,
    PagingPanel,
    // SearchState,
    SortingState,
    IntegratedSorting,
    FilteringState,
    TableColumnWidthInfo,
    Sorting, Column, Filter, RowDetailState
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    Toolbar,
    TableHeaderRow,
    PagingPanel as PagingPanelMUI,
    ColumnChooser,
    DragDropProvider,
    TableColumnVisibility,
    TableColumnReordering,
    TableColumnResizing,
    TableFilterRow,
    TableFixedColumns,
    TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
// import { connectProps } from '@devexpress/dx-react-core';
import TimeLabel from 'components/Label/Time';
// import Preloader from 'components/Preloader';
import HighlightText from 'components/HighlightText';
import endPoint from '@cab/endPoints/registryRecord';
import dataTableConnect from 'services/dataTable/connect';
import evalate from 'helpers/evalate';
import storage from 'helpers/storage';
import processList from 'services/processList';
import ExportToExelButton from './ExportToExelButton';
import { CreateNewRecordButton } from './CreateNewRecordButton';
import RegistryModal from './RegistryModal';
// import SortLabel from './SortLabel';
// import FilterCell from './FilterCell';
import { TableRow } from './TableRow';
// import SearchInput from './SearchInput';
import { IRegistryKeyRecord, Registry } from '@eis/registry'
import EvaluateError from '@core/helpers/evalate/EvaluateError';
import { ToggleColumnChooser } from './ToggleColumnChooser';
import { ActionsColumn } from './ActionsColumn';
import { RowDetailRecord } from './RowDetailRecord';
import { DeleteRecord } from './DeleteRecord';
import { JSONEditor } from '@eis/components';
import Ajv from 'ajv';
import { EnableFilterButton } from './EnableFilter';


const ROOT_COLUMNS = ['id', 'createdBy', 'createdAt'];

type IRecord = IRegistryKeyRecord<Record<string, any>>
interface RecColumn extends Column {
    sortingEnabled?: boolean,
    hidden?: boolean,
    propertyName?: string
}

interface TableSettings {
    columnWidths?: Array<TableColumnWidthInfo>,
    columnOrder?: Array<string>,
    hiddenColumns?: Array<string>
}

interface IInitTableSettings extends TableSettings {
    newRecord?: Partial<IRecord>;
    selectedRecord?: Partial<IRecord>;

    columns: Array<RecColumn>,
    tableColumnExtensions: Array<Table.ColumnExtension>,

    customColumns: Array<string>,
    expandedRowIds: Array<string | number>,
}

const initialState = (t: (s: string) => string): IInitTableSettings => ({
    columns: [{
        name: 'id',
        title: 'ID',
    }, {
        name: 'data',
        title: t('Name'),
        sortingEnabled: true
    }, {
        name: 'createdBy',
        title: t('CreatedBy'),
        sortingEnabled: true
    }, {
        name: 'createdAt',
        title: t('CreatedAt'),
        sortingEnabled: true
    }, {
        name: '$actions',
        title: t('Actions'),
        sortingEnabled: false
    }
    ],
    tableColumnExtensions: [
        { columnName: 'createdBy', align: 'right' },
        { columnName: 'createdAt', align: 'right' }
    ],
    columnWidths: [
        { columnName: 'data', width: 640 },
        { columnName: 'id', width: 300 },
        { columnName: 'createdBy', width: 200 },
        { columnName: 'createdAt', width: 240 },
        { columnName: '$actions', width: 50 }
    ],
    columnOrder: [...ROOT_COLUMNS, 'data'],
    hiddenColumns: [...ROOT_COLUMNS, 'data'],
    customColumns: [],
    expandedRowIds: [],
});

interface RegistryKeyTableState extends IInitTableSettings {
    newRecord?: Partial<IRecord>;
    selectedRecord?: Partial<IRecord>;
    activeRecord?: Partial<IRecord>;
    initDelete?: boolean;
    editJSON?: boolean;

    anchorMenuActions?: any;
    validator?: Ajv.ValidateFunction;
    useSearch?: boolean;
    filterEnabled?: boolean;
}

interface RegistryKeyTableProps {
    filters: any,
    selectedKey: Registry.IRegistryKey,
    actions: any,
    t: (s: string) => string,
    data?: Array<any>,
    sort: any,
    rowsPerPage: number,
    page: number,
    count: number,
    classes: any,
    handleSave: (record: IRecord) => void;
}

class RegistryKeyTable extends React.Component<RegistryKeyTableProps, RegistryKeyTableState> {

    private lastFilters?: Array<Filter>;

    constructor(props: RegistryKeyTableProps) {
        super(props);
        this.state = this.propsToState(initialState(props.t), props);
        // this.searchInput = connectProps(SearchInput, () => {
        //     const { useSearch } = this.state;
        //     return { useSearch };
        // });
        // this.filterCell = connectProps(FilterCell, () => {
        //     const { useSearch } = this.state;
        //     return { useSearch };
        // });
    }



    hiddenColumnsNames = () => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return [];
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { hiddenColumns } = registryTableSettings[selectedKey.id] || {};

        return hiddenColumns || [...ROOT_COLUMNS, 'data'];
    };

    setTableSettings = (settings: Partial<TableSettings>) => {
        const { selectedKey } = this.props;

        if (!selectedKey) {
            return;
        }

        const registryTableSettings = JSON.parse(storage.getItem('registryTableSettings') || '{}');

        registryTableSettings[selectedKey.id] = {
            ...(registryTableSettings[selectedKey.id] || {}),
            ...settings
        };

        storage.setItem('registryTableSettings', JSON.stringify(registryTableSettings));
        this.setState(settings);
    };

    propsToState = (defaultProps: IInitTableSettings, { selectedKey }: RegistryKeyTableProps): RegistryKeyTableState => {
        if (!selectedKey || !selectedKey.schema) {
            return defaultProps;
        }

        let customColumns = [];
        if (typeof selectedKey.schema.toTable === 'object') {
            customColumns = Object.keys(selectedKey.schema.toTable);
        } else {
            customColumns = Object.keys(selectedKey.schema.properties || {})
                .filter(propertyName => !['object', 'array'].includes(selectedKey.schema.properties[propertyName].type));
        }

        const registryTableSettings: Record<string, {
            columnWidths?: Array<TableColumnWidthInfo>,
            columnOrder?: Array<string>
        }> = JSON.parse(storage.getItem('registryTableSettings') || '{}');
        const { columnWidths, columnOrder } = registryTableSettings[selectedKey.id] || {};

        return {
            columns: [
                ...defaultProps.columns,
                ...customColumns.map(propertyName => ({
                    name: ['data', propertyName].join('.'),
                    title: (selectedKey.schema.properties[propertyName] || {}).description || propertyName,
                    hidden: !!(selectedKey.schema.properties[propertyName] || {}).hidden,
                    sortingEnabled: !(selectedKey.schema.properties[propertyName] || {}).disableSort,
                    propertyName
                }))
            ],
            tableColumnExtensions: defaultProps.tableColumnExtensions,
            columnWidths: [
                ...defaultProps.columnWidths!,
                ...customColumns.map(propertyName => ({
                    columnName: ['data', propertyName].join('.'),
                    width: 240
                }))].map(item => {

                    if (!columnWidths) return item;
                    const col = columnWidths.find(fItem => fItem.columnName === item.columnName);
                    if (col) {
                        return col
                    } else {
                        return item
                    }

                }),

            columnOrder: columnOrder || [
                ...defaultProps.columnOrder!,
                ...(customColumns.map(propertyName => ['data', propertyName].join('.'))),
                '$actions'
            ],
            customColumns: customColumns.map(propertyName => ['data', propertyName].join('.')),
            useSearch: false,
            expandedRowIds: [],
        };
    };

    deleteHiddenColumns = (array: Array<RecColumn>) => array.filter(item => !item.hidden);

    setColumnOrder = (columnOrder: Array<string>) => this.setTableSettings({ columnOrder });

    onHiddenColumnNamesChange = (hiddenColumns: Array<string>) => this.setTableSettings({ hiddenColumns });

    setColumnWidths = (columnWidths: TableColumnWidthInfo[]) => this.setTableSettings({ columnWidths });

    handleStore = async (record: IRecord) => {
        const { actions } = this.props;
        this.setState({ selectedRecord: record });
        await actions.storeRecord(record.id, record);
        await actions.load();
    };

    handleStoreNewRecord = async (newRecord: IRecord) => {
        const { actions } = this.props;
        await actions.createRecord(newRecord);
        await actions.load();
        this.setState({ newRecord: undefined });
    };

    getSorting = () => {
        const { sort } = this.props;

        return Object.keys(sort).map(columnName => ({
            columnName,
            direction: sort[columnName]
        }));
    };

    /**
     * Sorting data (server side).
     * By first selected column only
     */
    setSorting = ([sorting]: Array<Sorting>) => {
        if (!sorting) return;
        const { actions } = this.props;
        actions.onColumnSortChange(sorting.columnName, sorting.direction, true, true);
    };

    setFilters = (filters: Array<Filter>) => {
        if (!filters) return;
        this.lastFilters = filters.map(f => ({...f, value: ''}));
        const { actions, filters: { name: search } } = this.props;
        actions.onSearchChange(search, true, filters);
    };

    setSearchVisible = () => {
        // const { useSearch, savedFirstElement } = this.state;
        // const { data, selectedKey } = this.props;

        // const { toSearchString } = selectedKey || {};
        // const [firstElement] = Array.isArray(data) ? data : [];
        // const useSearchUpdate = toSearchString && savedFirstElement && evalate(toSearchString, savedFirstElement) !== null;

        // const updateFirstElement = firstElement && (JSON.stringify(firstElement) !== JSON.stringify(savedFirstElement));

        // if (updateFirstElement) {
        //     this.setState({ savedFirstElement: firstElement });
        // }

        // if (useSearch !== useSearchUpdate) {
        //     this.setState({
        //         useSearch: useSearchUpdate
        //     });
        // }
    };

    pagingPanelMessages: PagingPanel.LocalizationMessages = {
        info: function ({ from, to, count }) {
            return `${from} - ${to} / ${count}`
        },
        rowsPerPage: this.props.t('RowsPerPage')
    };

    setFixedColumnsComponent = (props: TableFixedColumns.CellProps) => {
        return (
            <ActionsColumn
                {...props}
                hint={this.props.t('Actions')}
                handleClick={(el, row) => this.setState({
                    anchorMenuActions: el,
                    activeRecord: row
                })} />
        )
    }

    handleMenuDelete = () => {
        const { activeRecord } = this.state;
        if (activeRecord) {
            this.setState({
                initDelete: true,
                anchorMenuActions: undefined
            });
        }
    }

    handleMenuView = () => {
        const { activeRecord } = this.state;
        if (activeRecord) {
            this.setState({
                selectedRecord: activeRecord,
                activeRecord: undefined,
                anchorMenuActions: undefined
            });
        }
    }

    handleMenuEditJson = () => {
        this.setState({
            editJSON: true,
            anchorMenuActions: undefined
        });
    }

    renderMenu = () => {
        const { anchorMenuActions } = this.state;
        const { t, selectedKey } = this.props;

        const pos = !!anchorMenuActions
            ? anchorMenuActions.getBoundingClientRect()
            : { top: 0, left: 0 }

        return (
            <Menu
                anchorEl={anchorMenuActions}
                open={Boolean(anchorMenuActions)}
                anchorReference="anchorPosition"
                anchorPosition={{
                    left: pos.left,
                    top: pos.top
                }}
                onClose={() => this.setState({
                    anchorMenuActions: undefined,
                    activeRecord: undefined
                })}
            >
                <MenuItem onClick={this.handleMenuView}>{t('View')}</MenuItem>
                <MenuItem onClick={this.handleMenuEditJson} disabled={!selectedKey.access.allowUpdate}>{t('EditAsJson')}</MenuItem>
                <MenuItem onClick={this.handleMenuDelete} disabled={!selectedKey.access.allowDelete}>{t('Delete')}</MenuItem>
            </Menu>
        )
    }

    handelRowClick = (row: any) => {
        console.log('CLICK', row)
        this.setState({ selectedRecord: row });
    }

    handleToggleFilters = async () => {
        const { actions, filters } = this.props;
        this.setState({ filterEnabled: !this.state.filterEnabled }, async () => {
            if (!this.state.filterEnabled) {
                actions.onSearchChange(filters.name, true, this.lastFilters);
                await actions.load();
            }
        });
   
        
    }

    renderGrid = () => {
        const { columns, tableColumnExtensions, columnWidths, columnOrder, customColumns, validator, filterEnabled } = this.state;
        const { t, data, selectedKey, rowsPerPage, page, /*loading, */count, actions, filters: { name: search } } = this.props;

        const tableMessages = { noData: t('NoData') };
        const tableData = selectedKey ? data : [];
        // if (loading && (!tableData || tableData.length === 0)) {
        //     return Preloader;
        // }
        const columnChooserMessages = { showColumnChooser: t('ChooseColumns') };

        this.setSearchVisible();

        const sortingStateColumnExtensions = (columns || []).map(({ name, sortingEnabled }) => ({
            columnName: name, sortingEnabled: !!sortingEnabled
        }));

        return (
            <Grid
                rows={Array.isArray(tableData) ? tableData : []}
                columns={this.deleteHiddenColumns(columns) || []}
            >
                <DataTypeProvider
                    for={['createdAt']}
                    formatterComponent={({ row }) => (row.createdAt ? <TimeLabel date={row.createdAt} /> : null)}
                />
                <DataTypeProvider
                    for={['data']}
                    formatterComponent={
                        ({ row }) => {
                            if (!selectedKey) {
                                return null;
                            }

                            const content: any = evalate(selectedKey.toString, row);

                            if (content instanceof Error) {
                                (content as EvaluateError).commit({
                                    type: 'registry',
                                    selectedKey
                                });

                                return null;
                            }

                            return content || null;
                        }
                    }
                />
                <DataTypeProvider
                    // key={customColumns}
                    for={customColumns}
                    formatterComponent={
                        ({ row, column }) => {
                            let text;
                            if (typeof selectedKey.schema.toTable === 'object') {
                                text = evalate(selectedKey.schema.toTable[column.title!], row);
                            } else {
                                text = objectPath.get(row, column.name);
                            }
                            if (text instanceof Error) {
                                text = evalate(selectedKey.schema.toTable[(column as RecColumn).propertyName!], row);
                            }
                            // console.log('text', text, row, column.name);

                            const displayText: string = typeof text === 'object' ? JSON.stringify(text) : text;

                            if (displayText === undefined) return null;

                            return <HighlightText highlight={search} text={String(displayText)} />;
                        }
                    }
                />
                <RowDetailState
                    expandedRowIds={this.state.expandedRowIds}
                    onExpandedRowIdsChange={(rows) => this.setState({expandedRowIds: [...rows]})}
                />

                { filterEnabled &&
                    <FilteringState
                        columnFilteringEnabled={filterEnabled}
                        onFiltersChange={this.setFilters}
                    />
                }

                <SortingState
                    sorting={this.getSorting()}
                    onSortingChange={this.setSorting}
                    columnExtensions={sortingStateColumnExtensions}
                />
                {/* <SearchState
                    value={search}
                    onValueChange={actions.onSearchChange} 
                />*/}
                <PagingState
                    currentPage={page || 0}
                    onCurrentPageChange={newPage => actions.onChangePage(newPage - 1)}
                    pageSize={rowsPerPage}
                    onPageSizeChange={actions.onChangeRowsPerPage}
                />
                <CustomPaging
                    totalCount={selectedKey && count ? count : 0}
                />
                <IntegratedSorting />
                {/* <IntegratedFiltering /> */}
                <DragDropProvider />

                <Table
                    messages={tableMessages}
                    columnExtensions={tableColumnExtensions}
                    rowComponent={(props) => <TableRow {...props} validator={validator} handleClick={this.handelRowClick} />}
                />

                { filterEnabled &&
                    <TableFilterRow
                        showFilterSelector={false}
                        messages={{ filterPlaceholder: t('SearchFieldLabel') }}
                    // cellComponent={this.filterCell}
                    />
                }

                <TableColumnReordering
                    order={columnOrder}
                    onOrderChange={this.setColumnOrder}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    // resizingMode="nextColumn"
                    onColumnWidthsChange={this.setColumnWidths}
                />
                <TableHeaderRow
                    showSortingControls={true}
                // sortLabelComponent={SortLabel}
                />
                <PagingPanelMUI
                    messages={this.pagingPanelMessages}
                    pageSizes={selectedKey ? [10, 20, 50, 100] : []}
                />
                <TableRowDetail
                    contentComponent={({ row }) => <RowDetailRecord row={row} validator={validator} />}
                />
                <TableFixedColumns
                    rightColumns={['$actions']}
                    cellComponent={this.setFixedColumnsComponent}
                />

                <TableColumnVisibility
                    hiddenColumnNames={this.hiddenColumnsNames()}
                    columnExtensions={[{ columnName: '$actions', togglingEnabled: false }]}
                    onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                />

                <Toolbar />

                {/* <SearchPanel inputComponent={this.searchInput} messages={{ searchPlaceholder: t('SearchFieldLabel') }} /> */}
                <ExportToExelButton {...{ selectedKey }} />
                <ColumnChooser
                    toggleButtonComponent={ToggleColumnChooser}
                    messages={columnChooserMessages}
                />

                <EnableFilterButton
                    hint={t('UseFilters')}
                    enabled={Boolean(filterEnabled)}
                    onClick={this.handleToggleFilters} />

                <CreateNewRecordButton
                    label={t('AddNewRow')}
                    disabled={!selectedKey || !selectedKey.access.allowCreate}
                    onClick={() => this.setState({
                        newRecord: {
                            registerId: selectedKey.registerId,
                            keyId: selectedKey.id,
                            data: {}
                        }
                    })}
                />
            </Grid>
        );
    };

    init = () => {
        const { actions, selectedKey } = this.props;
        if (selectedKey && !processList.has('RegistryKeysTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true)) {
            actions.clearFilters();
            processList.set('RegistryKeysTableLoad', actions.onFilterChange, { keyId: selectedKey.id }, true);
        }

        if (selectedKey) {
            const ajv = new Ajv({ allErrors: true });
            const validator = ajv.compile({
                type: "object",
                additionalProperties: false,
                ...selectedKey.schema
            });
            this.setState({ validator });
        }
    };

    componentDidMount = () => this.init();

    componentDidUpdate = () => {
        const { filters: { keyId }, selectedKey } = this.props;
        if (selectedKey && keyId !== selectedKey.id) this.init();
        // this.searchInput.update();
        // this.filterCell.update();
    };

    componentWillReceiveProps = (nextProps: RegistryKeyTableProps) => {
        const defaultProps = initialState(nextProps.t);
        this.setState(this.propsToState(defaultProps, nextProps), this.forceUpdate);
    };


    applyFromJson = async (data: any) => {
        const { activeRecord } = this.state;
        const { actions } = this.props;
        const record = { ...activeRecord, data } as IRecord;
        await actions.storeRecord(record.id, record);
        await actions.load();

        this.setState({
            editJSON: false,
            activeRecord: undefined
        });
    }

    render = () => {
        const { selectedRecord, newRecord, initDelete, activeRecord, editJSON } = this.state;
        const { selectedKey, actions, classes, t } = this.props;

        return (
            <Box className={classes.table} >
                {this.renderGrid()}

                { this.renderMenu()}

                { initDelete &&
                    <DeleteRecord
                        rowsSelected={activeRecord}
                        actions={actions}
                        hideButton={true}
                        initState={true}
                        handleClose={() => this.setState({ initDelete: false, activeRecord: undefined })} />
                }

                {editJSON && activeRecord && <JSONEditor
                    classes={classes}
                    closeModal={() => { this.setState({ editJSON: false }) }}
                    data={activeRecord.data!}
                    idRecord={activeRecord.id!}
                    schema={selectedKey.schema}
                    onSave={this.applyFromJson}
                    translateMsg={{
                        save: t('Save'),
                        close: t('Close')
                    }} />}

                { selectedRecord &&
                    <RegistryModal
                        open={!!(selectedKey && selectedRecord)}
                        selected={selectedKey || {}}
                        value={(selectedRecord || {})}
                        handleSave={this.handleStore}
                        handleClose={() => this.setState({ selectedRecord: undefined })}
                        handleDelete={actions.onRowsDelete.bind(null, [(selectedRecord || {}).id])}
                    />
                }

                { newRecord &&
                    <RegistryModal
                        editMode={true}
                        open={true}
                        selected={selectedKey || {}}
                        value={(newRecord || {})}
                        handleClose={() => this.setState({ newRecord: undefined })}
                        handleSave={this.handleStoreNewRecord}
                    />
                }
            </Box>
        );
    };
}

const translated = translate('RegistryPage')(RegistryKeyTable);
export default dataTableConnect(endPoint)(translated);
