import { IModuleInfo } from '@eis/types';
import ProfileAppbar from './components/ProfileAppbar';
import { UserProfile } from './pages/UserProfile';

const module: IModuleInfo = {
    appbar: [{
        component: ProfileAppbar
    }],
    routes: [{
        path: '/profile',
        component: UserProfile,
        title: 'EditUserHeader'
    }]
};
export default module;