import React from 'react';
import { useTranslate } from 'react-translate';
import { Link, Typography, } from '@material-ui/core';
import { useStyles } from './styles';
import { PaperLayout, useAppHeader } from '@eis/components';
import fundLogo from '@app/assets/img/fond.png';


export const InfoPage: React.FC = () => {
    const t = useTranslate('InfoPage');
    const classes = useStyles();

    useAppHeader({
        title: t('title'),
    });

    return (
        <PaperLayout>
            <Typography variant="h5" className={classes.title}>
                Підтримка кабінету заявника програми "Енергодім"
                </Typography>
            <Typography variant="body1" className={classes.text}>
            <Link target="_blank" href="https://eefund.org.ua/sites/default/files/Програма%2030.06/Інструкція_Користувача_заявка_1.pdf">Інструкція</Link>
            </Typography>

            <Typography variant="h6" className={classes.headline}>
                Телефонуйте
            </Typography>
            <Typography variant="body1" className={classes.text}>
                Головний офіс: <a className={classes.link} href="tel:+380442229590">+38 (044) 222-95-90</a><br />
                Участь у програмі: <a className={classes.link} href="tel:+380443442846">+38 (044) 344-28-46</a><br />
            </Typography>
            <Typography variant="h6" className={classes.headline}>
                Наша адреса
            </Typography>
            <Typography variant="body1" className={classes.text}>
                вул. Ділова 24, Київ, Україна 03150
                <br/>
                <img  src={fundLogo} className={classes.logo} alt={'Фонд енергоефективності'}/>
            </Typography>
            <Typography variant="h6" className={classes.headline}>
                Для листування
            </Typography>
            <Typography variant="body1" className={classes.text}>
                <a className={classes.link} href="mailto:info@eefund.org.ua">info@eefund.org.ua</a><br />
                <a className={classes.link} href="mailto:support@eefund.org.ua">support@eefund.org.ua</a>
            </Typography>
            <Typography variant="body2" align="right" color="textSecondary">
                ver. {process.env.REACT_APP_VERSION}
            </Typography>

        </PaperLayout>
    );
}
