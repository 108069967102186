import storage from '../../storage';
import toSwagger from './toSwagger';

const helloWorld = () => 'Hello world!'
// eslint-disable-next-line
const evalHelloWorld = eval('() => `Eval Hello world!`');

export default {
    main: {
        helloWorld,
        evalHelloWorld
    },
    storage,
    converter: {
        toSwagger
    }
};