import { History } from 'history';
import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';

import reducers from '../application/reducers';


export const rootReducer = combineReducers(reducers);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const { application: { environment, type } } = config;
// TODO Передавать список редьюсеров по типу приложения

export default function configureStore(history: History) {
    let middleware = [
        routerMiddleware(history),
        thunk
    ];
    if (true) {
        middleware.push(createLogger({ collapsed: true }));
    } 

    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleware))
    );
}
