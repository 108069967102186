import { useSelector } from 'react-redux';
import { AppRootState } from '@app/store';
import config from 'config/index';
import checkAccess from '@core/helpers/checkAccess';

// FEE Stuff = 
// [1000178, 1000179, 1000180, 1000181, 1000182, 1000183, 1000184];

export interface IUserStaff {
    /** User is business staff.  */
    staff: boolean;
    /** User is platform administrator  */
    admin: boolean;
    /** User is supper administrator */
    supperAdmin: boolean;
    /** Units */
    units: Array<{id: number; name: string}>
}

/**  
 * Complex check user's based roles.
 */
export const useRoles = (): IUserStaff => {
    const { userUnits, userInfo }: { userUnits: Array<{id: number}>, userInfo: {role: string} } = 
        useSelector((state: AppRootState) => ({
            userUnits: state.auth.userUnits || [],
            userInfo: state.auth.info
        }));
    const staffUnits: Array<number> = config.staffUnits || [];
    return {
        staff: userUnits.reduce((result: boolean, current: { id: number; }) => result || staffUnits.includes(current.id), false),
        admin: checkAccess({ userIsAdmin: true }, userInfo, userUnits) || checkAccess({ userIsGod: true }, {}, userUnits),
        supperAdmin: checkAccess({ userIsGod: true }, {}, userUnits),
        units: userUnits as any
    }
};
