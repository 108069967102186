import React from 'react';
// import { Link } from 'react-router-dom';
import {
    AppBar,
    createStyles,
    IconButton,
    makeStyles,
    Toolbar,
    Typography
} from '@material-ui/core';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import { IAppBarItemInfo } from '@eis/types';
import { useAppHeader } from './AppHeaderContext';


const useStyles = makeStyles(theme => createStyles({
    menuButton: {
        marginLeft: -theme.spacing(),
        [theme.breakpoints.down('xs')]: {
            color: '#fff'
        }
    },
    header: {
        color: '#000',
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        paddingRight: 10,
        backgroundColor: (theme as any).headerBg,
        [theme.breakpoints.down('xs')]: {
            padding: 8,
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        }
    },
    toolbar: {
        padding: '0 4px',
        minHeight: 'auto',
        alignItems: 'center',
        '& > *': {
            marginRight: 4
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    flex: {
        display: 'flex'
    },
    whiteSm: {
        flexGrow: 1,
        lineHeight: 1.5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('xs')]: {
            color: '#fff'
        }
    },
    userInfo: {
        justifyContent: 'flex-end'
    },
    backLink: {
        color: 'rgba(0, 0, 0, 0.54)',
        [theme.breakpoints.down('xs')]: {
            color: '#fff'
        }
    }
}));


interface AppHeaderProps {
    widgets: Array<IAppBarItemInfo>,
    title?: string,
    hideMenuButton?: boolean,
    onDrawerToggle: any,
    openSidebar?: boolean
}

export const AppHeader: React.FC<AppHeaderProps> = props => {
    const { widgets, title, hideMenuButton, onDrawerToggle } = props;
    const settings = useAppHeader();
    // const { backButton } = settings;

    const classes = useStyles();

    return (
        <AppBar className={classes.header} position="relative" elevation={1}>
            <Toolbar className={classes.toolbar}>
                {/* {
                    backButton &&
                    <IconButton 
                        className={classes.menuButton}
                        component={Link}
                        to={backButton} > 
                        <ArrowBackIcon />
                    </IconButton>
                }
                {
                    !backButton && !hideMenuButton &&
                    <IconButton
                        aria-label="Open drawer"
                        onClick={onDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                } */}
                {!hideMenuButton &&
                    <IconButton
                        aria-label="Open drawer"
                        onClick={onDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                }
                <Typography className={classes.whiteSm} variant="h5" title={settings.title || title}>
                    {settings.title || title}
                </Typography>
                {
                    widgets.map((widget, key) => {
                        const Component: React.JSXElementConstructor<any> = widget.component!;
                        return (
                            <Component key={key} />
                        )
                    })
                }
            </Toolbar>
        </AppBar>
    );

}
