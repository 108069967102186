import React, { FC, Fragment, useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';

import {
    createStyles,
    makeStyles,
    Tab,
    Tabs,
    Toolbar,
} from '@material-ui/core';

import { TreeListSelect } from 'components/TreeList';

import { CleanLayout, useAppHeader } from '@eis/components';
import { requestRegisters, requestRegisterKeys } from '@cab/actions/registry';

import recordEndPoint from '@cab/endPoints/registryRecord';
import { load, onFilterChange, updateRecordValues, storeRecord, onRowsDelete, createRecord } from 'services/dataTable/actions';

import RegistryKeyTable from './components/RegistryKeyTable';
import RegistryHistoryTable from './components/RegistryHistoryTable';
import { AppRootState } from '@app/store';


const useStyles = makeStyles(theme => createStyles({
    root: {
        margin: theme.spacing()
    },
    toolbar: {
        padding: 0
    },
    button: {
        width: 192,
        marginLeft: 20
    },
    table: {
        backgroundColor: 'white',
        borderTop: '1px solid silver',
        borderBottom: '1px solid silver',
    },
    editorHeader: {
        padding: '0 16px',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

interface ApiData {
    loading: boolean,
    data: Array<any>
}

const useSelectors = (): {
    registers: Array<{id: string, description: string}>,
    keys: Array<{id: string, description: string, registerId: string}>,
    loading: boolean,
    records: Array<{description: string}>
} => {
    return useSelector((state: AppRootState) => ({
        registers: state.registry.registers,
        keys: state.registry.keys,
        loading: (state.registryRecordList as ApiData).loading,
        records: (state.registryRecordList as ApiData).data
    }));
}

interface RegistryPageProps {}
export const RegistryPage: FC<RegistryPageProps> = props => {
    const { registers, keys } = useSelectors();

    const t = useTranslate('RegistryPage');
    const classes = useStyles();
    const [selected, setSelected] = useState<any>();
    // const [newRecord, setNewRecord] = useState<any>();
    const [activeTab, setActiveTab] = useState<number>(0);

    const dispatch = useDispatch();
    const { actions } = mapDispatchToProps(dispatch);

    useAppHeader({
        title: t('title'),
    });
    
    const handleSelectKey = (selected?: { id: string, name: string }) => {
        if (!selected) {
            setSelected(selected);
            return;
        }
        const selectedKey = selected && keys.find((key: { id: string }) => key.id === selected.id);
        setSelected({
            ...selectedKey,
            name: selectedKey && selectedKey.description
        });
    };

    useEffect(() => {
        if (!registers) {
            actions.requestRegisters();
        }

        if (!keys) {
            actions.requestRegisterKeys();
        }
        // eslint-disable-next-line
    }, []);


    let items = null;
    if (registers && keys) {
        items = (registers || []).map(({ id, description }) => ({
            id,
            name: description,
            items: (keys || []).filter(key => key.registerId === id).map(key => ({
                id: key.id,
                name: key.description
            }))
        })).map(parent => parent.items.length === 1 ? parent.items.shift() : parent);
    }

    const allowHistory = selected && selected.access && selected.access.allowHistory;
    

    const renderTable = () => {
        const tableProps = {
            t,
            selectedKey: selected,
            classes
        };
        return activeTab === 0 
            ? <RegistryKeyTable {...tableProps} /> 
            : (activeTab === 1) ? <RegistryHistoryTable {...tableProps} /> : null;
    }

    return (
        <CleanLayout>
            <Toolbar classes={{gutters: classes.toolbar, root: classes.root}} id="select-key">
                <TreeListSelect
                    placeholder={t('SelectKey')}
                    items={items}
                    selected={selected}
                    onSelect={handleSelectKey}
                    id="select-key"
                />
            </Toolbar>
            {selected && <Fragment>
                {allowHistory && <Tabs
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, tab) => setActiveTab(tab)}
                    aria-label="disabled tabs example"
                >
                    <Tab label={t('Records')} />
                    {allowHistory ? <Tab label={t('History')} /> : null}
                </Tabs>
                }
                {renderTable()}
            </Fragment>}
        </CleanLayout>

    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: {
        load: bindActionCreators(load(recordEndPoint as any), dispatch),
        onFilterChange: bindActionCreators(onFilterChange(recordEndPoint), dispatch),
        requestRegisters: bindActionCreators(requestRegisters, dispatch),
        requestRegisterKeys: bindActionCreators(requestRegisterKeys, dispatch),
        storeRecord: bindActionCreators(storeRecord(recordEndPoint), dispatch),
        updateRecordValues: bindActionCreators(updateRecordValues(recordEndPoint), dispatch),
        onRowsDelete: bindActionCreators(onRowsDelete(recordEndPoint), dispatch),
        createRecord: bindActionCreators(createRecord(recordEndPoint), dispatch)
    }
});
