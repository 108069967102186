import { ThemeOptions } from '@material-ui/core/styles';
// import colorsPrimary from '@material-ui/core/colors/blue';
import colorsSecondary from '@material-ui/core/colors/yellow';
import { grey } from '@material-ui/core/colors';

/**
 * Додаткові параметри теми 
 */
export interface ThemeOptionsExt {
    leftSidebarBg: string,
    headerBg: string,
    buttonBg: string,
    buttonHoverBg: string,
    controlsMaxWidth: number
}

/**
 * Приводить загальний тип теми до додаткових параметрів ThemeOptionsExt
 */
export function ThemeOptionsExt(theme: any): ThemeOptionsExt { 
    return {
        leftSidebarBg: '#4d66ff',
        headerBg: '#eeeeee',
        buttonBg: '#ffd417',
        buttonHoverBg: '#ffe565',
        controlsMaxWidth: 800,
        ...theme
    }
}

export const FontFamily = [
    '"Montserrat"', 
    '"Roboto"', 
    '"Helvetica"', 
    '"Arial"', 
    'sans-serif'
].join(', ');

// Тема
const uisTheme: ThemeOptions & ThemeOptionsExt = {
    palette: {
        primary: {
            light: '#7084ff',
            main: '#4d66ff',
            dark: '#3547b2',
            contrastText: '#FFFFFF'
        },
        secondary: colorsSecondary
    },
    leftSidebarBg: '#4d66ff',
    headerBg: '#eeeeee',
    buttonBg: "#ffd417",
    buttonHoverBg: "#ffe565",
    controlsMaxWidth: 800,
    typography: {
        fontFamily: FontFamily,
        fontSize: 14
    },
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: 'bold',
                borderBottomColor: grey[500],
                backgroundColor: grey[50],
            },
            root: {
                padding: '8px 16px',
                "&>.MuiBox-root": {
                  alignItems: "center",
                  overflowWrap: "anywhere",
                  whiteSpace: "normal",
                },
                '&>ul>li>div>span>div': {
                  overflowWrap: 'anywhere'
                },
                "&>div>div>svg": {
                    minWidth: 26,
                },
            }
        },
        MuiInputLabel: {
            shrink: {
                width: `${100/0.8}%`,
                transform: 'translate(0, 1.5px) scale(0.8)'
            },
            formControl: {
                width: '100%',
                height: '1em%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }
        },
        MuiFormHelperText: {
            root: {
                color: 'rgba(0, 0, 0, 0.7)',
            }
        },
        MuiFormLabel: {
            root: {
                color: 'rgba(0, 0, 0, 0.9)',
            }
        },
        
    }
}
export default uisTheme;
