import dispatchType from 'services/dataTable/dispatchType';
import { merge } from 'lodash/fp';
import cleanDeep from 'clean-deep';

import objectPath from 'object-path';
import { IEndpoint } from '../../../manager/application/endPoints/types';

const GET_LIST = 'GET_LIST';
const ON_ERROR_CLOSE = 'ON_ERROR_CLOSE';
const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
const GET_LIST_FAIL = 'GET_LIST_FAIL';

const CLEAR_FILTERS = 'CLEAR_FILTERS';

const ON_ROW_SELECT = 'ON_ROW_SELECT';
const ON_CHANGE_PAGE = 'ON_CHANGE_PAGE';
const ON_SEARCH_CHANGE = 'ON_SEARCH_CHANGE';
const ON_FILTER_CHANGE = 'ON_FILTER_CHANGE';
const COLUMN_SORT_CHANGE = 'COLUMN_SORT_CHANGE';
const ON_CHANGE_ROWS_PER_PAGE = 'ON_CHANGE_ROWS_PER_PAGE';
const UPDATE_RECORD_VALUES = 'UPDATE_RECORD_VALUES';

const ON_ROWS_RECOVER_SUCCESS = 'ON_ROWS_RECOVER_SUCCESS';
const ON_ROWS_DELETE_SUCCESS = 'ON_ROWS_DELETE_SUCCESS';
const ON_ROWS_DELETE_PERMANENT_SUCCESS = 'ON_ROWS_DELETE_PERMANENT_SUCCESS';
const TOGGLE_COLUMN_VISIBLE = 'TOGGLE_COLUMN_VISIBLE';
const SET_HIDDEN_COLUMNS = 'SET_HIDDEN_COLUMNS';

interface IState {
    loading: boolean;
    error: any;
    errors: Array<any>;
    count: number | null;
    page: number | null
    rowsPerPage: number;
    data: any;
    rowsSelected: Array<string>;
    hiddenColumns: Array<string>;
    filters: Record<string, string>;
    search: string;
    sort: Record<string, string>;
}

const initialState: IState = {
    loading: false,
    error: null,
    errors: [],
    count: null,
    page: null,
    rowsPerPage: 20,
    data: null,
    rowsSelected: [],
    hiddenColumns: [],
    filters: {},
    search: '',
    sort: {}
};

interface IMeta {
    currentPage: number; 
    perPage: number; 
    total: number;
}

const mapDataDefault = (payload: {meta: IMeta}) => {
    const { meta } = payload;
    const { currentPage, perPage, total } = meta || {};

    return {
        data: payload,
        page: currentPage,
        rowsPerPage: perPage,
        count: total
    };
};

// interface IReduceArgs { 
//     dataURL?: string;
//     sourceName: string; 
//     defaultOptions: Partial<IState>; 
//     mapData: any; 
//     reduce: any; 
// }

export default ({ sourceName, defaultOptions, mapData, reduce }: IEndpoint) => 
    (state = merge(initialState, defaultOptions), action: any) => {

    if (reduce) {
        state = reduce(state, action);
    }

    switch (action.type) {
        case dispatchType(sourceName, CLEAR_FILTERS):
            const result = initialState;
            const perPage = localStorage.getItem('registryTableRowsPerPage');
            if (perPage) result.rowsPerPage =  parseInt(perPage);
            return result;
        case dispatchType(sourceName, GET_LIST):
            return { ...state, loading: true };
        case dispatchType(sourceName, ON_ERROR_CLOSE):
            return { ...state, errors: state.errors!.filter((_: any, index: number) => index !== action.payload) };
        case dispatchType(sourceName, GET_LIST_SUCCESS):
            return {
                ...state,
                ...(mapData || mapDataDefault)(action.payload, state),
                loading: false
            };
        case dispatchType(sourceName, GET_LIST_FAIL):
            return {
                ...state,
                loading: false,
                error: action.payload.message
            };
        case dispatchType(sourceName, ON_ROW_SELECT):
            return { ...state, rowsSelected: action.payload };
        case dispatchType(sourceName, ON_CHANGE_PAGE):
            return { ...state, page: action.payload, rowsSelected: [] };
        case dispatchType(sourceName, ON_CHANGE_ROWS_PER_PAGE):
            localStorage.setItem('registryTableRowsPerPage', action.payload);
            return { ...state, rowsPerPage: action.payload, page: 0 };
        case dispatchType(sourceName, ON_SEARCH_CHANGE):
            return { ...state, search: action.payload };
        case dispatchType(sourceName, ON_FILTER_CHANGE):
            return { ...state, filters: action.payload, rowsSelected: [], page: 0 };
        case dispatchType(sourceName, COLUMN_SORT_CHANGE): {
            const { column, direction, hard } = action.payload;
            const sort: Record<string, string> = {};
            if (column && direction) {
                if (hard) {
                    sort[column] = direction;
                } else {
                    objectPath.set(sort, column, direction);
                }
            }
            return { ...state, sort };
        }
        case dispatchType(sourceName, UPDATE_RECORD_VALUES): {
            const { rowId, data, hard } = action.payload;
            const rowPosition = state.data.map(({ id }: {id: string}) => id).indexOf(rowId);

            let cleanDocument = cleanDeep(state.data[rowPosition], { emptyObjects: false, emptyArrays: false, nullValues: false });

            if (hard) {
                Object.keys(data).forEach((key) => {
                    cleanDocument.data[key] = data[key];
                });
            } else {
                cleanDocument = merge(cleanDocument, { data });
            }

            const document = cleanDeep(cleanDocument, { emptyObjects: false, emptyArrays: false, nullValues: false });

            state.data[rowPosition] = document;
            return state;
        }
        case dispatchType(sourceName, ON_ROWS_RECOVER_SUCCESS):
        case dispatchType(sourceName, ON_ROWS_DELETE_SUCCESS):
        case dispatchType(sourceName, ON_ROWS_DELETE_PERMANENT_SUCCESS):
            return { ...state, rowsSelected: [] };
        case dispatchType(sourceName, TOGGLE_COLUMN_VISIBLE): {
            const { hiddenColumns } = state;

            if (hiddenColumns.includes(action.payload)) {
                hiddenColumns.splice(hiddenColumns.indexOf(action.payload), 1);
            } else {
                hiddenColumns.push(action.payload);
            }

            return { ...state, hiddenColumns: [...hiddenColumns] };
        }
        case dispatchType(sourceName, SET_HIDDEN_COLUMNS):
            return { ...state, hiddenColumns: action.payload };
        default:
            return state;
    }
};
