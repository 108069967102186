import { IOpenApiObject } from "./openapi";


function toSwagger(data: any): IOpenApiObject {
    return {
        openapi: '3.0.0',
        info: {
            title: 'Incoming demo server',
            version: '1.0.0',
            description: "Demo-swagger"
        },
        paths: {}
    }
}


export default toSwagger;