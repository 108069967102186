import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import renderHTML from 'helpers/renderHTML';

import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    withStyles
} from '@material-ui/core';
import ElementContainer from 'components/JsonSchema/components/ElementContainer';

const styles = {
    labelSize: {
        '& span': {
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: '-0.02em'
        }
    },
    distance: {
        marginTop: 10,
        maxWidth: 1000
    },
    blockItem: {
        paddingBottom: 20
    },
    sampleComponent: {
        marginLeft: 25,
        fontWeight: 300,
        padding: '0 0 20px 0'
    },
    disabledItem: {
        marginLeft: 25,
        fontWeight: 300,
        padding: '0 0 20px 0'
    }
};

const CheckboxLayout = ({
    sample,
    description,
    required,
    classes,
    readOnly,
    items,
    rowDirection,
    error,
    path,
    width,
    maxWidth,
    propertyName,
    checkedKeys,
    isDisabled,
    handleChange,
    getSample,
    noMargin,
    bottomSample
}) => (
        <ElementContainer
            sample={sample}
            description={description}
            required={Array.isArray(required) ? required.includes(propertyName) : required}
            error={error}
            width={width}
            maxWidth={maxWidth}
            noMargin={noMargin}
            bottomSample={bottomSample}
        >
            <FormGroup row={rowDirection}>
                {
                    items.map(key => (
                        <Fragment key={key.id}>
                            <FormControlLabel
                                className={
                                    classNames({
                                        [classes.labelSize]: true,
                                        [classes.distance]: !rowDirection,
                                        [classes.blockItem]: (!checkedKeys.includes(key.id) && !rowDirection) || (checkedKeys.includes(key.id) && !rowDirection && !key.sample && !key.getSample)
                                    })
                                }
                                key={key.id}
                                disabled={isDisabled(key)}
                                control={
                                    (
                                        <Checkbox
                                            id={path.join('-')}
                                            color="primary"
                                            disabled={readOnly}
                                            checked={checkedKeys.includes(key.id)}
                                            onChange={handleChange(key.id)}
                                        />
                                    )
                                }
                                label={renderHTML(key.title)}
                            />
                            {
                                ((!rowDirection && checkedKeys.includes(key.id)) || isDisabled(key)) && getSample(key) ? (
                                    <div
                                        className={classNames({
                                            [classes.sampleComponent]: true,
                                            [classes.disabledItem]: isDisabled(key)
                                        })
                                        }
                                    >
                                        {renderHTML(getSample(key) || '')}
                                    </div>
                                ) : null
                            }
                        </Fragment>
                    ))
                }
            </FormGroup>
        </ElementContainer>
    );

CheckboxLayout.propTypes = {
    isDisabled: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    getSample: PropTypes.func.isRequired,
    sample: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    items: PropTypes.array.isRequired,
    rowDirection: PropTypes.bool.isRequired,
    error: PropTypes.array,
    path: PropTypes.array.isRequired,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
    propertyName: PropTypes.string,
    checkedKeys: PropTypes.array.isRequired,
    bottomSample: PropTypes.bool
};

CheckboxLayout.defaultProps = {
    description: null,
    sample: null,
    required: false,
    readOnly: false,
    error: null,
    width: null,
    maxWidth: null,
    propertyName: null,
    bottomSample: false
};

const styled = withStyles(styles)(CheckboxLayout);
export default styled;
