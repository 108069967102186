import React from 'react';
import { useSelector } from 'react-redux';

import { Switch } from 'react-router-dom';

import { getRoutes } from '../utils';
import { AppRootState } from '@app/store';
import { IModuleInfo } from '../types';

import { PrivateRoute } from './PrivateRoute';

interface AppRouterProps {
    modules: Array<IModuleInfo>
}

export const AppRouter: React.FC<AppRouterProps> = props => {
    const { onboardingTaskId } = useSelector((state: AppRootState) => state.auth.info || {});

    const routes = getRoutes(props.modules)
        .filter(({ isOnboarding }) => (onboardingTaskId ? isOnboarding : !isOnboarding));

    return (
        <Switch>
            {routes.map((route, key) => <PrivateRoute key={key} {...route} />)}
        </Switch>
    );
}
