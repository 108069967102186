import React from 'react';
import InputMask from 'react-input-mask';
import { cleanUndefined } from '@core/helpers/cleanObject';


export interface MaskedProps {
    // ref: React.Ref<HTMLInputElement>;
    maskChar?: string | null;
    formatChars?: { [key: string]: string };
    mask?: string;
}

export const Masked: React.FC<MaskedProps> = (props) => {
    const rest = { 
        ...props, 
        maskChar: props.maskChar || null, 
    }; 
    return <InputMask 
                {...cleanUndefined(rest) as any} 
                 />
} 