import React from 'react';
import { translate } from 'react-translate';
import PropTypes from 'prop-types';
import evalate from 'helpers/evalate';

import emptyValues from 'components/JsonSchema/emptyValues';
import ChangeEvent from 'components/JsonSchema/ChangeEvent';

import ArrayElementContainer from 'components/JsonSchema/elements/ArrayElement/components/ArrayElementContainer';
import ArrayElementItem from 'components/JsonSchema/elements/ArrayElement/components/ArrayElementItem';
import ArrayElementAddBtn from 'components/JsonSchema/elements/ArrayElement/components/ArrayElementAddBtn';

class ArrayElement extends React.Component {
    componentDidMount() {
        const { value, onChange, allowEmpty } = this.props;
        if (!value && !allowEmpty) {
            onChange && onChange(this.getItems());
        }
    }

    handleAddItem = () => {
        const { onChange, items } = this.props;
        onChange && onChange(this.getItems().concat([emptyValues[items.type || 'object']]));
    };

    handleDeleteItem = index => () => {
        const { onChange, value, allowEmpty, items } = this.props;
        const arr = Object.values(value);
        arr.splice(index, 1);

        if (!allowEmpty && !arr.length) {
            arr.push(emptyValues[items.type || 'object']);
        }

        onChange && onChange(new ChangeEvent(arr, false, true));
    };

    getItems = () => {
        const { value, items, allowEmpty } = this.props;
        const data = Object.values(value || {});
        return (data.length || allowEmpty) ? data : [].concat(emptyValues[(items || {}).type || 'object']);
    }

    renderElement = (values, index) => {
        const {
            taskId,
            customControls,
            steps,
            actions,
            rootDocument,
            originDocument,
            template,
            stepName,
            errors,
            path,
            allowEmpty,
            items,
            readOnly,
            onChange,
            activeStep,
            clearWhenEmpty,
            disableBoxShadow,
            staticState,
            fileStorage
        } = this.props;
        const arrayItems = this.getItems();

        return (
            <ArrayElementItem
                key={path.concat(index)}
                error={errors.find(err => err.path === path.concat(index).join('.'))}
                path={path.concat(index)}
                deleteAllowed={!readOnly && (allowEmpty || arrayItems.length > 1)}
                handleDeleteItem={this.handleDeleteItem(index)}
                disableBoxShadow={disableBoxShadow}
                staticState={staticState}
                schemaProps={
                    {
                        fileStorage,
                        customControls,
                        taskId,
                        steps,
                        rootDocument,
                        originDocument,
                        stepName,
                        activeStep,
                        actions,
                        errors,
                        template,
                        pathIndex: {
                            index
                        },
                        path: path.concat(index),
                        schema: {
                            ...items,
                            type: items.type || 'object',
                            required: items.required || [],
                            clearWhenEmpty,
                            handleDeleteItem: this.handleDeleteItem(index)
                        },
                        name: index,
                        value: values,
                        readOnly: readOnly || items.readOnly,
                        onChange: onChange.bind(null, index)
                    }
                }
            />
        );
    };

    render() {
        const {
            t,
            addItem,
            rootDocument,
            maxElements,
            hidden,
            allowEmpty,
            readOnly,
            staticState,
            ...rest
        } = this.props;

        if (hidden) {
            return null;
        }

        const arrayItems = this.getItems();
        let calcMaxElements = 0;

        if (maxElements) {
            calcMaxElements = maxElements;
            if (typeof maxElements === 'string') {
                calcMaxElements = evalate(maxElements, rootDocument.data);

                if (calcMaxElements instanceof Error) {
                    calcMaxElements.commit({ type: 'array element maxElements' });
                    calcMaxElements = undefined;
                }
            }
        }

        const allowAdd = (!calcMaxElements || arrayItems.length < calcMaxElements) && !readOnly;

        return (
            <ArrayElementContainer
                handleAddItem={this.handleAddItem}
                {...rest}
            >
                {arrayItems.map(this.renderElement)}
                {
                    allowAdd && !staticState ? (
                        <ArrayElementAddBtn
                            addItemText={(addItem && addItem.text) || t('AddArrayItem')}
                            handleAddItem={this.handleAddItem}
                            arrayItems={arrayItems}
                        />
                    ) : null
                }
            </ArrayElementContainer>
        );
    }
}

ArrayElement.propTypes = {
    t: PropTypes.func.isRequired,
    errors: PropTypes.array,
    value: PropTypes.array,
    allowEmpty: PropTypes.bool,
    path: PropTypes.array,
    clearWhenEmpty: PropTypes.bool,
    staticState: PropTypes.bool
};

ArrayElement.defaultProps = {
    errors: [],
    value: [],
    allowEmpty: false,
    path: [],
    clearWhenEmpty: false,
    staticState: false
};

export default translate('Elements')(ArrayElement);
