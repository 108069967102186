import React, { FC, Fragment, ReactNode, useRef } from 'react';
import { useSelector } from 'react-redux';
import PopupCheckValidTools from '../debugTools/PopupCheckValidTools';
import PopupDebugTools from '../debugTools/PopupDebugTools';
import TaskDataTools from '../debugTools/TaskDataTools';
import CheckValidFunction from '../debugTools/CheckValidFunction';
import CheckHiddenFunction from '../debugTools/CheckHiddenFunction';
import { AppRootState } from '@app/store';
import { useAppHeader } from '@eis/components';


interface TaskPageLayoutProps {
    title: string;
    task: any,
    template: any,
    stepId: any
}
const TaskPageLayout: FC<TaskPageLayoutProps> = props => {
    const { title, children, task, template, stepId } = props;
    // const [debugTools, setDebugTools] = useState<Record<string, ReactNode> | undefined>(undefined);

    const popupDebugTools = useSelector((state: AppRootState) => state.debugTools.popup);

    const taskProps = { task, template };


    const getDebugTools = () => {
        const debugTools: Record<string, ReactNode> = {
            TaskDataTools: <TaskDataTools {...taskProps} />,
            CheckValidFunction: <CheckValidFunction {...{ ...taskProps, stepId }} />,
            CheckHiddenFunction: <CheckHiddenFunction {...taskProps} />
        };
    
        if (popupDebugTools) {
            debugTools.PopupDebugTools = <PopupDebugTools {...popupDebugTools} />;
            debugTools.PopupCheckValidTools = <PopupCheckValidTools {...popupDebugTools} />;
        }
       
        return debugTools;
    };
    const ref = useRef(getDebugTools())

    useAppHeader({title, debugTools: ref.current});
    return (
        <Fragment>{children}</Fragment>
    );
};
export default TaskPageLayout;

        // TaskPageLayout.propTypes = {
            //     children: PropTypes.oneOfType([
                //         PropTypes.arrayOf(PropTypes.node),
                //         PropTypes.node
                //     ]).isRequired,
                //     location: PropTypes.object.isRequired,
                //     title: PropTypes.string.isRequired,
                //     loading: PropTypes.bool.isRequired,
                //     task: PropTypes.object.isRequired,
//     template: PropTypes.object.isRequired
// };

// const mapStateToProps = ({ debugTools: { popup } }) => ({ popupDebugTools: popup });
// export default connect(mapStateToProps)(TaskPageLayout);
