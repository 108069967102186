import React from 'react';
import WorkflowIcon from '@material-ui/icons/AssignmentTurnedIn';

import WorkflowPage from './pages/Workflow';
import WorkflowListPage from './pages/WorkflowList';

import DraftsTableToolbar from './pages/WorkflowList/components/TableToolbar';
import TrashTableTools from './pages/WorkflowList/components/TableToolbar/TrashTableTools';

import workflowEndPoint from '@cab/endPoints/workflow';
import workflowDraftEndPoint from '@cab/endPoints/workflowDraft';
import workflowTrashEndPoint from '@cab/endPoints/workflowTrash';
import { IModuleInfo } from '@eis/types';
import { PagesIDEnum } from '@app/application/menu/consts';


const module: IModuleInfo = {
    routes: [{
        path: '/workflow/drafts',
        component: WorkflowListPage,
        endPoint: workflowDraftEndPoint,
        TableToolbar: DraftsTableToolbar,
        title: 'Drafts',
        defaultFilters: { tasks: { deleted: 0 }, is_draft: true },
        defaultSort: { columnName: 'documents.updated_at', direction: 'desc' },
        hiddenColumns: {
            notUnitedUser: [],
            isUnitedUser: 'properties.hiddenColumns.workflow.Drafts'
        },
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Drafts' }
    },
    {
        path: '/workflow/trash',
        component: WorkflowListPage,
        endPoint: workflowTrashEndPoint,
        TableToolbar: TrashTableTools,
        title: 'TrashPage',
        defaultFilters: { tasks: { deleted: 1 }, is_draft: true },
        defaultSort: { columnName: 'tasks.created_at', direction: 'desc' },
        hiddenColumns: {
            notUnitedUser: [],
            isUnitedUser: 'properties.hiddenColumns.workflow.Trash'
        },
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Trash' }
    },
    {
        path: '/workflow/:workflowId',
        component: WorkflowPage,
        access: {
            isUnitedUser: false,
            unitHasAccessTo: [
                'navigation.workflow.MyWorkflow',
                'navigation.workflow.Drafts',
                'navigation.workflow.Trash'
            ]
        }
    },
    {
        path: '/workflow',
        component: WorkflowListPage,
        endPoint: workflowEndPoint,
        TableToolbar: DraftsTableToolbar,
        title: 'InboxTitle',
        defaultFilters: { tasks: { deleted: 0 }, is_draft: false },
        defaultSort: { columnName: 'tasks.finished_at', direction: 'desc' },
        hiddenColumns: {
            notUnitedUser: [],
            isUnitedUser: 'properties.hiddenColumns.workflow.MyWorkflow'
        },
        access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
    }],
    navigation: [{
        id: PagesIDEnum.Workflow,
        icon: <WorkflowIcon />,
        priority: 30,
        access: {
            isUnitedUser: false,
            unitHasAccessTo: [
                'navigation.workflow.MyWorkflow',
                'navigation.workflow.Drafts',
                'navigation.workflow.Trash'
            ]
        },
        children: [{
            id: PagesIDEnum.MyWorkflow,
            path: '/workflow',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.MyWorkflow' }
        }, {
            id: PagesIDEnum.WorkflowDrafts,
            path: '/workflow/drafts',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Drafts' }
        }, {
            id: PagesIDEnum.WorkflowTrash,
            path: '/workflow/trash',
            access: { isUnitedUser: false, unitHasAccessTo: 'navigation.workflow.Trash' }
        }]
    }]
};

export default module;