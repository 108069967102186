import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
// import config from 'config'; 

const vars: Record<string, string> = { // TODO: Прокинуть конфиг config.localStorageVars || {
    edrpou: ''
};

const useStyles = makeStyles({
    dl: {
        margin: '8px'
    },
    row: {
        display: 'flex',
        borderBottom: '1px dotted silver'
    },
    dt: {
        width: '10%'
    },
    dd: {
        width: '88%'
    },
    input: {
        backgroundColor: 'white',
        width: '100%',
        border: '1px solid gray',
        '&focused': {
            border: '1px solid gray',
        }
    }

});

/**
 * Компонент для редактирования перечисленных переменных из localStorage
 * Отображается в режиме разработчике (в нижней dev панели) 
 */
export const LocalStorageEditor: React.FC<{ names: Array<string> }> = ({ names }) => {

    [...Object.keys(vars), ...names].forEach(name => vars[name] = localStorage.getItem(name) || '');
    const [state, setState] = useState(vars);
    const [selected, setSelected] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [selected]: e.target.value })
    }

    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        console.log(e);
        const id = e.currentTarget.parentElement!.attributes.getNamedItem('id');

        // const id = e.currentTarget!.parentNode!.attributes['id'];
        if (id && id.value !== selected) {
            setSelected(id.value);
        }
    }
    const handleBlur = () => {
        Object.keys(state).forEach(name => localStorage.setItem(name, state[name]));
    }

    const classes = useStyles();

    return (
        <Box className={classes.dl}>
            {Object.keys(state).map(name => (
                <Box className={classes.row} id={name} key={name}>
                    <Box className={classes.dt} onClick={handleClick}>{name}</Box>
                    <Box className={classes.dd}
                        contentEditable={false}
                        onClick={handleClick}
                    >{name !== selected && state[name]}
                        {name === selected &&
                            <input className={classes.input}
                                autoFocus={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={state[name]} />}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}