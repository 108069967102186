import React from 'react';
import Select from '@core/components/Select';
import { ChangeEvent } from '@core/components/JsonSchema';
import ElementContainer from '../components/ElementContainer';
import evalate from '@core/helpers/evalate';

interface IOptionItem {
    id: number | string; 
    name: string;
}

interface CustomSelectProps {
    sample?: string;
    required?: boolean;
    error?: string;
    path: Array<string>;
    width?: number;
    noMargin?: boolean;
    options?: string | Array<IOptionItem>;
    onChange?: (changeEvent: ChangeEvent) => void;
    rootDocument?: any,
    hidden?: boolean;
}

export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
    const { sample, required, error, path, width, noMargin, options, onChange, rootDocument, hidden } = props; 

    if (hidden) return null;

    const getOptions = (): Array<any> => {
        if (!options) return [];

        return typeof options === 'string'
            ? evalate(options, rootDocument).map((item: IOptionItem) => ({value: item.id, label: item.name }))
            : options.map(option => ({ ...option, value: option.id, label: option.name }))
    }

    const handleChange = (value: any) => {
        onChange && onChange(new ChangeEvent(value, false, true));
    };

    return (
        <ElementContainer
            sample={sample}
            required={required}
            error={error}
            bottomSample={true}
            width={width}
            noMargin={noMargin}
        >
            <Select
                {...props}
                error={error}
                id={path.join('-')}
                onChange={handleChange}
                options={getOptions()}
            />
        </ElementContainer>
    )
} 