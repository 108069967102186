import { Dispatch } from 'redux';
import * as api from 'services/api';
// import * as Sentry from '@sentry/browser';

export const SET_OPEN_SIDEBAR = 'APP/SET_OPEN_SIDEBAR';
export const SET_OPEN_DRAWER = 'APP/SET_OPEN_DRAWER';
export const TOGGLE_EXPAND_MENU = 'APP/TOGGLE_EXPAND_MENU';
export const SET_APP_LOADING = 'APP/SET_APP_LOADING';

const RESET_STATE = 'APP/RESET_STATE';

export const ping = () => (dispatch: Dispatch) => api.get('test/ping', 'PING', dispatch).catch((error) => {
    return error;
});

export const healthCheck = () => (dispatch: Dispatch) => api.get('test/ping/services', 'GET_SERVICES_STATUSES', dispatch)
    .catch((error) => {
        // Sentry.captureException(error);
        return error;
    });

// Open/Hide left sidebar with main menu
interface SetOpenSidebarAction {
    type: typeof SET_OPEN_SIDEBAR
    payload: boolean
}
export const setOpenSidebar = (open: boolean): SetOpenSidebarAction => ({
    type: SET_OPEN_SIDEBAR,
    payload: open
});

// Sidebar 
interface SetOpenDrawerAction {
    type: typeof SET_OPEN_DRAWER
    payload: boolean
}
export const setOpenDawer = (open: boolean): SetOpenDrawerAction => ({
    type: SET_OPEN_DRAWER,
    payload: open
});

// Expand/close submenu items 
interface ToggleExpandMenuAction {
    type: typeof TOGGLE_EXPAND_MENU
    payload: string
}
export const toggleExpandMenu = (name: string): ToggleExpandMenuAction => ({
    type: TOGGLE_EXPAND_MENU,
    payload: name
});

// Set visible root app loader 
interface SetAppLoadingAction {
    type: typeof SET_APP_LOADING,
    payload: boolean
}
export const setAppLoading = (value: boolean) => ({
    type: SET_APP_LOADING,
    payload: value
})

// Reset state ??
export const resetState = () => ({
    type: RESET_STATE
});

export type AppActionTypes = SetOpenDrawerAction 
    | SetOpenSidebarAction 
    | ToggleExpandMenuAction 
    | SetAppLoadingAction;