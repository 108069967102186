import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Preloader from 'components/Preloader';
import FileDataTable from 'components/FileDataTable';

import Header from './Header';

const WorkflowLayout = props => {
    const {
        // location,
        // title,
        actions,
        loading,
        // debugTools,
        workflow,
        fileStorage
    } = props;
    if (loading) return <Preloader />;
 //     todo title={title}
    return (
        <Fragment>
            <Header
                workflow={workflow}
                timeline={workflow.timeline || []}
            />
            <FileDataTable
                data={workflow.files}
                fileStorage={fileStorage}
                actions={actions}
            />
        </Fragment>
    );
}

WorkflowLayout.propTypes = {
    location: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    debugTools: PropTypes.object,
    workflow: PropTypes.object,
    fileStorage: PropTypes.object
};

WorkflowLayout.defaultProps = {
    loading: false,
    debugTools: {},
    workflow: null,
    fileStorage: {}
};

export default WorkflowLayout;
