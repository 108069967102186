import React from 'react';
import { IModuleAccess, IModuleInfo } from '@eis/types';
import Dashboard from './pages/Dashboard'; 
import { Logo } from './menu/logo';
import DashboardIcon from '@material-ui/icons/Dashboard'; 
import { PagesIDEnum } from '@app/application/menu/consts';
import config from 'config/index';

const analyticsUnits: IModuleAccess = { userHasUnit: config.analyticsUnits || []} 

const module: IModuleInfo = {
    navigation: [{
        id: PagesIDEnum.Logo,
        priority: 70,
        Component: Logo
    },
    {
        id: PagesIDEnum.Dashboard,
        priority: 65,
        icon: <DashboardIcon />,
        path: '/dashboard',
        access: analyticsUnits
    }
    ],

    routes: [{
        path: '/dashboard',
        component: Dashboard,
        access: analyticsUnits
    }
    ]
};

export default module;
