import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography, Button } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Registry } from '@eis/registry';
import { AppRootState } from '@app/store';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IStatus, IRequest, ITest } from '../../types';
import { useStyles } from '../../styles';
import { RequestTable } from './RequestTable';

interface RequestItem {
    item: IObjectInfo,
    statuses: Array<IStatus>,
    accessObj: Record<number, ITest>
}

interface ILastStatus {
    code: string,
    status: string
}

interface IObjectInfo {
    processInfo: Registry.dc_process,
    requestsList: Array<IRequest>,
    lastRequestStatus?: ILastStatus
}

export const ProcessItem: React.FC<RequestItem> = props => {
    const { item: { processInfo, requestsList }, statuses, accessObj} = props;
    const classes = useStyles();
    const history = useHistory();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const { templates } = useSelector((state: AppRootState) => {
        return {
            templates: state.workflowTemplate.list
        }
    });

    const handleChange =
        (panel: string) => (event: React.InputHTMLAttributes<HTMLInputElement>, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
    };

    const handleClickRequest =
      (processId: number) => (event:  React.MouseEvent<HTMLElement>) => {
        const currTask = templates.filter((item: any) => {
            return item.id === processId
        });
        history.push(`/tasks/create/${processId}/${currTask[0].entryTaskTemplateIds[0].id}`)
    };

    return (
        <Accordion
            key={processInfo.id}
            expanded={expanded === `panel${processInfo.data.menuOrder}`}
            onChange={handleChange(`panel${processInfo.data.menuOrder}`)}
            disabled={!accessObj[processInfo.data.menuOrder].isVisible}
        >
            <AccordionSummary
                expandIcon={<KeyboardArrowDown />}
                aria-controls="panel1a-content"
                id={`panel${processInfo.data.menuOrder}a-header`}
                className={classes.accordionHeader}
            >
                <Typography>
                    {`${processInfo.data.menuOrder}) ${processInfo.data.name}`}
                </Typography>
                { accessObj[processInfo.data.menuOrder].isApply && 
                  accessObj[processInfo.data.menuOrder].isVisible && 
                    <Button 
                        className={classes.accordionButton} 
                        variant="contained" 
                        color="primary"
                        onClick={handleClickRequest(processInfo.data.process)}
                    >
                        Подати
                    </Button>
                }
            </AccordionSummary>
            <AccordionDetails className={classes.accordionInfo}>
                <Typography>
                    {processInfo.data.description}
                </Typography>
                { !!requestsList.length &&
                    <RequestTable requestsList={requestsList} statuses={statuses} />
                }
            </AccordionDetails>
        </Accordion>
    );
};