import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestRegisterKeyRecords, requestRegisterKeyRecord } from "@core/actions/registry";
import { RegistryKeysEnum } from '@eis/consts';
import { equalUID } from '@eis/utils';


interface StoreWithRegistries {
    registry: {
        keyRecords: Record<string, Array<any>>
        records: Record<string, any>
    }
}

/**
 * Hook for get records from state repositories
 * @param key Repository key id
 * @param needLoad Specify to TRUE if need load data 
 */
export function useRegistry<T>(key: RegistryKeysEnum, needLoad = false): Array<T> {

    const records = useSelector((state: StoreWithRegistries) => {
        const records = state.registry.keyRecords[key];
        return records ? records as Array<T> : undefined;
    });

    const dispatch = useDispatch();
    useEffect(() => {
        const load = async () => {
            await requestRegisterKeyRecords(key, { strict: false, limit: 30000 })(dispatch);
        }
        if (records === undefined && needLoad) load();
        // eslint-disable-next-line
    }, []);//key, records, needLoad, dispatch]);

    return records || [];
}

/**
 * Hook for get record from app state if exists.
 * @param key {string} Id of Registry key
 * @param recordId Record id
 */
function useRegistryRecordPure<T extends { id: string }>(key: RegistryKeysEnum, recordId: string): T | undefined {
    let result: T | undefined = undefined;
    const { keyRecords, records } = useSelector((state: StoreWithRegistries) => state.registry);
    if (keyRecords[key] !== undefined) {
        result = (keyRecords[key] as Array<T>).find(r => equalUID(r.id, recordId));
    }
    return result || (records[recordId] ? records[recordId] : result);
}

/**
 * Hook for get record from app state or load if not exists. 
 * "Undefined" value specify if record not loaded, "Null" if value not exists
 * @param key Id of Registry key
 * @param recordId Record id
 */
export function useRegistryRecord<T extends { id: string }>(key: RegistryKeysEnum, recordId: string): T | undefined | null {     
    const [record, setRecord] = useState<T | undefined | null>(useRegistryRecordPure<T>(key, recordId)); 

    const dispatch = useDispatch();
    useEffect(() => {
        const load = async () => {
            const res = await requestRegisterKeyRecord(key, recordId)(dispatch);
            if (((res as any) as T).id === recordId ) {
                setRecord((res as any) as T);
            } else {
                setRecord(null);
            }
        }
        if (record === undefined) load();
        // eslint-disable-next-line 
    }, []); // key, records, dispatch, recordId

    return record;
};

/**
 * Hook for get record from app state or load if not exists. 
 * "Undefined" value specify if record not loaded, "Null" if value not exists
 * @param key FF
 * @param find 
 */
export function useRegistryRecordFilter<T extends { id: string }>(key: RegistryKeysEnum, filter: Record<string, string>): Array<T> | undefined | null { 
    const [ records, setRecords ] = useState<Array<T> | undefined | null>(undefined);
    
    const dispatch = useDispatch();
    useEffect(() => {
        const load = async () => {
            const res = await requestRegisterKeyRecords(key, filter)(dispatch);
            // console.log('RES', res);
            if (res && Array.isArray(res)) {
                setRecords((res as any) as Array<T>);
            } else {
                setRecords(null);
            }
        }
        if (records === undefined && filter !== undefined) load();
        // eslint-disable-next-line 
    }, []); // key, records, dispatch, recordId

    return records;
}


/**
 * Hook for set own method as event listener
 * @param event Event name like "blur"
 * @param listener Method
 * @param options See https://developer.mozilla.org/ru/docs/Web/API/EventTarget/addEventListener
 */
export function useWindowEvent<T extends keyof WindowEventMap>(
    event: T,
    listener: (this: Window, ev: WindowEventMap[T]) => any,
    options: boolean | AddEventListenerOptions = false
) {
    useEffect(() => {
        // initiate the event handler
        window.addEventListener(event, listener, options);

        return function cleanup() {
            window.removeEventListener(event, listener);
        };
        // eslint-disable-next-line
    }, []);
}


