import React, { useState } from 'react';
import Ajv from 'ajv';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-twilight';

import {
    // withStyles,
    Dialog,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';


interface JSONEditorProps {
    classes: Record<string, string>;
    closeModal: any;
    data: Record<string, any>; 
    idRecord: string;
    schema: Record<string, any>;
    translateMsg: Record<string, string>;
    onSave: (data: Record<string, any>) => void;
}

export const JSONEditor: React.FC<JSONEditorProps> = props => {
    const { classes, closeModal, data, idRecord, schema, translateMsg, onSave } = props;

    const [modify, setModify] = useState(false);
    const [json, setJson] = useState(JSON.stringify(data, null, 4));

    const ajv = new Ajv();
    const validate = ajv.compile({
        type: "object",
        additionalProperties: false,
        ...schema
    });

    const handleChangeFunc = (newValue: string) => {
        setModify(true);
        setJson(newValue);
    }

    const handleValidate = (annotations: any) => {
        // Редактор возвращает список синтаксических ошибок, но они иногда не является ошибками
        // поэтому основаться на них нельзя
        let valid = true;
        try {
            const newData = JSON.parse(json);

            valid = !!validate(newData);
        } catch {
            valid = false;
        }

        // Кнопка 'Save' всегда активна после изменений, здесь мы его дизейблим
        if (!valid) {
            setModify(false);
        }
    }

    return (
        <Dialog
            open={true}
            fullScreen={true}
            fullWidth={true}
        >
            <Toolbar className={classes.editorHeader}>
                <Typography>ID: {idRecord}</Typography>
                <Box>
                    <Tooltip title={translateMsg.save}>
                        <span>
                            <IconButton color="primary" onClick={() => onSave(JSON.parse(json))} disabled={!modify}>
                                <CheckIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Tooltip title={translateMsg.close}>
                        <IconButton onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <AceEditor
                mode="json"
                theme="twilight"
                fontSize={16}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={json}
                width="100%"
                height="calc(100% - 18px)"
                readOnly={false}
                onChange={handleChangeFunc}
                onValidate={handleValidate}
                wrapEnabled={true}
                setOptions={
                    {
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 4
                    }
                }
            />
        </Dialog>
    )
}
