import { createTheme } from "@material-ui/core/styles";

const SideBarTheme = createTheme({
    palette: {
        type: 'dark',

    },
    overrides: {
        MuiList: {
            root: {
                color: 'white'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: '46px'
            }
        }
    }
})

export default SideBarTheme;