import React from 'react';
import PropTypes from 'prop-types';

import DueDate from './DueDate';
import HeaderInfo from './HeaderInfo';
import SignerList from './SignerList';
import TaskAssign from './TaskAssign';

const TaskDetails = ({ task, template, showSignerList }) => (
    <HeaderInfo task={task} template={template} >
        <DueDate task={task} />
        {showSignerList ? <SignerList task={task} /> : null}
        <TaskAssign task={task} />
    </HeaderInfo>
);

TaskDetails.propTypes = {
    task: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
    showSignerList: PropTypes.bool.isRequired
};

export default TaskDetails;
