// import { UisBaseUnit, UisStaffUnit, UnitInfotech } from '@cab/modules/common/pages/dashboard-settings';
import { IModuleInfo, IRouteItemInfo, IAppBarItemInfo, INavigatorItemInfo } from '@eis/types';

// const prioritySort = (a: INavigatorItemInfo , b: INavigatorItemInfo): number => {
//     const aPriority = a.priority || 0;
//     const bPriority = b.priority || 0;

//     return (aPriority > bPriority) ? -1 : (aPriority < bPriority) ? 1 : 0;
// };

/**
 * Return all pages routers
 * @param modules 
 */
export const getRoutes = (modules: Array<IModuleInfo>) => 
    (new Array<IRouteItemInfo>()).concat(...modules.map(module => module.routes || []))
        .sort(({ redirect: a }, { redirect: b }) => ((a === b) ? 0 : a ? 1 : -1));

/**
 * Return all widgets for app bar
 * @param modules 
 */
export const getAppbar = (modules: Array<IModuleInfo>) => 
    (new Array<IAppBarItemInfo>()).concat(...modules.map(module => module.appbar || []))
        .filter(item => item.component !== undefined);


export const getNavigation = (modules: Array<IModuleInfo>) => 
    (new Array<INavigatorItemInfo>()).concat(...modules.map(module => module.navigation || []));

// export const getAccess = () => access;
// export const getRoutes = () => [].concat(...modules.map(module => module.routes || [])).sort(({ redirect: a }, { redirect: b }) => ((a === b) ? 0 : a ? 1 : -1));
// export const getReducers = () => reducers;
// export const getInitActions = () => initActions || {};


/**
 * Сравнивает два идентификатора в формате f4178ba0-4423-11e... или F4178BA0442311EB... 
 * Возвращает TRUE если значения равны
 */
export const equalUID = (uid1: string, uid2?: string): boolean => {
    return Boolean(uid2) 
        ? uid1.replaceAll('-', '').toLowerCase() === uid2!.replaceAll('-', '').toLowerCase()
        : false
}

/** 
 * Проверяет является ли пользователь только c ролью подразделения ЕИС
 */
// export function isUisStaffOnly(userUnits: any) {
//     let uCount = 0;
//     let hasStaff = false;
//     for (const u of userUnits) {
//         hasStaff = hasStaff || u.id === UisStaffUnit || u.id === UnitInfotech ;
//         if (u.id !== UisBaseUnit) uCount++;
//     }
//     return (uCount === 1) && hasStaff;
// }