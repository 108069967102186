import * as AppAction from '../actions/app';
import storage from 'helpers/storage';

const LARGE_SCREEN_WIDTH = 600;
const APP_STATE_KEY = 'appState';

interface IAppStore {
    loading?: boolean,
    openSidebar: boolean,
    openDrawer: boolean,
    expandMenu: Record<string, boolean>,
    /**
     * Дата последнего чтения новостей
     */
    newsDateRead: number
}

const initialState: IAppStore = {
    openSidebar: window.innerWidth > LARGE_SCREEN_WIDTH,
    openDrawer: false,
    newsDateRead: Date.now() - (1000 * 60 * 60 * 24 * 7), // 7 дней актуальность новостей
    expandMenu: {}
};
const getInitialState = (): IAppStore => {
    try {
        // Востановливаем открытых/закрытых пунктов меню
        const data = storage.getItem(APP_STATE_KEY);
        return {
            ...initialState,
            ...(data ? JSON.parse(data) : {}) as IAppStore
        }
    } catch (err) {
        return initialState;
    }
}

const rootReducer = (state = getInitialState(), action: AppAction.AppActionTypes): IAppStore => {
    let result: IAppStore = state;
    switch (action.type) {
        case AppAction.SET_OPEN_SIDEBAR:
            result = { ...state, openSidebar: action.payload };
            break;
        case AppAction.SET_OPEN_DRAWER:
            result = { ...state, openDrawer: action.payload };
            break;
        case AppAction.TOGGLE_EXPAND_MENU:
            const { expandMenu, ...other } = state;
            const name = action.payload as string;
            result = { ...other, expandMenu: { ...expandMenu, [name]: !expandMenu[name] } };
            break;
        case AppAction.SET_APP_LOADING:
            result = { ...state, loading: action.payload }
            break;
        default:
            return state;
    }

    // Сохраняем состояние для востановления после перезагрузки страницы
    // открытых/закрытых пунктов меню и т.п. 
    const { loading, ...toSave } = result;
    storage.setItem(APP_STATE_KEY, JSON.stringify(toSave));
    return result;
};

export default rootReducer;
