import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    htmlWrap: {
        flex: 2
    },
    htmlBox: {
        height: '100%'
    }
});

interface IFramePageProps {
    url: string
}

/**
 * Отображение внешней страницы/сайта в <iframe>
 */
export const IFramePage: React.FC<IFramePageProps> = props => {
    const classes = useStyles();
    return (
        <div className={classes.htmlWrap}>
            <div className={classes.htmlBox}>
                    <iframe
                        title='CRM'
                        width='100%'
                        height='100%'
                        frameBorder={0}
                        src={props.url}
                    />
                </div>
        </div>
    );
}