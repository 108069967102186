import React from 'react';
import classNames from 'classnames';

import {
  TableCell,
  TableRow,
  Checkbox,
  withStyles,
  createStyles
} from '@material-ui/core';

import { IColumn } from './types';

const styles = (theme: any) => createStyles({
  selected: {
    backgroundColor: `${theme.dataTableHoverBg}!important`
  },
  hover: {
    '&:hover': {
      '& td': {
        color: `${theme.dataTableHoverColor}!important`
      }
    }
  },
  clickable: {
    cursor: 'pointer'
  },
  tableCell: {
    TableCell: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        fontSize: 13,
        lineHeight: '18px'
      }
    }
  },
  hightlight: {
    backgroundColor: '#FFFCE5'
  }
});

interface IDataTableRow {
  classes: any;
  selected: boolean;
  hightlight: boolean;
  selectable: boolean;
  checkable: boolean;
  columns: Array<IColumn>;
  rowIndex: number;
  item: any;
  hiddenColumns: Array<String>;
  cellStyle: any;
  onClick: () => void;
  onSelect: () => void;
}

const defaultProps: Partial<IDataTableRow> = {
  selected: false,
  hightlight: false,
  selectable: false,
  checkable: false,
  columns: []
}

const DataTableRow: React.FC<IDataTableRow> = ({
  classes,
  rowIndex,
  item,
  selected,
  hightlight,
  selectable,
  checkable,
  columns,
  hiddenColumns, 
  cellStyle,
  onClick,
  onSelect
}) => (
  <TableRow
    hover={true}
    selected={selected}
    classes={{
      hover: classes.hover,
      selected: classes.selected
    }}
    className={classNames({
      [classes.clickable]: !!onClick,
      [classes.hightlight]: hightlight
    })}
  >
    {checkable ? (
      <TableCell style={cellStyle} padding="checkbox" width={64}>
        <Checkbox
          checked={selected}
          disabled={!selectable}
          onChange={onSelect}
          color="primary"
        />
      </TableCell>
    ) : null}
    {columns
      .filter((column: IColumn) => !(hiddenColumns || []).includes(column.id))
      .map(({ id, render, handleClick, disableClick, sortable, ...rest }, columnKey: number) => (
        <TableCell
          style={cellStyle}
          className={classes.tableCell}
          {...rest}
          key={columnKey}
          onClick={disableClick ? null : (handleClick ? handleClick(item).bind(this) : onClick)}
        >
          {render ? render(item[id], item, columnKey, rowIndex) : item[id]}
        </TableCell>
      ))}
  </TableRow>
);

DataTableRow.defaultProps = defaultProps;

export default withStyles(styles)(DataTableRow);
