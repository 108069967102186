/**
 * Данные о том, какие процессы отвечают за какие задачи находятся в ключе 67 (реестр 14) 
 */

/**
 * Links for start process
 */
export enum CreateTasksEnum {
    // Створення учасника ЄІС МВС    
    memberUisCreate = 'member_EISMVS_create',
    // Реєстрація інформаційного ресурсу учасника ЄІС МВС	
    informResourceCreate = 'inform_resource_EISMVS_registry',
    // Реєстрація сервісу інформаційного ресурсу учасника ЄІС МВС
    serviceCreate = 'service_EISMVS_registry',
    // Реєстрація методів сервісу (підключення) інформаційного ресурсу учасника ЄІС МВС: Інтерфейс взаємодії: АРІ (система-система)
    methodCreate = 'method_EISMVS_registry',
    // Отримання доступу до веб-додатку сервісу інформаційного ресурсу ЄІС МВС
    webGetAccess = 'get_access_Web_EIS_MVS',
    // Реєстрація облікового запису в 02 ІС ЦП ЄІС МВС
    serviceGetAccess = 'get_access_method_WSO2_EIS_MVS',
    //
    userAdd = 'userAdd',
}

/**
 * Init values for process
 */
export const DefaultCreateTasksId: Record<CreateTasksEnum, number> = {
    [CreateTasksEnum.methodCreate]: 70085, 	
    [CreateTasksEnum.serviceCreate]: 70084, 	
    [CreateTasksEnum.informResourceCreate]: 70083, 
    [CreateTasksEnum.memberUisCreate]: 700082,
    [CreateTasksEnum.webGetAccess]: 70088,
    [CreateTasksEnum.serviceGetAccess]: 70087,
    [CreateTasksEnum.userAdd]: 70222,

}
