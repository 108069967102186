import translation from '@core/translation/uk-UA';

const {
    App,
    Navigator,
    DebugTools,
    RegistryPage,
    UserProfile,
    UserListPage,
    WorkflowListPage,
    Elements,
    TaskListPage
} = translation;

export default {
    ...translation,
    Navigator: {
        ...Navigator,
        AddNewTask: 'Подати нову заявку',
        Workflow: 'Заявки',
        MyWorkflow: 'Активні',
        WorkflowTrash: 'Архів',
        WorkflowDrafts: 'Чернетки',
        Connection: 'Сервіси',
        MyConnection: 'Надані',
        UnitConnection: 'Отримані',
        ConnectionList: 'Каталог',
        InfoPage: 'Підтримка',
        CRM: 'СЕД ЄІС МВС',
        Registry: 'Довідники',
        News: 'Новини',
        GeneratorLevel: 'Постачальник',
        RecipientLevel: 'Отримувач',
        AllServices: 'Каталог сервісів',
        AllResources: 'Каталог ресурсів',
        Providers: 'Постачальники',
        Services: 'Сервіси',
        Resources: 'Ресурси',
        Methods: 'Методи',
        CurrentTasks: 'Поточні',
        TrashTasks: 'Архів',
        Recipients: 'Отримувачі',
        Projects: 'Проекти',
        Dashboard: 'Інфо-панель',
        Home: 'Подати заявку',
    },
    InfoPage: {
        title: 'Підтримка'
    },
    Projects: {
        title: 'Список проектів',
        ProjectsListClear: 'Перелік проектів порожній',
        ProjectsNotFound: 'Не знайдено жодного проекту',
        ProjectsCount: 'Кількість',
        NotFound: 'Проект за таким ідентифікатором не знайдено',
        NoFiles: 'Прикріплених файлів немає',
        previewButton: 'Переглянути ескіз',
        signButton: 'Переглянути підписанта документу',
        downloadButton: 'Завантажити документ',   
        InfoPart: 'Загальна інформація',
        DocsPart: 'Документи',
        RequestPart: 'Заявки',
        InventoryPart: 'Анкета',
        RootTitle: 'Документу до проекту {{project}}',
        OtherDocs: 'Інші документи',
        rowsPerPage: 'На сторінці',
        searchPlaceholder: 'Пошук...',
        viewModeHint: 'Перегляд даних деревом або звичайним переліком',
        requestNumber: 'Номер заявки',
        requestProcessNumber: 'Номер процесу',
        requestDate: 'Дата отримання',
        requestStatus: 'Статус',
        requestEmpty: 'не вказано'
    },
    ConnectionListPage: {
        Units: 'Підрозділи',
        MyServices: 'Опубліковані підключення',
        SubscribedServices: 'Отримані підключення',
        ConnectionList: 'Каталог підключень',
        Methods: 'Методи',
        ConnectionUsage: 'Використання',
        BackToUnits: 'Назад до підрозділів',
        BackToMethods: 'Назад до методів',
        BackToConnections: 'Назад до підключень',
        BackToUsers: 'Назад до користувачів',
        BlockUser:  'Заблокувати користувача',
        Usages: 'Всього використань: 15',
        Name: 'Повна назва',
        Subject: 'Організація',
        ShortName: 'Скорочена назва',
        Login: 'Ідентифікатор/логін',
        SecretKey: 'Секретний ключ',
        MethodName: 'Назва методу',
        Type: 'Тип',
        Url: 'URL',
        FullUrl: 'Повний URL',
        HelpUrl: 'URL help',
        Status: 'Статус',
        ConnectionName: 'Назва підключення',
        SchemaUrl: 'URL схеми',
        OwnerUnit: 'Підрозділ власника',
        CustomerUnit: 'Підрозділ користувача',
        IpAddress: 'IP-адреса',
        Date: 'Дата',
        Time: 'Час',
        InboxTitle: 'Замовлені послуги',
        ProjectTitle: 'Подані проекти',
        Drafts: 'Чернетки',
        WorkflowNumber: 'Номер заяви',
        WorkflowName: 'Послуга',
        ProjectName: 'Проект',
        LastStepLabel: 'Статус',
        DraftStatus: 'Чернетка',
        TrashPage: 'Кошик',
        Active: 'Активний',
        Rejected: 'Заблоковано',
        NoStatus: 'Статус не задано',
        MyServicesMethods: 'Методи опублікованих підключень',
        SubscribedServicesMethods: 'Методи отриманих підключень',
        MyConnectionMethods: 'Методи підключень каталогу',
        undefined: 'Не задано',
        MyUsage: 'Використання опублікованих підключень',
        SubscribedUsage: 'Використання отриманих підключень',
        Usage: 'Використання підключень'
    },
    CRM: {
        title: 'Система електронного документообігу ЄІС МВС',
        UnAuthorizeWarning: 'На жаль, до системи документообігу мають доступ лише адміністратори учасників ЄІС МВС.'
    },
    Dashboard: {
        title: 'Інформаційна панель',
        members_title: 'Учасники ЄІС МВС',
        members_subject: 'Загальна кількість учасників',
        resources_title: 'Інформаційні ресурси',
        resources_subject: 'Загальна кількість інформаційних ресурсів, опублікованих в ЄІС МВС',
        services_title: 'Інформаційні ресурси, опубліковані в ЄІС МВС',
        services_subject: 'Загальна кількість сервісів, опублікованих в ЄІС МВС',
        traffic_title: 'Кількість потоків',
        traffic_subject: 'Кількість інформаційних потоків (підключень) між всіма учасниками',
        connections_title: 'Загальна кількість інформаційних потоків в ЄІС МВС',
        connections_subject: 'Загальна кількість інформаційних потоків в ЄІС МВС',
        connections_own_title: 'Інформаційні потоки постачальника',
        connections_own_subject: 'Кількість активних / загальна кількість наданих вами сервісів',
        connections_recipient_title: 'Інформаційні потоки отримувача',
        connections_recipient_subject: 'Кількість сервісів, до яких отримано дозвіл',
        traffic_own_title: 'Кількість запитів, тис',
        traffic_own_subject: 'Кількість запитів до ваших ресурсів за попередню добу',
        messages_title: 'Вхідні повідомлення',
        messages_subject: 'Ваші вхідні повідомлення, стосовно заявок або отриманих задач',
        View: 'Огляд',
        Add: 'Розпочати процес',
        global_part_title: 'Загальний рівень',
        provider_part_title: 'Рівень постачальника',
        recipient_part_title: 'Рівень отримувача',
        top_services_title: 'Рейтинг сервісів',
        top_services_subject: 'Сервіси, які користуються найбільшим попитом',
        my_recipient_title: 'Учасники ЄІС МВС',
        my_recipient_subject: 'Загальна кількість учасників, яким надано доступ',
        my_beneficiary_title: 'Учасники ЄІС МВС',
        my_beneficiary_subject: 'Загальна кількість учасників, які надали доступ',
        my_recipient_resources_title: 'Інформаційні ресурси',
        my_recipient_resources_subject: 'Загальна кількість зареєстрованих інформаційних ресурсів',
        my_beneficiary_resources_title: 'Інформаційні ресурси',
        my_beneficiary_resources_subject: 'Загальна кількість інформаційних ресурсів, до яких отримано доступ',
        services_own_title: 'Сервіси інформаційних ресурсів постачальника',
        services_own_subject: 'Загальна кількість сервісів, до яких надано доступ',
        services_recipient_title: 'Сервіси інформаційних ресурсів, доступні отримувачу',
        services_recipient_subject: 'Загальна кількість сервісів, до яких отримано доступ',
        MetricDetail: 'Деталі',
        Metrics: 'Метрики',
        UnAuthorizeWarning: 'Увага! Відсутні права доступу. За ключем, з яким виконано вхід до системи, неможливо встановити обліковий запис.'
    },
    DebugTools: {
        ...DebugTools,
        LocalStorage: 'Local Storage'
    },
    Subjects: {
        title: 'Реєстр учасників ЄІС МВС',
        Joined: 'Приєднано',
        Search: 'Пошук',
        Search_help: 'Пошук виконується за такими полями: назва, скорочена назва, ЄДРПОУ',
        SubjectTitle: 'Учасник',
        ResourcesList: 'Ресурси учасника',
        NotFound: 'Учасника не знайдено або помилка в ідентифікаторі'
    },
    NewsPage: {
        title: 'Новини',
        added: 'опубліковано',
        Author: 'Автор',
        NoNews: 'На жаль, зараз новин немає.'
    },
    RegistryPage: {
        ...RegistryPage,
        title: 'Довідники',
        View: 'Перегляд',
        EditAsJson: 'Редагувати як JSON',
        UseFilters: 'Використовувати фільтри'
    },
    UserProfile: {
        ...UserProfile,
        title: 'Профіль'
    },
    UserListPage: {
        ...UserListPage,
        title: 'Користувачі'
    },
    WorkflowListPage: {
        ...WorkflowListPage,
        titleActive: 'Активні заявки',
        titleDraft: 'Чернетки заявок',
        titleArchive: 'Архівні заявки',
        EmptyTrashListTitle: 'Архів порожній',
        EmptyTrashListDescription: 'Тут будуть відображатись видалені заявки',
        InboxTitle: 'Активні заявки',
        EmptyListTitle: 'Ви не подали жодної заявки',
        EmptyListDescription: 'Тут будуть відображатись заявки, що подані',
    },
    Resources: {
        title: 'Інформаційні ресурси',
        titleSelected: 'Ресурс',
        MyResourcesTitle: 'Мої Інформаційні ресурси',
        Search: 'Пошук',
        Search_help: 'Пошук виконується за такими полями: назва, скорочена назва',
        FormFunctioning: 'Форма функціювання',
        GetAccessToService: 'Отримати доступу до сервісу',
        NotFound: 'Ресурс не знайдено або помилка в ідентифікаторі',
        status_info: 'інформації',
        status_system: 'системи'
    },
    App: {
        ...App,
        RestartPage: 'Оновити сторінку щоб отримати останні зміни кабінету'
    },    
    Analytics: {
        title: 'Метрики',
        NoDefined: 'Сторінка з метриками не здана в конфігурації.'
    },
    Elements: {
        ...Elements,
        Required: 'Обов\'язкове для заповнення',
        Download: 'Завантажити',
        View: 'Переглянути',
        ErrorGenItems: 'Помилка генерації пунктів',
        NotSet: '<не вказано>'
    },
    Home: {
        title: 'Подати заявку'
    },
    TaskListPage: {
        ...TaskListPage,
        UnitInboxTasksTitle: 'Поточні задачі',
        UnitClosedTasksTitle: 'Закриті задачі'
    }    
};
