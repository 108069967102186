import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import { withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { setTaskDueDate } from '@cab/actions/task';
import { default as Content } from 'layouts/components/Content';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import checkAccess from 'helpers/checkAccess';

const styles = {
    icon: {
        position: 'absolute',
        top: '50%',
        left: -11,
        marginTop: -20
    },
    input: {
        marginLeft: 30
    },
    relative: {
        position: 'relative'
    }
};

const DueDate = ({
    t,
    task,
    actions,
    userInfo,
    userUnits,
    task: { dueDate, performerUnits, finished },
    classes
}) => {
    const isUserUnitHead = checkAccess({ isUserUnitHead: performerUnits }, userInfo, userUnits);

    if (!isUserUnitHead) return null;

    return (
        <Content small={true}>
            <div className={classes.relative}>
                {
                    dueDate && !finished ? (
                        <IconButton
                            className={classes.icon}
                            onClick={() => actions.setTaskDueDate && actions.setTaskDueDate(task.id, null)}
                        >
                            <ClearIcon />
                        </IconButton>
                    ) : null
                }
                <KeyboardDatePicker
                    className={dueDate ? classes.input : null}
                    fullWidth={!dueDate}
                    label={t('DueDate')}
                    format={'DD.MM.YYYY HH:mm'}
                    placeholder={dueDate && moment(dueDate).format('DD.MM.YYYY HH:mm')}
                    cancelLabel={t('Cancel')}
                    minDateMessage={t('MinDateMessage')}
                    invalidDateMessage={t('InvalidDateMessage')}
                    onChange={
                        value => {
                            if (!value || !value.isValid()) {
                                if (value === null) actions.setTaskDueDate && actions.setTaskDueDate(task.id, value);
                                return;
                            }
                            actions.setTaskDueDate && actions.setTaskDueDate(task.id, value.toISOString());
                        }
                    }
                    keyboard={true}
                    autoOk={true}
                    minDate={moment()}
                    value={dueDate && moment(dueDate)}
                    disabled={finished}
                />
            </div>
        </Content>
    );
};

DueDate.propTypes = {
    task: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

const mapState = ({
    auth: { userUnits, info }
}) => ({
    userUnits,
    userInfo: info
});

const mapDispatchToProps = dispatch => ({
    actions: {
        setTaskDueDate: bindActionCreators(setTaskDueDate, dispatch)
    }
});

const translated = translate('TaskPage')(DueDate);
const styled = withStyles(styles)(translated);
export default connect(mapState, mapDispatchToProps)(styled);
